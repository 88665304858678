import { memo } from 'react';
import { SubSection as ISubSection } from '../../../interfaces/Section';
import SubSection from './SubSection';

interface SubSectionListProps {
  subSections: ISubSection[];
}

const SubSectionList = memo(({ subSections }: SubSectionListProps) => (
  <>
    {subSections!.map((subSection, index: number) => (
      <SubSection key={subSection.id} index={index} subSection={subSection} />
    ))}
  </>
));

export default SubSectionList;
