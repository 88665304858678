import { gql } from '@apollo/client';

const getDiscountQuery = gql`
  query GetDiscountQuery($id: ID!) {
    discount(id: $id) {
      id
      title
      enabled
      code
      recurrence
      type
      price
      valueType
      createdAt
      finishDate
      tag {
        name
      }
    }
  }
`;

export default getDiscountQuery;
