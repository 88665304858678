import { Stack, Box, Typography, Select, MenuItem } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons/';
import { useContext, useMemo } from 'react';
import ScheduleCard from '../../../../../../../components/ScheduleCard';
import { getPageTypeFriendlyName, PageType } from '../../../../../../../interfaces/Plan';
import BuilderContext from '../../../../context';
import { Group as GroupProps } from '../../../Sidebar/settings/main/typings';
import SettingsAccordion from './SettingsAccordion';
import { SmallTitlesStyles } from './styles';

const PageOptions = ({ expanded, onExpand, }: GroupProps) => {
  const { form } = useContext(BuilderContext);
  const pageTypeList = useMemo(() => (
    Object.values(PageType).map((type) => (
      <MenuItem key={type} value={type}>
        {getPageTypeFriendlyName(type)}
      </MenuItem>
    ))), []);
  return (
    <SettingsAccordion
      expanded={expanded}
      onExpand={onExpand}
      title="Opções de página"
    >
      <Stack flexDirection="row" gap={4} justifyContent="space-between">
        <Box sx={{ height: 160, width: 214 }}>
          <Typography sx={{ ...SmallTitlesStyles, my: 1 }}>
            Tipo de página{' '}
            <Typography
              component="span"
              sx={{ color: 'error.main', fontSize: 14 }}
            >
              *
            </Typography>
          </Typography>
          <Select
            IconComponent={ExpandMoreIcon}
            name="type"
            sx={{
              backgroundColor: 'grey.500',
              border: 1,
              borderColor: 'grey.400',
              color: 'grey.300',
              width: '100%',
              '.MuiSelect-icon': { color: 'secondary.main' },
            }}
            value={form.values.type}
            onChange={form.handleChange}
          >
            {pageTypeList}
          </Select>
        </Box>
        <ScheduleCard
          label="Agendar entrada?"
          name="scheduleDate"
          value={form.values.scheduleDate}
          SwitchProps={{
            checked: form.values.scheduleDateEnabled,
            name: 'scheduleDateEnabled',
            onChange: form.handleChange,
          }}
          onChange={form.handleChange}
        />
        <ScheduleCard
          label="Agendar saída?"
          name="finishDate"
          value={form.values.finishDate}
          SwitchProps={{
            checked: form.values.finishDateEnabled,
            name: 'finishDateEnabled',
            onChange: form.handleChange,
          }}
          // @ts-ignore
          DateTimePickerProps={{ minDate: form.values.scheduleDate }}
        />
      </Stack>
    </SettingsAccordion>
  );
};

export default PageOptions;
