import * as yup from 'yup';

const validationSchema = yup
  .object()
  .noUnknown()
  .shape({
    title: yup.string().required().max(160),
    description: yup.string().required(),
    type: yup.string().required(),
    thumbnail: yup.string().required(),
    authorizedOnMobile: yup.bool().required(),
    authorizedOnDesktop: yup.bool().required(),
    authorizedOnTv: yup.bool().required(),
    published: yup.bool().required(),
    scheduleDate: yup.mixed().when('scheduleDateEnabled', {
      is: true,
      then: yup.date().required('scheduleDateEnabled'),
    }),
    scheduleDateEnabled: yup.bool().required(),
    finishDate: yup.mixed().when('finishDateEnabled', {
      is: true,
      then: yup.date().required('finishDateEnabled'),
    }),
    finishDateEnabled: yup.bool().required(),
    externalContent: yup.bool().required(),
    externalEmbed: yup.string().required('Campo obrigatório'),
    externalUrl: yup
      .string()
      .url('Formato inválido.')
      .required('Campo obrigatório'),
    tags: yup.array().of(
      yup
        .object()
        .shape({ id: yup.string().required() })
        .transform(({ id }) => ({ id })),
    ),
    chatEnable: yup.bool(),
    chatEmbed: yup.mixed().when('chatEnable', {
      is: true,
      then: yup.string().required('Campo obrigatório'),
    }),
  });

export default validationSchema;
