import { Theme } from '@material-ui/core';
import { Components } from '@material-ui/core/styles/components';

const makeMuiRadio = (theme: Theme): Components['MuiRadio'] => ({
  styleOverrides: {
    root: {
      color: 'info.main',
      '& input + span': {
        backgroundColor: theme.palette.grey[500],
        borderRadius: '50%',
        display: 'flex',
        height: '16px',
        width: '16px',
        '& svg': {
          position: 'absolute',
          top: '0',
          transform: 'scale(0.8) translate(-5px, -5px)',
          transformOrigin: 'center center',
        },
        '& svg:first-of-type path': {
          display: 'none',
        },
        '& svg:last-of-type path': {
          color: 'transparent',
          transition: '0.1s',
        },
      },
      '&.Mui-checked': {
        '& svg:last-of-type path': {
          color: theme.palette.info.main,
        },
      }
    }
  }
});

export default makeMuiRadio;
