export const preventFloatingNumbers = (
  e: React.KeyboardEvent,
  characters = ['E', 'e', '-', '+', '.'],
) => {
  const hasNoUnwantedKeys = characters.indexOf(e.key) === -1;
  if (hasNoUnwantedKeys) {
    return;
  }
  e.preventDefault();
};

export const normalizePrice = (price: number) => price.toFixed(2);

export const getFloatPrice = (price: string) => {
  const digitsOnPrice = price.replace(/[^0-9]/g, '');
  const floatPrice = (Number(digitsOnPrice) / 100).toString();
  return floatPrice;
}

export default preventFloatingNumbers;
