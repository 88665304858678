import {
  Checkbox,
  FormControlLabel,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { ChangeEvent } from 'react';
import { Permission, PermissionType } from '../../../interfaces/Permission';
import {
  getPermissionFriendlyName,
} from '../utils/permissions';
import { ActionType } from '../typings';

interface PermissionItemProps {
  title: PermissionType;
  // eslint-disable-next-line react/require-default-props
  permission?: Permission | null;
  onChangePermission: (
    action: ActionType,
    title: PermissionType,
    valueAction: boolean,
  ) => void;
}

const PermissionItem = ({
  title,
  permission,
  onChangePermission,
}: PermissionItemProps) => {
  const changePermissionsHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    const action = evt.target.name as ActionType;
    const valueAction = evt.target.checked;
    onChangePermission(action, title, valueAction);
  };
  return (
    <ListItem
      secondaryAction={
        <>
          <FormControlLabel
            label="Criar"
            control={
              <Checkbox
                name="create"
                checked={permission?.create}
                onChange={changePermissionsHandler}
              />
            }
            sx={{ span: { color: 'grey.100', fontSize: 12 } }}
          />
          <FormControlLabel
            label="Editar"
            control={
              <Checkbox
                name="update"
                checked={permission?.update}
                onChange={changePermissionsHandler}
              />
            }
            sx={{ span: { color: 'grey.100', fontSize: 12 } }}
          />
          <FormControlLabel
            label="Excluir"
            control={
              <Checkbox
                name="delete"
                checked={permission?.delete}
                onChange={changePermissionsHandler}
              />
            }
            sx={{ span: { color: 'grey.100', fontSize: 12 } }}
          />
          <FormControlLabel
            label="Visualizar"
            control={
              <Checkbox
                name="read"
                checked={permission?.read}
                onChange={changePermissionsHandler}
              />
            }
            sx={{ span: { color: 'grey.100', fontSize: 12 } }}
          />
        </>
      }
      sx={{ p: 0 }}
    >
      <ListItemText
        primary={
          <Typography sx={{ alignItems: 'center', display: 'flex' }}>
            {getPermissionFriendlyName(title)}
          </Typography>
        }
        sx={{ color: 'grey.100', fontSize: 14, fontWeight: 600 }}
      />
    </ListItem>
  );
};

export default PermissionItem;
