import { useQuery } from '@apollo/client';
import { Pagination, Stack, Typography } from '@material-ui/core';
import { ChangeEvent, useContext, useState } from 'react';
import Context from '../Context';
import teamsQuery from '../gql/teamsQuery';
import { SortByType } from '../typings';
import sortTeamBy from '../utils/sortTeamList';
import Head from './Head';
import TeamItem from './TeamItem';

const Container = () => {
  const { input, setInput } = useContext(Context);
  const [sortBy, setSortBy] = useState<SortByType>();
  const { loading, data, refetch } = useQuery(teamsQuery, {
    variables: { input },
  });
  const sortByHandler = (sortByType: SortByType) => setSortBy(sortByType);
  const paginationHandler = (_: ChangeEvent<unknown>, page: number) =>
    setInput({ ...input, paginate: { page } });
  if (loading) {
    return <div>Carregando...</div>;
  }
  const teams = data.teams.items;
  const { meta } = data.teams;
  const sortedTeams = sortBy
    ? [...teams]?.sort(sortTeamBy(sortBy))
    : [...teams];
  return (
    <>
      <Stack
        justifyContent="center"
        sx={{
          bgcolor: 'primary.main',
          borderRadius: 2,
          height: 45,
          mb: 2,
          mt: 6,
          px: 4,
        }}
      >
        <Stack alignItems="center" direction="row">
          <Typography
            sx={{
              color: 'grey.100',
              fontSize: 14,
              fontWeight: 600,
              width: 259,
            }}
          >
            Time do coração
          </Typography>
          <Head title="Sigla" prop="slug" onSortHead={sortByHandler} />
          <Head title="Tag" prop="name" onSortHead={sortByHandler} />
        </Stack>
      </Stack>
      {sortedTeams.map((team) => (
        <TeamItem team={team} refetch={refetch} />
      ))}
      <Pagination
        count={meta.totalPages}
        page={input.paginate.page}
        sx={{ mt: 4 }}
        onChange={paginationHandler}
      />
    </>
  );
};

export default Container;
