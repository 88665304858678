import { Button, Card, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useContext, useState } from 'react';
import { PlanType } from '../../../interfaces/Plan';
import Context from '../Context';
import { FilterAllPlans, PlanTypeToFinancialPlanReport } from '../typings';

const typings = {
  [PlanType.monthly]: 'Mensal',
  [PlanType.season]: 'Temporada',
  [PlanType.partner]: 'Parceiros',
  [PlanType.free]: 'Gratuito',
};

const getPlanTypeFriendlyName = (type: PlanType) => typings[type];

const Filter = () => {
  const [type, setType] = useState<PlanTypeToFinancialPlanReport>(FilterAllPlans.all);
  const { setPlanType } = useContext(Context);
  return (
    <Card sx={{ height: 110, mt: 4, p: 6, position: 'relative' }}>
      <InputLabel sx={{ color: 'grey.300', fontSize: 14, mb: 2 }}>
        Tipo de plano
      </InputLabel>
      <Select
        value={type}
        sx={{ minWidth: 156 }}
        onChange={(evt) => setType(evt.target.value as PlanTypeToFinancialPlanReport)}
      >
        <MenuItem value={FilterAllPlans.all}>Todos</MenuItem>
        {Object.values(PlanType).map((currentType) => (
          <MenuItem value={currentType}>
            {getPlanTypeFriendlyName(currentType)}
          </MenuItem>
        ))}
      </Select>
      <Button
        sx={{ bottom: 24, position: 'absolute', right: 24 }}
        onClick={() => setPlanType(type)}
      >
        Atualizar
      </Button>
    </Card>
  );
};

export default Filter;
