import { Button } from '@material-ui/core';
import { useAuthorize } from '@tshio/react-router-permissions';
import { useContext } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Topbar from '../../components/Topbar';
import TipAlert from '../../components/UI/molecules/TipAlert';
import HasNoPermission from '../../components/HasNoPermission';
import Context from '../../Context';
import Container from './components/Container';

const Plans = () => {
  const [params] = useSearchParams();
  const type = params.get('type');
  const { me } = useContext(Context);
  const permissionForEverything = me.superAdmin;
  const permissionToRead =
    permissionForEverything || useAuthorize(['plans', 'read']);
  const permissionToCreate =
    permissionForEverything || useAuthorize(['plans', 'create']);
  if (!permissionToRead) return <HasNoPermission />;
  return (
    <>
      <Topbar
        title="Meus planos"
        description="Crie os planos necessários para cada oferta de serviço."
      >
        {permissionToCreate && (
          <Link to={`/plan/create?type=${type}`}>
            <Button color="info" data-testid="button-new-plans">
              Novo plano
            </Button>
          </Link>
        )}
      </Topbar>
      <TipAlert sx={{ my: 4 }}>
        Selecione as páginas necessárias para cada etapa do ciclo de utilização
        do seu cliente.
      </TipAlert>
      <Container />
    </>
  );
};

export default Plans;
