import { useMutation } from '@apollo/client';
import {
  AccordionSummary,
  Button,
  Chip,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Edit as EditIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  MoreVert as MoreOptionsIcon,
} from '@material-ui/icons';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../../components/Dialog';
import { HeartTeam } from '../../../interfaces/HeartTeam';
import deleteTeamMutation from '../gql/deleteTeamMutation';

interface TeamItemSummaryProps {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  refetch: () => void;
  team: HeartTeam;
}

const TeamItemSummary = ({
  expanded,
  setExpanded,
  refetch,
  team,
}: TeamItemSummaryProps) => {
  const alert = useAlert();
  const dialog = useDialog();
  const navigate = useNavigate();
  const [deleteTeam] = useMutation(deleteTeamMutation);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;
  const openMenuHandler = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const closeMenuHandler = () => setAnchorEl(null);
  const removeTeamHandler = async () => {
    closeMenuHandler();
    const confirm = await dialog.confirm({
      title: 'Você tem certeza?',
      message:
        'Excluir um time ocasionará a perda das seções e conteúdos associados.',
    });
    if (confirm) {
      try {
        await deleteTeam({ variables: { id: team.id } });
        alert.success('Time excluído com sucesso.');
        refetch();
      } catch (err: any) {
        const userRelatedFavoriteTeam =
          err.graphQLErrors?.[0]?.message === 'userRelatedFavoriteTeam';
        if (userRelatedFavoriteTeam) {
          alert.error('Este time está vinculado a um usuário.');
          return;
        }
        alert.error('Ocorreu um erro ao excluir o time.');
      }
    }
  };
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeMenuHandler}
      >
        <MenuItem onClick={() => navigate(`/team/edit/${team.id}`)}>
          <ListItemIcon>
            <EditIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Editar time</Typography>
        </MenuItem>
        <MenuItem onClick={removeTeamHandler}>
          <ListItemIcon>
            <CloseIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Excluir time</Typography>
        </MenuItem>
      </Menu>
      <AccordionSummary
        expandIcon={
          <Stack alignItems="center" direction="row" spacing={2}>
            <Button
              size="large"
              endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              sx={{ bgcolor: 'grey.500', color: 'grey.200' }}
              onClick={() => setExpanded(!expanded)}
            >
              Visualizar
            </Button>
            <Button
              size="large"
              endIcon={<MoreOptionsIcon sx={{ fontSize: 24, mr: 3 }} />}
              sx={{
                bgcolor: 'grey.500',
                color: 'grey.200',
                width: 30,
              }}
              onClick={openMenuHandler}
            />
          </Stack>
        }
        sx={{
          borderRadius: 2,
          minHeight: 74,
          px: 4,
          '&.Mui-expanded': {
            borderColor: 'grey.500',
            borderStyle: 'solid',
            borderBottomWidth: 1,
          },
          // FIXME: check another alternative
          '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(0deg)',
            WebkitTransform: 'rotate(0deg)',
          },
        }}
      >
        <Stack alignItems="center" direction="row">
          <Typography
            sx={{
              color: 'grey.100',
              fontSize: 14,
              fontWeight: 600,
              width: 259,
            }}
          >
            {team.name}
          </Typography>
          <Typography
            sx={{
              color: 'grey.100',
              fontSize: 14,
              fontWeight: 600,
              ml: 10,
              p: 0,
              width: 150,
            }}
          >
            {team.slug}
          </Typography>
          <Chip
            size="small"
            label={team.name}
            sx={{ borderRadius: 1, bgcolor: 'grey.500', ml: 10, minWidth: 61 }}
          />
        </Stack>
      </AccordionSummary>
    </>
  );
};

export default TeamItemSummary;
