import { gql } from '@apollo/client';

const sectionsPaginateQuery = gql`
  query SectionsPaginateQuery($input: ListSectionsPaginateInput!) {
    sectionsPaginate(input: $input) {
      id
      title
      scheduleDate
      scheduleDateEnabled
      finishDate
      finishDateEnabled
      position
      published
      sortableHighlightedContents {
        sortableItems {
          position 
          highlightedContent {
            id
            title
            fullHdLandscape
            fullHdPortrait
            content {
              thumbnail
            }
          }
        }
      }
      generalSubSection {
        id
        title
        position
        sortableContents {
          position
          content {
            id
            title
            thumbnail
          }
        }
      }
      subSections {
        id
        title
        position
        sortableContents {
          position
          content {
            id
            title
            thumbnail
          }
        }
      }
    }
  }
`;

export default sectionsPaginateQuery;
