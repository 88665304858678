import { gql } from '@apollo/client';

const listTeamsQuery = gql`
  query ListTeamsQuery {
    teams(input: { paginate: { page: 1, limit: 1000 } }) {
      items {
        id
        name
      }
    }
  }
`;

export default listTeamsQuery;
