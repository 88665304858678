import { createContext } from 'react';
import { DiscountType } from '../../interfaces/Discount';

type ContextType = {
  discountType: DiscountType;
  setDiscountType: (type: DiscountType) => void;
};

const Context = createContext({} as ContextType);

export default Context;
