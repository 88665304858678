import { Spectator, UserPlan } from '../../../interfaces/Spectator';
import { formatDateByFNS } from '../../../utils/date';
import { getFriendlyNameByPaymentMethod } from '../../../utils/payment';

const mountSpectatorFinancialObjectForDownload = (spectator: Spectator) => {
  const mountedObject = spectator.userPlans.map((userPlan: UserPlan) => ({
    Plano: userPlan.plan.title,
    'Data de entrada': formatDateByFNS(userPlan.createdAt, 'dd/MM/yyyy'),
    'Data de saída': userPlan.deletedAt
      ? formatDateByFNS(userPlan.deletedAt, 'dd/MM/yyyy')
      : '',
    'Valor pago': userPlan.userPlanHistory.netValue,
    'Forma de pagamento': getFriendlyNameByPaymentMethod(
      userPlan.userPlanHistory.paymentHistory?.paymentMethod,
    ),
    'Últimos 4 dígitos':
      userPlan.userPlanHistory.paymentHistory?.lastFourCardDigits,
  }));
  return mountedObject;
};

export default mountSpectatorFinancialObjectForDownload;
