import { DealStatus } from '../../../interfaces/History';

const dealStatusName = {
  [DealStatus.activation]: 'Contratação do plano',
  [DealStatus.reactivation]: 'Reativação do plano',
  [DealStatus.renewal]: 'Renovação do plano',
  [DealStatus.reactivationPreCanceled]: 'Reativação do plano pré cancelado',
};

export const getFriendlyNameToDealStatus = (dealStatus?: DealStatus) =>
  dealStatus ? dealStatusName[dealStatus] : 'Cancelamento do plano';
