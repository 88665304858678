import { SortableHighlightedContents } from './Highlight';
import { Partner } from './Partner';

export enum PlanStatus {
  active = 'active',
  cancelled = 'cancelled',
  suspended = 'suspended',
  preCancelled = 'preCancelled',
}

export enum PlanType {
  'monthly' = 'monthly',
  'season' = 'season',
  'partner' = 'partner',
  'free' = 'free',
}

export enum PaymentType {
  debit = 'debit',
  pix = 'pix',
  credit = 'credit',
}

export enum DiscountType {
  value = 'value',
  percentage = 'percentage',
}

export enum PaymentRenewal {
  purchaseDate = 'purchaseDate',
  specificDay = 'specificDay',
}

export enum Month {
  january = 'january',
  february = 'february',
  march = 'march',
  april = 'april',
  may = 'may',
  june = 'june',
  july = 'july',
  august = 'august',
  september = 'september',
  october = 'october',
  november = 'november',
  december = 'december',
}

const MonthName = {
  [Month.january]: 'Janeiro',
  [Month.february]: 'Fevereiro',
  [Month.march]: 'Março',
  [Month.april]: 'Abril',
  [Month.may]: 'Maio',
  [Month.june]: 'Junho',
  [Month.july]: 'Julho',
  [Month.august]: 'Agosto',
  [Month.september]: 'Setembro',
  [Month.october]: 'Outubro',
  [Month.november]: 'Novembro',
  [Month.december]: 'Dezembro',
};

export const getFriendlyNameTheMonth = (month: Month): string =>
  MonthName[month];

export const PaymentTypeName = {
  [PaymentType.credit]: 'Cartão de crédito',
  [PaymentType.debit]: 'Cartão de débito',
  [PaymentType.pix]: 'PIX',
};

export const getFriendlyNameThePaymentType = (
  paymentType: PaymentType,
): string => PaymentTypeName[paymentType];

export interface Plan {
  id: string;
  title: string;
  description: string;
  published: boolean;
  price: number;
  type: PlanType;
  startMonth: Month;
  finishMonth: Month;
  monthlyChargeEnabled: boolean;
  monthlyRecurrence?: number;
  monthlyRecurrenceEnabled: boolean;
  membershipsCount: number;
  unlimitedMemberships: boolean;
  trialDays: number;
  trialDaysIsActive: boolean;
  paymentRenewal: PaymentRenewal;
  paymentRenewalDay?: number;
  partners: Partner[];
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  pages: Page[];
  sections: Section[];
  sortableHighlightedContents: SortableHighlightedContents[];
}

export enum PageType {
  subscriptionSuccess = 'subscriptionSuccess',
  subscriptionError = 'subscriptionError',
  subscriptionRenewalSuccess = 'subscriptionRenewalSuccess',
  subscriptionRenewalError = 'subscriptionRenewalError',
  cancellationHold = 'cancellationHold',
  retentionSuccess = 'retentionSuccess',
  retentionError = 'retentionError',
  subscriptionCancellation = 'subscriptionCancellation',
}

export const getPageTypeFriendlyName = (type?: PageType): string =>
  type
    ? {
        [PageType.subscriptionSuccess]: 'Sucesso ao contratar o plano',
        [PageType.subscriptionError]: 'Falha ao contratar o plano',
        [PageType.subscriptionRenewalSuccess]: 'Sucesso na renovação do plano',
        [PageType.subscriptionRenewalError]: 'Falha na renovação do plano',
        [PageType.cancellationHold]: 'Retenção de cancelamento',
        [PageType.retentionSuccess]: 'Sucesso na retenção de clientes',
        [PageType.retentionError]: 'Falha na retenção de clientes',
        [PageType.subscriptionCancellation]: 'Fim da contratação do plano',
      }[type]
    : 'Página Livre';

export interface Page {
  id: string;
  title: string;
  type: PageType;
  published: boolean;
  scheduleDateEnabled: boolean;
  createdAt: Date;
}

export interface Discount {
  id: string;
  title: string;
  price: number;
  type: DiscountType;
  usageLimit?: number;
  usageUnlimited: boolean;
  scheduleDate: Date;
  finishDate: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface Section {
  id: string;
  title: string;
}
