import { gql } from '@apollo/client';

const discountReportsAnalyse = gql`
  query DiscountReportsAnalyseQuery($input: DiscountReportsAnalyseInput!) {
    getDiscountReportsAnalyse(input: $input) {
      plansLinkedByDiscountId {
        id
        title
        actions {
          startedAt
          finishedAt
        }
      }
      spectatorsActivies
      viewsDiscountRetentions
      spectatorsWithActiveRetention
      notUtilizedsDiscounts
      durationAvg
    }
  }
`;

export default discountReportsAnalyse;
