import { useNode } from '@craftjs/core';
import { Button, Stack } from '@material-ui/core';
import { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import ButtonsSettings from '../../Sidebar/settings/widgets/ButtonsSettings';
import DeleteBlockButton, { DeletableBlocks } from './DeleteBlockButton';

interface ButtonProps {
  alignment: string;
  bgcolor: string;
  color: string;
  href: string;
  label: string;
}

const DynamicButton = ({
  alignment,
  bgcolor,
  color,
  href,
  label,
}: ButtonProps) => {
  const {
    id,
    connectors: { connect, drag: craftDrag },
    isClicked,
  } = useNode((node) => ({
    isClicked: node.events.selected,
  }));
  const [{ isDragging }, drag, preview] = useDrag<
    any,
    unknown,
    { isDragging: boolean }
  >(() => ({
    type: 'ButtonBlock',
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  }));
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  return (
    <Stack
      ref={(ref: HTMLElement) => connect(craftDrag(ref))}
      sx={{
        alignItems: alignment,
        border: 4,
        borderColor: isClicked ? 'info.main' : 'transparent',
        borderRadius: 1,
        opacity: isDragging ? 0.3 : 1,
        position: 'relative',
        width: '100%',
      }}
    >
      <Button
        ref={drag}
        title={href}
        sx={{
          bgcolor,
          color,
          fontSize: 16,
          height: 36,
          minWidth: 215,
          px: 4,
          '&:hover': { bgcolor },
        }}
      >
        {label}
      </Button>
      {isClicked ? <DeleteBlockButton id={id} name={DeletableBlocks.button} /> : null}
    </Stack>
  );
};

DynamicButton.craft = { related: { settings: ButtonsSettings } };

export default DynamicButton;
