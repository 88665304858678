import { useNode } from '@craftjs/core';
import { Stack, Typography } from '@material-ui/core';
import { Markup } from 'interweave';
import { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import TextsSettings from '../../Sidebar/settings/widgets/TextsSettings';
import DeleteBlockButton, { DeletableBlocks } from './DeleteBlockButton';

interface DynamicTextProps {
  color: string;
  fontFamily: string;
  fontSize: number;
  text: string;
}

const DynamicText = ({
  color,
  fontFamily,
  fontSize,
  text,
}: DynamicTextProps) => {
  const {
    id,
    connectors: { connect, drag: craftDrag },
    isClicked,
  } = useNode((node) => ({
    isClicked: node.events.selected,
  }));
  const [{ isDragging }, drag, preview] = useDrag<
    any,
    unknown,
    { isDragging: boolean }
  >(() => ({
    type: 'TextBlock',
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  }));
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  return (
    <Stack
      ref={(ref: HTMLElement) => connect(craftDrag(ref))}
      sx={{
        border: 4,
        borderColor: isClicked ? 'info.main' : 'transparent',
        borderRadius: 1,
        justifyContent: 'flex-start',
        opacity: isDragging ? 0.3 : 1,
        position: 'relative',
        width: '100%',
      }}
    >
      <Typography
        ref={drag}
        component="span"
        sx={{
          color,
          fontFamily,
          fontSize: `${fontSize}px`,
          lineHeight: 1.5,
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          // TODO: Find a better solution for rich text editor
          '& span > p': { margin: 0 },
          '& .ql-align-center': { textAlign: 'center' },
          '& .ql-align-right': { textAlign: 'right' },
        }}
      >
        <Markup content={text} />
      </Typography>
      {isClicked ? <DeleteBlockButton id={id} name={DeletableBlocks.text} /> : null}
    </Stack>
  );
};

DynamicText.craft = { related: { settings: TextsSettings } };

export default DynamicText;
