import { gql } from '@apollo/client';

const userPlansHistories = gql`
  query UserPlansHistoriesQuery($id: ID!) {
    userPlansHistories(id: $id) {
      id
      createdAt
      dealStatus
      netValue
      plan {
        id
        title
        price
      }
      discountPlanHistories {
        id
        discount {
          id
          title
          price
          valueType
        }
      }
    }
  }
`;

export default userPlansHistories;
