import { Grid, Pagination, Typography } from '@material-ui/core';
import { ChangeEvent, useContext, useState } from 'react';
import { SortByType } from '../../Discount/typings';
import Context from '../Context';
import { sortArrayBy } from '../utils/sort';
import Head from './Head';
import PlanItem from './PlanItem';

const PlansList = () => {
  const { plans, meta, input, setInput } = useContext(Context);
  const [sortBy, setSortBy] = useState<SortByType>();
  const sortByHandler = (sortByType: SortByType) => setSortBy(sortByType);
  const paginationHandler = (_: ChangeEvent<unknown>, page: number) =>
    setInput({ ...input, paginate: { page } });
  const sortedPlans = sortBy
    ? [...plans]?.sort(sortArrayBy(sortBy))
    : [...plans];
  return (
    <>
      <Grid
        container
        sx={{
          alignItems: 'center',
          bgcolor: 'primary.main',
          borderRadius: 2,
          display: 'flex',
          height: 45,
          mb: 2,
          mt: 6,
          px: 4,
        }}
      >
        <Grid item xs>
          <Typography
            sx={{
              color: 'grey.100',
              fontSize: 14,
            }}
          >
            Titulo do plano
          </Typography>
        </Grid>
        <Grid item xs sx={{ display: 'flex', justifyContent: 'center' }}>
          <Head title="Valor" prop="price" onSortHead={sortByHandler} />
        </Grid>
        <Grid item xs sx={{ display: 'flex', justifyContent: 'center' }}>
          <Head
            title="Periodo de teste"
            prop="trialDays"
            onSortHead={sortByHandler}
          />
        </Grid>
        <Grid item xs sx={{ display: 'flex', justifyContent: 'center' }}>
          <Head
            title="Telas simultâneas"
            prop="scheduleDate"
            onSortHead={sortByHandler}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sx={{ display: 'flex', justifyContent: 'flex-end', pr: '9px' }}
        >
          <Typography
            sx={{
              color: 'grey.100',
              fontSize: 14,
            }}
          >
            Status
          </Typography>
        </Grid>
        <Grid item xs sx={{ display: 'flex', justifyContent: 'center' }} />
      </Grid>
      {sortedPlans.map((plan: any) => (
        <PlanItem key={plan.id} plan={plan} />
      ))}
      <Pagination
        count={meta?.totalPages}
        page={input.paginate.page}
        sx={{ mt: 4 }}
        onChange={paginationHandler}
      />
    </>
  );
};

export default PlansList;
