import { gql } from '@apollo/client';

const pagesQuery = gql`
  query PagesQuery {
    pages {
      id
      title
      blocks
      type
      published
      scheduleDate
      scheduleDateEnabled
      finishDate
      finishDateEnabled
      seoTitle
      seoDescription
      seoKeywords
      scripts
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export default pagesQuery;
