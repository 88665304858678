import { gql } from '@apollo/client';

const partnersQuery = gql`
  query PartnersQuery {
    partners {
      code
      description
    }
  }
`;

export default partnersQuery;
