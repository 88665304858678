import { DiscountType } from '../../../interfaces/Discount';

export const getPropsByDiscountType = (type: DiscountType) => {
  switch (type) {
    case DiscountType.Internal:
      return {
        title: 'Desconto Interno',
        description:
          'Crie descontos permitindo um relacionamento mais duradouro com seu espectador.',
      };
    case DiscountType.Collective:
      return {
        title: 'Desconto Externo',
        description:
          'Crie descontos que irão ser aplicados automáticamente no momento da compra do plano.',
      };
    case DiscountType.Retention:
      return {
        title: 'Desconto de Retenção',
        description:
          'Crie descontos para utilizar na retenção de clientes que queiram cancelar.',
      };
    default:
      return {
        title: 'Cupom',
        description: 'Crie cupons para promover ações de marketing.',
      };
  }
};
