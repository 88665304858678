/* eslint-disable react/require-default-props */
import { useNode } from '@craftjs/core';
import { Stack } from '@material-ui/core';
import { VideoCameraBack as VideoIcon } from '@material-ui/icons';
import { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { VideosSettings } from '../../Sidebar/settings/widgets';
import DeleteBlockButton, { DeletableBlocks } from './DeleteBlockButton';

interface DynamicVideosProps {
  thumbnail?: string;
  videoId?: string;
}

const DynamicVideo = ({ thumbnail = '', videoId = '' }: DynamicVideosProps) => {
  const {
    id,
    connectors: { connect, drag: craftDrag },
    isClicked,
  } = useNode((node) => ({
    isClicked: node.events.selected,
  }));
  const [{ isDragging }, drag, preview] = useDrag<
    any,
    unknown,
    { isDragging: boolean }
  >(() => ({
    type: 'VideoBlock',
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  }));
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  const getVideoUrl = `https://beyond.spalla.io/player/?video=${videoId}&autoplay=0`;
  return (
    <Stack ref={(ref: HTMLElement) => connect(craftDrag(ref))}>
      <Stack
        ref={drag}
        sx={{
          border: isClicked ? 4 : 'none',
          borderColor: isClicked ? 'info.main' : 'transparent',
          height: '100%',
          objectFit: 'contain',
          opacity: isDragging ? 0.3 : 1,
          position: 'relative',
        }}
      >
        <Stack
          sx={{ backgroundImage: `url('${thumbnail}')`, pointerEvents: 'none' }}
        >
          {!videoId ? (
            <Stack
              sx={{
                alignItems: 'center',
                bgcolor: '#20133A', // TODO: export from theme
                border: '2px dashed',
                borderColor: 'info.main',
                borderRadius: 1,
                color: 'info.main',
                height: 208,
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <VideoIcon sx={{ height: 35, width: 42 }} />
            </Stack>
          ) : (
            <iframe
              id="spalla-player"
              src={getVideoUrl}
              title="Spalla video player"
              height="452px"
              frameBorder="0"
              scrolling="no"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope"
              allowFullScreen
            />
          )}
        </Stack>
        {isClicked ? (
          <DeleteBlockButton id={id} name={DeletableBlocks.video} />
        ) : null}
      </Stack>
    </Stack>
  );
};

DynamicVideo.craft = { related: { settings: VideosSettings } };

export default DynamicVideo;
