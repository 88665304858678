import { gql } from '@apollo/client';

const getPlansForDiscountQuery = gql`
  query PlansForDiscount($discountType: DiscountType!) {
    availablePlansForDiscount(discountType: $discountType) {
      id
      title
      type
    }
  }
`;

export default getPlansForDiscountQuery;
