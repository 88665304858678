import { Element, useNode } from '@craftjs/core';
import { Stack } from '@material-ui/core';
import { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { StructuresSettings } from '../../Sidebar/settings/widgets';
import DeleteBlockButton, { DeletableBlocks } from './DeleteBlockButton';
import HalfContainer from './HalfContainer';

const DoubleContainer = ({ bgColor }: { bgColor: string }) => {
  const {
    connectors: { connect, drag: craftDrag },
    id,
    isClicked,
  } = useNode((node) => ({ isClicked: node.events.selected }));
  const [{ isDragging }, drag, preview] = useDrag<
    any,
    unknown,
    { isDragging: boolean }
  >(() => ({
    type: 'DoubleContainerBlock',
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  }));
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  return (
    <Stack
      ref={drag}
      position="relative"
      sx={{
        bgcolor: bgColor,
        border: isClicked ? '2px solid' : 'none',
        borderColor: isClicked ? 'info.main' : 'transparent',
        borderRadius: 1,
        cursor: 'grab',
        minHeight: 208,
        opacity: isDragging ? 0.3 : 1,
      }}
    >
      <Stack
        ref={(ref: HTMLElement) => connect(craftDrag(ref))}
        alignItems="center"
        flexDirection="row"
        height="100%"
        justifyContent="space-between"
        sx={{ gap: 4 }}
      >
        <Element
          canvas
          id={`${id}-left-column`}
          is={HalfContainer}
          parentId={id}
        />
        <Element
          canvas
          id={`${id}-right-column`}
          is={HalfContainer}
          parentId={id}
        />
        {isClicked ? (
          <DeleteBlockButton id={id} name={DeletableBlocks.structure} />
        ) : null}
      </Stack>
    </Stack>
  );
};

DoubleContainer.craft = {
  related: { settings: StructuresSettings },
  rules: { canMoveIn: () => false },
};

export default DoubleContainer;
