import { escape } from 'lodash';

export interface CreateUriAndNameToFileProps {
  file: string;
  fileName: string;
  fileType: string;
}

export interface CreateUriAndNameToFileReturn {
  name: string;
  uri: string;
}

export const convertJsonToCsv = (json: Object): string => {
  const jsonToString = JSON.stringify(json);
  const parsedJson = JSON.parse(jsonToString);
  const heading = Object.keys(parsedJson).join(';');
  const jsonValues = Object.values(parsedJson);
  const body = jsonValues.map((jsonMap: any) => jsonMap).join(';');
  return `${heading}\n${body}`;
};

export const convertJsonArrayToCsv = (json: Object[]) => {
  const jsonToString = JSON.stringify(json[0]);
  const parsedJson = JSON.parse(jsonToString);
  const heading = Object.keys(parsedJson).join(';');
  const body = json.map((jsonMap: any) => {
    const jsonValues = Object.values(jsonMap);
    const values = jsonValues.map((value: any) => value).join(';');
    return `\n${values}`;
  });
  return `${heading}\n${body}`;
};

export const createUriAndNameToFile = ({
  file,
  fileName,
  fileType,
}: CreateUriAndNameToFileProps): CreateUriAndNameToFileReturn => {
  const name = fileName.replace(/ /g, '_');
  const uri = `data:text/${fileType};charset=utf-8,%EF%BB%BF,${escape(file)}`;
  return { uri, name };
};
