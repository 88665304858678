import { Stack, Typography } from '@material-ui/core';
import { useContext } from 'react';
import { Context } from '../Context';

const ButtonsFilter = () => {
  const { listLeads, setListLeads } = useContext(Context);
  return (
    <Stack alignItems="center" direction="row" gap={2} sx={{ mt: 4 }}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          bgcolor: 'grey.600',
          borderRadius: 2,
          cursor: 'pointer',
          height: 59,
          width: 276,
        }}
        onClick={() => setListLeads(false)}
      >
        <Typography
          sx={{
            color: listLeads ? 'grey.400' : 'common.white',
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          Cadastrados
        </Typography>
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          bgcolor: 'grey.600',
          borderRadius: 2,
          cursor: 'pointer',
          height: 59,
          width: 276,
        }}
        onClick={() => setListLeads(true)}
      >
        <Typography
          sx={{
            color: !listLeads ? 'grey.400' : 'common.white',
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          Não cadastrados
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ButtonsFilter;
