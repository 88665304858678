import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@material-ui/core';
import { useContext } from 'react';
import { useAlert } from 'react-alert';
import uploadFile from '../../ManagersList/services/uploadFile';
import getAssetUri from '../../../utils/getAssetUri';
import Context from '../Context';

const Details = () => {
  const alert = useAlert();
  const { form } = useContext(Context);
  const changeThumbnailHandler = async (evt: any) => {
    const res = await uploadFile<any>(evt.target.files[0]);
    const hasErrors = !!res.data.errors;
    const formatUnsupported =
      hasErrors && res.data?.errors[0]?.message.includes('is unsupported');
    if (formatUnsupported) {
      alert.error('Formato de imagem não suportado.');
      return;
    }
    if (res.data?.data.fileUpload) {
      const fileSrc = getAssetUri(res.data?.data.fileUpload);
      const isFirstAvatar = form.values.avatars.length === 0;
      // eslint-disable-next-line consistent-return
      return form.setFieldValue('avatars', [
        ...form.values.avatars,
        { enabled: isFirstAvatar, url: fileSrc },
      ]);
    }
  };
  return (
    <>
      <Card>
        <CardContent>
          <Typography sx={{ color: 'grey.100', fontSize: 16, mb: 4 }}>
            Dados do time do coração
          </Typography>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Box>
              <InputLabel sx={{ color: 'grey.300', fontSize: 14, mb: 1 }}>
                Digite o nome do time
              </InputLabel>
              <OutlinedInput
                name="name"
                value={form.values.name}
                onChange={form.handleChange}
              />
              <FormHelperText error sx={{ height: 25 }}>
                {form.errors.name}
              </FormHelperText>
            </Box>
            <Box>
              <InputLabel sx={{ color: 'grey.300', fontSize: 14, mb: 1 }}>
                Sigla do time
              </InputLabel>
              <OutlinedInput
                name="slug"
                value={form.values.slug}
                onChange={form.handleChange}
              />
              <FormHelperText error sx={{ height: 25 }}>
                {form.errors.slug}
              </FormHelperText>
            </Box>
          </Stack>
          <Divider sx={{ bgcolor: 'grey.400', mb: 6, mt: 3 }} />
          <Typography sx={{ color: 'grey.100', fontSize: 16, mb: 4 }}>
            Ícone de perfil
          </Typography>
          <Typography sx={{ color: 'grey.300', fontSize: 14, mb: 4 }}>
            Adicione imagens que represente este time, o espectador usará em seu
            perfil.
          </Typography>
          <Divider sx={{ bgcolor: 'grey.400', my: 6 }} />
          <Stack alignItems="center" direction="row" spacing={2}>
            <label htmlFor="avatar">
              <input
                id="avatar"
                accept="image/*"
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={changeThumbnailHandler}
              />
              <Button component="span" sx={{ width: 200 }}>
                Adicionar imagem
              </Button>
            </label>
            <Typography sx={{ color: 'grey.400', fontSize: 12, width: 253 }}>
              Selecione imagens com as dimenções maiores que 180x180px.
            </Typography>
          </Stack>
        </CardContent>
      </Card>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Typography sx={{ color: 'grey.100', fontSize: 16, mb: 4 }}>
            Dados do time do coração
          </Typography>
          <Box>
            <InputLabel sx={{ color: 'grey.300', fontSize: 14, mb: 1 }}>
              URL para direcionamento
            </InputLabel>
            <OutlinedInput
              name="externalUrl"
              value={form.values.externalUrl}
              onChange={form.handleChange}
            />
            <FormHelperText error>{form.errors.externalUrl}</FormHelperText>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default Details;
