import { gql } from '@apollo/client';

const contentsQuery = gql`
  query ContentsQuery {
    contents {
      id
      title
      description
      scheduleDate
      finishDate
      type
      published
      thumbnail
      createdAt
      updatedAt
    }
  }
`;

export default contentsQuery;
