import { useMutation } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../components/Dialog';
import Navbar from '../../components/UI/molecules/Navbar';
import FormFooter from '../../components/UI/templates/FormFooter';
import Details from './components/Details';
import AvatarList from './components/AvatarList';
import Context, { FormValues } from './Context';
import createTeamMutation from './gql/createTeamMutation';
import validationSchema from './providers/validationSchema';

const CreateHeartTeam = () => {
  const alert = useAlert();
  const dialog = useDialog();
  const navigate = useNavigate();
  const [createTeam] = useMutation(createTeamMutation);
  const form = useFormik<FormValues>({
    initialValues: {
      name: '',
      slug: '',
      externalUrl: '',
      avatars: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      const input = validationSchema.cast(values);
      try {
        await createTeam({ variables: { input } });
        alert.success('Time criado com sucesso.');
        navigate('/teams');
      } catch {
        alert.error('Ocorre um erro ao criar o time.');
      }
    },
  });
  const cancelCreation = async () => {
    const confirm = await dialog.confirm({
      title: 'Você tem certeza?',
      message: 'Você perderá todos os dados não salvos.',
    });
    if (confirm) {
      navigate('/teams');
    }
  };
  return (
    <Context.Provider value={{ form }}>
      <Navbar route="/teams" title="Criar novo time" />
      <Grid container sx={{ mt: 4 }} gap={4}>
        <Grid item xs={5}>
          <Details />
        </Grid>
        <Grid item xs>
          <AvatarList />
        </Grid>
      </Grid>
      <FormFooter
        LeftButtonProps={{
          children: 'Salvar time',
          type: 'submit',
          loading: false,
          onClick: () => form.handleSubmit(),
        }}
        helperText=""
        helperTextProps={{ color: 'error.main' }}
        RightButtonProps={{
          children: 'Cancelar criação',
          onClick: () => cancelCreation(),
        }}
        sx={{ mt: 6 }}
      />
    </Context.Provider>
  );
};

export default CreateHeartTeam;
