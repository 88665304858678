import { Card, Chip, Stack, Typography } from '@material-ui/core';
import {
  Check as CheckIcon,
  BarChart as RetentionIcon,
} from '@material-ui/icons';
import { Discount, DiscountType } from '../../../interfaces/Discount';
import { formatDateByFNS } from '../../../utils/date';
import CardGroup from '../../FinancialDiscountReport/Components/Card';
import { ValueGroup } from '../../FinancialDiscountReport/Components/helpers';
import getFriendlyNameByDiscountType from '../utils/getFriendlyNameByDiscountType';
import { DiscountAnalyse } from '../typings';

interface RulesAndRetentinosProps {
  discount: Discount;
  discountAnalyse: DiscountAnalyse;
}

const RulesAndRetentinos = ({
  discount,
  discountAnalyse,
}: RulesAndRetentinosProps) => {
  const getSymbolByValueType =
    discount.valueType === 'value'
      ? `R$ ${discount.price}`
      : `${discount.price}%`;
  const getRecurrenceLabel = () => {
    switch (discount.recurrence) {
      case null:
        return 'Indefinido';
      case 1:
        return `${discount.recurrence} mês`;
      default:
        return `${discount.recurrence} meses`;
    }
  };
  const discountIsRetentionType = discount.type === DiscountType.Retention;
  return (
    <>
      <Card sx={{ height: 129, p: 6 }}>
        <Stack alignItems="center" direction="row">
          <Typography
            sx={{
              color: 'grey.100',
              fontSize: 24,
              fontWeight: 600,
              maxWidth: 272,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {discount.title}
          </Typography>
          <Chip
            color={discount.enabled ? 'success' : 'error'}
            label={discount.enabled ? 'Ativo' : 'Inativo'}
            variant="outlined"
            size="small"
            sx={{ borderRadius: 1, ml: 3 }}
          />
        </Stack>
        <Typography sx={{ color: 'grey.200', fontSize: 14, mt: 2 }}>
          Código do cupom: {discount.code ? discount.code : '-'}
        </Typography>
      </Card>
      <CardGroup
        icon={CheckIcon}
        title={`Regras do ${getFriendlyNameByDiscountType(discount.type)}`}
        sx={{ height: 212, mt: 4 }}
      >
        <Stack alignItems="center" direction="row" sx={{ mt: 4 }}>
          <ValueGroup title="Valor do desconto" value={getSymbolByValueType} />
          <ValueGroup
            title="Recorrência"
            value={getRecurrenceLabel()}
            sx={{ ml: '74px' }}
          />
        </Stack>
        <Stack alignItems="center" direction="row" sx={{ mt: 5 }}>
          <ValueGroup
            title="Quantidade utilizada"
            value={discountAnalyse.spectatorsActivies}
          />
          <ValueGroup
            title="Período ativo"
            value={`De ${formatDateByFNS(discount.createdAt, 'dd/MM/yy')} até ${
              discount.finishDate
                ? formatDateByFNS(discount.finishDate, 'dd/MM/yy')
                : ' -'
            }`}
            sx={{ ml: '56px' }}
          />
        </Stack>
      </CardGroup>
      <CardGroup
        icon={RetentionIcon}
        title="Retenção"
        sx={{ height: 212, mt: 4 }}
      >
        <ValueGroup
          title="Vezes oferecido ao espectador"
          value={
            discountIsRetentionType
              ? discountAnalyse.viewsDiscountRetentions
              : '-'
          }
          sx={{ mt: 4 }}
        />
        <Stack alignItems="center" direction="row" sx={{ mt: 5 }}>
          <ValueGroup
            title="Quantidade utilizada"
            value={
              discountIsRetentionType
                ? discountAnalyse.spectatorsWithActiveRetention
                : '-'
            }
          />
          <ValueGroup
            title="Quantidade não utilizada"
            value={
              discountIsRetentionType
                ? discountAnalyse.notUtilizedsDiscounts
                : '-'
            }
            sx={{ ml: '56px' }}
          />
        </Stack>
      </CardGroup>
    </>
  );
};

export default RulesAndRetentinos;
