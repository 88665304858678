import { useMutation } from '@apollo/client';
import { Button, OutlinedInput, Stack, Typography } from '@material-ui/core';
import { ContentCopy as CopyIcon } from '@material-ui/icons';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import upsertManagerApiAccessTokenMutation from '../gql/upsertManagerApiAccessTokenMutation';
import deleteManagerApiAccessTokenMutation from '../gql/deleteManagerApiAccessTokenMutation';

const GenerateToken = () => {
  const alert = useAlert();
  const [token, setToken] = useState('');
  const [upsertManagerApiAccessToken] = useMutation(
    upsertManagerApiAccessTokenMutation,
  );
  const [deleteManagerApiAccessToken] = useMutation(
    deleteManagerApiAccessTokenMutation,
  );
  const copyToken = async () => {
    await navigator.clipboard.writeText(token);
    alert.success('Token copiado com sucesso.');
  };
  const generateToken = async () => {
    try {
      const { data } = await upsertManagerApiAccessToken();
      setToken(data.upsertManagerApiAccessToken);
    } catch {
      alert.error('Ocorreu um erro ao gerar o token');
    }
  };
  const deleteToken = async () => {
    try {
      await deleteManagerApiAccessToken();
      setToken('');
      alert.success('Token apagado com sucesso.');
    } catch {
      alert.error('Ocorreu um erro ao excluir o token');
    }
  };
  return (
    <>
      <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
        Forneça uma identificação exclusiva de seu usuário.
      </Typography>
      <OutlinedInput
        fullWidth
        readOnly
        placeholder="Você precisa gerar um novo token"
        value={token}
        endAdornment={
          <CopyIcon
            sx={{ cursor: 'pointer', color: 'grey.400' }}
            onClick={copyToken}
          />
        }
        sx={{ height: 51, mt: 4 }}
      />
      <Stack
        alignItems="center"
        direction="row"
        sx={{ gap: 4, mt: 4, width: '100%' }}
      >
        <Button fullWidth onClick={generateToken} sx={{ height: 44 }}>
          Gerar novo Token
        </Button>
        <Button
          fullWidth
          onClick={deleteToken}
          sx={{ bgcolor: 'grey.500', height: 44 }}
        >
          Excluir Token
        </Button>
      </Stack>
    </>
  );
};

export default GenerateToken;
