import { MenuItem, Select, Switch, Typography } from '@material-ui/core';
import { useContext } from 'react';
import { formatDistance, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Navbar from '../../../components/UI/molecules/Navbar';
import TipAlert from '../../../components/UI/molecules/TipAlert';
import FormFooter from '../../../components/UI/templates/FormFooter';
import EditContentContext from '../context';
import { ContainerProps } from './typings';
import Details from './Details';
import Setup from './Setup';
import ExternalLinks from '../../CreateExternalContent/Components/ExternalLinks';

const Container = ({ onDelete }: ContainerProps) => {
  const { form, isUpdating } = useContext(EditContentContext);
  const compareDistanceEditDate = form.values.updatedAt
    ? formatDistance(
        parseISO(form.values.updatedAt),
        parseISO(new Date().toISOString()),
        { locale: ptBR },
      )
    : '';
  return (
    <>
      <Navbar route="/library" title={form.values.title}>
        {form.values.externalContent && (
          <Select
            name="type"
            value={form.values.type}
            onChange={form.handleChange}
          >
            <MenuItem value="vod">VOD</MenuItem>
            <MenuItem value="live">LIVE</MenuItem>
          </Select>
        )}
        <Typography sx={{ color: 'grey.100', ml: 5 }}>
          Publicado
          <Switch
            data-testid="switch-published"
            name="published"
            checked={form.values.published}
            color="secondary"
            sx={{ ml: 2 }}
            onClick={form.handleChange}
          />
        </Typography>
      </Navbar>
      <TipAlert sx={{ mt: 4 }}>
        Salve as edições para que o seu conteúdo seja publicado
      </TipAlert>
      <Details />
      {form.values.externalContent && <ExternalLinks />}
      <Setup />
      <FormFooter
        LeftButtonProps={{
          children: 'Salvar alterações',
          type: 'submit',
          loading: isUpdating,
          onClick: form.handleSubmit,
        }}
        helperText={`Última alteração: ${compareDistanceEditDate}`}
        RightButtonProps={{
          children: 'Excluir conteúdo',
          onClick: onDelete,
        }}
        sx={{ mt: 4 }}
      />
    </>
  );
};

export default Container;
