import { useMutation } from '@apollo/client';
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { Tag } from '../../../../interfaces/Tag';
import { useDialog } from '../../../../components/Dialog';
import createCategoryMutation from '../CategoriesContainer/gql/createCategoryMutation';
import validationSchema from './providers/form';

const CreateCategoryForm = () => {
  const dialog = useDialog();
  const [createCategory] = useMutation(createCategoryMutation);
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldError,

    values,
  } = useFormik<Pick<Tag, 'name'>>({
    initialValues: { name: '' },
    onSubmit: async ({ name }) => {
      try {
        const trimmedName = name.trim();
        await createCategory({
          variables: { input: { name: trimmedName, slug: trimmedName } },
        });
        dialog.close(true);
      } catch (err: any) {
        const categoryAlreadyExists =
          !!err.graphQLErrors?.[0].extensions?.errors?.slug?.notExists;
        if (categoryAlreadyExists) {
          setFieldError('name', 'Uma categoria com este nome já existe');
        }
      }
    },
    validationSchema,
  });
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack>
          <InputLabel sx={{ color: 'grey.300', mb: 1, fontSize: 14 }}>
            Nome da categoria
          </InputLabel>
          <OutlinedInput
            name="name"
            autoFocus
            placeholder="Digite aqui"
            inputProps={{ maxLength: 160 }}
            sx={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'grey.400',
              fontSize: 14,
              height: 37,
            }}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.name && (
            <Typography sx={{ color: 'error.main', fontSize: 12, mt: 1 }}>
              {errors.name}
            </Typography>
          )}
        </Stack>
      </form>
    </>
  );
};

export default CreateCategoryForm;
