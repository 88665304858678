import { useQuery } from '@apollo/client';
import { Grid, Pagination, Typography } from '@material-ui/core';
import { ChangeEvent, useContext, useState } from 'react';
import { Discount } from '../../../interfaces/Discount';
import { SortByDirection } from '../../../typings/sort';
import sortArrayBy from '../../Discount/utils/sort';
import Context from '../Context';
import discountReportsAnalyse from '../gql/discountReportAnalyseQuery';
import DiscountItem from './DiscountItem';
import Head from './Head';

export type SortByType = {
  direction: SortByDirection;
  prop: keyof Pick<Discount, 'enabled' | 'price'>;
};

interface SortByHandlerProps {
  direction: SortByDirection;
  prop: 'price' | 'enabled';
  onSortHead: (sortBy: SortByType) => void;
}

const DiscountList = () => {
  const { input, setInput } = useContext(Context);
  const [sortBy, setSortBy] = useState<SortByHandlerProps>();
  const paginationHandler = (_: ChangeEvent<unknown>, page: number) =>
    setInput({ ...input, paginate: { page } });
  const { loading, data } = useQuery(discountReportsAnalyse, {
    variables: { input },
  });
  const sortByHandler = (sortByType: SortByHandlerProps) =>
    setSortBy(sortByType);
  if (loading) return <div>Carregando...</div>;
  const discounts = data.getDiscountReportsAnalyse.listDiscountsByType.items;
  const { meta } = data.getDiscountReportsAnalyse.listDiscountsByType;
  const sortedDiscounts = sortBy
    ? [...discounts].sort(sortArrayBy(sortBy))
    : [...discounts];
  return (
    <>
      <Grid
        container
        sx={{
          alignItems: 'center',
          bgcolor: 'primary.main',
          borderRadius: 2,
          display: 'flex',
          height: 45,
          mb: 2,
          px: 4,
        }}
      >
        <Grid item sx={{ width: 200 }}>
          <Typography
            sx={{
              color: 'grey.100',
              fontSize: 14,
            }}
          >
            Titulo do desconto
          </Typography>
        </Grid>
        <Grid item xs sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs sx={{ display: 'flex', justifyContent: 'center' }}>
            <Head title="Ativos" prop="enabled" onSortHead={sortByHandler} />
          </Grid>
          <Grid item xs sx={{ display: 'flex', justifyContent: 'center' }}>
            <Head title="Valor" prop="price" onSortHead={sortByHandler} />
          </Grid>
          <Grid item xs sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              sx={{
                color: 'grey.100',
                fontSize: 14,
              }}
            >
              Status
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs sx={{ display: 'flex', justifyContent: 'center' }} />
      </Grid>
      {sortedDiscounts.map((discount: any) => (
        <DiscountItem key={discount.id} discount={discount} />
      ))}
      <Pagination
        count={meta?.totalPages}
        page={input.paginate.page}
        sx={{ mt: 4 }}
        onChange={paginationHandler}
      />
    </>
  );
};

export default DiscountList;
