import { Grid, Stack, Typography } from '@material-ui/core';
import { SxProps, Theme } from '@material-ui/system';

interface LeadsListRowProps {
  data: any;
  isLastItem: boolean;
  sx?: SxProps<Theme>;
}

const LeadsListRow = ({ sx, data, isLastItem }: LeadsListRowProps) => {
  const formatedDate = new Date(data.createdAt).toLocaleDateString('pt-br');
  return (
    <Grid
      container
      sx={{
        ...sx,
        borderColor: 'grey.500',
        borderStyle: 'solid',
        borderWidth: 0,
        borderBottomWidth: !isLastItem ? 1 : 0,
        height: '100%',
      }}
    >
      <Grid item xs={4}>
        <Stack direction="row">
          <Stack direction="column" sx={{ marginLeft: 4 }}>
            <Typography sx={{ color: 'grey.100' }}>{data.name}</Typography>
            <Typography sx={{ color: 'grey.400', fontSize: 12 }}>
              {data.email}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack direction="column" sx={{ justifyContent: 'center' }} spacing={1}>
          <Typography sx={{ color: 'grey.100' }}>{data.phone}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack direction="column" sx={{ justifyContent: 'center' }} spacing={1}>
          <Typography sx={{ color: 'grey.100' }}>{data?.answer?.name}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack direction="column" sx={{ justifyContent: 'center' }} spacing={1}>
          <Typography sx={{ color: 'grey.100' }}>{formatedDate}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default LeadsListRow;
