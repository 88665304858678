import * as yup from 'yup';

const validationSchema = yup
  .object()
  .noUnknown()
  .shape({
    id: yup.string().required('Campo obrigatório.'),
    name: yup.string().required('Campo obrigatório.'),
    slug: yup.string().required('Campo obrigatório.'),
    externalUrl: yup.string().required('Campo obrigatório.'),
    avatars: yup
      .array()
      .of(
        yup.object().noUnknown().shape({
          enabled: yup.boolean().required(),
          url: yup.string().required(),
        }),
      )
      .min(1, 'Adicione pelo menos um avatar.'),
  });

export default validationSchema;
