import { useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../components/UI/molecules/Navbar';
import TipAlert from '../../components/UI/molecules/TipAlert';
import FormFooter from '../../components/UI/templates/FormFooter';
import { useDialog } from '../../components/Dialog';
import AddImageSection from '../CreateHighlights/Components/AddImageSection';
import Details from '../CreateHighlights/Components/Details';
import Scheduling from '../CreateHighlights/Components/Scheduling';
import Context from '../CreateHighlights/Context';
import deleteHighlightedContentMutation from './gql/deleteHighlightedContentMutation';
import updateHighlightedContentMutation from './gql/updateHighlightedContentMutation';
import getHighlightedContentQuery from './gql/getHighlightedContentQuery';
import validationSchema from './providers/validationSchema';

const EditHighlights = () => {
  const { id } = useParams();
  const { loading, data } = useQuery(getHighlightedContentQuery, {
    variables: { id },
  });
  const [updateHighlightedContent, { loading: isUpdating }] = useMutation(
    updateHighlightedContentMutation,
  );
  const [deleteHighlightedContent, { loading: isDeleting }] = useMutation(
    deleteHighlightedContentMutation,
  );
  const alert = useAlert();
  const dialog = useDialog();
  const navigate = useNavigate();
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      id,
      title: data?.highlightedContent.title,
      description: data?.highlightedContent.description,
      fullHdPortrait: data?.highlightedContent.fullHdPortrait,
      fullHdLandscape: data?.highlightedContent.fullHdLandscape,
      scheduleDate: data?.highlightedContent.scheduleDate,
      finishDate: data?.highlightedContent.finishDate,
      position: data?.highlightedContent.position,
      content: data?.highlightedContent.content,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const input = validationSchema.cast(values);
        await updateHighlightedContent({ variables: { input } });
        alert.success('Destaque criado com sucesso');
        navigate('/highlights');
      } catch {
        alert.error('Ops! Algo deu errado, tente novamente.');
      }
    },
  });
  const deleteHighlight = async () => {
    const confirm = await dialog.confirm({
      title: 'Excluir destaque',
      message: 'Você tem certeza que deseja excluir este destaque?',
    });
    if (confirm) {
      await deleteHighlightedContent({ variables: { id } });
      alert.success('Destaque deletado com sucesso');
      navigate('/highlights');
    }
  };
  if (loading) return <div>Carregando...</div>;
  return (
    <Context.Provider value={{ form }}>
      <Navbar
        route="/highlights"
        title={`Editar  Destaque - ${data?.highlightedContent.title}`}
      />
      <TipAlert sx={{ mt: 6 }}>
        Salve as edições para que o seu conteúdo seja publicado
      </TipAlert>
      <Details />
      <Scheduling />
      <AddImageSection />
      <FormFooter
        LeftButtonProps={{
          children: 'Salvar destaque',
          type: 'submit',
          loading: isUpdating || isDeleting,
          onClick: () => form.handleSubmit(),
        }}
        helperText=""
        helperTextProps={{ color: 'error.main' }}
        RightButtonProps={{
          children: 'Excluir destaque',
          onClick: () => deleteHighlight(),
        }}
        sx={{ mt: 6 }}
      />
    </Context.Provider>
  );
};

export default EditHighlights;
