import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Folder as FolderIcon,
  Label as LabelIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { useContext, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { stringToSingular } from '../../../../utils/string';
import { CardGroup as ICardGroup, Item } from './typings';
import Context from '../../Context';

const CardGroup = ({
  buttonLabel,
  emptyHelperText,
  filterItems,
  inputProps,
  items,
  ItemsWrapper = Box,
  ItemsWrapperProps = {},
  name,
  renderItems,
  onCreate,
  onCreateFromSuggest,
  creatingFromSuggest,
}: ICardGroup) => {
  const { permissionToCreate } = useContext(Context);
  const [newItemName] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [itemList, setItemList] = useState<Item[]>(items);
  const [newItemSuggest, setNewItemSuggest] = useState<boolean>(false);
  const renderedItems = itemList.map((item: Item) => renderItems(item));
  const searchChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(evt.target.value);
  const createItemHandler = () => onCreate(newItemName);

  useEffect(() => {
    if (searchQuery) {
      const newItemList = items.filter((item: Item) =>
        filterItems(searchQuery, item),
      );
      if (newItemList.length === 0) {
        setNewItemSuggest(true);
      } else {
        setNewItemSuggest(false);
      }
      setItemList(newItemList);
      return;
    }
    setNewItemSuggest(false);
    setItemList(items);
  }, [searchQuery]);

  useEffect(() => {
    setItemList(items);
  }, [items]);

  const createFromSuggestHandler = () => {
    onCreateFromSuggest?.(searchQuery);
    setSearchQuery('');
  };
  const onKeyDownHandler = (evt: KeyboardEvent) => {
    const enterWasPressed = evt.key === 'Enter';
    if (enterWasPressed) {
      createFromSuggestHandler();
    }
  };
  const hasItems = items.length > 0;
  const cardContent = hasItems ? (
    <Grid container item direction="column" sx={{ height: '100%' }}>
      <Grid item>
        <Box sx={{ p: 5 }}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              sx={{ color: 'common.white', fontSize: 16, fontWeight: 600 }}
            >
              {name}
            </Typography>
          </Stack>
          <OutlinedInput
            fullWidth
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'info.main' }} />
              </InputAdornment>
            }
            inputProps={{ maxLength: 160 }}
            value={searchQuery}
            sx={{ color: 'secondary.light', mt: 4 }}
            onChange={searchChangeHandler}
            onKeyDown={onKeyDownHandler}
            {...inputProps}
          />
        </Box>
        <Divider sx={{ borderColor: 'grey.500' }} />
        {creatingFromSuggest && <div>Carregando...</div>}
      </Grid>
      <Grid item xs sx={{ overflow: 'hidden', width: '100%' }}>
        <PerfectScrollbar>
          {permissionToCreate && newItemSuggest && !creatingFromSuggest && (
            <ListItem
              button
              onClick={createFromSuggestHandler}
              sx={{ alignItems: 'center', display: 'flex', px: 5 }}
            >
              <ListItemIcon sx={{ minWidth: 28 }}>
                <AddIcon sx={{ color: 'info.main' }} />
              </ListItemIcon>
              <ListItemText
                primary={`${buttonLabel}: ${searchQuery}`}
                primaryTypographyProps={{
                  fontSize: 14,
                  fontWeight: 600,
                  noWrap: true,
                }}
                sx={{ color: 'info.main' }}
              />
            </ListItem>
          )}
          <ItemsWrapper {...ItemsWrapperProps}>{renderedItems}</ItemsWrapper>
        </PerfectScrollbar>
      </Grid>
    </Grid>
  ) : (
    <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
      {name === 'Tags' ? (
        <LabelIcon sx={{ color: 'info.main', fontSize: 40 }} />
      ) : (
        <FolderIcon sx={{ color: 'info.main', fontSize: 40 }} />
      )}
      <Typography
        sx={{ color: 'common.white', fontSize: 20, fontWeight: 600, mt: 4 }}
      >
        {`Crie sua primeira ${stringToSingular(name)}`}
      </Typography>
      <Typography
        sx={{
          color: 'grey.300',
          fontSize: 14,
          lineHeight: '21px',
          maxWidth: 300,
          mt: 3,
          textAlign: 'center',
        }}
      >
        {emptyHelperText}
      </Typography>
      {permissionToCreate && (
        <Button
          color="info"
          variant="outlined"
          size="small"
          sx={{ minWidth: 112, mt: 5 }}
          onClick={createItemHandler}
        >
          {buttonLabel}
        </Button>
      )}
    </Stack>
  );
  useEffect(() => {
    if (searchQuery) {
      const newItemList = items.filter((item: Item) =>
        filterItems(searchQuery, item),
      );
      const isSuggestingNewItem = newItemList.length === 0;
      setNewItemSuggest(isSuggestingNewItem);
      setItemList(newItemList);
      return;
    }
    setNewItemSuggest(false);
    setItemList(items);
  }, [searchQuery]);
  useEffect(() => {
    setItemList(items);
  }, [items]);
  return (
    <Card
      sx={{
        bgcolor: 'primary.main',
        borderRadius: 2,
        color: 'common.white',
      }}
    >
      <CardContent sx={{ height: 400, p: 0, ':last-child': { pb: 0 } }}>
        {cardContent}
      </CardContent>
    </Card>
  );
};

export default CardGroup;
