import { gql } from '@apollo/client';

const discountReportsAnalyse = gql`
  query DiscountReportsAnalyseQuery($input: DiscountReportsAnalyseInput!) {
    getDiscountReportsAnalyse(input: $input) {
      listDiscountsByType {
        items {
          id
          title
          price
          valueType
          enabled
          activies
        }
        meta {
        totalItems
        itemCount
        totalPages
        currentPage
      }
      }
    }
  }
`;

export default discountReportsAnalyse;
