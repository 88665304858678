import { gql } from '@apollo/client';

const updateCategoryMutation = gql`
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
      name
      slug
    }
  }
`;

export default updateCategoryMutation;
