import {
  Card,
  CardContent,
  Switch,
  Stack,
  Typography,
} from '@material-ui/core';
import { TogglerCardProps } from './typings';

const TogglerCard = ({ label, info, SwitchProps, sx }: TogglerCardProps) => (
  <Card sx={sx}>
    <CardContent>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography sx={{ color: 'grey.100', fontWeight: 600 }}>
          {label}
        </Typography>
        <Switch
          sx={{ marginLeft: 'auto' }}
          {...SwitchProps}
        />
      </Stack>
      <Typography sx={{ color: 'grey.300', fontSize: 14, mt: 4 }}>
        {info}
      </Typography>
    </CardContent>
  </Card>
);

export default TogglerCard;
