import { Discount } from './Discount';
import { Plan } from './Plan';

export enum DealStatus {
  activation = 'activation',
  reactivation = 'reactivation',
  renewal = 'renewal',
  reactivationPreCanceled = 'reactivationPreCanceled',
}

interface DiscountPlanHistories {
  discount: Discount;
}

export interface History {
  dealStatus: DealStatus;
  plan: Plan;
  discountPlanHistories: DiscountPlanHistories[];
  createdAt: Date;
  netValue: number;
}
