import { gql } from '@apollo/client';

const listCategoriesQuery = gql`
  query ListCategories {
    categories {
      id
      name
      slug
      tags {
        id
        name
        slug
      }
    }
  }
`;
export default listCategoriesQuery;
