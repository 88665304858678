import { gql } from '@apollo/client';

const GetDiscount = gql`
  query GetDiscount($id: ID!) {
    discount(id: $id) {
      title
      code
      price
      type
      valueType
      recurrence
      enabled
      minimumPurchaseMonth
      usageLimit
      usageUnlimited
      tag {
        id
        name
      }
      plans {
        id
        title
        type
      }
    }
  }
`;

export default GetDiscount;
