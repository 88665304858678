import { useMutation } from '@apollo/client';
import { Alert, OutlinedInput, Stack, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginMutation from './gql/LoginMutation';

interface LoginInput {
  email: string;
  password: string;
}

const Login = () => {
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const navigate = useNavigate();
  const [login] = useMutation(LoginMutation);
  const form = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit: async (input: LoginInput) => {
      try {
        setInvalidCredentials(false);
        const { data } = await login({ variables: { input } });
        localStorage.setItem('accessToken', data.login.accessToken);
        navigate('/library');
      } catch (err: any) {
        const containsInvalidCredentials =
          err.graphQLErrors?.[0]?.extensions?.code === 'BAD_USER_INPUT';
        if (containsInvalidCredentials) {
          setInvalidCredentials(true);
        }
      }
    },
  });
  return (
    <Stack
      sx={{
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
        px: '80px', /// TODO: add this to theme
        width: '100%',
      }}
    >
      <Stack sx={{ width: '100%' }}>
        <Typography sx={{ color: 'common.white', fontSize: 32, mb: 4 }}>
          Bem vindo ao <b>Spalla</b>
        </Typography>
        <Typography sx={{ color: 'grey.300', fontSize: 16, mb: 6 }}>
          Faça o login com o email registrado na plataforma.
        </Typography>
        <form onSubmit={form.handleSubmit}>
          <OutlinedInput
            data-testid="input-email"
            placeholder="Seu email"
            value={form.values.email}
            name="email"
            error={!!form.errors.email}
            fullWidth
            sx={{ bgcolor: 'grey.100', color: 'primary.light', height: 51 }}
            onChange={form.handleChange}
          />
          <OutlinedInput
            data-testid="input-password"
            placeholder="Sua senha"
            type="password"
            error={!!form.errors.password}
            value={form.values.password}
            name="password"
            fullWidth
            sx={{
              bgcolor: 'grey.100',
              color: 'primary.light',
              height: 51,
              mt: 4,
            }}
            onChange={form.handleChange}
          />
          {invalidCredentials && (
            <Alert
              data-testid="wrongCredentialsMsg"
              variant="filled"
              severity="error"
              sx={{ mt: 3 }}
            >
              As credenciais estão incorretas.
            </Alert>
          )}
          <LoadingButton
            data-testid="submit-button"
            type="submit"
            disabled={form.isSubmitting}
            loading={form.isSubmitting}
            fullWidth
            sx={{
              color: 'common.white',
              fontSize: 16,
              fontWeight: 600,
              height: 60,
              mt: 6,
            }}
          >
            Entrar agora
          </LoadingButton>
        </form>
      </Stack>
    </Stack>
  );
};

export default Login;
