// TODO: Remove when Craft.js support settings props without param reassign
/* eslint-disable no-param-reassign */
import { useNode } from '@craftjs/core';
import { Stack, MenuItem, Select, Typography, SelectChangeEvent } from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  TextFormat as TextFormatIcon,
} from '@material-ui/icons/';
import { useState } from 'react';
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';
import ColorPicker from '../../components/ColorPicker';
import SidebarAccordion from '../../components/SidebarAccordion';
import { NodePropsType } from './typings';

interface TextProps extends Object {
  color?: string;
  fontFamily?: string;
  fontSize?: number;
  text?: string;
}

const TextsSettings = () => {
  const [expanded, setExpanded] = useState(true);
  const {
    actions: { setProp },
    props: { color, fontFamily, fontSize, text },
  }: NodePropsType<TextProps> = useNode((node) => ({ props: node.data.props }));
  const changeTextHandler = (value: string) => {
    setProp((currentProps) => { currentProps.text = value });
  };
  // TODO: Fix SelectChangeEvent value typing - (evt: SelectChangeEvent<number>)
  // Error: Type 'string | number' is not assignable to type 'number | undefined'.
  // Type 'string' is not assignable to type 'number | undefined'.
  const fontSizeHandler = (evt: SelectChangeEvent<any>) => {
    setProp((currentProps) => { currentProps.fontSize = evt.target.value });
  };
  const textColorHandler = (currentColor: string) => {
    setProp((currentProps) => { currentProps.color = currentColor });
  };
  const fontFamilyHandler = (evt: SelectChangeEvent<string>) => {
    setProp((currentProps) => { currentProps.fontFamily = evt.target.value });
  };
  return (
    <SidebarAccordion
      expanded={expanded}
      icon={
        <TextFormatIcon
          sx={{
            color: 'secondary.main',
            height: 24,
            width: 24,
          }}
        />
      }
      title="Opções do texto"
      onExpand={() => setExpanded(!expanded)}
    >
      <Typography sx={{ color: 'grey.300', fontSize: 14, fontWeight: 400 }}>
        Seu texto{' '}
        <Typography component="span" sx={{ color: 'error.main', fontSize: 14 }}>
          *
        </Typography>
      </Typography>
      <Stack sx={{ mb: 6 }}>
        <RichTextEditor
          value={text || 'Digite seu texto'}
          onChange={changeTextHandler}
        />
      </Stack>
      <Typography color="grey.300" fontSize={14}>
        Fonte do texto:
      </Typography>
      <Select
        displayEmpty
        IconComponent={ExpandMoreIcon}
        sx={{
          backgroundColor: 'grey.500',
          border: 1,
          borderColor: 'grey.400',
          color: 'grey.300',
          width: 222,
          '.MuiSelect-icon': { color: 'secondary.main' },
        }}
        value={fontFamily || 'Arial'}
        onChange={fontFamilyHandler}
      >
        <MenuItem value="Arial">Arial</MenuItem>
        <MenuItem value="Consolas">Consolas</MenuItem>
        <MenuItem value="Poppins">Poppins</MenuItem>
      </Select>
      <Typography color="grey.300" fontSize={14}>
        Tamanho da fonte:
      </Typography>
      <Select
        displayEmpty
        IconComponent={ExpandMoreIcon}
        sx={{
          backgroundColor: 'grey.500',
          border: 1,
          borderColor: 'grey.400',
          color: 'grey.300',
          width: 222,
          '.MuiSelect-icon': { color: 'secondary.main' },
        }}
        value={fontSize || 20}
        onChange={fontSizeHandler}
      >
        <MenuItem value={32} key={32}>
          Extra grande - 32px
        </MenuItem>
        <MenuItem value={24} key={24}>
          Grande - 24px
        </MenuItem>
        <MenuItem value={20} key={20}>
          Médio - 20px
        </MenuItem>
        <MenuItem value={14} key={14}>
          Pequeno - 14px
        </MenuItem>
      </Select>
      <ColorPicker
        color={color || '#FFFFFF'}
        name="Cor do texto"
        onChange={textColorHandler}
      />
    </SidebarAccordion>
  );
};

export default TextsSettings;
