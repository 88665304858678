import { useMutation, useQuery } from '@apollo/client';
import { Box, Stack } from '@material-ui/core';
import { useAuthorize } from '@tshio/react-router-permissions';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDialog } from '../../components/Dialog';
import HasNoPermission from '../../components/HasNoPermission';
import TipAlert from '../../components/UI/molecules/TipAlert';
import FormFooter from '../../components/UI/templates/FormFooter';
import Context from '../../Context';
import { Plan, PlanType, Section } from '../../interfaces/Plan';
import getMultiSectionQuery from '../HighlightSections/gql/getMultiSectionQuery';
import Details from './Components/Details';
import MembershipsAndAmounts from './Components/MembershipsAndAmounts';
import PaymentBonds from './Components/PaymentBonds';
import Payments from './Components/Payments';
import Header from './Components/Header';
import ScreensAndTrialPeriod from './Components/ScreensAndTrialPeriod';
import SectionsAndContents from './Components/SectionsAndContents';
import CreatePlanMutation from './gql/createPlanMutation';
import validationSchema from './tools/validationSchema';
import CreatePlanContext from './Context';

type CreatePlanInput = Omit<
  Plan,
  'id' | 'createdAt' | 'updatedAt' | 'deletedAt'
>;

const CreatePlan = () => {
  const [params] = useSearchParams();
  const type = params.get('type');
  const typeIsNotFree = type !== PlanType.free;
  const { me } = useContext(Context);
  const permissionForEverything = me.superAdmin;
  const permissionToCreate =
    permissionForEverything || useAuthorize(['plans', 'create']);
  const hasNoPermissionToCreate = !permissionToCreate;
  if (hasNoPermissionToCreate) return <HasNoPermission />;
  const dialog = useDialog();
  const alert = useAlert();
  const navigate = useNavigate();
  const { loading, data: multiSection } = useQuery(getMultiSectionQuery);
  const [createPlan, { loading: isUpdating }] = useMutation(CreatePlanMutation);
  const form = useFormik<any>({
    initialValues: {
      title: 'Novo plano',
      description: '',
      published: true,
      price: 0,
      membershipsCount: 0,
      unlimitedMemberships: false,
      trialDays: 0,
      trialDaysIsActive: false,
      installments: 1,
      nextSeasonDate: null,
      type,
      sortableHighlightedContents: [],
      paymentType: [],
      partners: [],
      sections: [],
    },
    validationSchema,
    onSubmit: async (values: CreatePlanInput) => {
      const multiSectionIsSelected = values.sections.some(
        (section: Section) => section.id === multiSection?.multiSection.id,
      );
      const removeMultiSection = values.sections.filter(
        (section: Section) => section.id !== multiSection?.multiSection.id,
      );
      const newSections = multiSectionIsSelected
        ? removeMultiSection
        : values.sections;
      const newMultiSection = multiSectionIsSelected
        ? { id: multiSection?.multiSection.id }
        : null;
      try {
        const newValues = {
          ...values,
          sections: newSections,
          multiSection: newMultiSection,
        };
        const input = validationSchema.cast(
          newValues,
        ) as unknown as CreatePlanInput;
        const { data } = await createPlan({ variables: { input } });
        navigate(`/plan/${data.createPlan.id}`);
        alert.success('Plano criado com sucesso.');
      } catch (err: any) {
        const planAlreadyExists =
          !!err.graphQLErrors?.[0].message.includes('planAlreadyExists');
        if (planAlreadyExists) {
          form.setFieldError('title', 'Um plano com este nome já existe.');
        }
      }
    },
  });
  const cancelCreatePlanHandler = async () => {
    const confirm = await dialog.confirm({
      title: 'Cancelar criação',
      message:
        'Cancelar a criação fará com que você perca todas as configurações realizadas neste plano. ',
    });
    if (confirm) {
      navigate(`/plans?type=${type}`);
    }
  };
  const hasErrors = !form.isValid;
  const planTypeIsNotPartners = form.values.type !== PlanType.partner;
  const planTypeIsNotFreeOrPartners =
    form.values.type !== PlanType.free && planTypeIsNotPartners;
  if (loading) {
    return <div>loading...</div>;
  }
  return (
    <CreatePlanContext.Provider value={{ form }}>
      <>
        <Header route={`/plans?type=${type}`} title="Criação do Plano" />
        {typeIsNotFree && <PaymentBonds />}
        <TipAlert sx={{ mt: typeIsNotFree ? 4 : 6 }}>
          Crie os planos necessários para cada oferta de serviço e selecione as
          páginas necessárias para cada etapa do espectador na plataforma.
        </TipAlert>
        <Stack direction="row" gap={4} sx={{ mt: 6 }}>
          <Box>
            <Details />
            <SectionsAndContents />
            {planTypeIsNotFreeOrPartners && <ScreensAndTrialPeriod />}
          </Box>
          <Box>
            <MembershipsAndAmounts />
            <Payments />
          </Box>
        </Stack>
        <FormFooter
          LeftButtonProps={{
            children: 'Salvar plano',
            type: 'submit',
            loading: isUpdating,
            onClick: () => form.handleSubmit(),
          }}
          helperText={hasErrors ? 'Verifique os campos acima.' : ''}
          helperTextProps={{ color: 'error.main' }}
          RightButtonProps={{
            children: 'Cancelar criação',
            onClick: () => cancelCreatePlanHandler(),
          }}
          sx={{ mt: 6 }}
        />
      </>
    </CreatePlanContext.Provider>
  );
};

export default CreatePlan;
