import { Spectator } from '../../../interfaces/Spectator';

interface ListSpectator {
  user: Spectator;
}

export const mountObjectSpectatorsForDownload = (
  listSpectators: ListSpectator[],
) => {
  const newSpectators = listSpectators.map((spectator: ListSpectator) => ({
    Nome: spectator.user?.name,
    Email: spectator.user?.email,
    CPF: spectator.user?.cpf,
    Telefone: spectator.user?.phone,
    'Time do coração': spectator.user?.signupAnswer?.tag?.name,
    'Data de cadastro': spectator.user?.createdAt,
    'Data de cancelamento': spectator.user?.deletedAt || '',
  }));
  return newSpectators;
};
