import { gql } from '@apollo/client';

const createRoleMutation = gql`
  mutation CreateRoleMutation($input: CreateRoleInput!) {
    createRole(input: $input) {
      id
    }
  }
`;

export default createRoleMutation;
