import { gql } from '@apollo/client';

const changeUserAnswerMutation = gql`
  mutation ChangeUserAnswer($input: ChangeUserAnswerInput!) {
    changeUserAnswer(input: $input) {
      id
    }
  }
`;

export default changeUserAnswerMutation;
