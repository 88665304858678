import { Avatar, Box, Button, Radio, Stack } from '@material-ui/core';
import withoutTeamAvatar from '../../../assets/png/withoutTeamAvatar.png';
import { Avatar as IAvatar } from '../../../interfaces/HeartTeam';

interface AvatarItemProps {
  avatar?: IAvatar;
  changeEnabledAvatar?: (avatar: IAvatar) => void;
  removeAvatarHandler?: (avatar: IAvatar) => void;
}

const AvatarItem = ({
  changeEnabledAvatar,
  avatar,
  removeAvatarHandler,
}: AvatarItemProps) => {
  const avatarIsEmpty = !avatar;
  const allowedRemoveAvatar = avatar && removeAvatarHandler;
  const allowedEditAvatar = avatar && changeEnabledAvatar;
  return (
    <Box
      sx={{
        bgcolor: 'grey.600',
        borderColor: avatar?.enabled ? 'secondary.main' : 'grey.600',
        borderRadius: 1,
        borderStyle: 'solid',
        borderWidth: 2,
        height: 175,
        px: 3,
        py: 2,
        width: 128,
      }}
    >
      <Radio
        checked={avatar?.enabled}
        disabled={avatarIsEmpty}
        sx={{
          p: 1,
          '.MuiSvgIcon-root': { color: 'grey.500' },
          '&.Mui-checked': {
            '& svg:last-of-type path': {
              color: 'secondary.main',
            },
          }
        }}
        onChange={() => allowedEditAvatar && changeEnabledAvatar(avatar)}
      />
      <Stack alignItems="center" justifyContent="center" spacing={2}>
        <Avatar
          src={avatar?.url || withoutTeamAvatar}
          sx={{ height: 80, width: 80 }}
        />
        <Button
          disabled={avatarIsEmpty}
          sx={{ width: '100%' }}
          onClick={() => allowedRemoveAvatar && removeAvatarHandler(avatar)}
        >
          Excluir
        </Button>
      </Stack>
    </Box>
  );
};

export default AvatarItem;
