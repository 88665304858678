import {
  Box,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from '@material-ui/core';
import { useContext } from 'react';
import { getSymbolByValue, ValueType } from '../../../interfaces/Coupon';
import { DiscountType } from '../../../interfaces/Discount';
import {
  getFloatPrice,
  normalizePrice,
  preventFloatingNumbers,
} from '../../../utils/number';
import Context from '../Context';
import AssociatedPlans from './AssociatedPlans';
import FormCard from './FormCard';
import RecurrenceCard from './RecurrenceCard';

const DiscountRules = () => {
  const { form, type } = useContext(Context);
  const isTypeDiscountInternal = DiscountType.Internal === type;
  const isTypeDiscountRetention = DiscountType.Retention === type;
  const isTypeCoupon = DiscountType.Coupon === type;
  return (
    <>
      <FormCard
        title="Título"
        description="Determine um título para o desconto que será aplicado para os espectadores."
        isRequired
      >
        <OutlinedInput
          name="title"
          value={form.values.title}
          sx={{
            borderColor: 'grey.400',
            borderStyle: 'solid',
            borderWidth: '1px',
            height: 37,
            mt: 4,
            width: 243,
          }}
          onChange={form.handleChange}
        />
        <FormHelperText error>{form.errors.title}</FormHelperText>
      </FormCard>
      {isTypeCoupon && (
        <FormCard
          title="Código"
          description="Este é o código que será aplicado pelo espectador ao utilizar o cupom."
          isRequired
          sx={{ mt: 4 }}
        >
          <OutlinedInput
            name="code"
            value={form.values.code}
            sx={{
              borderColor: 'grey.400',
              borderStyle: 'solid',
              borderWidth: '1px',
              height: 37,
              mt: 4,
              width: 243,
            }}
            onChange={(evt) => {
              const code = evt.target.value;
              const removeSpaces = code.replace(/\s/g, '');
              const transformUpperCase = removeSpaces.toUpperCase();
              form.setFieldValue('code', transformUpperCase);
            }}
          />
          <FormHelperText error>{form.errors.code}</FormHelperText>
        </FormCard>
      )}
      <FormCard
        title="Valor"
        description="Defina o valor do cupom e se o desconto será um valor fixo ou percentual."
        isRequired
        sx={{ mt: 4 }}
      >
        <Stack alignItems="center" direction="row" sx={{ gap: 5, mt: 4 }}>
          <Box>
            <InputLabel
              sx={{
                color: 'grey.300',
                fontSize: 14,
                mb: 1,
              }}
            >
              Valor do desconto
            </InputLabel>
            <OutlinedInput
              name="price"
              value={normalizePrice(form.values.price || 0)}
              type="number"
              sx={{
                borderColor: 'grey.400',
                borderStyle: 'solid',
                borderWidth: '1px',
                height: 37,
                width: 243,
              }}
              onChange={(e) => {
                e.target.value = getFloatPrice(e.target.value);
                form.handleChange(e);
              }}
              onKeyDown={(e) => preventFloatingNumbers(e)}
            />
          </Box>
          <Box>
            <InputLabel
              sx={{
                color: 'grey.300',
                fontSize: 14,
                mb: 1,
              }}
            >
              Tipo
            </InputLabel>
            <Select
              name="valueType"
              value={form.values.valueType}
              displayEmpty
              renderValue={() => getSymbolByValue(form.values.valueType)}
              sx={{
                borderColor: 'grey.400',
                borderStyle: 'solid',
                borderWidth: '1px',
                width: 72,
              }}
              onChange={form.handleChange}
            >
              <MenuItem value={ValueType.value}>R$</MenuItem>
              <MenuItem value={ValueType.percentage}>%</MenuItem>
            </Select>
          </Box>
        </Stack>
        <FormHelperText error>{form.errors.price}</FormHelperText>
      </FormCard>
      {isTypeDiscountInternal && <AssociatedPlans />}
      {isTypeDiscountRetention && <RecurrenceCard />}
    </>
  );
};
export default DiscountRules;
