import { Theme } from '@material-ui/core';
import { Components } from '@material-ui/core/styles/components';

const makeMuiCardContent = (theme: Theme): Components['MuiCardContent'] => ({
  styleOverrides: {
    root: { padding: theme.spacing(5) },
  },
});

export default makeMuiCardContent;
