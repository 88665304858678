import { gql } from '@apollo/client';

const updateCollectiveDiscountMutation = gql`
  mutation UpdateCollectiveDiscountMutation($input: UpdateCollectiveDiscountInput!) {
    updateCollectiveDiscount(input: $input) {
      id
    }
  }
`;

export default updateCollectiveDiscountMutation;