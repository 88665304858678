import { Box, Typography } from '@material-ui/core';
import { SxProps, Theme } from '@material-ui/system';
import { ReactNode } from 'react';

interface ValueGroupProps {
  title: string;
  value: number | string;
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

const Title = ({ title }: { title: ValueGroupProps['title'] }) => (
  <Typography sx={{ color: 'grey.400', fontSize: 14 }}>{title}</Typography>
);
const Value = ({ value }: { value: ValueGroupProps['value'] }) => (
  <Typography sx={{ color: 'grey.100', fontSize: 14 }}>{value}</Typography>
);

export const ValueGroup = ({ title, value, sx, children }: ValueGroupProps) => (
  <Box sx={sx}>
    <Title title={title} />
    <Value value={value} />
    {children}
  </Box>
);
