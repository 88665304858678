import {
  Card,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { memo, useContext } from 'react';
import InlineTextEditor from '../../../components/InlineTextEditor';
import Context from '../Context';

const Details = memo(() => {
  const { form, isEditing } = useContext(Context);
  if (!form.values.title) {
    return <div>Carregando...</div>;
  }
  return (
    <Card sx={{ height: 317, mb: 4, p: 6, width: 679 }}>
      {isEditing ? (
        <Typography sx={{ color: 'grey.100', fontSize: 20 }}>
          {form.values.title}
        </Typography>
      ) : (
        <InlineTextEditor
          value={form.values.title}
          inputProps={{ maxLength: 70 }}
          onChange={(text) => {
            if (text) {
              form.handleChange({ target: { name: 'title', value: text } });
            }
          }}
          TypographyProps={{ sx: { color: 'grey.100', fontSize: 20 } }}
        />
      )}
      <FormHelperText error>{form.errors.title}</FormHelperText>
      <InputLabel shrink sx={{ color: 'grey.300', fontSize: 14, mt: 5 }}>
        Descrição do plano *
      </InputLabel>
      <OutlinedInput
        data-testid="input-description"
        name="description"
        error={form.touched.description && !!form.errors.description}
        value={form.values.description}
        readOnly={isEditing}
        multiline
        rows={8}
        sx={{
          color: 'grey.300',
          height: 180,
          mt: 1,
          px: 3,
          py: 2,
          width: 610,
          '.MuiInputBase-inputMultiline': {
            color: 'grey.100',
            fontSize: 12,
          },
        }}
        onChange={form.handleChange}
      />
      <FormHelperText error>
        {form.touched.description && form.errors.description}
      </FormHelperText>
    </Card>
  );
});

export default Details;
