import { Question } from '../pages/Onboarding/typings';
import { Discount } from './Discount';
import { HeartTeam } from './HeartTeam';
import { DealStatus } from './History';
import { Plan, PlanStatus } from './Plan';
import { Tag } from './Tag';

export enum GatewayPayment {
  guiteway = 'guiteway',
  mobiaccess = 'mobiaccess',
  pagarme = 'pagarme',
  stripe = 'stripe',
}

export enum PaymentMethod {
  credit = 'credit',
  debit = 'debit',
  pix = 'pix',
  gratis = 'gratis',
  partner = 'partner',
}

interface DiscountPlanHistory {
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  id: string;
  startedAt: Date;
  finishedAt: Date;
  discount: Discount;
}

interface PaymentHistory {
  id: string;
  gatewayPayment: GatewayPayment;
  metadata: JSON;
  paymentMethod: PaymentMethod;
  lastFourCardDigits: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  cardBrand: string;
}

interface UserPlanHistory {
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  id: string;
  status: PlanStatus;
  dealStatus: DealStatus;
  netValue: number;
  trialStartedAt: Date;
  trialFinishedAt: Date;
  plan: Plan;
  discountPlanHistories: DiscountPlanHistory[];
  paymentHistory: PaymentHistory;
}

export interface UserPlan {
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  id: string;
  trialStartedAt: Date;
  trialFinishedAt: Date;
  startedAt: Date;
  recurrenceDay: number;
  plan: Plan;
  userPlanHistory: UserPlanHistory;
}

export interface SignupAnswer {
  question?: Question;
  tag: Tag;
}
export interface Spectator {
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  id: string;
  name: String;
  email: String;
  phone: String;
  cpf: String;
  usercpf: String;
  paymentHistory: PaymentHistory[];
  signupAnswer: SignupAnswer;
  favoriteTeam: HeartTeam;
  userPlans: UserPlan[];
  userPlansHistory: UserPlanHistory[];
}
