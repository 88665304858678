import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { useAuthorize } from '@tshio/react-router-permissions';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate, useParams } from 'react-router-dom';
import { useDialog } from '../../components/Dialog';
import HasNoPermission from '../../components/HasNoPermission';
import { Content } from '../../interfaces/Content';
import Context from '../../Context';
import deleteContentMutation from '../Library/gql/deleteContentMutation';
import Container from './components/Container';
import contentQuery from './gql/contentQuery';
import EditContentContext from './context';
import updateContentMutation from './gql/updateContentMutation';
import validationSchema from './Providers/validationSchema';

const EditContent = () => {
  const { me } = useContext(Context);
  const permissionForEverything = me.superAdmin;
  const permissionToUpdate =
    permissionForEverything || useAuthorize(['contents', 'update']);
  const hasNoPermissionToUpdate = !permissionToUpdate;
  if (hasNoPermissionToUpdate) return <HasNoPermission />;
  const alert = useAlert();
  const navigate = useNavigate();
  const { contentId } = useParams();
  const dialog = useDialog();
  const { loading, data, refetch } = useQuery<{ content: Content }>(
    contentQuery,
    { variables: { id: contentId } },
  );
  const [deleteContent] = useMutation(deleteContentMutation);
  const [updateContent, { loading: isUpdating }] = useMutation(
    updateContentMutation,
  );
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.content.id,
      title: data?.content.title,
      description: data?.content.description,
      thumbnail: data?.content.thumbnail,
      type: data?.content.type,
      authorizedOnMobile: data?.content.authorizedOnMobile,
      authorizedOnDesktop: data?.content.authorizedOnDesktop,
      authorizedOnTv: data?.content.authorizedOnTv,
      published: data?.content.published,
      scheduleDate: data?.content.scheduleDate,
      scheduleDateEnabled: data?.content.scheduleDateEnabled,
      finishDate: data?.content.finishDate,
      finishDateEnabled: data?.content.finishDateEnabled,
      externalContent: data?.content.externalContent,
      externalEmbed: data?.content.externalEmbed,
      externalUrl: data?.content.externalUrl,
      spallaProvider: !data?.content.mclsContent,
      mclsContent: data?.content.mclsContent,
      tags: data?.content.tags,
      teams: data?.content.teams,
      updatedAt: data?.content.updatedAt,
      chatEnable: data?.content.chatEnable ? data?.content.chatEnable : false,
      chatEmbed: data?.content.chatEmbed ? data?.content.chatEmbed : '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const { spallaProvider, ...rest } = validationSchema.cast(values);
      await updateContent({
        variables: {
          input: {
            ...rest,
            eventDate: rest.externalContent
              ? rest.scheduleDate
              : data?.content.eventDate,
          },
        },
      });
      alert.success('Definições guardadas com sucesso!');
    },
  });
  const deleteContentHandler = async () => {
    const confirmDeletion = await dialog.confirm({
      title: `Deseja deletar o conteúdo?`,
    });
    if (confirmDeletion) {
      await deleteContent({ variables: { id: contentId } });
      navigate('/library', { replace: true });
    }
  };
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <EditContentContext.Provider
      value={{ id: contentId, form, refetch, isUpdating }}
    >
      <Container onDelete={deleteContentHandler} />
    </EditContentContext.Provider>
  );
};

export default EditContent;
