import { Button, Stack } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { sortBy } from 'lodash';
import { useEffect, useState, memo } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Topbar from '../../components/Topbar';
import TipAlert from '../../components/UI/molecules/TipAlert';
import { Highlight } from '../../interfaces/Highlight';
import reorder from '../../utils/reorderList';
import UpdateHighlightedContentMutation from '../EditHighlights/gql/updateHighlightedContentMutation';
import EmptyPage from './Components/EmptyPage';
import HighlightItem from './Components/HighlightItem';
import highlightedContentsQuery from './gql/highlightedContentsQuery';

const MemoizedHighlightsList = memo(
  ({ highlights }: { highlights: Highlight[] }) => (
    <>
      {highlights.map((highlightedContent: Highlight, index: number) => (
        <HighlightItem
          key={highlightedContent.id}
          highlightedContent={highlightedContent}
          index={index}
        />
      ))}
    </>
  ),
);

const Highlights = () => {
  const navigate = useNavigate();
  const { loading, data } = useQuery(highlightedContentsQuery);
  const [updateHighlightedContent] = useMutation(
    UpdateHighlightedContentMutation,
  );
  const [highlightedContents, setHighlightedContents] = useState<Highlight[]>(
    [],
  );
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const newHighlightedContents = reorder(
      highlightedContents,
      result.source.index,
      result.destination.index,
    );
    setHighlightedContents(newHighlightedContents);
    const highlightedContentToUpdate = newHighlightedContents[
      result.destination.index
    ] as Highlight;
    await updateHighlightedContent({
      variables: {
        input: {
          id: highlightedContentToUpdate.id,
          position: result.destination.index + 1,
        },
      },
    });
  };
  useEffect(() => {
    const sortByAsc = sortBy(data?.highlightedContents || [], 'position');
    setHighlightedContents(sortByAsc as Highlight[]);
  }, [data]);
  const emptyHighlightedContent = data?.highlightedContents.length === 0;
  if (loading) return <div>Carregando...</div>;
  return (
    <>
      <Topbar
        title="Destaques"
        description="Lista com os destaques da sua plataforma."
      >
        <Button onClick={() => navigate('/highlights/create')}>
          Novo destaque
        </Button>
      </Topbar>
      <TipAlert sx={{ mb: 4, mt: 4 }}>
        Você pode criar mais de um destaque para cada conteúdo e escolher qual
        quer utilizar dentro das definições dos seus planos.
      </TipAlert>
      {emptyHighlightedContent ? (
        <EmptyPage pathToNavigate="/highlights/create" />
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <Stack
                ref={provided.innerRef}
                sx={{ mt: 4 }}
                {...provided.droppableProps}
              >
                <MemoizedHighlightsList highlights={highlightedContents} />
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default Highlights;
