import { Theme } from '@material-ui/core';
import { Components } from '@material-ui/core/styles/components';

const makeMuiInputBase = (theme: Theme): Components['MuiInputBase'] => ({
  styleOverrides: {
    root: {
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.grey[200],
      fontSize: 14, // TODO: export from theme
      height: theme.shape.sizes.medium,
      '& input::placeholder, & textarea::placeholder': {
        color: theme.palette.grey[300],
      },
      '.MuiOutlinedInput-input.Mui-disabled': {
        WebkitTextFillColor: `${theme.palette.grey[100]} !important`,
      }
    },
  },
});

export default makeMuiInputBase;
