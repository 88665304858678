import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from '@material-ui/core';
import { useContext, useEffect } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useDialog } from '../../../components/Dialog';
import { SortableHighlightedContents } from '../../../interfaces/Highlight';
import DialoghighlightedContents from '../../CreatePlan/Components/DialogHighlightedContents';
import Context from '../Context';
import SearchableHighlightsItem from './SearchableHighlightsItem';
import sortItems from '../../../utils/sortItems';

const SearchableHighlights = () => {
  const { form } = useContext(Context);
  const dialog = useDialog();
  const addHighlightsHandler = (
    highlightedContents: SortableHighlightedContents[],
  ) =>
    form.setFieldValue(
      'sortableHighlightedContents.sortableItems',
      highlightedContents,
    );
  const openDialogHighlightedContents = async () => {
    await dialog.open({
      title: 'Adicionar destaques',
      element: (
        <DialoghighlightedContents
          highlightedContents={
            form.values.sortableHighlightedContents
              ?.sortableItems as SortableHighlightedContents[]
          }
          onSelect={addHighlightsHandler}
        />
      ),
      sx: { height: 580, width: 667 },
    });
  };
  useEffect(() => {
    const sortHighlightsByPosition = () => {
      const sortedHighlight = sortItems(
        (form.values?.sortableHighlightedContents
          ?.sortableItems as SortableHighlightedContents[]) || [],
        'position',
      );
      return sortedHighlight;
    };
    const sortedHighlight = sortHighlightsByPosition();
    form.setFieldValue(
      'sortableHighlightedContents.sortableItems',
      sortedHighlight,
    );
  }, []);
  return (
    <Card sx={{ width: 494 }}>
      <CardContent sx={{ height: 571, p: 5 }}>
        <Typography sx={{ color: 'grey.100', fontWeight: 600 }}>
          Destaques
        </Typography>
        <Typography sx={{ color: 'grey.300', fontSize: 14, mt: 3 }}>
          Escolha quais destaques estarão disponíveis nessa seção.
        </Typography>
        <Button onClick={openDialogHighlightedContents} sx={{ mt: 4 }}>
          Adicionar destaques à seção
        </Button>
        <Divider sx={{ bgcolor: 'grey.500', my: 3 }} />
        <Droppable droppableId="highlightList" type="highlight">
          {(provided) => (
            <Stack
              ref={provided.innerRef}
              sx={{ height: 400, overflowY: 'auto' }}
              gap={2}
              {...provided.droppableProps}
            >
              {form.values!.sortableHighlightedContents?.sortableItems.map(
                (highlight: SortableHighlightedContents, index: number) => (
                  <SearchableHighlightsItem
                    key={highlight.highlightedContent.id}
                    highlight={highlight}
                    index={index}
                  />
                ),
              )}
            </Stack>
          )}
        </Droppable>
      </CardContent>
    </Card>
  );
};

export default SearchableHighlights;
