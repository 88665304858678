import { Box, Stack, Typography } from '@material-ui/core';
import { BurstMode as BurstModeIcon } from '@material-ui/icons';
import { memo, useContext } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import {
  SortableContent as ISortableContent,
  SubSection,
} from '../../../interfaces/Section';
import EditSectionContext from '../Context';
import SortableContent, { SortableContentProps } from './SortableContent';

interface MemoSortableContentListProps {
  sortableContents: ISortableContent[];
  deleteContent: SortableContentProps['deleteContent'];
}

const MemoSortableContentList = memo(
  ({ sortableContents, deleteContent }: MemoSortableContentListProps) => (
    <>
      {sortableContents.map((sortableContent, index: number) => (
        <SortableContent
          key={sortableContent.content.id}
          index={index}
          sortableContent={sortableContent}
          deleteContent={deleteContent}
        />
      ))}
    </>
  ),
);

const SortableContentList = () => {
  const { form, section, selectedSubSection } = useContext(EditSectionContext);
  const isGeneralSubSectionSelected =
    selectedSubSection!.id === section.generalSubSection.id;
  const deleteContentHandler = (sortableContentToDelete: ISortableContent) => {
    if (isGeneralSubSectionSelected) {
      const newSortableContents =
        form.values.generalSubSection!.sortableContents.filter(
          (sortableContent: ISortableContent) =>
            sortableContent.content.id !== sortableContentToDelete.content.id,
        );
      form.setFieldValue(
        'generalSubSection.sortableContents',
        newSortableContents,
      );
    } else {
      const selectedSubSectionIndex = form.values.subSections!.findIndex(
        (subSection: SubSection) => subSection.id === selectedSubSection!.id,
      );
      const newSortableContents = form.values.subSections![
        selectedSubSectionIndex
      ].sortableContents.filter(
        (sortableContent: ISortableContent) =>
          sortableContent.content.id !== sortableContentToDelete.content.id,
      );
      form.setFieldValue(
        `subSections[${selectedSubSectionIndex!}].sortableContents`,
        newSortableContents,
      );
    }
  };
  const getSortableContents = () => {
    if (isGeneralSubSectionSelected) {
      return form.values.generalSubSection!.sortableContents;
    }
    const selectedSubSectionIndex = form.values.subSections!.findIndex(
      (subSection) => subSection.id === selectedSubSection!.id,
    );
    return form.values.subSections![selectedSubSectionIndex!].sortableContents;
  };
  const sortableContents = getSortableContents();
  return (
    <Box sx={{ borderRadius: 1, height: 122, mt: 5 }}>
      <Stack direction="row" spacing={5}>
        <Droppable
          droppableId="sortableContents"
          type="content"
          direction="horizontal"
        >
          {(provided) => (
            <Stack
              ref={provided.innerRef}
              direction="row"
              spacing={4}
              sx={{ mt: 4, overflowX: 'auto', width: '100%' }}
              {...provided.droppableProps}
            >
              {sortableContents.length === 0 ? (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{ height: 140 }}
                >
                  <BurstModeIcon
                    sx={{ color: 'info.main', height: 28, width: 28 }}
                  />
                  <Typography
                    sx={{
                      color: 'common.white',
                      fontSize: 20,
                      fontWeight: 600,
                      mt: 5,
                    }}
                  >
                    Sem conteúdo
                  </Typography>
                  <Typography
                    align="center"
                    sx={{
                      color: 'grey.300',
                      fontSize: 14,
                      mb: 5,
                      mt: 2,
                      width: 380,
                    }}
                  >
                    Arraste para aqui o seu conteúdo.
                  </Typography>
                </Stack>
              ) : (
                <MemoSortableContentList
                  sortableContents={sortableContents}
                  deleteContent={deleteContentHandler}
                />
              )}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </Stack>
    </Box>
  );
};

export default SortableContentList;
