import { Card, CardContent, Typography } from '@material-ui/core';
import { TileCardProps } from './typings';

const TileCard = ({ icon: Icon, title, value, sx }: TileCardProps) => (
  <Card sx={sx}>
    <CardContent>
      <Icon sx={{ color: 'secondary.dark', height: 32, width: 32 }} />
      <Typography
        sx={{ color: 'common.white', fontSize: 40, fontWeight: 600, mt: 6 }}
      >
        {value}
      </Typography>
      <Typography
        sx={{ color: 'grey.300', fontSize: 16, fontWeight: 600, mt: 1 }}
      >
        {title}
      </Typography>
    </CardContent>
  </Card>
);

export default TileCard;
