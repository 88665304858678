import { Theme } from '@material-ui/core';
import { Components } from '@material-ui/core/styles/components';

const makeMuiButton = (theme: Theme): Components['MuiButton'] => ({
  defaultProps: {
    color: 'primary',
    disableElevation: true,
    variant: 'contained',
  },
  styleOverrides: {
    root: {
      borderRadius: +theme.shape.borderRadius,
      color: theme.palette.common.white,
      fontSize: 14, // TODO: export from theme
      fontWeight: 600,
      height: theme.shape.sizes.large,
      textTransform: 'none',
    },
    sizeSmall: { fontSize: 12 },
    sizeLarge: { fontSize: 16 },
  },
  variants: [
    {
      props: { color: 'primary', variant: 'contained' },
      style: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
      },
    },
    {
      props: { color: 'secondary', variant: 'contained' },
      style: {
        backgroundColor: theme.palette.grey[500],
        color: theme.palette.common.white,
      },
    },
    {
      props: { color: 'primary', variant: 'outlined' },
      style: {
        backgroundColor: '#0f111f', // TODO: export from palette
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.light,
      },
    },
    {
      props: { color: 'error', variant: 'outlined' },
      style: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.main,
      },
    },
    {
      props: { color: 'warning', variant: 'outlined' },
      style: {
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.warning.main,
      },
    },
    {
      props: { color: 'info', variant: 'outlined' },
      style: {
        backgroundColor: 'rgba(137, 80, 252, 0.1)', // TODO: export from palette
        color: theme.palette.common.white,
      },
    },
    {
      props: { color: 'success', variant: 'outlined' },
      style: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.main,
      },
    },
    {
      props: { size: 'small' },
      style: {
        height: theme.shape.sizes.small,
      },
    },
    {
      props: { size: 'medium' },
      style: {
        height: theme.shape.sizes.medium,
      },
    },
  ],
});

export default makeMuiButton;
