import { gql } from '@apollo/client';

const getAllValuesFromOverview = gql`
  query GetAllValuesFromOverview {
    getAllActiveDiscountsCount
    getAllMembershipsCount
    getAllPublishedPlansCount
    getTop10Plans {
      id
      title
      type
      membershipsCount
    }
  }
`;

export default getAllValuesFromOverview;
