import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Share as ShareIcon,
  Star as StarIcon,
} from '@material-ui/icons';
import { useContext } from 'react';
import DialogListPlans from './DialogListPlans';
import MembershipsItem from './MembershipsItem';
import Context from '../Context';
import { sortByElement } from '../../PreviewPlan/utils/sort';
import { useDialog } from '../../../components/Dialog';
import PlanAdoptionList from '../../../components/PlanAdoptionList';
import TileCard from '../../../components/TileCard';
import TileCard6 from '../../../components/TileCard6';
import DialogPartners from './DialogPartners';
import partnersQuery from '../gql/partnersQuery';

const PlansAndMemberships = () => {
  const dialog = useDialog();
  const { loading, data } = useQuery(partnersQuery);
  if (loading) return <div>Carregando...</div>;
  const { valuesOverview } = useContext(Context);
  const reducedList = valuesOverview.getTop10Plans.slice(0, 4);
  const sortByLarger = reducedList
    .sort(sortByElement('membershipsCount'))
    .reverse();
  const openDialogListPlans = async () => {
    await dialog.open({
      title: 'Planos por Adesão',
      element: (
        <DialogListPlans
          listPlansByMemberships={valuesOverview.getTop10Plans}
        />
      ),
      sx: { height: 357, overflow: 'hidden', width: 570 },
    });
  };
  const openDialogViewPartners = async () => {
    await dialog.open({
      title: 'Afiliados e Marcas',
      element: <DialogPartners partners={data.partners} />,
    });
  };
  const renderShortList = sortByLarger.map((item, index) => (
    <MembershipsItem item={item} index={index} />
  ));
  return (
    <Grid container spacing={4} sx={{ mt: 0 }}>
      <Grid item xs={3}>
        <TileCard
          title="Planos publicados"
          value={`${valuesOverview.getAllPublishedPlansCount}`}
          icon={StarIcon}
          sx={{ height: '100%' }}
        />
      </Grid>
      <Grid item xs={3}>
        <TileCard6
          value={data.partners.length}
          helperText="Afiliados e Marcas cadastradas"
          buttonVisible
          icon={ShareIcon}
          sx={{ height: '100%', pb: 0 }}
          onClickViewPartners={() => openDialogViewPartners()}
        />
      </Grid>
      <Grid item xs={6}>
        <PlanAdoptionList
          title="Adesões por Planos"
          buttonIsVisible
          leftIcon={BarChartIcon}
          sx={{ width: 580, height: 238 }}
          onClickButton={openDialogListPlans}
        >
          {renderShortList}
        </PlanAdoptionList>
      </Grid>
    </Grid>
  );
};

export default PlansAndMemberships;
