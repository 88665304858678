import { Radio, Stack } from '@material-ui/core';

interface SelectableSettingProps {
  children: React.ReactNode;
  value: boolean;
  checked?: boolean;
  onSelect: () => void;
}

const SelectableSetting = ({
  children,
  checked = false,
  value,
  onSelect,
}: SelectableSettingProps) => (
  <Stack
    sx={{
      alignItems: 'center',
      backgroundColor: 'grey.600',
      borderRadius: 3,
      flexDirection: 'row',
      height: 72,
      p: 1,
      pr: 3,
    }}
  >
    <Radio checked={checked} value={value} onChange={() => onSelect()} />
    <Stack
      height={48}
      sx={{
        alignItems: 'center',
        backgroundColor: checked ? '#20133A' : 'grey.500', // TODO: Include color variation on theme
        border: checked ? '2px solid' : 'none',
        borderColor: 'info.main',
        borderRadius: 1,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        px: 3,
      }}
    >
      {children}
    </Stack>
  </Stack>
);

export default SelectableSetting;
