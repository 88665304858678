import { Button, Stack } from '@material-ui/core';

interface PageHeaderProps {
  hasButton: boolean;
  bgColor: string;
  buttonColor: string;
  buttonLink: string;
  textColor: string;
  buttonText: string;
}

const PageHeader = ({
  hasButton = false,
  bgColor = '#171717',
  buttonColor = '#DE2A2F',
  buttonLink = '',
  textColor = '#FFFFFF',
  buttonText = 'Assine agora!',
}: PageHeaderProps) => (
  <>
    <Stack
      alignItems="center"
      flexDirection="row"
      justifyContent={hasButton ? 'space-between' : 'center'}
      sx={{
        bgcolor: bgColor,
        height: 70,
        px: 9,
      }}
    >
      <img
        style={{ maxHeight: '50px' }}
        src="https://uilogos.co/img/logomark/leaf.png"
        alt="logo"
      />
      {hasButton && (
        <a href={buttonLink}>
          <Button
            sx={{
              bgcolor: buttonColor,
              color: textColor,
              '&:hover': { bgcolor: buttonColor },
            }}
          >
            {buttonText}
          </Button>
        </a>
      )}
    </Stack>
  </>
);

export default PageHeader;
