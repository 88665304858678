import { gql } from '@apollo/client';

const teamsQuery = gql`
  query teamsQuery($input: ListTeamsInput!) {
    teams(input: $input) {
      items {
        id
        name
        slug
        externalUrl
        avatars
      }
      meta {
        totalItems
        itemCount
        totalPages
        currentPage
      }
    }
  }
`;

export default teamsQuery;
