import { Box, Card, CardContent, Stack, Typography } from '@material-ui/core';
import { NewContentTileProps } from './typings';

const NewContentTile = ({
  icon: Icon,
  title,
  children,
  sx,
}: NewContentTileProps) => (
  <Card sx={{ height: 116, ...sx }}>
    <CardContent sx={{ py: 4 }}>
      <Stack direction="row" alignItems="center" sx={{ height: '100%' }}>
        <Icon sx={{ color: 'secondary.dark', height: 48, width: 48 }} />
        <Box sx={{ ml: 5 }}>
          <Typography sx={{ color: 'grey.100', fontWeight: 600, mb: 3 }}>
            {title}
          </Typography>
          {children}
        </Box>
      </Stack>
    </CardContent>
  </Card>
);

export default NewContentTile;
