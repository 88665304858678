import { createContext } from 'react';
import { Input } from './typings';

interface ContextType {
  input: Input;
  setInput: (input: Input) => void;
}

const Context = createContext({} as ContextType);

export default Context;
