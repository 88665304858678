import { useQuery } from '@apollo/client';
import { useState } from 'react';
import Topbar from '../../components/Topbar';
import ReportDownloadCard from './Components/Card';
import { Context, ReportsDownloadContext } from './Context';
import plansQuery from './gql/plansQuery';

const ReportsDownload = () => {
  const { loading: loadingPlans, data: plans } = useQuery(plansQuery, {
    variables: { input: { paginate: { page: 1, limit: 100 } } },
  });
  const [input, setInput] = useState<ReportsDownloadContext['input']>({
    filters: {
      planId: '',
      createdAtGte: null,
      createdAtLte: null,
      term: '',
    },
    paginate: { page: 1 },
  });
  if (loadingPlans) return <div>Carregando...</div>;
  return (
    <Context.Provider
      value={{
        input,
        setInput,
        plans: plans.plans.items,
      }}
    >
      <Topbar
        title="Download de relatórios"
        description="Tenha acesso ao download da lista de todos os relatórios refentes a plataforma."
      />
      <ReportDownloadCard
        title="Relatórios de planos"
        description="Tenha acesso a todas as informações referente aos espectadores da plataforma."
      />
    </Context.Provider>
  );
};

export default ReportsDownload;
