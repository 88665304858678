import { Discount } from '../../../interfaces/Plan';

export const sortByNewest = (
  prevDiscount: Discount,
  currentDiscount: Discount,
) => {
  const prevDiscountDate = new Date(prevDiscount.createdAt).getTime();
  const currentDiscountDate = new Date(currentDiscount.createdAt).getTime();
  const dateAreEquals = prevDiscountDate === currentDiscountDate;
  if (dateAreEquals) {
    return 0;
  }
  const prevDiscountDateIsBigger = prevDiscountDate > currentDiscountDate;
  if (prevDiscountDateIsBigger) {
    return -1;
  }
  return 1;
};

export const sortByOldest = (
  prevDiscount: Discount,
  currentDiscount: Discount,
) => {
  const prevDiscountDate = new Date(prevDiscount.createdAt).getTime();
  const currentDiscountDate = new Date(currentDiscount.createdAt).getTime();
  const dateAreEquals = prevDiscountDate === currentDiscountDate;
  if (dateAreEquals) {
    return 0;
  }
  const prevContentDateIsBigger = prevDiscountDate > currentDiscountDate;
  if (prevContentDateIsBigger) {
    return 1;
  }
  return -1;
};

export const sortByElement =
  (property: string) => (prevDiscount: any, currentDiscount: any) => {
    const isDate = property === 'startDate' || property === 'finishDate';
    let prev = prevDiscount[property];
    let current = currentDiscount[property];
    if (isDate) {
      prev = new Date(prev).getTime();
      current = new Date(current).getTime();
    }
    const propertyAreEquals = prev === current;
    const prevContentDateIsBigger = prev > current;
    if (propertyAreEquals) {
      return 0;
    }
    if (prevContentDateIsBigger) {
      return 1;
    }
    return -1;
  };
