import { gql } from '@apollo/client';

const createManagerMutation = gql`
  mutation CreateManagerMutation($input: CreateManagerInput!) {
    createManager(input: $input) {
      id
    }
  }
`;

export default createManagerMutation;
