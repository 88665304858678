import { Box, Card, Chip, Grid, Typography } from '@material-ui/core';
import {
  AccountBox as AccountBoxIcon,
  Check as CheckIcon,
  DateRange as DateRangeIcon,
  Restore as RestoreIcon,
  Star as StarIcon,
} from '@material-ui/icons/';
import { useContext, useMemo } from 'react';
import TileCard2 from '../../../components/TileCard2';
import { getFriendlyNameTheMonth, PlanType } from '../../../interfaces/Plan';
import { getFriendlyNameByPlanType } from '../../CreatePlan/Components/PaymentBonds';
import PreviewPlanContext from '../Context';

const Details = () => {
  const { plan } = useContext(PreviewPlanContext);
  const costFormat = plan.price.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
  const formatActivePeriod = useMemo(
    () =>
      `${getFriendlyNameTheMonth(plan.startMonth)} à ${getFriendlyNameTheMonth(
        plan.finishMonth,
      )}`,
    [plan.startMonth, plan.finishMonth],
  );
  const typePlanIsPartnersOrFree =
    plan.type === PlanType.partner || plan.type === PlanType.free;
  return (
    <Grid
      container
      spacing={4}
      sx={{ height: typePlanIsPartnersOrFree ? 239 : 353 }}
    >
      <Grid item xs={6}>
        <Card sx={{ height: '100%', p: 6 }}>
          <Typography sx={{ color: 'grey.100', fontSize: 24 }}>
            {plan.title}
          </Typography>
          <Chip
            variant="outlined"
            color={plan.published ? 'success' : 'info'}
            size="small"
            label={plan.published ? 'Publicado' : 'Inativo'}
            icon={plan.published ? <CheckIcon /> : <RestoreIcon />}
            sx={{ mb: 4, mt: 2, pl: 2 }}
          />
          <Box sx={{ maxHeight: 100, overflowY: 'auto' }}>
            <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
              {plan.description}
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid container item xs={6} spacing={4}>
        {!typePlanIsPartnersOrFree && (
          <Grid item xs={6}>
            <TileCard2
              title="Valor do plano"
              icon={StarIcon}
              value={costFormat}
              sx={{ height: '100%' }}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <TileCard2
            title="Adesões utilizadas"
            icon={AccountBoxIcon}
            value={`${plan.membershipsCount}`}
            sx={{ height: '100%' }}
          />
        </Grid>
        {!typePlanIsPartnersOrFree && (
          <Grid item xs={6}>
            <TileCard2
              title="Período ativo"
              icon={DateRangeIcon}
              value={
                plan.monthlyChargeEnabled ? formatActivePeriod : 'Indefinido'
              }
              sx={{ height: '100%' }}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <TileCard2
            title="Tipo de pagamento"
            icon={CheckIcon}
            value={getFriendlyNameByPlanType(plan.type)}
            sx={{ height: '100%' }}
          />
        </Grid>
        <Grid item xs={6}>
          <TileCard2
            title="Telas simultâneas"
            icon={CheckIcon}
            value="0 telas"
            sx={{ height: '100%' }}
          />
        </Grid>
        <Grid item xs={6}>
          <TileCard2
            title="Período de teste"
            icon={CheckIcon}
            value={plan.trialDaysIsActive ? '0' : plan.trialDays}
            sx={{ height: '100%' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Details;
