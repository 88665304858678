import { SmartButton as SmartButtonIcon } from '@material-ui/icons';
import CustomDragLayer from './CustomDragLayer';

const ButtonBlockDragLayer = () => (
  <CustomDragLayer type="ButtonBlock">
    <SmartButtonIcon sx={{ color: 'info.main', fontSize: 56 }} />
  </CustomDragLayer>
);

export default ButtonBlockDragLayer;
