import { useQuery, useMutation } from '@apollo/client';
import { Stack, Switch, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Navbar from '../../components/UI/molecules/Navbar';
import FormFooter from '../../components/UI/templates/FormFooter';
import { DiscountType } from '../../interfaces/Discount';
import Container from './Components/Container';
import Context from './Context';
import { getPropsByDiscountType } from './tools/getPropsByDiscountType';
import getDiscount from './gql/GetDiscount';
import { useDialog } from '../../components/Dialog';

const mock = {
  title: '',
  code: '',
  price: 0,
  valueType: 'value',
  recurrence: 0,
  enabled: true,
  recurrenceUnlimited: false,
  minimumPurchaseMonth: 0,
  usageLimit: 0,
  usageUnlimited: false,
  team: '',
  plans: [],
};

const CreateDiscount = () => {
  const alert = useAlert();
  const dialog = useDialog();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const type = params.get('type') as string;
  const id = params.get('id') as string;
  const { loading, data } = useQuery(getDiscount, { variables: { id } });
  const { helperText, mutation, validationSchema } = getPropsByDiscountType(
    type as DiscountType,
  );
  const [createDiscount, { loading: isUpdating }] = useMutation(mutation);
  const form = useFormik({
    enableReinitialize: true,
    initialValues: data
      ? {
          ...data?.discount,
          title: '',
          team: data?.discount?.tag?.id,
          enabled: true,
        }
      : { ...mock },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const input = validationSchema.cast(values);
        await createDiscount({ variables: { input } });
        alert.success('Desconto criado com sucesso');
        navigate(`/discounts?type=${type}`);
      } catch (err) {
        alert.success('Ocorreu um erro, tente novamente');
      }
    },
  });
  const cancelDiscountCreation = async () => {
    const confirm = await dialog.confirm({
      title: 'Cancelar criação',
      message: 'Você tem certeza que deseja cancelar a criação deste desconto?',
    });
    if (confirm) {
      navigate(`/discounts?type=${type}`);
    }
  };
  const isTypeDiscountInternal = DiscountType.Internal === type;
  if (loading) return <div>Loading...</div>;
  return (
    <Context.Provider
      value={{ discount: data?.discount, form, helperText, type }}
    >
      <Navbar route={`/discounts?type=${type}`} title="Novo desconto">
        {isTypeDiscountInternal && (
          <Stack alignItems="center" direction="row">
            <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
              Ativar desconto
            </Typography>
            <Switch
              name="enabled"
              checked={form.values.enabled}
              color="secondary"
              sx={{ ml: 3, mr: 2 }}
              onClick={form.handleChange}
            />
          </Stack>
        )}
      </Navbar>
      <Container />
      <FormFooter
        LeftButtonProps={{
          children: 'Salvar',
          type: 'submit',
          loading: isUpdating,
          onClick: () => form.handleSubmit(),
        }}
        helperText=""
        RightButtonProps={{
          children: 'Cancelar criação',
          onClick: () => cancelDiscountCreation(),
        }}
        sx={{ mt: 6 }}
      />
    </Context.Provider>
  );
};

export default CreateDiscount;
