import { Stack, Typography } from '@material-ui/core';
import { Markup } from 'interweave';
import { useContext } from 'react';
import Context from '../context';

interface DynamicTextProps {
  color: string;
  fontFamily: string;
  fontSize: number;
  text: string;
}

const DynamicText = ({
  color,
  fontFamily,
  fontSize,
  text,
}: DynamicTextProps) => {
  const { smartphoneScreen } = useContext(Context);
  return (
    <Stack
      className="dynamic-text"
      sx={{ justifyContent: 'flex-start', position: 'relative' }}
    >
      <Typography
        sx={{
          color,
          fontFamily,
          fontSize: `${smartphoneScreen ? fontSize : fontSize * 1.25}px`,
          lineHeight: 1.5,
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          // TODO: Find a better solution for rich text editor
          '& .ql-align-right': { textAlign: 'right' },
          '& .ql-align-center': { textAlign: 'center' },
          '& span > p': { margin: 0 },
        }}
      >
        <Markup content={text} />
      </Typography>
    </Stack>
  );
};

export default DynamicText;
