import { Theme } from '@material-ui/core';
import { Components } from '@material-ui/core/styles/components';

const makeMuiCssBaseline = (theme: Theme): Components['MuiCssBaseline'] => ({
  styleOverrides: {
    body: {
      backgroundColor: theme.palette.grey[500],
    },
    a: {
      textDecoration: 'none',
    },
    '.ps__thumb-y': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
    },
    '.slick-slide': {
      opacity: 0.1,
    },
    '.slick-active': {
      opacity: 1,
    },
    '.slick-prev, .slick-next': {
      transform: 'translate(0, 0)',
      zIndex: 100,
    },
    '.slick-prev:before': {
      left: '50%',
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      top: '50%',
    },
    '.slick-next:before': {
      right: '50%',
      position: 'absolute',
      transform: 'translate(50%, -50%)',
      top: '50%',
    },
  },
});

export default makeMuiCssBaseline;
