import { Checkbox, ListItem, ListItemText } from '@material-ui/core';
import { HeartTeam } from '../../../interfaces/HeartTeam';

const TeamItemList = ({
  checked,
  team,
  onAddTeam,
}: {
  checked: boolean;
  team: HeartTeam;
  onAddTeam: (team: HeartTeam) => void;
}) => (
  <ListItem sx={{ px: 0 }}>
    <Checkbox
      data-testid="tag-selected"
      defaultChecked={checked}
      color="secondary"
      onChange={() => onAddTeam(team)}
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
    <ListItemText
      primary={team.name}
      primaryTypographyProps={{ sx: { color: 'grey.100' } }}
    />
  </ListItem>
);

export default TeamItemList;
