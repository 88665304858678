import { ValueType } from './Coupon';
import { HeartTeam } from './HeartTeam';
import { Plan } from './Plan';

export enum DiscountType {
  Internal = 'internal',
  Collective = 'collective',
  Retention = 'retention',
  Coupon = 'coupon',
}
export interface Discount {
  id: string;
  title: string;
  code: string;
  price: number;
  createdAt: Date;
  type: DiscountType;
  valueType: ValueType;
  minimumPurchaseMonth: number;
  recurrence: number;
  enabled: boolean;
  usageLimit?: number;
  activies?: number;
  usageUnlimited?: boolean;
  finishDate: Date;
  team: HeartTeam;
  plans: Plan[];
}
