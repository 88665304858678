import * as yup from 'yup';

const NAME_MAXLENGTH = 160;

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .max(160, `O máximo de caracteres é ${NAME_MAXLENGTH}`)
    .required('O campo é obrigatório'),
});

export default validationSchema;
