// TODO: Remove when Craft.js support settings props without param reassign
/* eslint-disable no-param-reassign */
import { Node, useEditor } from '@craftjs/core';
import { Stack, Switch, Typography } from '@material-ui/core';
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
  Twitter as TwitterIcon,
  ViewCompact as ViewCompactIcon,
} from '@material-ui/icons';
import { useMemo, useState } from 'react';
import ColorPicker from '../../components/ColorPicker';
import SelectableSetting from '../../components/SelectableSetting';
import TextSetting from '../../components/TextSetting';
import SidebarAccordion from '../../components/SidebarAccordion';
import { EditorType, Group as GroupProps } from './typings';

const IconsGroup = ({ color }: { color: string }) => (
  <Stack flexDirection="row">
    <TwitterIcon sx={{ color, height: 14, width: 16 }} />
    <InstagramIcon sx={{ color, height: 14, width: 16 }} />
    <FacebookIcon sx={{ color, height: 14, width: 16 }} />
    <YouTubeIcon sx={{ color, height: 14, width: 16 }} />
  </Stack>
);

interface FooterProps extends Object {
  footerBgColor?: string;
  footerFacebookLink?: string;
  footerIconsColor?: string;
  footerInstagramLink?: string;
  footerHasVerticalLayout?: boolean;
  footerIsInvisible?: boolean;
  footerTwitterLink?: string;
  footerYoutubeLink?: string;
}

const Footer = ({ expanded, onExpand }: GroupProps) => {
  const [hasVerticalLayoutValue, setHasVerticalLayoutValue] = useState(true)
  const {
    actions: { setProp },
    nodes,
  }: EditorType<FooterProps> = useEditor((query) => ({ nodes: query.nodes }));
  if (!nodes) return null;
  const [firstNode] = useMemo(
    () =>
      Object.values(nodes).filter(
        (node: Node) => node.data.name === 'MainContainer',
      ),
    [nodes],
  );
  if (!firstNode) return null;
  const hasVerticalLayoutHandler = (value: boolean) =>
    setProp(firstNode.id, (currentProps) => {
      currentProps.footerHasVerticalLayout = value;
      setHasVerticalLayoutValue(currentProps.footerHasVerticalLayout);
    });
  const bgColorChangeHandler = (color: string) =>
    setProp(firstNode.id, (currentProps) => {
      currentProps.footerBgColor = color;
    });
  const iconColorChangeHandler = (color: string) =>
    setProp(firstNode.id, (currentProps) => {
      currentProps.footerIconsColor = color;
    });
  const instagramUrlChangeHandler = (text: string) =>
    setProp(firstNode.id, (currentProps) => {
      currentProps.footerInstagramLink = text;
    });
  const facebookUrlChangeHandler = (text: string) =>
    setProp(firstNode.id, (currentProps) => {
      currentProps.footerFacebookLink = text;
    });
  const youtubeUrlChangeHandler = (text: string) =>
    setProp(firstNode.id, (currentProps) => {
      currentProps.footerYoutubeLink = text;
    });
  const twitterUrlChangeHandler = (text: string) =>
    setProp(firstNode.id, (currentProps) => {
      currentProps.footerTwitterLink = text;
    });
  const footerInvisibilityHandler = (value: boolean) =>
    setProp(firstNode.id, (currentProps) => {
      currentProps.footerIsInvisible = value;
    });
  const footerInvisibility = firstNode.data.props.footerIsInvisible;
  return (
    <SidebarAccordion
      title="Rodapé"
      expanded={expanded}
      icon={
        <ViewCompactIcon
          sx={{
            color: 'secondary.main',
            height: 24,
            transform: 'rotate(180deg)',
            width: 24,
          }}
        />
      }
      onExpand={onExpand}
    >
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography color="grey.300" fontSize={14}>
          Desativar rodapé:
        </Typography>
        <Switch
          checked={footerInvisibility}
          onChange={() => footerInvisibilityHandler(!footerInvisibility)}
          color="secondary"
          sx={{ ml: 5 }}
        />
      </Stack>
      <Typography color="grey.300" fontSize={14}>
        Escolha a direção do rodapé:
      </Typography>
      <SelectableSetting
        checked={hasVerticalLayoutValue}
        value
        onSelect={() => hasVerticalLayoutHandler(true)}
      >
        <Stack width="100%" alignItems="center">
          <Typography
            color={hasVerticalLayoutValue ? 'grey.100' : 'grey.400'}
            fontSize={10}
          >
            LOGO
          </Typography>
          <IconsGroup color={hasVerticalLayoutValue ? 'grey.100' : 'grey.400'} />
        </Stack>
      </SelectableSetting>
      <SelectableSetting
        checked={!hasVerticalLayoutValue}
        value={false}
        onSelect={() => hasVerticalLayoutHandler(false)}
      >
        <Stack width="100%" flexDirection="row" justifyContent="space-between">
          <Typography
            color={!hasVerticalLayoutValue ? 'grey.100' : 'grey.400'}
            fontSize={10}
          >
            LOGO
          </Typography>
          <IconsGroup color={!hasVerticalLayoutValue ? 'grey.100' : 'grey.400'} />
        </Stack>
      </SelectableSetting>
      <TextSetting
        label="Link Instagram"
        value={firstNode.data.props.footerInstagramLink || ''}
        placeholder="https://instagram.com/user..."
        required={false}
        onChange={instagramUrlChangeHandler}
      />
      <TextSetting
        label="Link Facebook"
        value={firstNode.data.props.footerFacebookLink || ''}
        placeholder="https://facebook.com/pageu..."
        required={false}
        onChange={facebookUrlChangeHandler}
      />
      <TextSetting
        label="Link YouTube"
        value={firstNode.data.props.footerYoutubeLink || ''}
        placeholder="https://youtube.com/channe..."
        required={false}
        onChange={youtubeUrlChangeHandler}
      />
      <TextSetting
        label="Link Twitter"
        value={firstNode.data.props.footerTwitterLink || ''}
        placeholder="https://twitter.com/userur..."
        required={false}
        onChange={twitterUrlChangeHandler}
      />
      <ColorPicker
        color={firstNode.data.props.footerBgColor || '#FFFFFF'}
        name="Cor de fundo do rodapé"
        onChange={bgColorChangeHandler}
      />
      <ColorPicker
        color={firstNode.data.props.footerIconsColor || '#FFFFFF'}
        name="Cor dos ícones"
        onChange={iconColorChangeHandler}
      />
    </SidebarAccordion>
  );
};

export default Footer;
