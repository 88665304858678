import { Navigate, Route, Routes } from 'react-router-dom';
import AuthTemplate from './AuthTemplate';
import Login from './Login';

const Auth = () => (
  <Routes>
    <Route element={<AuthTemplate />}>
      <Route path="/login" element={<Login />} />
      <Route path="/password-recovery" element={<div />} />
    </Route>
    <Route path="*" element={<Navigate to="/auth/login" />} />
  </Routes>
);

export default Auth;
