import { createContext, Dispatch, SetStateAction } from 'react';

export enum SortPositionDirection {
  larger = 'larger',
  minor = 'minor',
}

type InputFilter = {
  filters: {
    published?: boolean;
    sortPositionDirection: SortPositionDirection;
    term: string;
  };
};

interface SectionListContext {
  isCreatingSection: boolean;
  setIsCreatingSection: Dispatch<
    SetStateAction<SectionListContext['isCreatingSection']>
  >;
  input: InputFilter;
  setInput: (input: InputFilter) => void;
  permissionToUpdate: boolean;
}

const Context = createContext({} as SectionListContext);

export default Context;
