import { createContext } from 'react';
import { Discount } from '../../interfaces/Discount';
import { DiscountInput } from '../Plans/typings';

export type Meta = {
  currentPage: number;
  itemCount: number;
  totalItems: number;
  totalPages: number;
};

type ContextType = {
  discounts: Discount[];
  isActive: boolean;
  meta: Meta | null;
  title: string;
  type: string;
  setIsActive: (value: boolean) => void;
  setInput: (input: DiscountInput) => void;
};

const Context = createContext({} as ContextType);

export default Context;
