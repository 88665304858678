import { gql } from '@apollo/client';

const contentsQuery = gql`
  query ContentsQuery {
    contents {
      id
      title
      description
      scheduleDate
      scheduleDateEnabled
      finishDate
      finishDateEnabled
      type
      published
      thumbnail
      createdAt
      updatedAt
      teams {
        id
        name
      }
    }
  }
`;

export default contentsQuery;
