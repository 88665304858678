import { gql } from '@apollo/client';

const getMultiSectionItemsQuery = gql`
  query GetMultiSectionItemsQuery {
    multiSectionsItems {
      id
      itemType
      position
      thumbnail
      title
      content {
        id
        title
      }
      section {
        id
        title
      }
    }
  }
`;

export default getMultiSectionItemsQuery;
