import { Button, Card, FormHelperText, Grid, Stack } from '@material-ui/core';
import { useContext } from 'react';
import { useAlert } from 'react-alert';
import highlightsDesktop from '../../../assets/png/highlightsDesktop.png';
import highlightsMobile from '../../../assets/png/highlightsMobile.png';
import uploadFile from '../../ManagersList/services/uploadFile';
import getAssetUri from '../../../utils/getAssetUri';
import Context from '../Context';

const AddImageSection = () => {
  const alert = useAlert();
  const { form } = useContext(Context);
  const changeThumbnailHandler = async (evt: any, device: string) => {
    const res = await uploadFile<any>(evt.target.files[0]);
    const hasErrors = !!res.data.errors;
    const desktop = device === 'desktop';
    const formatUnsupported =
      hasErrors && res.data?.errors[0]?.message.includes('is unsupported');
    if (formatUnsupported) {
      alert.error('Formato de imagem não suportado.');
      return;
    }
    if (res.data?.data.fileUpload) {
      const fileSrc = getAssetUri(res.data?.data.fileUpload);
      // eslint-disable-next-line consistent-return
      return desktop
        ? form.setFieldValue('fullHdLandscape', fileSrc)
        : form.setFieldValue('fullHdPortrait', fileSrc);
    }
  };
  return (
    <Grid container gap={4} sx={{ mt: 4 }}>
      <Grid item xs={8}>
        <Card sx={{ p: 8 }}>
          <Stack
            sx={{
              backgroundImage: `url(${
                form.values.fullHdLandscape|| highlightsDesktop
              })`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              borderRadius: 2,
              height: 360,
              width: '100%',
            }}
          />
          <label htmlFor="image-desktop">
            <input
              id="image-desktop"
              accept="image/*"
              multiple
              type="file"
              style={{ display: 'none' }}
              onChange={(evt) => changeThumbnailHandler(evt, 'desktop')}
            />
            <FormHelperText error>{form.touched.fullHdLandscape && form.errors.fullHdLandscape}</FormHelperText>
            <Button component="span" sx={{ mt: 6 }}>
              Adicionar imagem horizontal
            </Button>
          </label>
        </Card>
      </Grid>
      <Grid item xs>
        <Card sx={{ p: 8 }}>
          <Stack
            sx={{
              backgroundImage: `url(${
                form.values.fullHdPortrait || highlightsMobile
              })`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              borderRadius: 2,
              height: 360,
              width: '100%',
            }}
          />
          <label htmlFor="image-mobile">
            <input
              id="image-mobile"
              accept="image/*"
              multiple
              type="file"
              style={{ display: 'none' }}
              onChange={(evt) => changeThumbnailHandler(evt, 'mobile')}
            />
            <Button component="span" sx={{ mt: 6 }}>
              Adicionar imagem vertical
            </Button>
          </label>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AddImageSection;
