import { useApolloClient, useQuery } from '@apollo/client';
import { Pagination } from '@material-ui/core';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { identity, pickBy } from 'lodash';
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import Topbar from '../../components/Topbar';
import TipAlert from '../../components/UI/molecules/TipAlert';
import { Spectator } from '../../interfaces/Spectator';
import FiltersBar from './components/FiltersBar';
import SpectatorDrawer from './components/SpectatorDrawer';
import SpectatorList from './components/SpectatorList';
import SpectatorListRow from './components/SpectatorListRow';
import SpectatorMenu from './components/SpectatorMenu';
import { Context, DrawerType, SpectatorContextData } from './Context';
import userQuery from './gql/userQuery';
import usersPlansHistoryQuery from './gql/usersPlansHistoryQuery';
import usersStatusHistoryQuery from './gql/usersStatusHistoryQuery';
import usersQuery from './gql/usersQuery';
import plansQuery from './gql/usersPlansQuery';
import ButtonsFilter from './components/ButtonsFilter';
import FilterLeads from './components/FilterLeads';
import LeadsListRow from './components/LeadsListRow';
import getLeadsQuery from './gql/getLeadsQuery';

export const formatTheFilteringDate = (eventDate: Date) => {
  const timeZone = 'UTC';
  const zonedDate = utcToZonedTime(eventDate, timeZone);
  return format(zonedDate, 'yyyy-MM-dd HH:mm:ss');
};

const Spectators = () => {
  const apolloClient = useApolloClient();
  const [parameters] = useSearchParams();
  const spectatorId = parameters.get('SpectatorId');
  const actionViaReport = parameters.get('Action') as DrawerType;
  const { loading: loadingPlans, data: plans } = useQuery(plansQuery, {
    variables: { input: { paginate: { page: 1, limit: 100 } } },
  });
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [listLeads, setListLeads] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [drawerType, setDrawerType] = useState<DrawerType>(null);
  const [selectedSpectator, setSelectedSpectator] = useState<Spectator | null>(
    null,
  );
  const [input, setInput] = useState<SpectatorContextData['input']>({
    filters: {
      planId: '',
      createdAtGte: null,
      createdAtLte: null,
      gatewayPayment: '',
      partner: '',
      status: '',
      term: '',
    },
    paginate: { page: 1 },
  });
  const [searchByRecords, setSearchByRecords] = useState(false);
  const handleOpenMenu = (
    event: MouseEvent<HTMLButtonElement>,
    spectatorData: Spectator,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedSpectator(spectatorData);
  };
  const paginationHandler = (_: ChangeEvent<unknown>, page: number) =>
    setInput((currentInput) => ({ ...currentInput, paginate: { page } }));
  const getQueryNameByData = useCallback(() => {
    const selectFromUsersPlans =
      !!input.filters?.planId || !!input.filters?.gatewayPayment;
    if (searchByRecords) {
      return {
        query: usersStatusHistoryQuery,
        name: 'usersStatusHistory',
      };
    }
    if (selectFromUsersPlans) {
      return {
        query: usersPlansHistoryQuery,
        name: 'usersPlansHistory',
      };
    }
    return {
      query: usersQuery,
      name: 'users',
    };
  }, [input]);
  const putDataInState = (res: any) => {
    const queryName = getQueryNameByData().name;
    return setData(res.data[queryName]);
  };
  useEffect(() => {
    const newFilters = pickBy(
      {
        ...input.filters,
        createdAtGte: input.filters.createdAtGte
          ? formatTheFilteringDate(input.filters.createdAtGte)
          : '',
        createdAtLte: input.filters.createdAtLte
          ? formatTheFilteringDate(input.filters.createdAtLte)
          : '',
      },
      identity,
    );
    const newInput = { ...input, filters: newFilters };
    async function getSpectators() {
      const params = {
        query: getQueryNameByData().query,
        variables: { input: newInput },
      };
      setIsLoading(true);
      try {
        const res = await apolloClient.query(params);
        putDataInState(res);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('err', err);
      } finally {
        setIsLoading(false);
      }
    }
    async function getLeads() {
      const params = {
        query: getLeadsQuery,
        variables: { input: newInput },
      };
      setIsLoading(true);
      try {
        const { data: dataLeads } = await apolloClient.query(params);
        setData(dataLeads?.leads);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('err', err);
      } finally {
        setIsLoading(false);
      }
    }
    if (listLeads) {
      getLeads();
    } else {
      getSpectators();
    }
  }, [input, listLeads]);
  useEffect(() => {
    if (spectatorId) {
      const getSpectator = async () => {
        const params = {
          query: userQuery,
          variables: { id: spectatorId },
        };
        const res = await apolloClient.query(params);
        setSelectedSpectator(res.data.user);
        setDrawerType(actionViaReport);
      };
      getSpectator();
    }
  }, []);
  if (loadingPlans) return <div>Carregando...</div>;
  return (
    <Context.Provider
      value={{
        anchorEl,
        setAnchorEl,
        handleOpenMenu,
        listLeads,
        listSpectators: data?.items,
        input,
        setInput,
        searchByRecords,
        setSearchByRecords,
        setData,
        drawerType,
        setDrawerType,
        setListLeads,
        selectedSpectator,
        setSelectedSpectator,
        plans: plans.plans.items,
      }}
    >
      <Topbar title="Biblioteca de Espectadores" description="" />
      <ButtonsFilter />
      <TipAlert sx={{ mt: 4 }}>
        Veja informações relacionadas aos seus espectadores.
      </TipAlert>
      {listLeads ? <FilterLeads /> : <FiltersBar sx={{ mt: 4 }} />}
      {isLoading ? (
        'Carregando...'
      ) : (
        <>
          <SpectatorList
            sx={{ mt: 4 }}
            data={data?.items}
            renderItem={(eachItem: Spectator, index: number) =>
              listLeads ? (
                <LeadsListRow
                  data={eachItem}
                  isLastItem={index === data?.items.length - 1}
                />
              ) : (
                <SpectatorListRow
                  data={eachItem}
                  isLastItem={index === data?.items.length - 1}
                />
              )
            }
          />
          <Pagination
            page={input.paginate.page}
            count={data?.meta?.totalPages ?? 0}
            sx={{ mt: 4 }}
            onChange={paginationHandler}
          />
        </>
      )}
      <SpectatorMenu />
      <SpectatorDrawer />
    </Context.Provider>
  );
};

export default Spectators;
