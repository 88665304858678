import { Discount } from '../../../interfaces/Discount';
import { SearchBy } from '../typings';
import Informations from './Informations';
import Spectators from './Spectators';

interface ContainerProps {
  searchBy: SearchBy;
  discount: Discount;
}

const Container = ({ searchBy, discount }: ContainerProps) =>
  searchBy === SearchBy.informations ? (
    <Informations discount={discount} />
  ) : (
    <Spectators />
  );

export default Container;
