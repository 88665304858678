import { gql } from '@apollo/client';

const updateInternalDiscountMutation = gql`
  mutation UpdateInternalDiscountMutation(
    $input: UpdateInternalDiscountInput!
  ) {
    updateInternalDiscount(input: $input) {
      id
    }
  }
`;

export default updateInternalDiscountMutation;
