import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { TopbarProps } from './typings';

const Topbar = ({ children, title, description, sx }: TopbarProps) => (
  <Card sx={sx}>
    <CardContent>
      <Grid container alignItems="center">
        <Grid item xs>
          <Typography
            sx={{ color: 'common.white', fontSize: 24, fontWeight: 600 }}
          >
            {title}
          </Typography>
          <Typography sx={{ color: 'grey.300', fontSize: 14, mt: 1 }}>
            {description}
          </Typography>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default Topbar;
