import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  DragIndicator as DragIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import { useContext, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import InlineTextEditor from '../../../components/InlineTextEditor';
import { SubSection as ISubSection } from '../../../interfaces/Section';
import Context from '../Context';

interface SubSectionProps {
  subSection: ISubSection;
  index: number;
}

const SubSection = ({ subSection, index }: SubSectionProps) => {
  const { form, section, selectedSubSection, setSelectedSubSection } =
    useContext(Context);
  const [isEditing, setIsEditing] = useState(false);
  const [contextMenu, setContextMenu] = useState<any>(null);
  const handleClose = () => setContextMenu(null);
  const deleteSubSection = async () => {
    if (selectedSubSection!.id === subSection.id) {
      setSelectedSubSection(section.generalSubSection);
    }
    const newSubSections = form.values.subSections!.filter(
      (subSectionOnList) => subSectionOnList.id !== subSection.id,
    );
    form.setFieldValue('subSections', newSubSections);
    handleClose();
  };
  const handleContextMenu = (event: any) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  };
  const renameSubSection = async (title: string) => {
    const updatedSubSection = { ...subSection, title };
    form.setFieldValue(`subSections[${index}]`, updatedSubSection);
  };
  const toggleSubSectionEdition = () => {
    setIsEditing((prevState) => !prevState);
    handleClose();
  };
  return (
    <Draggable draggableId={subSection.id} index={index}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu!.mouseY, left: contextMenu!.mouseX }
                : undefined
            }
          >
            <MenuItem onClick={toggleSubSectionEdition}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Renomear</Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={deleteSubSection}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Remover</Typography>
            </MenuItem>
          </Menu>
          <Button
            size="small"
            title={subSection.title}
            sx={{
              bgcolor: 'grey.500',
              border:
                selectedSubSection!.id === subSection.id
                  ? '1px solid #EFE7FF'
                  : '2px solid #ffffff00',
              cursor: 'grab',
            }}
            startIcon={
              <DragIcon sx={{ color: 'grey.400', height: 16, width: 16 }} />
            }
            onClick={() => setSelectedSubSection(subSection)}
            onContextMenu={handleContextMenu}
          >
            <InlineTextEditor
              TypographyProps={{
                sx: {
                  color: 'grey.100',
                  display: '-webkit-box',
                  fontSize: 12,
                  fontWeight: 600,
                  lineHeight: 1.5,
                  maxWidth: 200,
                  overflow: 'hidden',
                  textAlign: { xs: 'center', lg: 'left' },
                  textOverflow: 'ellipsis',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                },
              }}
              value={subSection.title}
              onChange={(text) => renameSubSection(text)}
              onCancel={toggleSubSectionEdition}
              sx={{
                maxHeight: '26px',
                mt: 1,
                div: { height: '26px' },
                input: { background: 'none', border: 'none', fontSize: '12px' },
              }}
              isEditing={isEditing}
              hideIcon
            />
          </Button>
        </Box>
      )}
    </Draggable>
  );
};

export default SubSection;
