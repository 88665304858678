import { Theme } from '@material-ui/core';
import { Components } from '@material-ui/core/styles/components';

const makeMuiChip = (theme: Theme): Components['MuiChip'] => ({
  defaultProps: {
    color: 'primary',
    variant: 'filled',
  },
  styleOverrides: {
    label: {
      fontWeight: 600,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    sizeSmall: { fontSize: 12, height: theme.shape.sizes.small },
    sizeMedium: { fontSize: 14, height: theme.shape.sizes.medium },
  },
  variants: [
    {
      props: { color: 'error', variant: 'outlined' },
      style: {
        backgroundColor: 'rgba(255, 84, 94, 0.1)', // TODO: export from theme
        color: theme.palette.common.white,
      },
    },
    {
      props: { color: 'warning', variant: 'outlined' },
      style: {
        backgroundColor: 'rgba(241, 174, 0, 0.1)', // TODO: export from theme
        color: theme.palette.common.white,
      },
    },
    {
      props: { color: 'info', variant: 'outlined' },
      style: {
        backgroundColor: 'rgba(137, 80, 252, 0.1)', // TODO: export from palette
        color: theme.palette.common.white,
      },
    },
    {
      props: { color: 'success', variant: 'outlined' },
      style: {
        backgroundColor: 'rgba(0, 194, 113, 0.1)', /// TODO: export from palette
        color: theme.palette.success.main,
      },
    },
  ],
});

export default makeMuiChip;
