import { gql } from '@apollo/client';

const createInternalDiscountMutation = gql`
  mutation CreateInternalDiscountMutation($input: CreateInternalDiscountInput!) {
    createInternalDiscount(input: $input) {
      id
    }
  }
`;

export default createInternalDiscountMutation;
