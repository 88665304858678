import { Element, useEditor } from '@craftjs/core';
import { Stack } from '@material-ui/core';
import {
  Add as AddIcon,
  DragIndicator as DragIndicatorIcon,
} from '@material-ui/icons';
import { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { DoubleContainer } from '../../../BuildArea/components';

const DoubleContainerBlock = () => {
  const { connectors } = useEditor();
  const [{ isDragging }, drag, preview] = useDrag<
    any,
    unknown,
    { isDragging: boolean }
  >(() => ({
    type: 'DoubleContainerBlock',
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  }));
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  return (
    <Stack ref={drag}>
      <Stack
        ref={(ref: JSX.Element) =>
          connectors.create(
            ref,
            <Element canvas is={DoubleContainer} bgColor="#141414" />,
          )
        }
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          bgcolor: 'grey.600',
          cursor: isDragging ? 'grabbing' : 'grab',
          height: 72,
          opacity: isDragging ? 0.3 : 1,
          padding: 3,
          width: 222,
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: '#20133A',
            border: '2px solid',
            borderColor: 'info.main',
            borderRadius: 1,
            height: 48,
            width: 78,
          }}
        >
          <AddIcon sx={{ color: 'info.main', fontSize: 20 }} />
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: '#20133A',
            border: '2px solid',
            borderColor: 'info.main',
            borderRadius: 1,
            height: 48,
            width: 78,
          }}
        >
          <AddIcon sx={{ color: 'info.main', fontSize: 20 }} />
        </Stack>
        <DragIndicatorIcon sx={{ color: 'grey.400', fontSize: 32 }} />
      </Stack>
    </Stack>
  );
};

export default DoubleContainerBlock;
