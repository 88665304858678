import { SortByDirection } from '../../../typings/sort';
import { sortByStringAsc, sortByStringDesc } from '../../Discount/utils/sort';

export const sortArrayBy =
  ({ direction, prop }: any) =>
  (prev: any, next: any) => {
    let prevValue = null;
    let nextValue = null;
    prevValue = prev[prop];
    nextValue = next[prop];
    const sortingAsc = direction === SortByDirection.Asc;
    if (!prevValue || !nextValue) {
      return 0;
    }
    const isString =
      typeof prevValue === 'string' && typeof nextValue === 'string';
    if (isString) {
      return sortingAsc
        ? sortByStringAsc(prevValue, nextValue)
        : sortByStringDesc(prevValue, nextValue);
    }
    if (sortingAsc) {
      return Math.abs(prevValue as number) - Math.abs(nextValue as number);
    }
    return Math.abs(nextValue as number) - Math.abs(prevValue as number);
  };
