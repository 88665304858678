import { Box } from '@material-ui/core';

const HighlightSectionPreview = ({ thumbnail }: { thumbnail: string }) => (
  <Box
    sx={{
      backgroundImage: `url(${thumbnail})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      borderRadius: 2,
      height: 460,
      width: '100%',
    }}
  />
);

export default HighlightSectionPreview;
