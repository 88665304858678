import { gql } from '@apollo/client';

const highlightedContentsQuery = gql`
  query HighlightedContentsQuery {
    highlightedContents {
      id
      title
      description
      fullHdPortrait
      fullHdLandscape
      position
      content {
        id
        title
        thumbnail
      }
    }
  }
`;

export default highlightedContentsQuery;
