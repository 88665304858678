import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  CardContent,
  Divider,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { DateTimePicker } from '@material-ui/lab';
import { SxProps } from '@material-ui/system';
import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import DownloadButton from '../../../components/DownloadButton';
import { Tag } from '../../../interfaces/Tag';
import {
  convertJsonArrayToCsv,
  createUriAndNameToFile,
  CreateUriAndNameToFileReturn,
} from '../../../utils/download/convertFiles';
import { Question } from '../../Onboarding/typings';
import { Context } from '../Context';
import listQuestionsQuery from '../gql/listQuestionsQuery';
import StackWithLabel from './StackWithLabel';

export interface FilterLeadsProps {
  sx?: SxProps<Theme>;
}

interface LeadsForm {
  createdAtGte: Date | null;
  createdAtLte: Date | null;
  answer: string;
  term: string;
}

const FilterLeads = ({ sx }: FilterLeadsProps) => {
  const { loading, data } = useQuery(listQuestionsQuery);
  const { listSpectators, setInput } = useContext(Context);
  const formik = useFormik<LeadsForm>({
    initialValues: {
      createdAtGte: null,
      createdAtLte: null,
      answer: '',
      term: '',
    },
    onSubmit: async (filters) =>
      setInput((prevState) => ({
        ...prevState,
        filters,
        paginate: { page: 1 },
      })),
  });
  const resetFilter = () => {
    formik.resetForm();
    setInput((prevState) => ({
      ...prevState,
      filters: formik.initialValues,
      paginate: { page: 1 },
    }));
  };
  useEffect(() => {
    resetFilter();
  }, []);
  const transformedLeads = listSpectators?.map((lead) => ({
    ...lead,
    answer: lead.answer?.name,
  }));
  const csv =
    transformedLeads.length > 0
      ? convertJsonArrayToCsv(transformedLeads)
      : null;
  let createUriAndNameToFileReturn: CreateUriAndNameToFileReturn = {
    name: '',
    uri: '',
  };
  if (csv) {
    const createUriAndNameToFileProps = {
      file: csv,
      fileName: 'relatorios',
      fileType: 'csv',
    };
    createUriAndNameToFileReturn = createUriAndNameToFile(
      createUriAndNameToFileProps,
    );
  }
  if (loading) return <div>Loading...</div>;
  const requiredQuestions = data?.questions;
  const requiredQuestionsFiltered = requiredQuestions?.find(
    (question: Question) => question.required,
  );
  return (
    <>
      <Card sx={{ ...sx, mt: 4 }}>
        <CardContent>
          <form>
            <OutlinedInput
              name="term"
              fullWidth
              value={formik.values.term}
              placeholder="Pesquise por espectadores usando Nome, Email ou CPF"
              autoComplete="off"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'secondary.main' }} />
                </InputAdornment>
              }
              onChange={formik.handleChange}
            />
            <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  sx={{ background: 'grey.500' }}
                  flexItem
                />
              }
              spacing={4}
              sx={{ mt: 5 }}
            >
              <StackWithLabel label="Pergunta obrigatória">
                <Select
                  name="answer"
                  displayEmpty
                  value={formik.values.answer}
                  sx={{ minWidth: 150 }}
                  onChange={formik.handleChange}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {requiredQuestionsFiltered?.tags?.map((question: Tag) => (
                    <MenuItem key={question.id} value={question.id}>
                      {question.name}
                    </MenuItem>
                  ))}
                </Select>
              </StackWithLabel>
              <Stack direction="row" spacing={4} sx={{ width: 800 }}>
                <StackWithLabel label="Desde">
                  <DateTimePicker
                    mask="__/__/__"
                    inputFormat="dd/MM/yy HH:mm"
                    value={formik.values.createdAtGte}
                    InputAdornmentProps={{
                      position: 'start',
                      sx: { maxWidth: 20, svg: { color: 'secondary.main' } },
                    }}
                    renderInput={(params) => (
                      <TextField sx={{ maxWidth: 170 }} {...params} />
                    )}
                    onChange={(value) =>
                      formik.handleChange({
                        target: { name: 'createdAtGte', value },
                      })
                    }
                  />
                </StackWithLabel>
                <StackWithLabel label="Até">
                  <DateTimePicker
                    mask="__/__/__"
                    inputFormat="dd/MM/yy HH:mm"
                    value={formik.values.createdAtLte}
                    minDate={formik.values.createdAtGte}
                    InputAdornmentProps={{
                      position: 'start',
                      sx: { maxWidth: 20, svg: { color: 'secondary.main' } },
                    }}
                    renderInput={(params) => (
                      <TextField sx={{ maxWidth: 170 }} {...params} />
                    )}
                    onChange={(value) =>
                      formik.handleChange({
                        target: { name: 'createdAtLte', value },
                      })
                    }
                  />
                </StackWithLabel>
              </Stack>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={3}
                sx={{ mt: 5, width: '100%' }}
              >
                <DownloadButton
                  uri={createUriAndNameToFileReturn.uri}
                  fileName={createUriAndNameToFileReturn.name}
                  buttonTitle="Baixar relatório"
                  sx={{ bgcolor: 'success.main' }}
                />
                <Button variant="text" onClick={resetFilter}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: 'grey.400',
                      fontWeight: 600,
                    }}
                  >
                    Limpar Filtros
                  </Typography>
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    bgcolor: 'main',
                    borderColor: 'common.white',
                    color: 'common.white',
                  }}
                  onClick={(evt) => {
                    evt.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  Filtrar
                </Button>
              </Stack>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </>
  );
};

export default FilterLeads;
