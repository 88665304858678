import { ChangeEvent, useContext, useState } from 'react';
import {
  MenuItem,
  Select,
  Pagination,
  List,
  SelectChangeEvent,
} from '@material-ui/core';
import paginationAdapter from '../../../adapters/pagination';
import HasNoPermission from '../../../components/HasNoPermission';
import SearchBar from '../../../components/UI/molecules/SearchBar';
import { Manager } from '../../../interfaces/Manager';
import { compareTerms } from '../../../utils/string';
import Context from '../Context';
import { SortBy } from '../typings';
import { sortByDate } from '../utils/sort';
import ManagerItem from './ManagerItem';

const ManagerSearch = () => {
  const { managers, permissionToRead } = useContext(Context);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.None);
  const queryHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setQuery(evt.target.value);
  const paginationHandler = (_: ChangeEvent<unknown>, nextPage: number) =>
    setPage(nextPage);
  const sortByHandler = (evt: SelectChangeEvent<SortBy>) =>
    setSortBy(evt.target.value as SortBy);
  const filteredManagers = query
    ? managers.filter((manager) => compareTerms(manager.name, query))
    : [...managers];
  const sortedManagers = filteredManagers.sort((newestDate, oldestDate) =>
    sortByDate(newestDate, oldestDate, sortBy),
  );
  const paginatedManagers = paginationAdapter<Manager>({
    items: sortedManagers,
    itemsPerPage: 10,
    page,
  });
  return (
    <>
    {permissionToRead ?
    <>
      <SearchBar
        TextFieldProps={{
          placeholder: 'Procure aqui por um gestor',
          value: query,
          onChange: queryHandler,
        }}
        sx={{ mt: 4 }}
      >
        <Select value={sortBy} sx={{ minWidth: 161 }} onChange={sortByHandler}>
          <MenuItem value="none">Ordenar por</MenuItem>
          <MenuItem value={SortBy.Newest}>Mais recentes</MenuItem>
          <MenuItem value={SortBy.Oldest}>Mais antigos</MenuItem>
        </Select>
      </SearchBar>
      <List>
        {paginatedManagers.items.map((manager) => (
          <ManagerItem key={manager.id} manager={manager} />
        ))}
      </List>
      <Pagination
        count={paginatedManagers.total}
        sx={{ mt: 4 }}
        onChange={paginationHandler}
      />
    </> : <HasNoPermission /> }
    </>
  );
};

export default ManagerSearch;
