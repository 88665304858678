import { Theme } from '@material-ui/core';
import { Components } from '@material-ui/core/styles/components';

const makeMuiPaginationItem = (
  theme: Theme,
): Components['MuiPaginationItem'] => ({
  styleOverrides: {
    root: {
      borderRadius: theme.shape.borderRadius * 2,
      color: theme.palette.grey[100],
      fontSize: 12, // TODO: export from theme
      '&:hover': {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.grey[100],
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.common.white,
        },
      },
      '&.Mui-disabled': { opacity: 0.2 },
      '&.MuiPaginationItem-page': { marginLeft: theme.spacing(4) },
    },
    firstLast: {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.grey[100],
      fontWeight: 600,
      '.MuiSvgIcon-root': {
        color: theme.palette.grey[100],
      },
      '&:hover .MuiSvgIcon-root': {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.grey[100],
      },
    },
    previousNext: {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
      fontWeight: 600,
      '.MuiSvgIcon-root': {
        color: theme.palette.grey[100],
      },
      '&:hover .MuiSvgIcon-root': {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.grey[100],
      },
    },
    icon: { color: theme.palette.grey[400] },
  },
});

export default makeMuiPaginationItem;
