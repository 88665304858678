import { useContext, useState } from 'react';
import {
  Card,
  CardContent,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Edit as EditIcon,
  Folder as FolderIcon,
  MoreVert as MoreOptionsIcon,
  TextFormat as TextFormatIcon,
} from '@material-ui/icons';
import { useDrop } from 'react-dnd';
import { useAlert } from 'react-alert';
import { useMutation } from '@apollo/client';
import { useDialog } from '../../../../components/Dialog';
import InlineTextEditor from '../../../../components/InlineTextEditor';
import { Category } from '../../../../interfaces/Category';
import { Tag } from '../../../../interfaces/Tag';
import TagsModal from './TagsModal';
import updateCategoryMutation from '../CategoriesContainer/gql/updateCategoryMutation';
import Context from '../../Context';

interface CategoryCardProps {
  category: Category;
  onDelete: (category: Category) => void;
  onDropTag: (tag: Tag, category: Category) => void;
  onEdit: () => void;
}

const CategoryCard = ({
  category,
  onDelete,
  onDropTag,
  onEdit,
}: CategoryCardProps) => {
  const alert = useAlert();
  const { permissionToDelete, permissionToUpdate } = useContext(Context);
  const dialog = useDialog();
  const [updateCategory] = useMutation(updateCategoryMutation);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [categoryName, setCategoryName] = useState(category.name);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;
  const openMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const closeMenuHandler = () => setAnchorEl(null);
  const onChangeHandler = async (value: string) => {
    const trimmedName = value.trim();
    setCategoryName(trimmedName);
    try {
      const {
        data: {
          updateCategory: { id },
        },
      } = await updateCategory({
        variables: {
          input: { id: category.id, name: trimmedName, slug: trimmedName },
        },
      });
      if (id) {
        onEdit();
      }
    } catch (error) {
      alert.error('Uma categoria com este nome já existe');
      setCategoryName(categoryName);
    }
    setIsEditing(false);
  };
  const openEditMode = () => {
    closeMenuHandler();
    setIsEditing(true);
  };
  const openTagsModal = async () => {
    if (!isEditing) {
      await dialog.open({
        title: 'Tags da categoria',
        element: (
          <TagsModal
            category={category}
            permissionToDelete={permissionToDelete}
          />
        ),
        sx: { height: 390, overflowY: 'hidden' },
      });
    }
  };
  const deleteCategoryHandler = () => {
    closeMenuHandler();
    onDelete(category);
  };
  const tagsQuantity = () => category.tags.length;
  const [{ hovered }, drop] = useDrop<
    Tag,
    unknown,
    { highlighted: boolean; hovered: boolean }
  >(() => ({
    accept: 'TagListItem',
    drop: (tag) => {
      onDropTag(tag, category);
      return undefined;
    },
    collect: (monitor) => ({
      highlighted: monitor.canDrop(),
      hovered: monitor.isOver(),
    }),
  }));
  const hasNoPermission = permissionToDelete || permissionToUpdate;
  return (
    <Card
      ref={drop}
      data-testid={`category-card-${category.id}`}
      sx={{
        backgroundColor: hovered ? 'rgba(137, 80, 252, 0.1)' : 'inherit', // TODO: export from theme
        borderColor: hovered ? 'info.main' : 'grey.500',
        borderStyle: 'solid',
        borderWidth: 2,
        color: 'common.white',
        cursor: 'pointer',
        height: 99, // TODO: export from theme
        ':hover': { borderColor: 'info.light' },
        ':hover .MuiSvgIcon-root': { color: 'info.light' },
      }}
      variant="outlined"
      onClick={openTagsModal}
    >
      <CardContent sx={{ height: '100%', p: 3 }}>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={closeMenuHandler}
          onClick={(e) => e.stopPropagation()}
        >
          {permissionToUpdate && (
            <>
              <MenuItem onClick={openEditMode}>
                <ListItemIcon>
                  <TextFormatIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Renomear categoria</Typography>
              </MenuItem>
              <MenuItem onClick={openTagsModal}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Editar tags</Typography>
              </MenuItem>
              <Divider />
            </>
          )}
          {permissionToDelete && (
            <MenuItem onClick={deleteCategoryHandler}>
              <ListItemIcon>
                <CloseIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Excluir categoria
              </Typography>
            </MenuItem>
          )}
        </Menu>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ mb: 4 }}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            <FolderIcon fontSize="small" sx={{ color: 'grey.300' }} />
            <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
              {` ${tagsQuantity()} tags`}
            </Typography>
          </Stack>
          {hasNoPermission && (
            <IconButton sx={{ p: 0 }} onClick={openMenuHandler}>
              <MoreOptionsIcon
                sx={{ color: hovered ? 'info.main' : 'grey.400' }}
              />
            </IconButton>
          )}
        </Stack>
        <InlineTextEditor
          hideIcon
          isEditing={isEditing}
          value={categoryName}
          TypographyProps={{
            color: 'common.white',
            fontSize: 12,
            fontWeight: 600,
          }}
          onCancel={() => setIsEditing(false)}
          onChange={(name) => onChangeHandler(name)}
        />
      </CardContent>
    </Card>
  );
};

export default CategoryCard;
