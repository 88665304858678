import { useMutation, useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { useAlert } from 'react-alert';
import { Category } from '../../../../interfaces/Category';
import { Tag } from '../../../../interfaces/Tag';
import { compareTerms } from '../../../../utils/string';
import { useDialog } from '../../../../components/Dialog';
import CategoryCard from '../CategoryCard';
import CardGroup from '../CardGroup';
import CreateCategoryForm from '../CreateCategoryForm';
import addTagToCategoryMutation from './gql/addTagToCategoryMutation';
import createCategoryMutation from './gql/createCategoryMutation';
import deleteCategoryMutation from './gql/deleteCategoryMutation';
import listCategoriesQuery from './gql/listCategoriesQuery';

const CategoriesContainer = () => {
  const alert = useAlert();
  const { loading, data, refetch } = useQuery(listCategoriesQuery);
  const [addTagToCategory] = useMutation(addTagToCategoryMutation);
  const [createCategory] = useMutation(createCategoryMutation);
  const [deleteCategory] = useMutation(deleteCategoryMutation);
  const dialog = useDialog();
  const createCategoryHandler = async () => {
    const isCategoryCreated = await dialog.open({
      title: 'Criar nova categoria',
      element: <CreateCategoryForm />,
    });
    if (isCategoryCreated) {
      alert.success('Categoria criada com sucesso!');
      await refetch();
    }
  };
  const createFromSuggestHandler = async (name: string) => {
    await createCategory({ variables: { input: { name, slug: name } } });
    alert.success('Categoria criada com sucesso!');
    await refetch();
  };
  const deleteCategoryHandler = async ({ id, name }: Category) => {
    const confirmed = await dialog.confirm({
      title: 'Você tem certeza?',
      message: `Se excluir a categoria '${name}', perderá todas as relações dela com suas tags e não será possível recuperá-la.`,
    });
    if (confirmed) {
      await deleteCategory({ variables: { id } });
      alert.success('Categoria apagada com sucesso!');
      await refetch();
    }
  };
  const editCategoryHandler = async () => {
    alert.success('A categoria foi editada com sucesso!');
    await refetch();
  };
  const dropTaghandler = async (tag: Tag, category: Category) => {
    const newCategories = await refetch();
    const findCategory = newCategories.data.categories.find(
      (currentCategory: Category) => currentCategory.id === category.id,
    );
    const tagHasAlreadyBeenInserted = findCategory.tags.some(
      (currentTag: Tag) => currentTag.id === tag.id,
    );
    if (tagHasAlreadyBeenInserted) {
      alert.success('Já existe essa tag na categoria!');
      return;
    }
    await addTagToCategory({
      variables: { tagId: tag.id, categoryId: category.id },
    });
    alert.success('A tag foi associada com sucesso!');
    await refetch();
  };
  if (loading) {
    return <div>loading...</div>;
  }
  return (
    <CardGroup
      buttonLabel="Criar categoria"
      inputProps={{ placeholder: 'Pesquisar categoria' }}
      items={data.categories}
      ItemsWrapper={Grid}
      ItemsWrapperProps={{ container: true, spacing: 5, sx: { p: 5 } }}
      name="Categorias"
      emptyHelperText="Você vai organizar suas tags dentro das categorias."
      filterItems={(query, item) => compareTerms(item.name, query)}
      renderItems={(category) => (
        <Grid item xs={3} key={category.id}>
          <CategoryCard
            category={category}
            onDelete={deleteCategoryHandler}
            onDropTag={dropTaghandler}
            onEdit={editCategoryHandler}
          />
        </Grid>
      )}
      onCreate={createCategoryHandler}
      onCreateFromSuggest={createFromSuggestHandler}
    />
  );
};

export default CategoriesContainer;
