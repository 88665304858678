import { gql } from '@apollo/client';

const updatePlantMutation = gql`
  mutation UpdatePlantMutation($input: UpdatePlanInput!) {
    updatePlan(input: $input) {
      id
    }
  }
`;

export default updatePlantMutation;
