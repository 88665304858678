import { useAuthorize } from '@tshio/react-router-permissions';
import {useContext} from 'react';
import HasNoPermission from '../../../components/HasNoPermission';
import Context from '../../../Context';
import Container from './components/Container';

const HTMLPagesList = () => {
  const { me } = useContext(Context);
  const permissionForEverything = me.superAdmin;
  const permissionToCreate =
    permissionForEverything || useAuthorize(['pages', 'create']);
  const permissionToDelete =
    permissionForEverything || useAuthorize(['pages', 'delete']);
  const permissionToRead =
    permissionForEverything || useAuthorize(['pages', 'read']);
  const permissionToUpdate =
    permissionForEverything || useAuthorize(['pages', 'update']);
  if (!permissionToRead) return <HasNoPermission />;
  return (
    <Container
      permissionToCreate={permissionToCreate}
      permissionToDelete={permissionToDelete}
      permissionToRead={permissionToRead}
      permissionToUpdate={permissionToUpdate}
    />
  );
};

export default HTMLPagesList;
