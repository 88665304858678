import { gql } from '@apollo/client';

const createPlanMutation = gql`
  mutation CreatePlanMutation($input: CreatePlanInput!) {
    createPlan(input: $input) {
      id
    }
  }
`;

export default createPlanMutation;
