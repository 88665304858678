import { Card, Stack, SvgIconProps, Typography } from '@material-ui/core';
import { SxProps, Theme } from '@material-ui/system';
import { ComponentType, ReactNode } from 'react';

interface ReportCardProps {
  title: string;
  description?: string;
  icon?: ComponentType<SvgIconProps>;
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

const ReportCard = ({
  icon: Icon,
  title,
  description,
  children,
  sx,
}: ReportCardProps) => (
  <Card sx={{ p: 6, ...sx }}>
    <Stack alignItems="center" direction="row">
      {Icon && <Icon color="secondary" />}
      <Typography
        sx={{
          color: 'grey.200',
          fontSize: 20,
          fontWeight: 600,
          ml: Icon ? 3 : 0,
        }}
      >
        {title}
      </Typography>
    </Stack>
    {description && (
      <Typography sx={{ color: 'grey.300', fontSize: 14, mt: 2 }}>
        {description}
      </Typography>
    )}
    {children}
  </Card>
);

export default ReportCard;
