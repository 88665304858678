import {
  Button,
  Card,
  FormHelperText,
  Stack,
  Typography,
} from '@material-ui/core';
import { useContext } from 'react';
import { useDialog } from '../../../components/Dialog';
import { Section } from '../../../interfaces/Section';
import DialoghighlightedContents from '../../CreateHighlights/Components/DialogContents';
import Context from '../Context';
import SectionsDialog from './SectionsDialog';
import ShowSelectedType from './ShowSelectedType';

const SelectSectionOrContent = () => {
  const { form } = useContext(Context);
  const dialog = useDialog();
  const typeSelectedIsContent = form.values.itemType === 'content';
  const typeSelectedIsSection = form.values.itemType === 'section';
  const hasSelectedContent = typeSelectedIsContent && !!form.values.content;
  const hasSelectedSection = typeSelectedIsSection && !!form.values.section;
  const openDialogHighlightedContents = async () => {
    await dialog.open({
      title: 'Adicionar conteúdo',
      element: (
        <DialoghighlightedContents
          selectedContent={form.values.content}
          onSelect={(content) => {
            form.setFieldValue('content', content);
            form.setFieldValue('section', '');
          }}
        />
      ),
      sx: { height: 580, width: 667 },
    });
  };
  const openDialogSections = async () => {
    await dialog.open({
      title: 'Adicionar seção',
      element: (
        <SectionsDialog
          selectedSection={form.values.section}
          onSelect={(section: Section) => {
            form.setFieldValue('section', section);
            form.setFieldValue('content', '');
          }}
        />
      ),
    });
  };
  return (
    <Card sx={{ mt: 4, p: 6 }}>
      <Typography sx={{ color: 'grey.100', fontWeight: 600 }}>
        {typeSelectedIsContent ? 'Selecionar conteúdo' : 'Selecionar seção'}
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          bgcolor: 'grey.500',
          borderRadius: 2,
          height: 76,
          mt: 3,
          p: 4,
          width: '100%',
        }}
      >
        {hasSelectedContent || hasSelectedSection ? (
          <ShowSelectedType
            typeSelectedIsContent={typeSelectedIsContent}
            openDialogHighlightedContents={
              typeSelectedIsContent
                ? openDialogHighlightedContents
                : openDialogSections
            }
          />
        ) : (
          <>
            <Typography
              sx={{ color: 'grey.100', fontSize: 14, fontWeight: 600 }}
            >
              {typeSelectedIsContent
                ? 'Escolha um conteúdo'
                : 'Escolha uma seção'}
            </Typography>
            <Button
              onClick={
                typeSelectedIsContent
                  ? openDialogHighlightedContents
                  : openDialogSections
              }
            >
              Selecionar
            </Button>
          </>
        )}
      </Stack>
      <FormHelperText error>
        {form.errors.section || form.errors.content}
      </FormHelperText>
    </Card>
  );
};

export default SelectSectionOrContent;
