import { useQuery } from '@apollo/client';
import { Box, Stack } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { Section } from '../../../interfaces/Section';
import Context from '../Context';
import sectionsPaginateQuery from '../gql/sectionsPaginateQuery';
import CreateSectionCard from './CreateSectionCard';
import LiveSectionItem from './LiveSectionItem';
import SectionListEmpty from './SectionListEmpty';
import SectionList from './SectionList';

const Container = () => {
  const [sections, setSections] = useState<Section[]>([]);
  const { isCreatingSection, input } = useContext(Context);
  const { loading, data, refetch } = useQuery(sectionsPaginateQuery, {
    variables: {
      input,
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    setSections(data?.sectionsPaginate as Section[]);
  }, [data]);
  if (loading) {
    return <div>loading...</div>;
  }
  const liveSection = data?.sectionsPaginate.find(
    (section: Section) => section.position === 0,
  );
  const liveSectionRemoved = data?.sectionsPaginate.filter(
    (section: Section) => section.position !== 0,
  );
  return (
    <>
      {isCreatingSection && (
        <Box sx={{ mt: 4 }}>
          <CreateSectionCard refetch={refetch} />
        </Box>
      )}
      {sections?.length === 0 && !isCreatingSection && (
        <Box sx={{ mt: 4 }}>
          <SectionListEmpty />
        </Box>
      )}
      <Stack spacing={4} sx={{ mt: 4 }}>
        {liveSection && <LiveSectionItem liveSection={liveSection} />}
        <SectionList sections={liveSectionRemoved} refetch={refetch} />
      </Stack>
    </>
  );
};

export default Container;
