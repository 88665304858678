import { gql } from '@apollo/client';

const discountsQuery = gql`
  query ListDiscounts($input: ListDiscountsInput!) {
    discounts(input: $input) {
      items {
        id
        title
        code
        price
        createdAt
        type
        valueType
        minimumPurchaseMonth
        recurrence
        enabled
        usageLimit
        usageUnlimited
        team {
          id
          name
        }
        plans {
          id
          title
          type
        }
      }
      meta {
        totalItems
        itemCount
        totalPages
        currentPage
      }
    }
  }
`;

export default discountsQuery;
