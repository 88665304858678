import { Card, Stack, Typography } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import theme from '../../../Theme';
import { NavbarProps } from './typings';

const Navbar = ({ children, route, title, onClickArrow }: NavbarProps) => (
  <Card>
    <Stack
      sx={{
        alignItems: 'center',
        flexDirection: 'row',
        height: 68,
        pr: 5,
      }}
    >
      <Link to={route}>
        <Stack
          sx={{
            alignItems: 'center',
            borderRight: `1px solid ${theme.palette.grey[400]}`,
            justifyContent: 'center',
            height: 24,
            width: 54,
            mr: 3,
          }}
        >
          <ArrowBackIcon
            onClick={onClickArrow}
            sx={{
              color: theme.palette.grey[100],
              ml: 2,
            }}
          />
        </Stack>
      </Link>
      <Typography
        sx={{
          color: theme.palette.grey[100],
          fontWeight: 500,
          mr: 'auto',
        }}
      >
        {title}
      </Typography>
      {children}
    </Stack>
  </Card>
);

export default Navbar;
