import { Stack } from '@material-ui/core';
import { ReactNode, useContext } from 'react';
import Context from '../context';

interface HalfContainerProps {
  children?: ReactNode;
}

const HalfContainer = ({ children }: HalfContainerProps) => {
  const { smartphoneScreen } = useContext(Context);
  return (
    <Stack
      position="relative"
      width={smartphoneScreen ? '100%' : '50%'}
      sx={{
        py: smartphoneScreen ? 5 : 0,
        gap: smartphoneScreen ? 2 : 5,
        '& iframe': { height: smartphoneScreen ? 200 : 350 },
      }}
    >
      {children}
    </Stack>
  );
};

export default HalfContainer;
