import { useQuery } from '@apollo/client';
import { Divider, Stack, Switch, Typography } from '@material-ui/core';
import { useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TileCard5 from '../../../components/TileCard5';
import { Partner } from '../../../interfaces/Partner';
import Context from '../Context';
import partnersQuery from '../gql/partnersQuery';

const Partners = () => {
  const { loading, data } = useQuery(partnersQuery);
  const { form, isEditing } = useContext(Context);
  const toggleCheckedPartnerHandler = (evt: any) => {
    const partnerChecked = evt.target.checked;
    const partner = evt.target.value;
    if (partnerChecked) {
      form.setFieldValue('partners', [
        ...form.values.partners,
        { code: partner },
      ]);
      return;
    }
    form.setFieldValue(
      'partners',
      form.values.partners.filter(
        (currentpartner: Partner) => currentpartner.code !== partner,
      ),
    );
  };
  if (loading) return <div>Carregando...</div>;
  const partnerChecked = (partner: Partner) =>
    form.values.partners.find(
      (currentPartner: Partner) => partner.code === currentPartner.code,
    );
  return (
    <TileCard5
      title="Incluir Parceiros"
      sx={{ height: 405, mb: 4, width: 482 }}
    >
      <Typography sx={{ color: 'grey.300', fontSize: 14, mt: 2 }}>
        Selecionar quais parceiros se englobam nesse plano.
      </Typography>
      <Divider sx={{ borderColor: 'grey.500', mt: 5 }} />
      <PerfectScrollbar style={{ height: 280 }}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ flexWrap: 'wrap', gap: 8, height: 300, mt: 6, px: 8, }}
        >
          {data.partners.map((partner: Partner, index: number) => (
            <Stack alignItems="center" direction="row">
              <Typography
                sx={{
                  color: 'grey.100',
                  fontSize: 14,
                  fontWeight: 600,
                  mr: 4,
                }}
              >
                {partner.code}
              </Typography>
              <Switch
                data-testid={`checkbox-partner-${index}`}
                value={partner.code}
                disabled={isEditing}
                checked={!!partnerChecked(partner)}
                onClick={toggleCheckedPartnerHandler}
              />
            </Stack>
          ))}
        </Stack>
      </PerfectScrollbar>
    </TileCard5>
  );
};

export default Partners;
