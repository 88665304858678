import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@material-ui/core';
import {
  DragIndicator as DragIndicatorIcon,
  Edit as EditIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Launch as LaunchIcon,
} from '@material-ui/icons';
import { useContext, useState } from 'react';
import { DraggableStateSnapshot } from 'react-beautiful-dnd';
import { Link, useNavigate } from 'react-router-dom';
import { Section } from '../../../interfaces/Section';
import Context from '../Context';
import EmptyListCard from './EmptyListCard';
import SlideBase from './SlideBase';
import SlideCard from './SlideCard';

const SectionListAccordion = ({
  section,
  snapshot,
}: {
  section: Section;
  snapshot: DraggableStateSnapshot;
}) => {
  const navigate = useNavigate();
  const { permissionToUpdate } = useContext(Context);
  const [subSectionIndex, setSubSectionIndex] = useState(0);
  const subSections: Section['subSections'] = [
    section.generalSubSection,
    ...section.subSections,
  ];
  const [expanded, setExpanded] = useState(false);
  const { palette } = useTheme();
  const itsDifferentSection = !section.generalSubSection;
  const openTheAccordion = expanded && !itsDifferentSection;
  const manageHighlightSection = () => navigate('/section/highlights');
  return (
    <Accordion disableGutters expanded={openTheAccordion}>
      <AccordionSummary
        expandIcon={
          itsDifferentSection ? (
            <Button
              size="large"
              startIcon={<LaunchIcon color="info" />}
              sx={{
                bgcolor: 'grey.500',
                color: 'grey.200',
                mr: 2,
              }}
            >
              Gerenciar
            </Button>
          ) : (
            <Button
              size="large"
              endIcon={
                openTheAccordion ? <ExpandLessIcon /> : <ExpandMoreIcon />
              }
              sx={{
                bgcolor: 'grey.500',
                color: 'grey.200',
                cursor: snapshot.isDragging ? 'grabbing' : 'grab',
                mr: 2,
              }}
            >
              {openTheAccordion ? 'Ocultar' : 'Ver conteúdos'}
            </Button>
          )
        }
        sx={{
          bgcolor: snapshot.isDragging ? 'rgba(137, 80, 252, 0.1)' : null,
          border: snapshot.isDragging ? `2px solid ${palette.info.main}` : null,
          borderRadius: '4px',
          minHeight: 74,
          '&.Mui-expanded': {
            borderColor: 'grey.500',
            borderStyle: 'solid',
            borderBottomWidth: 1,
          },
          // FIXME: check another alternative
          '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(0deg)',
            WebkitTransform: 'rotate(0deg)',
          },
        }}
        onClick={() =>
          itsDifferentSection
            ? manageHighlightSection()
            : setExpanded(!expanded)
        }
      >
        <Box
          sx={{
            alignItems: 'center',
            color: 'common.white',
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            width: '100%',
          }}
        >
          {permissionToUpdate && (
            <DragIndicatorIcon
              sx={{
                cursor: 'grab',
                color: 'grey.400',
                height: 28,
                width: 28,
              }}
            />
          )}
          <Typography
            sx={{
              alignItems: 'center',
              bgcolor: snapshot.isDragging ? '#8950FC' : 'grey.500',
              border: itsDifferentSection ? '1px solid' : '0px solid',
              borderColor: itsDifferentSection ? 'grey.100' : 'grey.500',
              borderRadius: 1,
              display: 'flex',
              fontSize: 24,
              height: 40,
              justifyContent: 'center',
              mx: 5,
              width: 40,
            }}
          >
            {section.position + 1}
          </Typography>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent={
              itsDifferentSection ? 'flex-start' : 'space-between'
            }
            sx={{ width: '80%' }}
          >
            <Typography
              noWrap
              sx={{
                color: 'common.white',
                fontSize: 16,
                marginRight: 56,
                maxWidth: 700,
              }}
            >
              {section.title}
            </Typography>
            {!itsDifferentSection && (
              <Chip
                variant="outlined"
                label={section.published ? 'Ativa' : 'Inativa'}
                color={section.published ? 'success' : 'error'}
                sx={{ borderRadius: 2, color: 'common.white', width: 80 }}
              />
            )}
          </Stack>
          {!itsDifferentSection && permissionToUpdate && (
            <Box
              sx={{
                alignItems: 'center',
                bgcolor: 'grey.500',
                borderRadius: 1,
                display: 'flex',
                height: 40,
                justifyContent: 'center',
                position: 'absolute',
                right: 16,
                width: 48,
              }}
            >
              <Link to={`/section/${section.id}/edit`}>
                <EditIcon sx={{ color: 'info.main', height: 24, width: 24 }} />
              </Link>
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ py: 5, px: 0 }}>
        <Box sx={{ color: 'common.white', px: 9 }}>
          <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 4 }}>
            Conteúdo
          </Typography>
          <Stack direction="row" spacing={3} sx={{ mb: 5 }}>
            {subSections.map((subSection, index: number) => (
              <Button
                sx={{
                  bgcolor: 'grey.500',
                  border: subSectionIndex === index ? '1px solid #EFE7FF' : '',
                  transition: 'all 0.2s ease-in-out',
                }}
                onClick={() => setSubSectionIndex(index)}
              >
                {subSection?.title}
              </Button>
            ))}
          </Stack>
        </Box>
        <Box sx={{ mb: 2 }}>
          {!subSections[subSectionIndex] ||
          subSections[subSectionIndex].sortableContents.length === 0 ? (
            <EmptyListCard description="Adicione conteúdos a essa seção" />
          ) : (
            <SlideBase>
              {subSections[subSectionIndex].sortableContents.map(
                (sortableContent) => (
                  <SlideCard
                    id={sortableContent.content.id}
                    thumbnail={sortableContent.content.thumbnail}
                    title={sortableContent.content.title}
                  />
                ),
              )}
            </SlideBase>
          )}
        </Box>
        <Divider sx={{ bgcolor: 'grey.500', my: 3 }} />
        <Box sx={{ color: 'common.white', px: 9 }}>
          <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 4 }}>
            Destaques
          </Typography>
        </Box>
        {section.sortableHighlightedContents.sortableItems.length === 0 ? (
          <EmptyListCard description="Adicione destaques a essa seção" />
        ) : (
          <SlideBase>
            {section.sortableHighlightedContents.sortableItems.map(
              ({ highlightedContent }) => (
                <SlideCard
                  id={highlightedContent.id}
                  thumbnail={highlightedContent?.content?.thumbnail}
                  title={highlightedContent.title}
                />
              ),
            )}
          </SlideBase>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default SectionListAccordion;
