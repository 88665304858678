import { SortByDirection } from '../../../typings/sort';
import { SortByType } from '../typings';

export const sortByNameAsc = (prevValue: string, nextValue: string) => {
  if (prevValue < nextValue) {
    return -1;
  }
  if (prevValue > nextValue) {
    return 1;
  }
  return 0;
};

export const sortByNameDesc = (prevValue: string, nextValue: string) => {
  if (prevValue < nextValue) {
    return 1;
  }
  if (prevValue > nextValue) {
    return -1;
  }
  return 0;
};

const sortTeamBy =
  ({ direction, prop }: SortByType) =>
  (prev: any, next: any) => {
    let prevValue = null;
    let nextValue = null;
    prevValue = prev[prop];
    nextValue = next[prop];
    const sortingAsc = direction === SortByDirection.Asc;
    if (!prevValue || !nextValue) {
      return 0;
    }
    return sortingAsc
      ? sortByNameAsc(prevValue, nextValue)
      : sortByNameDesc(prevValue, nextValue);
  };

export default sortTeamBy;
