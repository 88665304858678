import { Button } from '@material-ui/core';
import LightModeRoundedIcon from '@material-ui/icons/LightModeRounded';
import { useNavigate } from 'react-router-dom';
import EmptyStateCard from '../../../components/EmptyStateCard';

interface EmptyPageProps {
  pathToNavigate: string;
}

const EmptyPage = ({pathToNavigate}: EmptyPageProps ) => {
  const navigate = useNavigate();
  return(
    <EmptyStateCard
      icon={
        <LightModeRoundedIcon
          fontSize="large"
          sx={{
            '&.MuiSvgIcon-root': {
              color: 'secondary.main',
            },
          }}
        />
      }
      title="Crie seu primeiro destaque"
      description="Você poderá escolher quais informações serão exibidas no seu destaque"
      sx={{ mt: 4 }}
    >
      <Button variant="outlined" onClick={() => navigate(pathToNavigate)}>Novo destaque</Button>
    </EmptyStateCard>
  );
}

export default EmptyPage;
