import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Checkbox,
  List,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@material-ui/core';
import { ChangeEvent, useState } from 'react';
import SearchBar from '../../../../components/UI/molecules/SearchBar';
import {
  getPageTypeFriendlyName,
  Page,
  PageType,
  PlanType,
} from '../../../../interfaces/Plan';
import { compareTerms } from '../../../../utils/string';
import PlanQuery from '../../gql/planQuery';
import updatePlanMutation from '../../gql/updatePlanMutation';
import DeleteItems from '../DeleteItems';
import PageItem from './PageItem';

const typesPartners = [
  'Sucesso na renovação do plano',
  'Falha na renovação do plano',
  'Fim da contratação do plano',
  'Sucesso na verificação do parceiro',
  'Falha na verificação do parceiro',
];

const ListPlans = ({ planId }: {planId: string}) => {
  const { loading, data, refetch } = useQuery(PlanQuery, {
    variables: { id: planId },
  });
  const [updatePlan] = useMutation(updatePlanMutation);
  const [query, setQuery] = useState('');
  const [filterBy, setFilterBy] = useState('none');
  const [selectedPages, setSelectedPages] = useState<Page[]>([]);
  const queryHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setQuery(evt.target.value);
  const filterByHandler = (evt: SelectChangeEvent<string>) =>
    setFilterBy(evt.target.value);
  if (loading) return <div>Carregando...</div>;
  const filteredPages = query
    ? data.plan.pages.filter((page: Page) => compareTerms(page.title, query))
    : data.plan.pages;
  const filteredByType = filteredPages.filter(
    (page: Page) => filterBy === 'none' || page.type === filterBy,
  );
  const toggleSelectedPages = (page: Page) => {
    const pageIndex = selectedPages.findIndex(
      (currentPage) => currentPage?.id === page.id,
    );
    const pageNotExists = pageIndex !== -1;
    if (pageNotExists) {
      const newContentsSelecteds = selectedPages.filter(
        (currentPage) => currentPage.id !== page.id,
      );
      setSelectedPages(newContentsSelecteds);
      return;
    }
    setSelectedPages((previousContents) => [page, ...previousContents]);
  };
  const checkedAll =
    selectedPages.length === data.plan.pages.length &&
    data.plan.pages.length !== 0;
  const onToggleCheckedAll = () => {
    const newSelectedPages = checkedAll ? [] : data.plan.pages;
    setSelectedPages(newSelectedPages);
  };
  const deletePagesHandler = async (pagesSelected: Page[]) => {
    let newPages: Page[] = data.plan.pages;
    // eslint-disable-next-line no-restricted-syntax
    for (const pageSelected of pagesSelected) {
      // eslint-disable-next-line no-await-in-loop
      newPages = newPages.filter(
        (page: Page) => page.type !== pageSelected.type,
      );
    }
    const pages = newPages.map(({ id }) => ({ id }));
    await updatePlan({ variables: { input: { pages }, id: planId } });
    await refetch();
    setSelectedPages([]);
  };
  const typePlanIsPartner = data.plan.type === PlanType.partner;
  return (
    <>
      <Stack direction="row" sx={{ border: '2px solid transparent' }}>
        <Checkbox
          data-testid="select-all-page"
          checked={checkedAll}
          color="secondary"
          sx={{ p: 0 }}
          onClick={onToggleCheckedAll}
        />
        <SearchBar
          sx={{ boxShadow: 'none' }}
          TextFieldProps={{
            sx: { width: 368 },
            placeholder: 'Busque por uma página',
            value: query,
            onChange: queryHandler,
          }}
          CardContentProps={{ sx: { pl: 4, pr: 0 } }}
        >
          <Select
            data-testid="filter-by-pages"
            value={filterBy}
            sx={{ width: 201 }}
            onChange={filterByHandler}
          >
            <MenuItem value="none">Todos</MenuItem>
            {typePlanIsPartner
              ? typesPartners.map((type) => (
                  <MenuItem data-testid="filter-by-pages-item" value={type}>
                    {type}
                  </MenuItem>
                ))
              : Object.values(PageType).map((type) => (
                  <MenuItem
                    data-testid="filter-by-pages-item"
                    key={type}
                    value={type}
                  >
                    {getPageTypeFriendlyName(type)}
                  </MenuItem>
                ))}
          </Select>
        </SearchBar>
      </Stack>
      {selectedPages.length ? (
        <DeleteItems
          quantity={selectedPages.length}
          sx={{ mb: 4 }}
          onDeleteItems={() => deletePagesHandler(selectedPages)}
        />
      ) : (
        ''
      )}
      <Box sx={{ maxHeight: 420, overflowY: 'auto' }}>
        <List data-testid="list-pages">
          {filteredByType.map((page: Page) => (
            <PageItem
              checked={selectedPages.includes(page)}
              page={page}
              onToggleCheckPage={toggleSelectedPages}
              onDeletePages={deletePagesHandler}
            />
          ))}
        </List>
      </Box>
    </>
  );
};

export default ListPlans;
