import { Stack } from '@material-ui/core';
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  YouTube as YouTubeIcon,
} from '@material-ui/icons';

interface PageFooterProps {
  hasVerticalLayout: boolean;
  bgColor: string;
  iconsColor: string;
  twitterLink: string;
  instagramLink: string;
  facebookLink: string;
  youtubeLink: string;
}

const PageFooter = ({
  hasVerticalLayout = true,
  bgColor = '#171717',
  iconsColor = '#FFFFFF',
  twitterLink = 'www.twitter.com',
  facebookLink = 'www.facebook.com',
  instagramLink = 'www.instagram.com',
  youtubeLink = 'www.youtube.com',
}: PageFooterProps) => (
  <>
    <Stack
      alignItems="center"
      flexDirection={hasVerticalLayout ? 'column' : 'row'}
      height={hasVerticalLayout ? 92 : 70}
      justifyContent={hasVerticalLayout ? 'space-around' : 'space-between'}
      sx={{ bgcolor: bgColor, px: 8 }}
    >
      <img
        style={{ maxHeight: '45px' }}
        src="https://uilogos.co/img/logomark/leaf.png"
        alt="logo"
      />
      <Stack flexDirection="row">
        <a style={{ color: iconsColor }} href={twitterLink}>
          <TwitterIcon />
        </a>
        <a style={{ color: iconsColor }} href={instagramLink}>
          <InstagramIcon />
        </a>
        <a style={{ color: iconsColor }} href={facebookLink}>
          <FacebookIcon />
        </a>
        <a style={{ color: iconsColor }} href={youtubeLink}>
          <YouTubeIcon />
        </a>
      </Stack>
    </Stack>
  </>
);

export default PageFooter;
