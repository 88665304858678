import { useQuery } from '@apollo/client';
import {
  Avatar,
  Button,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  SelectChangeEvent,
  Typography,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { ChangeEvent, useState } from 'react';
import {
  Highlight,
  SortableHighlightedContents,
} from '../../../interfaces/Highlight';
import { SortBy } from '../../../typings/sort';
import { compareTerms } from '../../../utils/string';
import highlightedContentsQuery from '../../Highlights/gql/highlightedContentsQuery';
import { sortByNewest, sortByOldest } from '../../PreviewPlan/utils/sort';
import { sortThePositions } from '../tools/sortThePositions';

interface DialoghighlightedContentsProps {
  onSelect: (highlights: SortableHighlightedContents[]) => void;
  highlightedContents: SortableHighlightedContents[];
}

const DialoghighlightedContents = ({
  highlightedContents,
  onSelect,
}: DialoghighlightedContentsProps) => {
  const { loading, data } = useQuery(highlightedContentsQuery);
  const [query, setQuery] = useState('');
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.Newest);
  const [selectedContents, setSelectedContents] =
    useState<SortableHighlightedContents[]>(highlightedContents || []);
  const changeQueryHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setQuery(evt.target.value);
  const sortByHandler = (evt: SelectChangeEvent<SortBy>) =>
    setSortBy(evt.target.value as SortBy);
  if (loading) return <div>Carregando...</div>;
  const contents = query
    ? data.highlightedContents.filter((content: Highlight) =>
        compareTerms(content.title, query),
      )
    : data.highlightedContents;
  const sortedContents = [...contents].sort(
    sortBy === SortBy.Oldest ? sortByOldest : sortByNewest,
  );
  const toggleSelectedContent = (content: Highlight) => {
    const contentIndex = selectedContents.findIndex(
      (currentContents: SortableHighlightedContents) =>
        currentContents.highlightedContent.id === content.id,
    );
    const contentToRemove = contentIndex !== -1;
    if (contentToRemove) {
      const removeHighlightContent = selectedContents.filter(
        (sortableHighlight: SortableHighlightedContents) =>
          sortableHighlight.highlightedContent.id !== content.id,
      );
      const reorderedList = sortThePositions(removeHighlightContent);
      onSelect(reorderedList);
      setSelectedContents(reorderedList);
      return;
    }
    const numberOfHighlights = selectedContents.length;
    const newSelectedContents = [
      ...selectedContents,
      { position: numberOfHighlights + 1, highlightedContent: content },
    ];
    onSelect(newSelectedContents);
    setSelectedContents(newSelectedContents);
  };
  const selectedContent = (content: Highlight) =>
    selectedContents.find(
      (currentContent) => currentContent?.highlightedContent?.id === content.id,
    );
  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ mb: 7 }}
      >
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: 'secondary.main' }} />
            </InputAdornment>
          }
          fullWidth
          placeholder="Pesquisar destaques"
          value={query}
          sx={{ mr: 4, width: 385 }}
          onChange={changeQueryHandler}
        />
        <Select value={sortBy} sx={{ minWidth: 201 }} onChange={sortByHandler}>
          <MenuItem value={SortBy.Newest}>Mais recentes</MenuItem>
          <MenuItem value={SortBy.Oldest}>Mais antigos</MenuItem>
        </Select>
      </Stack>
      {sortedContents.map((highlightedContent) => (
        <ListItem sx={{ height: 74, p: 0, width: '100%' }}>
          <ListItemButton key={highlightedContent.id} dense sx={{ pl: 0 }}>
            <ListItemAvatar>
              <Avatar
                alt={highlightedContent.title}
                src={highlightedContent?.content?.thumbnail}
                variant="rounded"
                sx={{ width: 74, height: 42 }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={highlightedContent.title}
              primaryTypographyProps={{
                color: 'grey.100',
                fontSize: 14,
                fontWeight: 600,
                width: 353,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              secondary={
                <>
                  <Typography
                    sx={{
                      color: 'info.main',
                      display: 'inline',
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >
                    On demand
                  </Typography>
                </>
              }
              sx={{ ml: 3 }}
            />
          </ListItemButton>
          <Button
            sx={{
              bgcolor: selectedContent(highlightedContent)
                ? 'success.main'
                : 'secondary',
              fontSize: 12,
              fontWeight: 500,
              height: 30,
              width: 77,
            }}
            onClick={() => toggleSelectedContent(highlightedContent)}
          >
            {selectedContent(highlightedContent) ? 'Adicionado' : 'Adicionar'}
          </Button>
        </ListItem>
      ))}
    </>
  );
};

export default DialoghighlightedContents;
