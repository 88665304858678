import { useQuery } from '@apollo/client';
import { Button } from '@material-ui/core';
import update from 'immutability-helper';
import { useCallback, useRef, useState } from 'react';
import LightbulbTwoToneIcon from '@material-ui/icons/LightbulbTwoTone';
import TipAlert from '../../components/UI/molecules/TipAlert';
import EmptyStateCard from '../../components/EmptyStateCard';
import Topbar from '../../components/Topbar';
import QuestionList from './components/QuestionList';
import listQuestionsAndTagsQuery from './gql/listQuestionsAndTagsQuery';
import QuestionDetails from './components/QuestionDetails';
import { useDialog } from '../../components/Dialog';
import { Tag } from '../../interfaces/Tag';
import Context from './context';
import QuestionDragLayer from './components/QuestionDragLayer';
import { Question } from './typings';

const Onboarding = () => {
  const dialog = useDialog();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const cardsRef = useRef(questions);
  const { loading, refetch } = useQuery<{ questions: Question[]; tags: Tag[] }>(
    listQuestionsAndTagsQuery,
    {
      onCompleted: (data) => {
        const orderedQuestions = [...data.questions].sort(
          (a: Question, b: Question) => a.position - b.position,
        );
        cardsRef.current = orderedQuestions;
        setQuestions(cardsRef.current);
        setTags(data.tags);
      },
      fetchPolicy: 'no-cache',
    },
  );
  const moveQuestion = useCallback((dragIndex: number, hoverIndex: number) => {
    const dragCard = cardsRef.current[dragIndex];
    cardsRef.current = update(cardsRef.current, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    });
    setQuestions(cardsRef.current);
  }, []);
  if (loading) {
    return <div>loading...</div>;
  }
  const openCreateQuestionModal = async () => {
    const createdQuestion = await dialog.open({
      element: (
        <QuestionDetails
          question={{
            id: '',
            title: '',
            description: '',
            published: false,
            answersEnabled: true,
            required: false,
            position: 1,
            tags: [],
          }}
          tags={tags}
        />
      ),
      sx: { height: 420, width: 852 },
    });
    if (createdQuestion) {
      cardsRef.current = update(cardsRef.current, { $push: [createdQuestion] });
      setQuestions(cardsRef.current);
    }
  };
  const theresNoQuestions = questions.length < 1;
  return (
    <Context.Provider
      value={{
        questions,
        cardsRef,
        setQuestions,
        tags,
        moveQuestion,
        openCreateQuestionModal,
        refetch
      }}
    >
      <Topbar
        title="Onboarding do espectador"
        description=""
      />
      <TipAlert sx={{ my: 4 }}>
        É aqui que você cria as perguntas do primeiro acesso do seu usuário,
        capriche e crie perguntas que façam sentido para o seu negócio.
      </TipAlert>
      {theresNoQuestions ? (
        <EmptyStateCard
          description="Faça a coleta de dados importantes para o seu negócio de forma divertida para o seu espectador."
          icon={
            <LightbulbTwoToneIcon sx={{ color: 'info.main', fontSize: 70 }} />
          }
          title="Onboarding do Espectador"
        >
          <Button
            onClick={openCreateQuestionModal}
            variant="outlined"
            sx={{ bgcolor: '#8950FC1A', borderColor: '#8950FC' }}
          >
            Criar passo do onboarding
          </Button>
        </EmptyStateCard>
      ) : (
        <QuestionList />
      )}
      <QuestionDragLayer />
    </Context.Provider>
  );
};

export default Onboarding;
