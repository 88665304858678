import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  SvgIconTypeMap,
  Typography,
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { ReactElement } from 'react';

interface SidebarAccordionProps {
  children: React.ReactNode;
  expanded: boolean;
  icon: ReactElement<
    OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string }
  >;
  title: string;
  onExpand: () => void;
}

const SidebarAccordion = ({
  children,
  expanded,
  icon,
  title,
  onExpand,
}: SidebarAccordionProps) => (
  <Accordion
    expanded={expanded}
    sx={{ '&.Mui-expanded': { margin: 0, minHeight: 48 } }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon sx={{ color: 'grey.400' }} />}
      sx={{
        backgroundColor: 'grey.600',
        height: 48,
        p: 3,
        '&.Mui-expanded': { minHeight: 48, margin: 0 },
      }}
      onClick={() => onExpand()}
    >
      <Box
        sx={{
          height: 24,
          m: 0,
          width: 24,
        }}
      >
        {icon}
      </Box>
      <Typography
        color="grey.100"
        fontSize={14}
        lineHeight={1.8}
        marginLeft={3}
      >
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        p: 5,
        position: 'relative',
      }}
    >
      {children}
    </AccordionDetails>
  </Accordion>
);

export default SidebarAccordion;
