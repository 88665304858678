import { gql } from '@apollo/client';

const contentsQuery = gql`
  query ContentsQuery {
    contents {
      id
      spallaVodId
      title
      type
      thumbnail
      createdAt
      tags {
        name
      }
    }
  }
`;

export default contentsQuery;