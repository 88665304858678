export const modules = {
  toolbar: [
    [
      "bold",
      "italic",
      "underline",
      { align: "" },
      { align: "center" },
      { align: "right" },
    ],
  ],
  clipboard: {
    matchVisual: false,
  },
};

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "align",
];
