import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import {
  EmojiObjects as EmojiObjectsIcon,
  Share as ShareIcon,
} from '@material-ui/icons';
import TileCard4 from '../../../components/TileCard4';
import Context from '../Context';

const SettingsPlans = () => {
  const navigate = useNavigate();
  const { permissionsToReadSpectators } = useContext(Context);
  return (
    <Grid container spacing={4} sx={{ mt: 4 }}>
      <Grid item xs={12}>
        <Typography
          sx={{
            alignItems: 'center',
            color: 'grey.100',
            display: 'flex',
            fontSize: 20,
            fontWeight: 600,
            mb: 2,
          }}
        >
          Configurações das perguntas
        </Typography>
      </Grid>
      {permissionsToReadSpectators && (
        <Grid item xs={4}>
          <TileCard4
            title="Onboarding do espectador"
            description="Personalize a experiência do seu espectador com perguntas importantes!"
            leftIcon={EmojiObjectsIcon}
            sx={{ mr: 4, p: 5 }}
          >
            <Button onClick={() => navigate('/plans/onboarding')}>
              Gerenciar onboarding
            </Button>
          </TileCard4>
        </Grid>
      )}
      <Grid item xs={4}>
        <TileCard4
          title="Afiliados e Marcas"
          description="Escolha e configure os parceiros que estarão integrados aos planos."
          leftIcon={ShareIcon}
          sx={{ mr: 4, p: 5 }}
        >
          <Button>Gerenciar afiliados e marcas</Button>
        </TileCard4>
      </Grid>
    </Grid>
  );
};

export default SettingsPlans;
