import { useQuery } from '@apollo/client';
import { Avatar, Box, Grid, Stack, Typography } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { format, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { useSpectator } from '../Context';
import userQuery from '../gql/userQuery';

const SpectatorDataDrawer = () => {
  const { selectedSpectator } = useSpectator();
  const { loading, data } = useQuery(userQuery, {
    variables: { id: selectedSpectator!.id },
  });
  if (!selectedSpectator) return null;
  const formatDate = useMemo(() => {
    const updatedAt = format(
      parseISO(new Date(selectedSpectator.updatedAt).toISOString()),
      'dd/MM/yyyy',
    );
    const createdAt = format(
      parseISO(new Date(selectedSpectator.createdAt).toISOString()),
      'dd/MM/yyyy',
    );
    return {
      updatedAt,
      createdAt,
    };
  }, []);
  if (loading) return <div>Carregando...</div>;
  return (
    <Stack direction="column" sx={{ px: 6, py: '85px' }}>
      <Stack
        direction="column"
        sx={{
          alignItems: 'center',
          borderBottomWidth: 1,
          borderColor: 'grey.500',
          borderStyle: 'solid',
          borderWidth: 0,
          mb: 6,
          pb: 6,
        }}
      >
        <Avatar alt="avatar" sx={{ height: 86, width: 86 }} />
        <Typography
          color="white"
          sx={{ fontSize: 16, fontWeight: 600, lineHeight: '24px', mt: 4 }}
        >
          {selectedSpectator.name}
        </Typography>
      </Stack>

      <Stack
        direction="column"
        sx={{
          borderBottomWidth: 1,
          borderColor: 'grey.500',
          borderStyle: 'solid',
          borderWidth: 0,
          mb: 6,
          pb: 6,
        }}
      >
        <Stack direction="row" alignItems="center" sx={{ mb: 6 }}>
          <Typography color="white" sx={{ fontSize: 20, fontWeight: 600 }}>
            Dados do espectador
          </Typography>
          <InfoIcon sx={{ color: 'grey.400', ml: 2, height: 12, width: 12 }} />
        </Stack>
        <Grid container>
          <Grid item xs={4}>
            <Stack direction="column" spacing={2}>
              <Typography color="grey.300" sx={{ fontSize: 10 }}>
                NOME COMPLETO
              </Typography>
              <Typography
                noWrap
                sx={{
                  color: 'grey.200',
                  fontSize: 14,
                  maxWidth: 150,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {selectedSpectator.name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <Stack direction="column" spacing={2}>
              <Typography color="grey.300" sx={{ fontSize: 10 }}>
                EMAIL
              </Typography>
              <Typography
                noWrap
                color="grey.200"
                sx={{
                  fontSize: 14,
                  color: 'grey.200',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  width: 180,
                }}
              >
                {selectedSpectator.email}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="column" spacing={2}>
              <Typography color="grey.300" sx={{ fontSize: 10 }}>
                TELEFONE
              </Typography>
              <Typography color="grey.200" sx={{ fontSize: 14 }}>
                {selectedSpectator.phone}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 6 }}>
          <Grid item xs={4}>
            <Stack direction="column" spacing={2}>
              <Typography color="grey.300" sx={{ fontSize: 10 }}>
                ESPECTADOR DESDE
              </Typography>
              <Typography
                noWrap
                color="grey.200"
                sx={{
                  fontSize: 14,
                }}
              >
                {formatDate.createdAt}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <Stack direction="column" spacing={2}>
              <Typography color="grey.300" sx={{ fontSize: 10 }}>
                ÚLTIMA ATUALIZAÇÃO
              </Typography>
              <Typography
                noWrap
                color="grey.200"
                sx={{
                  fontSize: 14,
                }}
              >
                {formatDate.updatedAt}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="column" spacing={2}>
              <Typography color="grey.300" sx={{ fontSize: 10 }}>
                ÚLTIMO ACESSO
              </Typography>
              <Stack direction="row" alignItems="center">
                <Box
                  sx={{
                    backgroundColor: 'success.main',
                    width: 8,
                    height: 8,
                    borderRadius: '4px',
                  }}
                />
                <Typography color="grey.200" sx={{ fontSize: 14, ml: '6px' }}>
                  Online agora
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="column">
        <Stack direction="row" alignItems="center" sx={{ mb: 6 }}>
          <Typography color="white" sx={{ fontSize: 16, fontWeight: 600 }}>
            Informações de cadastro
          </Typography>
          <InfoIcon
            sx={{
              color: 'grey.400',
              ml: 2,
              width: 12,
              height: 12,
            }}
          />
        </Stack>
        <Grid container>
          <Grid item xs={4}>
            <Stack direction="column" spacing={2}>
              <Typography color="grey.300" sx={{ fontSize: 10 }}>
                PERGUNTA DO CADASTRO
              </Typography>
              <Typography
                noWrap
                color="grey.200"
                sx={{
                  fontSize: 14,
                  maxWidth: 155,
                }}
              >
                {data.user.signupAnswer?.question?.title}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs="auto">
            <Stack direction="column" spacing={2}>
              <Typography color="grey.300" sx={{ fontSize: 10 }}>
                RESPOSTA DO CADASTRO
              </Typography>
              <Typography
                noWrap
                color="grey.200"
                sx={{
                  fontSize: 14,
                }}
              >
                {data.user.signupAnswer?.tag?.name}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default SpectatorDataDrawer;
