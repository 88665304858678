import { FormikProps } from 'formik';
import { createContext } from 'react';

interface ContextType {
  // TODO: Add typing
  form: FormikProps<any>;
}

const Context = createContext({} as ContextType);

export default Context;
