import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  AspectRatio as AspectRatioIcon,
  Computer as ComputerIcon,
  MoreHoriz as MoreHorizIcon,
  Smartphone as SmartphoneIcon,
  PlayArrow as PlayArrowIcon,
  Save as SaveIcon,
  Today as TodayIcon,
  Tv as TvIcon,
  WatchLater as WatchLaterIcon,
} from '@material-ui/icons';
import bytes from 'bytes';
import { useAuthorize } from '@tshio/react-router-permissions';
import { useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import Player from '../../components/Player';
import TileCard2 from '../../components/TileCard2';
import TileCard3 from '../../components/TileCard3';
import Navbar from '../../components/UI/molecules/Navbar';
import TipAlert from '../../components/UI/molecules/TipAlert';
import HasNoPermission from '../../components/HasNoPermission';
import ContextToMe from '../../Context';
import { Tag } from '../../interfaces/Tag';
import { getDurationFromSecs } from '../../utils/date';
import ContentQuery from './gql/ContentQuery';

const ContentPreview = () => {
  const { me } = useContext(ContextToMe);
  const permissionForEverything = me.superAdmin;
  const permissionToRead =
    permissionForEverything || useAuthorize(['contents', 'read']);
  const permissionToUpdate =
    permissionForEverything || useAuthorize(['contents', 'update']);
  if (!permissionToRead) return <HasNoPermission />;
  const { contentId } = useParams();
  const [open, setOpen] = useState(false);
  const { loading, data } = useQuery(ContentQuery, {
    variables: { id: contentId },
  });
  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);
  const getUrlByContentType = () => {
    const contentIsVod = data.content.type === 'vod';
    if (contentIsVod) {
      return process.env.REACT_APP_VOD_PREVIEW_URL;
    }
    return process.env.REACT_APP_LIVE_PREVIEW_URL;
  };
  if (loading) {
    return <div>loading...</div>;
  }
  return (
    <Box>
      <Navbar title={data.content.title} route="/library">
        {permissionToUpdate && (
          <Link to={`/content/${contentId}/edit`}>
            <Button
              data-testid="edit-content"
              sx={{ fontSize: 14, px: 3, py: 2 }}
            >
              Editar conteúdo
            </Button>
          </Link>
        )}
      </Navbar>
      <TipAlert sx={{ my: 4 }}>
        Overlays são uma ótima forma de prender seu espectador no conteúdo de
        forma dinâmica e visual
      </TipAlert>
      <Card>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={7}>
              <Stack direction="row" spacing={4}>
                <Chip
                  variant="outlined"
                  color={data.content.type === 'live' ? 'error' : 'info'}
                  label={
                    <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                      {data.content.type === 'live' ? 'Ao vivo' : 'On demand'}
                    </Typography>
                  }
                  size="small"
                />
                <Chip
                  variant="outlined"
                  color={data.content.published ? 'success' : 'warning'}
                  label={
                    <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                      {data.content.published ? 'Publicado' : 'Privado'}
                    </Typography>
                  }
                  size="small"
                />
              </Stack>
              <Typography
                sx={{
                  color: 'common.white',
                  fontSize: 24,
                  mt: 5,
                  width: '80%',
                }}
              >
                {data.content.title}
              </Typography>
              <Stack
                sx={{
                  bgcolor: 'grey.500',
                  borderRadius: 1,
                  flexDirection: 'row',
                  height: 36,
                  mt: 4,
                  width: 228,
                }}
              >
                {/* TODO: ajust the margin of the PlayArrowIcon */}
                <Chip
                  icon={<PlayArrowIcon />}
                  label={
                    <Stack
                      direction="row"
                      sx={{ alignItems: 'center', '.MuiChip-label': { pr: 2 } }}
                    >
                      <Typography sx={{ color: 'common.white', fontSize: 12 }}>
                        <b>1.762.658</b> Visualizações
                      </Typography>
                      {/* TODO: fix margin between tip and chip, also fix the height of tip and the font size */}
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={handleTooltipClose}
                          open={open}
                          placement="right"
                          arrow
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="1455 em outras plataformas"
                        >
                          <IconButton
                            onClick={handleTooltipOpen}
                            sx={{
                              bgcolor: 'transparent',
                              color: 'grey.300',
                              pl: 4,
                              my: 2,
                            }}
                          >
                            <MoreHorizIcon />
                          </IconButton>
                        </Tooltip>
                      </ClickAwayListener>
                    </Stack>
                  }
                  sx={{
                    bgcolor: 'grey.500',
                    borderRadius: 1,
                    color: 'secondary.main',
                    height: 36,
                    '.MuiChip-label': { pr: 0 },
                    'MuiSvgIcon-root': { mr: 0, ml: 4 },
                  }}
                />
              </Stack>
              <Typography
                sx={{ color: 'grey.300', fontSize: 14, mt: 5, width: '80%' }}
              >
                {data.content.description}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              {data.content.externalContent ? (
                <iframe
                  src={data.content.externalUrl.replace('watch?v=', 'embed/')}
                  title={data.content.title}
                  style={{ height: '295px', width: '100%' }}
                />
              ) : (
                <Player
                  // @ts-ignore
                  source={{
                    sources: [
                      {
                        src: `${getUrlByContentType()}${
                          data.content.spallaContentId
                        }/playlist.m3u8`,
                        type: 'application/x-mpegurl',
                      },
                    ],
                    textTracks: {
                      default: true,
                      src: `${getUrlByContentType()}${
                        data.content.spallaContentId
                      }/thumbnail.vtt`,
                      label: 'thumbnails',
                      kind: 'metadata',
                    },
                  }}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={4} sx={{ mt: 0 }}>
        <Grid item xs={3}>
          <TileCard2
            icon={WatchLaterIcon}
            title="Duração do conteúdo:"
            value={getDurationFromSecs(data.content.sourceDuration)}
          />
        </Grid>
        <Grid item xs={3}>
          <TileCard2
            icon={SaveIcon}
            title="Tamanho do arquivo:"
            value={bytes(data.content.sourceSize)}
          />
        </Grid>
        <Grid item xs={3}>
          <TileCard2
            icon={AspectRatioIcon}
            title="Resolução original:"
            value={data.content.sourceResolution}
          />
        </Grid>
        <Grid item xs={3}>
          <TileCard2
            icon={TodayIcon}
            title="Publicado em:"
            value={format(
              parseISO(data.content.createdAt),
              "dd/MM/yyyy 'às' HH:mm",
            )}
          />
        </Grid>
      </Grid>
      <Typography sx={{ color: 'common.white', fontSize: 20, mb: 4, mt: 6 }}>
        Informações de exibição
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs>
          <TileCard3
            title="Tags do conteúdo"
            tip="teste tip tags"
            sx={{ height: '100%' }}
          >
            <Stack direction="row" spacing={4} sx={{ flexWrap: 'wrap' }}>
              {data.content.tags.length === 0 ? (
                <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
                  Não há tags associadas a este conteúdo.
                </Typography>
              ) : (
                data.content.tags.map((element: Tag) => (
                  <Chip
                    label={
                      <Typography
                        sx={{
                          color: 'grey.100',
                          fontSize: 12,
                          fontWeight: 600,
                        }}
                      >
                        {element.name}
                      </Typography>
                    }
                    sx={{ bgcolor: 'grey.500', color: 'grey.100' }}
                  />
                ))
              )}
            </Stack>
          </TileCard3>
        </Grid>
        <Grid item xs>
          <TileCard3
            title="Dispositivos autorizados"
            tip="teste tip dispositivos"
            sx={{ height: '100%' }}
          >
            <Stack direction="row" spacing={4} sx={{ flexWrap: 'wrap' }}>
              {data.content.authorizedOnMobile && (
                <Chip
                  icon={<SmartphoneIcon />}
                  label={
                    <Typography
                      sx={{ color: 'grey.100', fontSize: 12, fontWeight: 600 }}
                    >
                      Mobile
                    </Typography>
                  }
                  sx={{ bgcolor: 'grey.500', color: 'secondary.main' }}
                />
              )}
              {data.content.authorizedOnDesktop && (
                <Chip
                  icon={<ComputerIcon />}
                  label={
                    <Typography
                      sx={{ color: 'grey.100', fontSize: 12, fontWeight: 600 }}
                    >
                      Desktop
                    </Typography>
                  }
                  sx={{ bgcolor: 'grey.500', color: 'secondary.main' }}
                />
              )}
              {data.content.authorizedOnTv && (
                <Chip
                  icon={<TvIcon />}
                  label={
                    <Typography
                      sx={{ color: 'grey.100', fontSize: 12, fontWeight: 600 }}
                    >
                      Smarttv
                    </Typography>
                  }
                  sx={{ bgcolor: 'grey.500', color: 'secondary.main' }}
                />
              )}
              {!data.content.authorizedOnTv &&
                !data.content.authorizedOnMobile &&
                !data.content.authorizedOnDesktop && (
                  <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
                    Nenhum dispositivo autorizado para este conteúdo.
                  </Typography>
                )}
            </Stack>
          </TileCard3>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentPreview;
