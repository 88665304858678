import { Stack, useTheme } from '@material-ui/core';
import { useDragLayer, XYCoord } from 'react-dnd';

interface DragLayerCollectedProps {
  currentOffset: XYCoord | null;
  isDragging: boolean;
  itemType: string;
}

interface CustomDragLayerProps {
  type: string;
  children: React.ReactNode;
  height?: number;
  width?: number;
  sourceOffset?: boolean;
}

// TODO: Add more props to maximize the customization
const CustomDragLayer = ({
  type,
  children,
  height = 138,
  width = 285,
  sourceOffset = false,
}: CustomDragLayerProps) => {
  const { palette, shape } = useTheme();
  const { currentOffset, isDragging, itemType } =
    useDragLayer<DragLayerCollectedProps>((monitor) => ({
      currentOffset: sourceOffset
        ? monitor.getSourceClientOffset()
        : monitor.getClientOffset(),
      isDragging: monitor.isDragging(),
      itemType: monitor.getItemType() as string,
    }));
  const isNotDraggingBlock = !isDragging || itemType !== type || !currentOffset;
  if (isNotDraggingBlock) {
    return null;
  }
  return (
    <Stack
      // TODO: using "style" here instead of "sx" to avoid page rendering lagging.
      style={{
        background: '#20133A', // TODO: export from theme
        border: `2px solid ${palette.info.main}`,
        borderRadius: shape.borderRadius,
        height,
        left: currentOffset!.x,
        pointerEvents: 'none',
        position: 'fixed',
        top: currentOffset!.y,
        transform: 'rotate(2deg)',
        width,
        zIndex: 100, // TODO: export from theme
      }}
      alignItems="center"
      flexDirection="row"
      justifyContent="center"
    >
      {children}
    </Stack>
  );
};

export default CustomDragLayer;
