import { useQuery, useMutation } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../components/UI/molecules/Navbar';
import FormFooter from '../../components/UI/templates/FormFooter';
import { HighlightSection } from '../../interfaces/HighlightSection';
import ChooseImage from '../CreateHighlightSections/components/ChooseImage';
import validationSchema from '../CreateHighlightSections/providers/validationSchema';
import Details from '../CreateHighlightSections/components/Details';
import Context from '../CreateHighlightSections/Context';
import getMultiSectionItemQuery from './gql/getMultiSectionItemQuery';
import updateMultiSectionItemMutation from './gql/updateMultiSectionItemMutation';

type FormValues = Pick<
  HighlightSection,
  | 'id'
  | 'section'
  | 'content'
  | 'itemType'
  | 'title'
  | 'thumbnail'
  | 'scheduleDate'
  | 'finishDate'
>;

const EditHighlightSections = () => {
  const alert = useAlert();
  const { highlightId } = useParams();
  const navigate = useNavigate();
  const [updateMultiSectionItem] = useMutation(updateMultiSectionItemMutation);
  const { loading, data } = useQuery(getMultiSectionItemQuery, {
    variables: { id: highlightId },
  });
  const [isEditing, setIsEditing] = useState(false);
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.multiSectionItem.id,
      itemType: data?.multiSectionItem.itemType,
      scheduleDate: data?.multiSectionItem.scheduleDate,
      finishDate: data?.multiSectionItem.finishDate,
      title: data?.multiSectionItem.title,
      content: data?.multiSectionItem.content || '',
      section: data?.multiSectionItem.section || '',
      thumbnail: data?.multiSectionItem.thumbnail,
    },
    validationSchema,
    onSubmit: (values: FormValues) => {
      try {
        setIsEditing(true);
        const input = validationSchema.cast(values);
        updateMultiSectionItem({
          variables: { input },
        });
        alert.success('Destaque atualizado com sucesso!');
      } catch {
        alert.error('Ocorreu um erro ao editar o destaque.');
      } finally {
        setIsEditing(false);
      }
    },
  });
  if (loading) return <div>Carregando...</div>;
  return (
    <Context.Provider value={{ form }}>
      <Navbar title="Editar destaque" route="/section/highlights" />
      <Grid container gap={5} sx={{ mt: 6 }}>
        <Grid item xs={8}>
          <Details />
        </Grid>
        <Grid item xs>
          <ChooseImage />
        </Grid>
      </Grid>
      <FormFooter
        LeftButtonProps={{
          children: 'Salvar destaque',
          type: 'submit',
          loading: isEditing,
          onClick: () => form.handleSubmit(),
        }}
        helperText=""
        RightButtonProps={{
          children: 'Cancelar edição',
          onClick: () => navigate('/section/highlights'),
        }}
        sx={{ mt: 6 }}
      />
    </Context.Provider>
  );
};

export default EditHighlightSections;
