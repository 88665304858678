import { Button, Stack, Typography } from '@material-ui/core';
import {
  FileCopy as FileCopyIcon,
  Launch as LaunchIcon,
} from '@material-ui/icons/';
import { useAuthorize } from '@tshio/react-router-permissions';
import { useContext } from 'react';
import { useDialog } from '../../../components/Dialog';
import TileCard4 from '../../../components/TileCard4';
import Context from '../../../Context';
import PreviewPlanContext from '../Context';
import ListPages from './ListPages';

const Pages = () => {
  const { me } = useContext(Context);
  const permissionForEverything = me.superAdmin;
  const permissionToRead =
    permissionForEverything || useAuthorize(['pages', 'read']);
  const dialog = useDialog();
  const {
    plan: { id },
  } = useContext(PreviewPlanContext);
  const openDialogListPages = async () => {
    await dialog.open({
      title: 'Páginas do Plano',
      element: <ListPages planId={id} />,
      sx: { width: 667, height: 580 },
    });
  };
  return (
    <>
      <Typography
        sx={{ color: 'grey.100', fontSize: 20, fontWeight: 600, mb: 6, mt: 8 }}
      >
        Ações de Marketing
      </Typography>
      <Stack direction="row" spacing={4}>
        <TileCard4
          disabled
          title="Páginas do Plano"
          description="São as páginas asssociadas a cada etapa do clico de vida do seu cliente."
          leftIcon={FileCopyIcon}
          rightIcon={LaunchIcon}
          sx={{ mr: 4, p: 5, width: 374 }}
        >
          {permissionToRead && (
            <Button
              disabled
              data-testid="button-show-pages"
              onClick={openDialogListPages}
            >
              Ver páginas do plano
            </Button>
          )}
        </TileCard4>
      </Stack>
    </>
  );
};

export default Pages;
