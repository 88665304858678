import { useMutation, useQuery } from '@apollo/client';
import { Stack } from '@material-ui/core';
import { memo, useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { HighlightSection } from '../../../interfaces/HighlightSection';
import reorder from '../../../utils/reorderList';
import sortItems from '../../../utils/sortItems';
import getMultiSectionItemsQuery from '../gql/getMultiSectionItemsQuery';
import updateMultiSectionItemMutation from '../gql/updateMultiSectionItemMutation';
import HighlightListItem from './HighlightListItem';

const MemoizedHighlightSection = memo(
  ({
    multiSectionsItems,
    refetch,
  }: {
    multiSectionsItems: HighlightSection[];
    refetch: () => void;
  }) => (
    <>
      {multiSectionsItems.map((multiSectionsItem, index: number) => (
        <HighlightListItem
          key={multiSectionsItem.id}
          index={index}
          refetch={refetch}
          multiSectionsItem={multiSectionsItem}
        />
      ))}
    </>
  ),
);

const HighlightSectionList = () => {
  const [multiSectionsItems, setMultiSectionsItems] = useState<
    HighlightSection[]
  >([]);
  const { loading, data, refetch } = useQuery(getMultiSectionItemsQuery);
  const [updateMultiSectionItem] = useMutation(updateMultiSectionItemMutation);
  useEffect(() => {
    if (data?.multiSectionsItems) {
      const sortedItemsByPosition = sortItems(
        data.multiSectionsItems,
        'position',
      ) as HighlightSection[];
      setMultiSectionsItems(sortedItemsByPosition);
    }
  }, [data]);
  if (loading) return <div>Carregando...</div>;
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const newMultiSectionsItems = reorder(
      multiSectionsItems,
      result.source.index,
      result.destination.index,
    );
    setMultiSectionsItems(newMultiSectionsItems);
    const itemToUpdate = newMultiSectionsItems[
      result.destination.index
    ] as HighlightSection;
    await updateMultiSectionItem({
      variables: {
        input: {
          id: itemToUpdate.id,
          position: result.destination.index + 1,
          itemType: itemToUpdate.itemType,
          content: itemToUpdate.content ? itemToUpdate.content.id : null,
          section: itemToUpdate.section ? itemToUpdate.section.id : null,
        },
      },
    });
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="listHighlightSection">
        {(provided) => (
          <Stack ref={provided.innerRef} {...provided.droppableProps}>
            <MemoizedHighlightSection
              multiSectionsItems={multiSectionsItems || []}
              refetch={refetch}
            />
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default HighlightSectionList;
