import { HeartTeam } from './HeartTeam';
import { Tag } from './Tag';

export interface MclsContent {
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  id: string;
  embed: String;
  eventId: string;
  title: String;
  urlM3u8: String;
}

export enum ContentType {
  vod = 'vod',
  live = 'live',
}

export interface Content {
  id: string;
  spallaContentId: string;
  title: string;
  description: string;
  thumbnail: string;
  authorizedOnMobile: boolean;
  authorizedOnDesktop: boolean;
  authorizedOnTv: boolean;
  type: string;
  eventDate?: Date | null;
  scheduleDate?: Date | null;
  scheduleDateEnabled: boolean;
  finishDate?: Date | null;
  finishDateEnabled: boolean;
  createdAt: Date;
  updatedAt: Date;
  published: boolean;
  externalContent: boolean;
  spallaProvider?: boolean;
  mclsContent?: MclsContent;
  externalEmbed: string;
  externalUrl: string;
  tags: Tag[];
  teams: HeartTeam[];
  chatEnable?: boolean;
  chatEmbed?: string;
}
