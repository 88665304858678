import {
  Card,
  CardContent,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  SvgIconProps,
  Typography,
} from '@material-ui/core';
import {
  MoreVert as MoreOptionsIcon,
  TextFormat as TextFormatIcon,
} from '@material-ui/icons';
import { SxProps, Theme } from '@material-ui/system';
import { ComponentType, useState } from 'react';

interface TileCard6Props {
  buttonVisible: boolean;
  icon: ComponentType<SvgIconProps>;
  helperText?: string;
  value: string | number;
  secondValue?: string;
  sx?: SxProps<Theme>;
  onClickViewPartners?: () => void;
}

const TileCard6 = ({
  buttonVisible,
  icon: Icon,
  helperText,
  value,
  secondValue,
  sx,
  onClickViewPartners,
}: TileCard6Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;
  const closeMenuHandler = () => setAnchorEl(null);
  const openMenuHandler = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const openPartnersModal = () => {
    onClickViewPartners!();
    closeMenuHandler();
  };
  return (
    <Card sx={{ height: 177, width: 277, p: 5, ...sx }}>
      <CardContent sx={{ p: 0 }}>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={closeMenuHandler}
        >
          <MenuItem data-testid="view-partners" onClick={openPartnersModal}>
            <ListItemIcon>
              <TextFormatIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Visualizar Afiliados...</Typography>
          </MenuItem>
        </Menu>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Icon color="secondary" sx={{ fontSize: 40 }} />
          {buttonVisible && (
            <IconButton
              data-testid="icon-open-menu"
              sx={{ p: 0 }}
              onClick={openMenuHandler}
            >
              <MoreOptionsIcon sx={{ color: 'primary.light' }} />
            </IconButton>
          )}
        </Stack>
        <Stack alignItems="center" direction="row" gap={3} sx={{ mt: 4 }}>
          <Typography
            sx={{ color: 'common.white', fontSize: 40, fontWeight: 600 }}
          >
            {value}
          </Typography>
          <Typography
            sx={{ color: 'grey.300', fontSize: 16, fontWeight: 600, mt: 1 }}
          >
            {secondValue}
          </Typography>
        </Stack>
        <Typography
          sx={{ color: 'grey.300', fontSize: 16, fontWeight: 600, mt: 1 }}
        >
          {helperText}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TileCard6;
