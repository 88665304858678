import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@material-ui/core';
import { Delete as DeleteIcon, Group as GroupIcon } from '@material-ui/icons';
import { useAuthorize } from '@tshio/react-router-permissions';
import { useContext, useState } from 'react';
import { useDialog } from '../../components/Dialog';
import EmptyStateCard from '../../components/EmptyStateCard';
import HasNoPermission from '../../components/HasNoPermission';
import TipAlert from '../../components/UI/molecules/TipAlert';
import Topbar from '../../components/Topbar';
import BulkActionController from '../../components/UI/templates/BulkActionController';
import ContextToMe from '../../Context';
import { Manager } from '../../interfaces/Manager';
import GenerateToken from './Components/GenerateToken';
import ManagerModal from './Components/ManagerModal';
import ManagerSearch from './Components/ManagerSearch';
import Context from './Context';
import createManagerMutation from './gql/createManagerMutation';
import deleteManagerMutation from './gql/deleteManagerMutation';
import managersQuery from './gql/managersQuery';
import updateManagerMutation from './gql/updateManagerMutation';
import uploadFile from './services/uploadFile';

const makeManager = {
  id: '1',
  name: '',
  email: '',
  password: '',
  enabled: true,
  thumbnail: 'https://i.imgur.com/N3btMsU.png',
  createdAt: new Date(),
};

const ManagersList = () => {
  const { me } = useContext(ContextToMe);
  const permissionForEverything = me.superAdmin;
  const permissionToRead =
    permissionForEverything || useAuthorize(['managers', 'read']);
  const permissionToCreate =
    permissionForEverything || useAuthorize(['managers', 'create']);
  const permissionToDelete =
    permissionForEverything || useAuthorize(['managers', 'delete']);
  const permissionToUpdate =
    permissionForEverything || useAuthorize(['managers', 'update']);
  const noPermission =
    !permissionToRead &&
    !permissionToCreate &&
    !permissionToDelete &&
    !permissionToUpdate;
  if (noPermission) return <HasNoPermission />;
  const dialog = useDialog();
  const { loading, data, refetch } = useQuery(managersQuery);
  const [createManager] = useMutation(createManagerMutation);
  const [updateManager] = useMutation(updateManagerMutation);
  const [deleteManager] = useMutation(deleteManagerMutation);
  const [selectedManager, setSelectedManager] = useState<Manager[]>([]);
  const checkboxSelectedManager = (manager: Manager) => {
    const managerIndex = selectedManager.findIndex(
      (currentManager) => currentManager?.id === manager.id,
    );
    const managerExists = managerIndex !== -1;
    if (managerExists) {
      const newContentSelected = selectedManager.filter(
        (currentManager) => currentManager.id !== manager.id,
      );
      setSelectedManager(newContentSelected);
      return;
    }
    setSelectedManager((previousContents) => [manager, ...previousContents]);
  };
  const deleteManagerHandler = async (managersToDelete: Manager[]) => {
    const confirmed = await dialog.confirm({
      title: 'Você tem certeza?',
      message: `Excluir um gestor o remove permanentemente da sua lista. Não será possível recuperá-lo.`,
    });
    if (confirmed) {
      // eslint-disable-next-line no-restricted-syntax
      for (const managerToDelete of managersToDelete) {
        // eslint-disable-next-line no-await-in-loop
        await deleteManager({
          variables: { id: managerToDelete.id },
        });
      }
      await refetch();
      setSelectedManager([]);
    }
  };
  const editManagerHandler = async (manager: Manager) => {
    const managerEdit = await dialog.open({
      title: 'Editar Gestor',
      element: (
        <ManagerModal manager={manager} titleButton="Salvar Alterações" />
      ),
      sx: { height: '100%', width: 400, overflowY: 'hidden' },
    });
    if (managerEdit) {
      const isUploadingFile = managerEdit.thumbnail instanceof File;
      if (isUploadingFile) {
        const res = await uploadFile<{ data: { fileUpload: string } }>(
          managerEdit.thumbnail,
        );
        managerEdit.thumbnail = res.data.data.fileUpload;
      }
      await updateManager({
        variables: { input: { ...managerEdit, id: manager.id } },
      });
      await refetch();
    }
  };
  const createManagerHandler = async () => {
    const newManager = await dialog.open({
      title: 'Cadastrar novo gestor',
      element: (
        <ManagerModal
          managers={data.managers}
          manager={makeManager as Manager}
        />
      ),
      sx: { height: '100%', width: 400, overflowY: 'hidden' },
    });
    if (newManager) {
      const isUploadingFile = newManager.thumbnail instanceof File;
      if (isUploadingFile) {
        const res = await uploadFile<{ data: { fileUpload: string } }>(
          newManager.thumbnail,
        );
        newManager.thumbnail = res.data.data.fileUpload;
      }
      await createManager({ variables: { input: newManager } });
      await refetch();
    }
  };
  const changeEnabledManagerHandler = async (
    enabled: boolean,
    id: Manager['id'],
  ) => {
    await updateManager({
      variables: { input: { id, enabled } },
    });
    await refetch();
  };
  const openModalToGenerateToken = () => {
    dialog.open({
      title: 'Token do usuário',
      element: <GenerateToken />,
      sx: { width: 436 },
    });
  };
  if (loading) return <div>Carregando...</div>;
  return (
    <Context.Provider
      value={{
        managers: data.managers,
        selectedManager,
        changeEnabledManagerHandler,
        checkboxSelectedManager,
        editManagerHandler,
        deleteManagerHandler,
        permissionToDelete,
        permissionToRead,
        permissionToUpdate,
      }}
    >
      <Topbar
        title="Gestores"
        description="Crie ou edite seus gestores restringindo ou dando acessos exclusivos."
      >
        <Button
          sx={{ bgcolor: 'secondary.main', mr: 4 }}
          onClick={openModalToGenerateToken}
        >
          Gerenciar token
        </Button>
        {permissionToCreate && (
          <Button
            data-testid="button-new-manager"
            sx={{ bgcolor: 'secondary.main' }}
            onClick={createManagerHandler}
          >
            Novo gestor
          </Button>
        )}
      </Topbar>
      <TipAlert sx={{ my: 4 }}>
        Permita que gestores específicos visualizem, editem e excluam itens da
        plataforma de acordo com suas permissões.
      </TipAlert>
      {data.managers.length ? (
        <ManagerSearch />
      ) : (
        <EmptyStateCard
          icon={<GroupIcon sx={{ color: 'info', fontSize: 40 }} />}
          title="Gestores"
          description="Crie gestores permitindo anexar cargos com permissões específicas."
        >
          {permissionToCreate && (
            <Button
              variant="outlined"
              color="info"
              onClick={createManagerHandler}
            >
              Criar Gestor
            </Button>
          )}
        </EmptyStateCard>
      )}
      {permissionToDelete && (
        <BulkActionController
          iconItem={DeleteIcon}
          listItem={selectedManager}
          ButtonProps={{
            color: 'error',
            children: 'Excluir',
            onClick: () => deleteManagerHandler(selectedManager),
          }}
          ListItemIconProps={{ color: 'error' }}
        />
      )}
    </Context.Provider>
  );
};

export default ManagersList;
