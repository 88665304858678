import { createContext } from 'react';
import { PlanTypeToFinancialPlanReport } from './typings';

type ContextType = {
  planType: PlanTypeToFinancialPlanReport;
  setPlanType: (type: PlanTypeToFinancialPlanReport) => void;
};

const Context = createContext({} as ContextType);

export default Context;
