import {
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useContext } from 'react';
import Context from '../Context';

const SectionListEmpty = () => {
  const { isCreatingSection, setIsCreatingSection } = useContext(Context);
  const createSectionHandler = () => setIsCreatingSection(true);
  return (
    <Card>
      <CardContent>
        <Stack alignItems="center" justifyContent="center" sx={{ height: 328 }}>
          <MenuIcon sx={{ color: 'info.main', height: 48, width: 48 }} />
          <Typography
            sx={{ color: 'common.white', fontSize: 20, fontWeight: 600, mt: 5 }}
          >
            Crie sua primeira seção
          </Typography>
          <Typography
            align="center"
            sx={{
              color: 'grey.300',
              fontSize: 14,
              mt: 2,
              width: 380,
            }}
          >
            Você vai organizar a forma na qual o conteúdo é exibido para os seus
            espectadores
          </Typography>
          {!isCreatingSection && (
            <Button
              color="info"
              variant="outlined"
              sx={{ mt: 5 }}
              onClick={createSectionHandler}
            >
              Criar nova seção
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SectionListEmpty;
