import { gql } from '@apollo/client';

const sectionsQuery = gql`
  query SectionsOnlyQuery {
    sectionsOnly {
      id
      title
    }
  }
`;

export default sectionsQuery;
