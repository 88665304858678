import { ViewColumn as ViewColumnIcon } from '@material-ui/icons';
import SidebarAccordion from '../../components/SidebarAccordion';
import DoubleContainerBlock from '../widgets/DoubleContainerBlock';
import SingleContainerBlock from '../widgets/SingleContainerBlock';

interface GroupProps {
  expanded: boolean;
  onExpand: () => void;
}

const Structure = ({ expanded, onExpand }: GroupProps) => (
  <SidebarAccordion
    title="Estruturas"
    expanded={expanded}
    icon={
      <ViewColumnIcon
        sx={{
          color: 'secondary.main',
          height: 24,
          width: 24,
        }}
      />
    }
    onExpand={onExpand}
  >
    <SingleContainerBlock />
    <DoubleContainerBlock />
  </SidebarAccordion>
);

export default Structure;
