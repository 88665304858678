import { Chip, FormHelperText, Stack, Typography } from '@material-ui/core';
import { useContext } from 'react';
import { useDialog } from '../../../components/Dialog';
import TileCard5 from '../../../components/TileCard5';
import Context from '../Context';
import ModalToAddPlans from './ModalToAddPlans';

const AssociatedPlans = () => {
  const { form } = useContext(Context);
  const dialog = useDialog();
  const openModalToAddPlan = () =>
    dialog.open({
      title: 'Adicionar plano',
      element: (
        <ModalToAddPlans
          plans={form.values.plans}
          onChange={(plans) => form.setFieldValue('plans', plans)}
        />
      ),
      sx: { width: 400 },
    });
  return (
    <TileCard5
      title="Associar planos"
      titleProps={{ sx: { color: 'grey.100', fontSize: 16, mb: 2 } }}
      buttonVisible
      onClickButton={openModalToAddPlan}
      sx={{ height: 215, mt: 4 }}
    >
      <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
        Escolha em quais planos o desconto estará inclusos.
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        gap={2}
        sx={{ maxHeight: 100, mt: 3, overflowY: 'auto', pb: 4 }}
      >
        {form.values?.plans?.map((plan: any) => (
          <Chip
            label={plan.title}
            variant="outlined"
            size="small"
            sx={{
              color: 'grey.100',
              bgcolor: 'grey.500',
              borderRadius: 1,
              maxWidth: 230,
              pl: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          />
        ))}
      </Stack>
      <FormHelperText error>{form.errors.plans}</FormHelperText>
    </TileCard5>
  );
};

export default AssociatedPlans;
