import {
  Button,
  Card,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { useContext } from 'react';
import { useDialog } from '../../../components/Dialog';
import InlineTextEditor from '../../../components/InlineTextEditor';
import DialoghighlightedContents from './DialogContents';
import Context from '../Context';

const Details = () => {
  const dialog = useDialog();
  const { form } = useContext(Context);
  const openDialogHighlightedContents = async () => {
    await dialog.open({
      title: 'Adicionar destaques',
      element: (
        <DialoghighlightedContents
          selectedContent={form.values.content}
          onSelect={(content) => form.setFieldValue('content', content)}
        />
      ),
      sx: { height: 580, width: 667 },
    });
  };
  const contentIsSelected = !!form.values?.content?.title;
  return (
    <Grid container gap={4} sx={{ mt: 6 }}>
      <Grid item xs>
        <Card sx={{ height: 260, p: 6 }}>
          <InlineTextEditor
            value={form.values?.title}
            IconProps={{
              sx: {
                color: 'info.main',
                cursor: 'pointer',
                fontSize: 16,
                ml: 2,
              },
            }}
            TypographyProps={{
              sx: { color: 'grey.100', fontSize: 20, fontWeight: 600 },
            }}
            onChange={(title) => form.setFieldValue('title', title)}
          />
          <FormHelperText error>{form.errors.title}</FormHelperText>
          <InputLabel
            sx={{
              color: 'grey.300',
              fontSize: 14,
              mb: 1,
              mt: 4,
            }}
          >
            Descrição do destaque
          </InputLabel>
          <OutlinedInput
            name="description"
            value={form.values.description}
            sx={{
              borderColor: 'grey.400',
              borderStyle: 'solid',
              borderWidth: '1px',
              height: 110,
              width: 535,
            }}
            onChange={form.handleChange}
          />
          <FormHelperText error>{form.touched.description && form.errors.description}</FormHelperText>
        </Card>
      </Grid>
      <Grid item xs>
        <Card sx={{ height: 260, p: 6 }}>
          <Typography sx={{ color: 'grey.100', fontSize: 16, fontWeight: 600 }}>
            Seleção do conteúdo
          </Typography>
          <Typography sx={{ color: 'grey.300', fontSize: 14, mt: 4 }}>
            Escolha entre seus conteúdos, a qual vai pertencer esse destaque
          </Typography>
          <Typography
            sx={{
              color: 'grey.300',
              fontSize: 18,
              fontWeight: 600,
              mt: 4,
              maxWidth: 380,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {form.values?.content?.title || ''}
          </Typography>
          <Button
            sx={{ mt: contentIsSelected ? '40px' : '66px' }}
            onClick={openDialogHighlightedContents}
          >
            Adicionar conteúdo
          </Button>
          <FormHelperText error>{form.touched.content?.id && form.errors.content?.id}</FormHelperText>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Details;
