import { Button, ListItem, ListItemText } from '@material-ui/core';
import { Section } from '../../../interfaces/Section';

interface SectionsDialogItemProps {
  section: Section;
  isSelected: boolean;
  onSelect: (section: Section) => void;
}

const SectionsDialogItem = ({
  section,
  onSelect,
  isSelected,
}: SectionsDialogItemProps) => (
  <ListItem
    sx={{
      px: 0,
      '.MuiListItemSecondaryAction-root': { right: 0 },
    }}
    secondaryAction={
      <Button
        data-testid="button-toggle-select"
        variant="outlined"
        color={isSelected ? 'success' : 'primary'}
        sx={{ fontSize: 12, height: 30 }}
        onClick={() => onSelect(section)}
      >
        {isSelected ? 'Selecionada' : 'Selecionar'}
      </Button>
    }
  >
    <ListItemText
      primary={section.title}
      primaryTypographyProps={{
        sx: {
          maxWidth: 170,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      }}
      sx={{
        color: 'white',
      }}
    />
  </ListItem>
);

export default SectionsDialogItem;
