import { useEditor } from '@craftjs/core';
import { Switch, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useContext } from 'react';
import Navbar from '../../../../../components/UI/molecules/Navbar';
import BuilderContext from '../../context';
import DynamicTopBar from './DynamicTopBar';

const Header = () => {
  const { form } = useContext(BuilderContext);
  const { query } = useEditor();
  const savePageHandler = () => {
    form.setSubmitting(true);
    form.setFieldValue('blocks', JSON.parse(query.serialize()));
    form.handleSubmit();
  };
  return (
    <>
      <Navbar title={form.values.title} route="/pages">
        <Typography sx={{ color: 'grey.100', fontSize: 16, fontWeight: '600' }}>
          Publicado
        </Typography>
        <Switch
          name="published"
          checked={form.values.published}
          sx={{ ml: 3, mr: 5 }}
          onChange={form.handleChange}
        />
        <LoadingButton
          disabled={form.isSubmitting}
          loading={form.isSubmitting}
          onClick={savePageHandler}
        >
          Salvar alterações
        </LoadingButton>
      </Navbar>
      <DynamicTopBar />
    </>
  );
};

export default Header;
