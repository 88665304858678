import * as yup from 'yup';
import { DiscountType as ValueType } from '../../../interfaces/Plan';

const retentionDiscount = yup
  .object()
  .noUnknown()
  .shape({
    title: yup.string().required('Campo obrigatório'),
    price: yup.number().required('Campo obrigatório'),
    valueType: yup.mixed<ValueType>().oneOf(Object.values(ValueType)),
    recurrence: yup.number().required('Campo obrigatório'),
    recurrenceUnlimited: yup.boolean(),
    enabled: yup.boolean(),
    team: yup.mixed().transform((id) => (id ? { id } : null)),
    minimumPurchaseMonth: yup.number().required('Campo obrigatório'),
    plans: yup
      .array()
      .of(
        yup
          .object()
          .required('Campo obrigatório')
          .shape({ id: yup.string().required('Campo obrigatório') })
          .transform(({ id }) => ({ id })),
      )
      .min(1, 'Escolha pelo menos um plano.')
      .required('Campo obrigatório'),
  });

export default retentionDiscount;
