import { createContext, Dispatch, SetStateAction } from 'react';
import { FormikProps } from 'formik';
import { Content } from '../../interfaces/Content';
import { Section, SubSection } from '../../interfaces/Section';

export type SectionForm = Partial<Section>;

interface EditSectionContext {
  form: FormikProps<SectionForm>;
  section: Section;
  selectedContents: Content[];
  setSelectedContents: (contents: Content[]) => void;
  selectedSubSection: SubSection | undefined;
  setSelectedSubSection: Dispatch<
    SetStateAction<EditSectionContext['selectedSubSection']>
  >;
  selectableContents: Content[];
}

const Context = createContext({} as EditSectionContext);

export default Context;
