import { Typography } from '@material-ui/core';
import { SxProps, Theme } from '@material-ui/system';

interface DeleteItemsProps {
  quantity: number;
  sx?: SxProps<Theme>;
  onDeleteItems: () => void;
}

const DeleteItems = ({ quantity, sx, onDeleteItems }: DeleteItemsProps) => (
  <Typography
    onClick={onDeleteItems}
    sx={{ color: 'error.main', cursor: 'pointer', fontSize: 14, mb: 4, ...sx }}
  >
    Deletar os itens selecionados ({quantity})
  </Typography>
);

export default DeleteItems;
