import { SortByDirection } from '../../../typings/sort';
import { SortByType } from '../typings';

const getNumberByBoolean = (value?: boolean) =>
  Number((value as unknown as number) + 1);
export const sortByStringAsc = (prevValue: string, nextValue: string) => {
  if (prevValue < nextValue) {
    return -1;
  }
  if (prevValue > nextValue) {
    return 1;
  }
  return 0;
};
export const sortByStringDesc = (prevValue: string, nextValue: string) => {
  if (prevValue < nextValue) {
    return 1;
  }
  if (prevValue > nextValue) {
    return -1;
  }
  return 0;
};
const sortArrayBy =
  ({ direction, prop }: SortByType) =>
  (prev: any, next: any) => {
    let prevValue = null;
    let nextValue = null;
    prevValue =
      prop === 'enabled' ? getNumberByBoolean(prev[prop]) : prev[prop];
    nextValue =
      prop === 'enabled' ? getNumberByBoolean(next[prop]) : next[prop];
    const sortingAsc = direction === SortByDirection.Asc;
    if (!prevValue || !nextValue) {
      return 0;
    }
    const isString =
      typeof prevValue === 'string' && typeof nextValue === 'string';
    if (isString) {
      return sortingAsc
        ? sortByStringAsc(prevValue, nextValue)
        : sortByStringDesc(prevValue, nextValue);
    }
    if (sortingAsc) {
      return Math.abs(prevValue as number) - Math.abs(nextValue as number);
    }
    return Math.abs(nextValue as number) - Math.abs(prevValue as number);
  };

export default sortArrayBy;
