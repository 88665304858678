/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import InlineTextEditor from '../../components/InlineTextEditor';
import Topbar from '../../components/Topbar';
import HighlightSectionList from './components/HighlightSectionList';
import getMultiSectionQuery from './gql/getMultiSectionQuery';
import updateMultiSectionMutation from './gql/updateMultiSectionMutation';

const HighlightSections = () => {
  const alert = useAlert();
  const [updateMultiSection] = useMutation(updateMultiSectionMutation);
  const [title, setTitle] = useState('Destaques da semana');
  const { loading, data } = useQuery(getMultiSectionQuery);
  const updateMultiSectionHandler = async (newTitle: string) => {
    try {
      await updateMultiSection({
        variables: { input: { title: newTitle } },
      });
      alert.success('Sucesso ao atualizar o título');
    } catch {
      alert.error('Ocorreu um erro ao atualizar o título.');
    }
  };
  useEffect(() => {
    if (data) {
      setTitle(data.multiSection.title);
    }
  }, [data]);
  if (loading) return <div>Carregando...</div>;
  return (
    <>
      <Topbar
        title="Super seção"
        description="Crie um destaque para um conteúdo ou seção"
        sx={{ mb: 4 }}
      >
        <Link to="/section/highlights/create">
          <Button>Novo item</Button>
        </Link>
      </Topbar>
      <Card sx={{ height: 110, my: 4, p: 6 }}>
        <Typography sx={{ color: 'grey.300', fontSize: 14, mb: 2 }}>
          Escolha o nome da seção que aparecerá para seus espectadores:
        </Typography>
        <InlineTextEditor
          value={title}
          TypographyProps={{
            color: 'grey.100',
            fontSize: 20,
            fontWeight: 600,
          }}
          onChange={(newTitle) => {
            setTitle(newTitle);
            updateMultiSectionHandler(newTitle);
          }}
        />
      </Card>
      <HighlightSectionList />
    </>
  );
};

export default HighlightSections;
