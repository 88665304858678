import { Editor, Frame } from '@craftjs/core';
import { Stack } from '@material-ui/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  DoubleContainer,
  DynamicButton,
  DynamicImage,
  DynamicText,
  DynamicVideo,
  HalfContainer,
  PageFooter,
  PageHeader,
  SingleContainer,
} from './components';
import MainContainer from './components/MainContainer';
import Context from './context';

const Viewer = ({ json }: { json: string }) => {
  const [width, setWidth] = useState<number>(0);
  const mainRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const currentWidth = mainRef.current ? mainRef.current.offsetWidth : 0;
    setWidth(currentWidth);
  }, [mainRef.current]);
  const smartphoneScreen = useMemo(() => width < 720, [width]);
  return (
    <Context.Provider value={{ smartphoneScreen }}>
      <Stack ref={mainRef}>
        <Editor
          resolver={{
            DoubleContainer,
            DynamicButton,
            DynamicImage,
            DynamicText,
            DynamicVideo,
            HalfContainer,
            MainContainer,
            PageFooter,
            PageHeader,
            SingleContainer,
          }}
          enabled={false}
        >
          <Frame data={json}>
            <></>
          </Frame>
        </Editor>
      </Stack>
    </Context.Provider>
  );
};

export default Viewer;
