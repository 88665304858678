import { useQuery } from '@apollo/client';
import {
  Card,
  CardContent,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { ChangeEvent, useContext } from 'react';
import Context from '../../../Context';
import { SortBy as SortByDate } from '../../../typings/sort';
import listTeamsQuery from '../gql/listTeamsQuery';
import { VideoState, VideoType } from '../typings';

const FiltersBar = ({
  filterByPublished,
  filterByType,
  filterByTeam,
  query,
  setFilterByPublished,
  setFilterByType,
  setFilterByTeam,
  setPage,
  setQuery,
  setSortByDate,
  sortByDate,
}: any) => {
  const { loading, data: listTeams } = useQuery(listTeamsQuery);
  const filterByContentTypeHandler = (
    evt: SelectChangeEvent<VideoType | 'default'>,
  ) => setFilterByType(evt.target.value as VideoType | 'default');
  const filterByTeamHandler = (evt: SelectChangeEvent<string>) =>
    setFilterByTeam(evt.target.value as string);
  const filterByPublishedHandler = (
    evt: SelectChangeEvent<VideoState | 'default'>,
  ) => setFilterByPublished(evt.target.value as VideoState | 'default');
  const sortByDateHandler = (evt: SelectChangeEvent<SortByDate>) =>
    setSortByDate(evt.target.value as SortByDate);
  const queryHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setQuery(evt.target.value);
  };
  const { me } = useContext(Context);
  const isSuperAdmin = me.superAdmin;
  if (loading) return <div>Carregando...</div>;
  return (
    <Card data-testid="search-bar" sx={{ mt: 4 }}>
      <CardContent>
        <TextField
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'secondary.main' }} />
              </InputAdornment>
            ),
          }}
          placeholder="Procure aqui por um conteúdo específico"
          value={query}
          onChange={queryHandler}
        />
        <Stack direction="row" alignItems="center" spacing={4} sx={{ mt: 4 }}>
          {isSuperAdmin && (
            <Select
              value={filterByTeam}
              sx={{ minWidth: 161 }}
              onChange={filterByTeamHandler}
            >
              <MenuItem value="default">Todos</MenuItem>
              {listTeams.teams.items.map((team: any) => (
                <MenuItem value={team.id}>{team.name}</MenuItem>
              ))}
            </Select>
          )}
          <Select
            className="filter-by"
            value={filterByType}
            sx={{ minWidth: 161 }}
            onChange={filterByContentTypeHandler}
          >
            <MenuItem value="default">Todos</MenuItem>
            <MenuItem data-testid="filter-by-live" value={VideoType.Live}>
              Ao vivo
            </MenuItem>
            <MenuItem data-testid="filter-by-vod" value={VideoType.Vod}>
              On demand
            </MenuItem>
          </Select>
          <Select
            value={filterByPublished}
            sx={{ minWidth: 161 }}
            onChange={filterByPublishedHandler}
          >
            <MenuItem value="default">Todos</MenuItem>
            <MenuItem value={VideoState.Published}>Publicados</MenuItem>
            <MenuItem value={VideoState.Private}>Privados</MenuItem>
          </Select>
          <Select
            className="sort-by"
            value={sortByDate}
            sx={{ minWidth: 161 }}
            onChange={sortByDateHandler}
          >
            <MenuItem value={SortByDate.Newest}>Mais recentes</MenuItem>
            <MenuItem value={SortByDate.Oldest}>Mais antigos</MenuItem>
          </Select>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FiltersBar;
