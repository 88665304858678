import { gql } from '@apollo/client';

const liveContentsQuery = gql`
  query liveContentsQuery($input: ListLiveContentsFilters!) {
    liveContents(input: $input) {
      position
      subSection {
        id
        title
        section {
          id
        }
      }
      content {
        id
        title
        thumbnail
        type
      }
    }
  }
`;

export default liveContentsQuery;
