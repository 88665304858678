import {
  Button,
  Box,
  ClickAwayListener,
  Stack,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';

interface ColorPickerProps {
  color: string;
  name: string;
  onChange: (color: string) => void;
}

const ColorPicker = ({
  color,
  name,
  onChange,
}: ColorPickerProps) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const clickAwayHandler = () => setShowColorPicker(false);
  const colorChangeHandler = (evt: ColorResult) => {
    const hexPickedColor = evt.hex;
    onChange(hexPickedColor);
  };
  const toggleColorPickerHandler = () =>
    setShowColorPicker((prevState) => !prevState);
  return (
    <Box>
      <ClickAwayListener onClickAway={clickAwayHandler}>
        <Stack>
          <Typography fontSize={14} sx={{ color: 'grey.300', mb: 1 }}>
            {name}
          </Typography>
          <Button
            color="secondary"
            sx={{ borderRadius: 3, color: 'neutral.body', px: 2, width: 140 }}
            onClick={toggleColorPickerHandler}
          >
            <Box
              sx={{
                bgcolor: color,
                borderRadius: 1,
                height: 23,
                mr: 3,
                width: 44,
              }}
            />
            <Typography
              fontSize={14}
              sx={{ color: 'grey.200', textTransform: 'uppercase' }}
            >
              {color}
            </Typography>
          </Button>
          {showColorPicker && (
            <Box sx={{ mt: 3 }}>
              <ChromePicker
                color={color}
                onChangeComplete={colorChangeHandler}
                disableAlpha
              />
            </Box>
          )}
        </Stack>
      </ClickAwayListener>
    </Box>
  );
};

export default ColorPicker;
