import { ColorType } from '../../../interfaces/Role';

const ColorTypeHex = {
  [ColorType.blue]: '#2F49D1',
  [ColorType.white]: '#DAE0FF',
  [ColorType.purple]: '#8950FC',
  [ColorType.green]: '#00C572',
  [ColorType.red]: '#FF545E',
  [ColorType.yellow]: '#F1AE00',
  [ColorType.gray]: '#4E4E4E',
};

const getColorByName = (colorName: ColorType): string =>
  ColorTypeHex[colorName];

export default getColorByName;
