import { createContext } from 'react';
import { Plan, PlanType } from '../../interfaces/Plan';
import { PlansInput } from './typings';

export type Meta = {
  currentPage: number;
  itemCount: number;
  totalItems: number;
  totalPages: number;
};

type ContextType = {
  isEnabled: string;
  plans: Plan[];
  type: PlanType;
  meta: Meta | null;
  input: PlansInput;
  setInput: (input: PlansInput) => void;
  disablePlanHandler: (id: string) => void;
};

const Context = createContext({} as ContextType);

export default Context;
