// TODO: Remove when Craft.js support settings props without param reassign
/* eslint-disable no-param-reassign */
import { Stack, Switch, Typography } from '@material-ui/core';
import { ViewCompact as ViewCompactIcon } from '@material-ui/icons';
import { Node, useEditor } from '@craftjs/core';
import { useMemo } from 'react';
import SidebarAccordion from '../../components/SidebarAccordion';
import ColorPicker from '../../components/ColorPicker';
import SelectableSetting from '../../components/SelectableSetting';
import TextSetting from '../../components/TextSetting';
import { EditorType, Group as GroupProps } from './typings';

interface HeaderProps extends Object {
  headerBgColor?: string;
  headerButtonColor?: string;
  headerButtonLink?: string;
  headerButtonText?: string;
  headerHasButton?: boolean;
  headerIsInvisible?: boolean;
  headerTextColor?: string;
}

const Header = ({ expanded, onExpand }: GroupProps) => {
  const {
    actions: { setProp },
    nodes,
  }: EditorType<HeaderProps> = useEditor((query) => ({ nodes: query.nodes }));
  if (!nodes) return null;
  const [firstNode] = useMemo(
    () =>
      Object.values(nodes).filter(
        (node: Node) => node.data.name === 'MainContainer',
      ),
    [nodes],
  );
  if (!firstNode) return null;
  const bgColorChangeHandler = (color: string) =>
    setProp(firstNode.id, (currentProps) => {
      currentProps.headerBgColor = color;
    });
  const buttonBgColorChangeHandler = (color: string) =>
    setProp(firstNode.id, (currentProps) => {
      currentProps.headerButtonColor = color;
    });
  const buttonLinkChangeHandler = (text: string) =>
    setProp(firstNode.id, (currentProps) => {
      currentProps.headerButtonLink = text;
    });
  const buttonTextChangeHandler = (text: string) =>
    setProp(firstNode.id, (currentProps) => {
      currentProps.headerButtonText = text;
    });
  const buttonTextColorChangeHandler = (color: string) =>
    setProp(firstNode.id, (currentProps) => {
      currentProps.headerTextColor = color;
    });
  const hasButtonHandler = (value: boolean) =>
    setProp(firstNode.id, (currentProps) => {
      currentProps.headerHasButton = value;
    });
  const headerInvisibilityHandler = (value: boolean) =>
    setProp(firstNode.id, (currentProps) => {
      currentProps.headerIsInvisible = value;
    });
  const headerInvisibility = firstNode.data.props.headerIsInvisible;
  return (
    <SidebarAccordion
      expanded={expanded}
      icon={
        <ViewCompactIcon
          sx={{
            color: 'secondary.main',
            height: 24,
            width: 24,
          }}
        />
      }
      title="Topo"
      onExpand={onExpand}
    >
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography color="grey.300" fontSize={14}>
          Desativar cabeçalho:
        </Typography>
        <Switch
          checked={headerInvisibility}
          onChange={() => headerInvisibilityHandler(!headerInvisibility)}
          color="secondary"
          sx={{ ml: 5 }}
        />
      </Stack>
      <Typography color="grey.300" fontSize={14}>
        Escolha a organização do topo:
      </Typography>
      <SelectableSetting
        checked={!firstNode.data.props.headerHasButton}
        value={false}
        onSelect={() => hasButtonHandler(false)}
      >
        <Typography
          color={!firstNode.data.props.headerHasButton ? 'grey.100' : 'grey.400'}
          fontSize={10}
        >
          LOGO
        </Typography>
      </SelectableSetting>
      <SelectableSetting
        checked={firstNode.data.props.headerHasButton}
        value
        onSelect={() => hasButtonHandler(true)}
      >
        <Typography
          color={firstNode.data.props.headerHasButton ? 'grey.100' : 'grey.400'}
          fontSize={10}
        >
          LOGO
        </Typography>
        <Stack
          sx={{
            // TODO: Include color variation on theme
            backgroundColor: firstNode.data.props.headerHasButton ? '#20133A' : 'grey.600',
            border: firstNode.data.props.headerHasButton ? '2px solid' : 'none',
            borderColor: 'info.main',
            alignItems: 'center',
            borderRadius: 1,
            height: 22,
            justifyContent: 'center',
            width: 53,
            marginLeft: 'auto',
          }}
        >
          <Typography
            color={firstNode.data.props.headerHasButton ? 'grey.100' : 'grey.400'}
            fontSize={10}
          >
            BOTÃO
          </Typography>
        </Stack>
      </SelectableSetting>
      {firstNode.data.props.headerHasButton && (
        <>
          <TextSetting
            label="Texto do botão"
            value={firstNode.data.props.headerButtonText || ''}
            placeholder="Assine agora!"
            required
            onChange={buttonTextChangeHandler}
          />
          <TextSetting
            label="Link do botão"
            value={firstNode.data.props.headerButtonLink || ''}
            placeholder="https://music.youtube.com..."
            required
            onChange={buttonLinkChangeHandler}
          />
        </>
      )}
      <ColorPicker
        color={firstNode.data.props.headerBgColor || '#FFFFFF'}
        name="Cor de fundo do topo"
        onChange={bgColorChangeHandler}
      />
      {firstNode.data.props.headerHasButton && (
        <>
          <ColorPicker
            color={firstNode.data.props.headerTextColor || '#FFFFFF'}
            name="Cor do texto do botão"
            onChange={buttonTextColorChangeHandler}
          />
          <ColorPicker
            color={firstNode.data.props.headerButtonColor || '#FFFFFF'}
            name="Cor de fundo do botão"
            onChange={buttonBgColorChangeHandler}
          />
        </>
      )}
    </SidebarAccordion>
  );
};

export default Header;
