import { gql } from '@apollo/client';

const usersQuery = gql`
  query ListUsersInputQuery($input: ListUsersInput!) {
    users(input: $input) {
      items {
        status
        user {
          id
          name
          email
          cpf
          phone
          createdAt
          deletedAt
          updatedAt
          favoriteTeam {
            id
            name
            slug
            externalUrl
            avatars
          }
          signupAnswer {
            question {
              id
              title
            }
            tag {
              id
              name
            }
          }
        }
      }
      meta {
        totalItems
        itemCount
        totalPages
        currentPage
      }
    }
  }
`;

export default usersQuery;
