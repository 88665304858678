import { Grid, Typography } from '@material-ui/core';
import { useContext } from 'react';
import Context from '../Context';
import DiscountAudience from './DiscountAudience';
import DiscountRules from './DiscountRules';

const Container = () => {
  const { helperText } = useContext(Context);
  return (
    <Grid container sx={{ gap: 4 }}>
      <Grid item xs>
        <Typography
          sx={{ color: 'grey.100', fontSize: 20, fontWeight: 600, my: 6 }}
        >
          Regras do {helperText}
        </Typography>
        <DiscountRules />
      </Grid>
      <Grid item xs>
        <Typography
          sx={{ color: 'grey.100', fontSize: 20, fontWeight: 600, my: 6 }}
        >
          Público do {helperText}
        </Typography>
        <DiscountAudience />
      </Grid>
    </Grid>
  );
};

export default Container;
