import {
  Card,
  CardContent,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import theme from '../../../Theme';
import SearchBarProps from './typings';

const SearchBar = ({
  CardContentProps,
  children,
  title,
  TextFieldProps,
  sx,
}: SearchBarProps) => (
  <Card data-testid="search-bar" sx={sx}>
    <CardContent {...CardContentProps}>
      <Stack
        direction="row"
        spacing={5}
        sx={{ alignItems: 'center', flexDirection: 'row' }}
      >
        {!!title && (
          <Typography
            sx={{
              color: theme.palette.grey[100],
              mr: 3,
            }}
          >
            {title}
          </Typography>
        )}
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'secondary.main' }} />
              </InputAdornment>
            ),
          }}
          sx={{
            flex: 1,
            'input:-webkit-autofill': {
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'white !important',
            },
          }}
          {...TextFieldProps}
        />
        {children}
      </Stack>
    </CardContent>
  </Card>
);

export default SearchBar;
