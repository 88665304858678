import { useMutation, useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import { memo, useContext } from 'react';
import { useAlert } from 'react-alert';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { SortableContent } from '../../../interfaces/Section';
import reorder from '../../../utils/reorderList';
import Context from '../Context';
import getSectionQuery from '../gql/getSectionQuery';
import updateSectionMutation from '../gql/updateSectionMutation';
import { SortableLiveContents } from '../typings';
import LiveSectionItem from './LiveSectionItem';

interface LiveSectionListProps {
  sortableContents: SortableLiveContents[];
}

const MemoizedLiveSectionList = memo(
  ({ sortableContents }: LiveSectionListProps) => (
    <>
      {sortableContents.map(
        (sortableContent: SortableLiveContents, index: number) => (
          <LiveSectionItem
            key={sortableContent.content.id}
            sortableContent={sortableContent}
            index={index}
          />
        ),
      )}
    </>
  ),
);

const LiveSectionList = () => {
  const { sortableContents, setSortableContents } = useContext(Context);
  const alert = useAlert();
  const [updateSection] = useMutation(updateSectionMutation);
  const { loading, data } = useQuery(getSectionQuery, {
    variables: { id: sortableContents[0].subSection.section.id },
  });
  if (loading) return <div>Carregando...</div>;
  const getContentsPositions = sortableContents.map(
    (sortableContent) => sortableContent.position,
  );
  const getMinimunPosition = Math.min(...getContentsPositions);
  const getContentsIds = sortableContents.map(
    (sortableContent) => sortableContent.content.id,
  );
  const contents = data.section.subSections[0].sortableContents.filter(
    (sortableContent: SortableContent) =>
      !getContentsIds.includes(sortableContent.content.id),
  );
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const switchListPositions = reorder(
      sortableContents,
      result.source.index,
      result.destination.index,
    ) as SortableLiveContents[];
    try {
      setSortableContents(switchListPositions);
      const reorderContentPosition = switchListPositions.map(
        (switchListPosition: SortableLiveContents, index: number) => ({
          position: getMinimunPosition - 1 + index + 1,
          content: { id: switchListPosition.content.id },
        }),
      );
      const contentsSortedByPosition = contents.map((content: any) => ({
        position: content.position,
        content: { id: content.content.id },
      }));
      const newSortableContents = [
        ...contentsSortedByPosition,
        ...reorderContentPosition,
      ];
      await updateSection({
        variables: {
          input: {
            id: switchListPositions[0].subSection.section.id,
            generalSubSection: {
              sortableContents: newSortableContents,
            },
          },
        },
      });
    } catch (error) {
      alert.error('Ocorreu um erro ao atualizar o conteúdo.');
    }
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            sx={{ mt: 4 }}
            {...provided.droppableProps}
          >
            <MemoizedLiveSectionList sortableContents={sortableContents} />
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default LiveSectionList;
