import { ComponentType, useContext } from 'react';
import { useQuery } from '@apollo/client';
import {
  Card,
  Grid,
  MenuItem,
  Select,
  Stack,
  SvgIconProps,
  Typography,
} from '@material-ui/core';
import { SxProps, Theme } from '@material-ui/system';
import { useFormik } from 'formik';
import {
  convertJsonToCsv,
  createUriAndNameToFile,
  CreateUriAndNameToFileReturn,
} from '../../../utils/download/convertFiles';
import { Plan } from '../../../interfaces/Plan';
import { ChangeNameOfKeysObject } from '../../../utils/changeNameOfKeysObject';
import DownloadButton from '../../../components/DownloadButton';
import StackWithLabel from '../../Spectators/components/StackWithLabel';
import { DownloadReportsPlanJson, ReportsDownloadFilters } from '../typings';
import { Context } from '../Context';
import plansReportsAnalyse from '../gql/plansReportsAnalyse';

interface ReportDownloadCardProps {
  title: string;
  description?: string;
  icon?: ComponentType<SvgIconProps>;
  sx?: SxProps<Theme>;
}

const ReportDownloadCard = ({
  icon: Icon,
  title,
  description,
  sx,
}: ReportDownloadCardProps) => {
  const { plans, setInput } = useContext(Context);
  const formik = useFormik<ReportsDownloadFilters>({
    initialValues: {
      planId: plans[0].id,
      createdAtGte: null,
      createdAtLte: null,
      term: '',
    },
    onSubmit: async (filters) =>
      setInput((prevState) => ({
        ...prevState,
        filters,
        paginate: { page: 1 },
      })),
  });
  const { data: getPlansReportsAnalyse } = useQuery(plansReportsAnalyse, {
    variables: {
      input: {
        paginate: { page: 1, limit: 100 },
        filters: { planId: formik.values.planId },
      },
    },
  });
  const getPlansReportsAnalyseObject =
    getPlansReportsAnalyse?.getPlansReportsAnalyse;
  let objectToDownload = {};
  if (getPlansReportsAnalyseObject) {
    objectToDownload = ChangeNameOfKeysObject(
      getPlansReportsAnalyseObject,
      DownloadReportsPlanJson,
    );
  }
  const csv = objectToDownload ? convertJsonToCsv(objectToDownload) : null;
  let createUriAndNameToFileReturn: CreateUriAndNameToFileReturn = {
    name: '',
    uri: '',
  };
  if (csv) {
    const createUriAndNameToFileProps = {
      file: csv,
      fileName: 'relatorios',
      fileType: 'csv',
    };
    createUriAndNameToFileReturn = createUriAndNameToFile(
      createUriAndNameToFileProps,
    );
  }
  return (
    <Card sx={{ mt: 4, p: 6, width: 569, ...sx }}>
      <Stack alignItems="center" direction="row">
        {Icon && <Icon color="secondary" />}
        <Typography
          sx={{
            color: 'grey.200',
            fontSize: 20,
            fontWeight: 600,
            ml: Icon ? 3 : 0,
          }}
        >
          {title}
        </Typography>
      </Stack>
      {description && (
        <Typography sx={{ color: 'grey.300', fontSize: 14, mt: 2 }}>
          {description}
        </Typography>
      )}
      <Grid container>
        <Grid item>
          <StackWithLabel label="Planos">
            <Select
              name="planId"
              displayEmpty
              value={formik.values.planId}
              sx={{ width: 160, mt: 3 }}
              onChange={formik.handleChange}
            >
              {plans.map((plan: Plan) => (
                <MenuItem key={plan.id} value={plan.id}>
                  {plan.title}
                </MenuItem>
              ))}
            </Select>
          </StackWithLabel>
        </Grid>
        <Grid item>
          <DownloadButton
            buttonTitle="Baixar"
            uri={createUriAndNameToFileReturn.uri}
            fileName={createUriAndNameToFileReturn.name}
            sx={{
              background: '#00C572',
              color: '#FFFFFF',
              ml: 3,
              mt: 5,
              width: 96,
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default ReportDownloadCard;
