import { createContext } from 'react';
import { Me } from './interfaces/Me';

type ContextType = {
  me: Me;
};

const Context = createContext<ContextType>({} as ContextType);

export default Context;
