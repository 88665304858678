import { ApolloError, gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  TextField,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Add as AddIcon } from '@material-ui/icons';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { useAlert } from 'react-alert';
import * as yup from 'yup';
import ScheduleCard from '../../../components/ScheduleCard';
import TogglerCard from '../../../components/UI/molecules/TogglerCard';
import { Section } from '../../../interfaces/Section';
import Context from '../Context';

const createSectionMutation = gql`
  mutation CreateSectionMutation($input: CreateSectionInput!) {
    createSection(input: $input) {
      id
      title
      scheduleDate
      scheduleDateEnabled
      finishDate
      finishDateEnabled
      position
      published
      subSections {
        id
        title
        position
        sortableContents {
          position
          content {
            id
            title
            thumbnail
          }
        }
      }
    }
  }
`;

type SectionForm = Pick<
  Section,
  | 'title'
  | 'scheduleDate'
  | 'scheduleDateEnabled'
  | 'finishDate'
  | 'finishDateEnabled'
  | 'position'
  | 'published'
>;

interface CreateSectionCardProps {
  refetch: () => void;
}

const CreateSectionCard = ({ refetch }: CreateSectionCardProps) => {
  const alert = useAlert();
  const { setIsCreatingSection } = useContext(Context);
  const [createSection, { loading: isCreatingSection }] = useMutation<{
    createSection: Section;
  }>(createSectionMutation);
  const form = useFormik<SectionForm>({
    initialValues: {
      title: '',
      scheduleDate: null,
      scheduleDateEnabled: false,
      finishDate: null,
      finishDateEnabled: false,
      position: 1,
      published: false,
    },
    validationSchema: yup.object().shape({
      title: yup.string().required().max(160),
      scheduleDate: yup.mixed().when('scheduleDateEnabled', {
        is: true,
        then: yup.date().required('scheduleDateEnabled'),
      }),
      finishDate: yup.mixed().when('finishDateEnabled', {
        is: true,
        then: yup.date().required('finishDateEnabled'),
      }),
    }),
    onSubmit: async (input) => {
      try {
        await createSection({ variables: { input } });
        alert.success('Seção criada com sucesso!');
        refetch();
      } catch (err) {
        const apolloError = err as ApolloError;
        const duplicatedSection =
          apolloError.graphQLErrors?.[0].message === 'sectionAlreadyExists';
        if (duplicatedSection) {
          alert.error('Uma seção com este nome já existe');
        }
      } finally {
        setIsCreatingSection(false);
      }
    },
  });
  const clearCreateSectionHandler = () => {
    setIsCreatingSection(false);
    form.resetForm();
  };
  return (
    <Card>
      <CardContent sx={{ p: 4 }}>
        <form onSubmit={form.handleSubmit}>
          <Stack alignItems="center" direction="row" spacing={5}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                bgcolor: 'grey.600',
                borderRadius: 2,
                height: 40,
                width: 40,
              }}
            >
              <AddIcon sx={{ color: 'grey.200' }} />
            </Stack>
            <Stack spacing={3}>
              <TextField
                name="title"
                placeholder="Título da sua seção"
                error={!!form.errors.title}
                value={form.values.title}
                onChange={form.handleChange}
              />
            </Stack>
          </Stack>
          <Box sx={{ mt: 6, mx: 10 }}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <ScheduleCard
                  name="scheduleDate"
                  error={form.errors.scheduleDate}
                  label="Agendar entrada?"
                  disabled={!form.values.scheduleDateEnabled}
                  value={form.values.scheduleDate}
                  SwitchProps={{
                    name: 'scheduleDateEnabled',
                    checked: form.values.scheduleDateEnabled,
                    onChange: form.handleChange,
                  }}
                  onChange={form.handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <ScheduleCard
                  name="finishDate"
                  error={form.errors.finishDate}
                  label="Agendar saída?"
                  disabled={!form.values.finishDateEnabled}
                  value={form.values.finishDate}
                  SwitchProps={{
                    name: 'finishDateEnabled',
                    checked: form.values.finishDateEnabled,
                    onChange: form.handleChange,
                  }}
                  // @ts-ignore
                  DateTimePickerProps={{ minDate: form.values.scheduleDate }}
                  onChange={form.handleChange}
                />
              </Grid>
              <Grid item container direction="column" spacing={4} xs={4}>
                <Grid item xs>
                  <TogglerCard
                    label="Seção ativa?"
                    info="Se estiver ativa, sua seção estará visível para o público no momento da publicação ou data de agendamento."
                    SwitchProps={{
                      name: 'published',
                      checked: form.values.published,
                      onChange: form.handleChange,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <LoadingButton
              color="info"
              size="large"
              loading={isCreatingSection}
              sx={{ mt: 5 }}
              onClick={form.submitForm}
            >
              Criar nova seção
            </LoadingButton>
            <Button
              color="secondary"
              size="large"
              disabled={isCreatingSection}
              sx={{ ml: 4, mt: 5 }}
              onClick={clearCreateSectionHandler}
            >
              Cancelar
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default CreateSectionCard;
