export const buttonOptions = [
  'Desconto de retenção',
  'Página já existente',
  'Link externo',
];

export const smallInputsStyles = {
  border: 1,
  borderColor: 'grey.400',
  borderRadius: 1,
  color: 'grey.300',
  fontSize: 14,
  height: 37,
  overflow: 'initial',
  padding: 4,
};

export const selectStyles = {
  backgroundColor: 'grey.500',
  border: 1,
  borderColor: 'grey.400',
  color: 'grey.300',
  width: 222,
  '.MuiSelect-icon': {
    color: 'secondary.main',
  },
};
