import {
  Box,
  IconPropsColorOverrides as IIconPropsColorOverrides,
  InputBaseComponentProps,
  OutlinedInput,
  Typography,
  TypographyProps as ITypographyProps,
} from '@material-ui/core';
import { BorderColor as BorderColorIcon } from '@material-ui/icons';
import { ChangeEvent, KeyboardEvent, memo, useEffect, useState } from 'react';

interface InlineTextEditorProps {
  hideIcon?: boolean;
  isEditing?: boolean;
  value: string;
  TypographyProps?: ITypographyProps;
  IconProps?: IIconPropsColorOverrides;
  sx?: any;
  inputProps?: InputBaseComponentProps;
  onCancel?: () => void;
  onChange: (text: string) => void;
}

const InlineTextEditor = memo(
  ({
    hideIcon,
    isEditing: isEditingFromProp,
    value,
    TypographyProps,
    IconProps,
    inputProps,
    sx,
    onCancel,
    onChange,
  }: InlineTextEditorProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState(value);
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value;
      setText(input);
    };
    const onClickOut = () => {
      const unchangedName = text === value;
      setIsEditing(false);
      if (unchangedName) return;
      onChange(text);
    };
    const editHandler = () => {
      onCancel?.();
      setIsEditing(!isEditing);
      setText(value);
    };
    const editActionHandler = (evt: KeyboardEvent) => {
      const escWasPressed = evt.key === 'Escape';
      if (escWasPressed) {
        setIsEditing(false);
        setText(value);
        onCancel?.();
        return;
      }
      const enterWasPressed = evt.key === 'Enter';
      if (enterWasPressed) {
        onChange(text);
        setIsEditing(false);
      }
    };
    useEffect(() => {
      setText(value);
    }, [value]);
    useEffect(() => {
      const isEditingFromPropIsUndefined =
        typeof isEditingFromProp === 'undefined';
      if (isEditingFromPropIsUndefined) {
        return;
      }
      setIsEditing(isEditingFromProp as boolean);
    }, [isEditingFromProp]);
    return (
      <Box sx={{ ...sx }}>
        {isEditing ? (
          <OutlinedInput
            data-testid="inline-text-editor"
            autoFocus
            inputProps={{ maxLength: 160, ...inputProps }}
            defaultValue={value}
            value={text}
            onBlur={onClickOut}
            onChange={changeHandler}
            onKeyDown={editActionHandler}
          />
        ) : (
          <Typography {...TypographyProps}>
            {value}
            {!hideIcon && (
              <BorderColorIcon
                data-testid="BorderColorIcon"
                sx={{
                  color: 'info.main',
                  cursor: 'pointer',
                  fontSize: 'inherit',
                  ml: 1,
                }}
                {...IconProps}
                onClick={editHandler}
              />
            )}
          </Typography>
        )}
      </Box>
    );
  },
);

export default InlineTextEditor;
