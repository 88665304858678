import { VideoLibrary as VideoLibraryIcon } from '@material-ui/icons';
import CustomDragLayer from './CustomDragLayer';

const VideoBlockDragLayer = () => (
  <CustomDragLayer type="VideoBlock">
    <VideoLibraryIcon sx={{ color: 'info.main', fontSize: 56 }} />
  </CustomDragLayer>
);

export default VideoBlockDragLayer;
