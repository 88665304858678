import { useEditor } from '@craftjs/core';
import { Stack, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

interface CustomContentBlockProps {
  children: React.ReactNode;
  createdComponent: JSX.Element;
  label: string;
  type: string;
}

const CustomContentBlock = ({
  children,
  createdComponent,
  label,
  type,
}: CustomContentBlockProps) => {
  const { connectors } = useEditor();
  // TODO: add typing
  const [{ isDragging }, drag, preview] = useDrag<
    any,
    unknown,
    { isDragging: boolean }
  >(() => ({
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    type,
  }));
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  return (
    <Stack ref={(ref: JSX.Element) => connectors.create(ref, createdComponent)}>
      <Stack
        ref={drag}
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: '#20133A', // TODO: export from theme
          border: '2px solid',
          borderColor: 'info.main',
          borderRadius: 1,
          cursor: 'grab',
          height: 48,
          opacity: isDragging ? 0.3 : 1,
          width: 99,
        }}
      >
        {children}
      </Stack>
      <Typography color="grey.300" fontSize={12} align="center">
        {label}
      </Typography>
    </Stack>
  );
};

export default CustomContentBlock;
