import {
  ClickAwayListener,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  AccountCircle as AccountCircleIcon,
  ExitToAppOutlined as ExitIcon,
} from '@material-ui/icons';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MenuItems from './MenuItems';
import spallaIcon from '../../assets/svg/spalla.svg';
import Context from '../../Context';

const SideMenu = () => {
  const navigate = useNavigate();
  const { me } = useContext(Context);
  const [open, setOpen] = useState(false);
  const toggleOpenMenuHandler = () => {
    setOpen(true);
  };
  const closeMenuHandler = () => {
    setOpen(false);
  };
  const logoutHandler = () => {
    localStorage.removeItem('accessToken');
    navigate('/auth/login');
  };
  return (
    <ClickAwayListener onClickAway={closeMenuHandler}>
      <Drawer
        variant="permanent"
        open={open}
        onClick={toggleOpenMenuHandler}
        PaperProps={{
          square: true,
          sx: {
            borderRadius: '0',
            overflow: 'hidden',
            transition: '0.2s',
            width: open ? 290 : 70,
          },
        }}
      >
        <Grid
          data-testid="drawer-menu"
          container
          direction="column"
          sx={{ height: '100%' }}
        >
          <Grid
            item
            data-testid="side-menu"
            sx={{ pl: 4, pt: 5, cursor: 'pointer' }}
          >
            <img src={spallaIcon} alt="spalla-logo" />
          </Grid>
          <Grid item xs sx={{ mt: 6, overflow: 'hidden' }}>
            <PerfectScrollbar>
              <MenuItems />
            </PerfectScrollbar>
          </Grid>
          <Grid item sx={{ pt: 5 }}>
            <List sx={{ bgcolor: 'primary.main', pl: 2 }}>
              <ListItem button>
                <ListItemIcon>
                  <AccountCircleIcon sx={{ color: 'common.white' }} />
                </ListItemIcon>
                <ListItemText primary={me.name} sx={{ color: 'grey.100' }} />
              </ListItem>
              <ListItem button onClick={logoutHandler}>
                <ListItemIcon>
                  <ExitIcon sx={{ color: 'grey.300' }} />
                </ListItemIcon>
                <ListItemText primary="Sair" sx={{ color: 'grey.100' }} />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Drawer>
    </ClickAwayListener>
  );
};

export default SideMenu;
