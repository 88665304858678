import { createContext } from 'react';
import { Manager } from '../../interfaces/Manager';
import { Permission } from '../../interfaces/Permission';

type ContextType = {
  managers: Manager[];
  selectedManager: Manager[];
  changeEnabledManagerHandler: (
    enabled: boolean,
    managerId: Manager['id'],
  ) => void;
  checkboxSelectedManager: (manager: Manager) => void;
  editManagerHandler: (manager: Manager) => void;
  deleteManagerHandler: (manager: Manager[]) => void;
  permissionToDelete: Permission['delete']
  permissionToRead: Permission['read'];
  permissionToUpdate: Permission['update'];
};

const Context = createContext<ContextType>({} as ContextType);

export default Context;
