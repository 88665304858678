import { gql } from '@apollo/client';

const plansQuery = gql`
  query PlansQuery($input: ListPlansInput!) {
    plans(input: $input) {
      items {
        id
        title
        published
        price
        trialDays
      }
      meta {
        totalItems
        itemCount
        totalPages
        currentPage
      }
    }
  }
`;

export default plansQuery;
