import Topbar from '../../components/Topbar';
import DiscountList from './Components/DiscountList';
import FiltersBar from './Components/FiltersBar';

const DiscountReports = () => (
  <>
    <Topbar
      title="Relatórios de desconto"
      description="Tenha acesso a todas as informações referentes aos descontos/cupons criados."
    />
    <FiltersBar sx={{ my: 4 }}>
      <DiscountList />
    </FiltersBar>
  </>
);

export default DiscountReports;
