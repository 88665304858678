import { Stack, Typography } from '@material-ui/core';
import { FinancialPlanReport } from '../../../interfaces/FinancialReport';
import Card from '../../FinancialDiscountReport/Components/Card';
import { ValueGroup } from '../../FinancialDiscountReport/Components/helpers';

interface DiscountAndMembershipsProps {
  financialDiscountReport: FinancialPlanReport;
}

const DiscountAndMemberships = ({
  financialDiscountReport,
}: DiscountAndMembershipsProps) => (
  <>
    <Card
      title="Descontos ofertados"
      description="Quantidade de espectadores que receberam descontos"
      sx={{ height: 169 }}
    >
      <Stack alignItems="center" direction="row" gap="30px" sx={{ mt: 4 }}>
        <ValueGroup
          title="Desc. Interno"
          value={financialDiscountReport.totalInternalDiscounts}
        />
        <ValueGroup
          title="Desc. Externo:"
          value={financialDiscountReport.totalCollectiveDiscounts}
        />
        <ValueGroup
          title="Desc. Retenção"
          value={financialDiscountReport.totalRetentionDiscounts}
        />
        <ValueGroup
          title="Cupom"
          value={financialDiscountReport.totalIndividualDiscounts}
        />
      </Stack>
    </Card>
    <Card
      title="Adesões do plano"
      description="Total de planos contratados e cancelados."
      sx={{ height: 261, mt: 4 }}
    >
      <Stack alignItems="center" direction="row" gap="30px" sx={{ mt: 4 }}>
        <ValueGroup
          title="Contratados"
          value={financialDiscountReport.totalHired}
        />
        <ValueGroup
          title="Cancelados"
          value={financialDiscountReport.totalCancelled}
        />
      </Stack>
      <ValueGroup
        title="Contratação X Cancelamento"
        value={`${financialDiscountReport.avgReactivated}% reativados`}
        sx={{ mt: 4 }}
      >
        <Typography sx={{ color: 'grey.300', fontSize: 14, mt: 1 }}>
          {financialDiscountReport.totalHired} contratados /{' '}
          {financialDiscountReport.totalCancelled} cancelados
        </Typography>
      </ValueGroup>
    </Card>
  </>
);

export default DiscountAndMemberships;
