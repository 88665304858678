import { gql } from '@apollo/client';

const listQuestionsAndTagsQuery = gql`
  query ListQuestionsAndTagsQuery {
    questions {
      id
      title
      description
      published
      answersEnabled
      required
      position
      tags{
        id
        name
      }
    }
    tags {
      id
      name
      slug
    }
  }
`;

export default listQuestionsAndTagsQuery;
