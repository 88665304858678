import { Stack } from '@material-ui/core';
import { Star as StarIcon } from '@material-ui/icons';
import { useContext } from 'react';
import { FinancialPlanReport } from '../../../interfaces/FinancialReport';
import { PlanType } from '../../../interfaces/Plan';
import Card from '../../FinancialDiscountReport/Components/Card';
import { ValueGroup } from '../../FinancialDiscountReport/Components/helpers';
import formatNumber from '../../FinancialDiscountReport/utils/formatNumber';
import Context from '../Context';

interface PlansAndSpectatorsProps {
  financialDiscountReport: FinancialPlanReport;
}

const PlansAndSpectators = ({
  financialDiscountReport,
}: PlansAndSpectatorsProps) => {
  const { planType } = useContext(Context);
  const planTypeIsNotFree = planType !== PlanType.free;
  const planTypeIsNotPartner = planType !== PlanType.partner;
  const planTypeIsFreeOrPartner =
    planType === PlanType.free || planType === PlanType.partner;
  const avgContractTimeLabel =
    financialDiscountReport.spectatorsAvgDuration === 1 ? 'mês' : 'meses';
  return (
    <>
      <Card
        icon={StarIcon}
        title={
          planTypeIsFreeOrPartner ? 'Planos Adquiridos' : 'Planos Comprados'
        }
        description="Quantidade de planos por meio de pagamento"
        sx={{
          height: !planTypeIsNotPartner ? 'auto' : 169,
        }}
      >
        {!planTypeIsNotFree && (
          <Stack alignItems="center" direction="row" gap="32px" sx={{ mt: 4 }}>
            <ValueGroup
              title="Plano Ativo"
              value={financialDiscountReport.totalActivePlansGratis}
            />
            <ValueGroup
              title="Tempo médio de contrato"
              value={financialDiscountReport.spectatorsAvgDuration}
            />
          </Stack>
        )}
        {!planTypeIsNotPartner && (
          <>
            <Stack
              alignItems="center"
              direction="row"
              sx={{ flexWrap: 'wrap', mt: 4 }}
            >
              {financialDiscountReport.totalPlansByPartner.map((partner) => (
                <ValueGroup
                  title={partner.code}
                  value={partner.count}
                  sx={{ mb: 2, mr: 6 }}
                />
              ))}
            </Stack>
            <ValueGroup
              title="Total Geral"
              value={financialDiscountReport.totalActivePlansGratis}
            />
          </>
        )}
        {!planTypeIsFreeOrPartner && (
          <Stack alignItems="center" direction="row" gap="32px" sx={{ mt: 4 }}>
            <ValueGroup
              title="Cartão de Crédito"
              value={financialDiscountReport.totalActivesByCredit}
            />
            <ValueGroup
              title="Cartão de Débito"
              value={financialDiscountReport.totalActivesByDebit}
            />
            <ValueGroup
              title="Pix"
              value={financialDiscountReport.totalActivesByPix}
            />
          </Stack>
        )}
      </Card>
      {planTypeIsNotFree && (
        <>
          <Card title="Espectadores" sx={{ height: 140, mt: 4 }}>
            <Stack
              alignItems="center"
              direction="row"
              gap="70px"
              sx={{ mt: 4 }}
            >
              <ValueGroup
                title="Com planos ativos"
                value={financialDiscountReport.totalActiveSpectators}
              />
              <ValueGroup
                title="Tempo médio de contrato"
                value={`${formatNumber(
                  financialDiscountReport.spectatorsAvgDuration,
                )} ${avgContractTimeLabel}`}
              />
            </Stack>
          </Card>
          {planTypeIsNotPartner && (
            <Card title="Custo do plano" sx={{ height: 140, mt: 4 }}>
              <ValueGroup
                title="Valor médio pago"
                value={`R$ ${formatNumber(financialDiscountReport.priceAvg)}`}
                sx={{ mt: 4 }}
              />
            </Card>
          )}
        </>
      )}
    </>
  );
};

export default PlansAndSpectators;
