import { Theme } from '@material-ui/core';
import { Components } from '@material-ui/core/styles/components';

const makeMuiPaper = (theme: Theme): Components['MuiPaper'] => ({
  styleOverrides: {
    root: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius * 2,
      '& .MuiClockPicker-arrowSwitcher': {
        '& button': {
          color: 'white'
        },
        '& + div span': {
          color: 'white'
        }
      },
      '.PrivatePickersFadeTransitionGroup-root span': {
        color: 'white'
      },
    },
  },
});

export default makeMuiPaper;
