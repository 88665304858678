import { Card, CardContent, Stack, Typography } from '@material-ui/core';
import { TileCardProps } from '../TileCard/typings';

const TileCard2 = ({ icon: Icon, title, value, sx }: TileCardProps) => (
  <Card sx={sx}>
    <CardContent>
      <Stack>
        <Typography sx={{ color: 'grey.200', fontSize: 14, fontWeight: 600 }}>
          {title}
        </Typography>
        <Stack flexDirection="row" sx={{ mt: 2 }}>
          <Icon
            sx={{ color: 'secondary.dark', height: 30, pr: 2, width: 30 }}
          />
          <Typography sx={{ color: 'grey.100', fontSize: 20, fontWeight: 400 }}>
            {value}
          </Typography>
        </Stack>
      </Stack>
    </CardContent>
  </Card>
);

export default TileCard2;
