import { createContext } from 'react';

type ContentType = {
    form: any;
    isEditing?: boolean;
}

const Context = createContext({} as ContentType);

export default Context;
