import {
  Button,
  ButtonProps,
  Card,
  CardContent,
  Stack,
  Theme,
  Typography,
} from '@material-ui/core';
import { LoadingButton, LoadingButtonProps } from '@material-ui/lab';
import { SxProps } from '@material-ui/system';

interface FormFooterProps {
  LeftButtonProps: LoadingButtonProps;
  helperText: string;
  RightButtonProps: ButtonProps;
  // eslint-disable-next-line react/require-default-props
  helperTextProps?: SxProps<Theme>;
  // eslint-disable-next-line react/require-default-props
  sx?: SxProps<Theme>;
}

const FormFooter = ({
  helperText,
  helperTextProps,
  LeftButtonProps,
  RightButtonProps,
  sx,
}: FormFooterProps) => (
  <Card sx={{ ...sx }}>
    <CardContent>
      <Stack direction="row" alignItems="center" sx={{ height: '100%' }}>
        <LoadingButton {...LeftButtonProps} />
        <Typography
          sx={{ color: 'grey.300', fontSize: 14, ml: 4, ...helperTextProps }}
        >
          {helperText}
        </Typography>
        <Button
          variant="text"
          sx={{ color: 'error.main', fontSize: 14, ml: 'auto' }}
          {...RightButtonProps}
        />
      </Stack>
    </CardContent>
  </Card>
);

export default FormFooter;
