import { Permission, PermissionType } from '../../../interfaces/Permission';

const PermissionTypeFriendly = {
  [PermissionType.categoriesAndTags]: 'Categorias e tags',
  [PermissionType.contents]: 'Conteúdos',
  [PermissionType.coupons]: 'Cupons',
  [PermissionType.managers]: 'Gestores',
  [PermissionType.managersLog]: 'Logs de Gestores',
  [PermissionType.pages]: 'Minhas páginas',
  [PermissionType.plans]: 'Central de Planos',
  [PermissionType.sections]: 'Seções',
  [PermissionType.spectators]: 'Espectadores',
  [PermissionType.teams]: 'Times',
};

export const getPermissionFriendlyName = (permissionName: PermissionType) =>
  PermissionTypeFriendly[permissionName];

const permissionTip = {
  [PermissionType.categoriesAndTags]:
    'Se refere à todas as funcionalidades de categorias e tags.',
  [PermissionType.contents]:
    'Se refere à todas as funcionalidades da biblioteca de conteúdos.',
  [PermissionType.coupons]: 'Se refere à todas as funcionalidades de cupons.',
  [PermissionType.managers]: 'Se refere à gestores e cargos.',
  [PermissionType.managersLog]:
    'É a visualização e download dos registros de gestores.',
  [PermissionType.pages]:
    'São as páginas html, páginas de login e cadastro, página principal do site e aparência do OTT.',
  [PermissionType.plans]:
    'São os planos, onboarding do espectador e afiliados e marcas.',
  [PermissionType.sections]: 'São as seções e subseções criadas na plataforma.',
  [PermissionType.spectators]:
    'Se refere à todas as funcionalidades da gestão de espectadores.',
  [PermissionType.teams]: 'Se refere à todas as funcionalidades de times.',
};

export const getPermissionTipByTitle = (permissionName: PermissionType) =>
  permissionTip[permissionName];

export const getPermissionsPhrase = (permissions: Permission): string => {
  const feature = getPermissionFriendlyName(permissions.feature);
  const hasFullPermissions =
    permissions.create &&
    permissions.delete &&
    permissions.update &&
    permissions.read;
  if (hasFullPermissions) {
    return `Acesso total à ${feature}`;
  }
  let response = '';
  if (permissions.create) {
    response += 'criar ';
  }
  if (permissions.delete) {
    response += 'deletar ';
  }
  if (permissions.update) {
    response += 'atualizar ';
  }
  if (permissions.read) {
    response += 'visualizar ';
  }
  const addCommaToActions = response.replaceAll(' ', ', ') + feature;
  const removeCommaToLastAction = addCommaToActions.replace(
    `, ${feature}`,
    ` ${feature}`,
  );
  return response ? removeCommaToLastAction : '';
};
