import { TextFields as TextFieldsIcon } from '@material-ui/icons';
import CustomDragLayer from './CustomDragLayer';

const TextBlockDragLayer = () => (
  <CustomDragLayer type="TextBlock">
    <TextFieldsIcon sx={{ color: 'info.main', fontSize: 56 }} />
  </CustomDragLayer>
);

export default TextBlockDragLayer;
