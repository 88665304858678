import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    const scrolling = document.querySelector('#container');
    if (scrolling) {
      scrolling.scrollTop = 0;
    }
  }, [pathname]);
  return null;
};

export default ScrollToTop;
