import {
  Button,
  Card,
  CardActions,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  useTheme,
} from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMemo, useState } from 'react';
import { formatQuantityResult } from '../../../../utils/string';
import { BulkActionControllerProps } from './typings';

const BulkActionController = ({
  ButtonProps,
  iconItem: IconItem,
  ListItemIconProps,
  listItem,
}: BulkActionControllerProps) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const sizeCointentsSelecteds = useMemo(() => {
    const quantityContents = formatQuantityResult(
      listItem.length,
      'selecionado',
    );
    return quantityContents;
  }, [listItem]);
  const toggleExpandHandler = () => setExpanded(!expanded);
  return (
    <Card
      sx={{
        borderRadius: '0',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        bottom: 0,
        bgcolor: 'grey.100',
        position: 'fixed',
        right: 16,
        width: 345,
        zIndex: theme.zIndex.modal,
      }}
    >
      <CardActions disableSpacing>
        <IconButton
          sx={{
            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          onClick={toggleExpandHandler}
        >
          <ExpandMoreIcon />
        </IconButton>
        <Typography sx={{ color: 'grey.400', fontSize: 14, fontWeight: 600 }}>
          {sizeCointentsSelecteds}
        </Typography>
        <Button
          disabled={listItem?.length === 0}
          sx={{ fontSize: 14, marginLeft: 'auto' }}
          variant="contained"
          {...ButtonProps}
        />
      </CardActions>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={{ maxHeight: 242, overflowY: 'auto' }}
      >
        <List component="nav">
          {listItem?.map((content: any) => (
            <ListItem key={content.id}>
              <ListItemIcon sx={{ minWidth: 30 }}>
                <IconItem sx={{ fontSize: 20 }} {...ListItemIconProps} />
              </ListItemIcon>
              <Typography noWrap key={content.title} sx={{ fontSize: 12 }}>
                {content.title || content.name}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Card>
  );
};

export default BulkActionController;
