import ReactQuill from 'react-quill';
import { useEffect, useState } from 'react';
import { formats, modules } from './setups';
import './styles.css';

interface RichTextEditorProps {
  value: string;
  onChange: (value: string) => void;
}

const RichTextEditor = ({
  value,
  onChange,
}: RichTextEditorProps) => {
  const [convertedText, setConvertedText] = useState(value);
  useEffect(() => {
    onChange(convertedText);
  }, [convertedText]);
  return (
    <ReactQuill
      formats={formats}
      modules={modules}
      theme="snow"
      value={convertedText}
      onChange={setConvertedText}
    />
  );
};

export default RichTextEditor;
