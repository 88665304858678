import { SmartButton as SmartButtonIcon } from '@material-ui/icons';
import CustomDragLayer from '../../HTMLPages/Builder/components/Sidebar/settings/widgets/CustomDragLayer';

const QuestionDragLayer = () => (
  <CustomDragLayer type="Question" height={382} width={300} sourceOffset>
    <SmartButtonIcon sx={{ color: 'info.main', fontSize: 56 }} />
  </CustomDragLayer>
);

export default QuestionDragLayer;
