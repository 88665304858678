import { useNode } from '@craftjs/core';
import { Stack } from '@material-ui/core';
import { Image as ImageIcon } from '@material-ui/icons';
import { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import getAssetUri from '../../../../../../utils/getAssetUri';
import { ImagesSettings } from '../../Sidebar/settings/widgets';
import DeleteBlockButton, { DeletableBlocks } from './DeleteBlockButton';

type ObjectFitTypes = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';

interface DynamicImageProps {
  alt: string;
  objectFit: ObjectFitTypes;
  objectPosition: string;
  url: string;
}

const DynamicImage = ({
  alt,
  objectFit,
  objectPosition,
  url,
}: DynamicImageProps) => {
  const {
    id,
    connectors: { connect, drag: craftDrag },
    isClicked,
  } = useNode((node) => ({
    isClicked: node.events.selected,
  }));
  const [{ isDragging }, drag, preview] = useDrag<
    any,
    unknown,
    { isDragging: boolean }
  >(() => ({
    type: 'ImageBlock',
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  }));
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  return (
    <Stack
      ref={(ref: HTMLElement) => connect(craftDrag(ref))}
      maxHeight={452}
      width="100%"
      sx={{
        border: isClicked ? 4 : 'none',
        borderColor: isClicked ? 'info.main' : 'transparent',
        opacity: isDragging ? 0.3 : 1,
        position: 'relative',
      }}
    >
      {!url ? (
        <Stack
          sx={{
            alignItems: 'center',
            bgcolor: '#20133A', // TODO: export from theme
            border: '2px dashed',
            borderColor: 'info.main',
            borderRadius: 1,
            color: 'info.main', 
            height: 208,
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <ImageIcon sx={{ height: 35, width: 42 }} />
        </Stack>
      ) : (
        <img
          alt={alt}
          ref={drag}
          src={getAssetUri(url)}
          style={{ height: '100%', objectFit, objectPosition, width: '100%' }}
        />
      )}
      {isClicked ? (
        <DeleteBlockButton id={id} name={DeletableBlocks.image} />
      ) : null}
    </Stack>
  );
};

DynamicImage.craft = { related: { settings: ImagesSettings } };

export default DynamicImage;
