import { Grid, Typography, Stack } from '@material-ui/core';
import { SortByType } from '../typings';
import SpectatorsHeaderHead from './SpectatorsHeaderHead';

interface SpectatorsHeaderProps {
  sortByHandler?: (sortByType: SortByType) => void;
}

const SpectatorsHeader = ({ sortByHandler }: SpectatorsHeaderProps) => (
  <Grid
    container
    gap={4}
    sx={{
      borderColor: 'grey.500',
      borderStyle: 'solid',
      borderWidth: 0,
      borderBottomWidth: 1,
      mb: 4,
      pb: 2,
    }}
  >
    <Grid item xs={3}>
      <SpectatorsHeaderHead
        title="Nome do espectador"
        prop="name"
        onSortHead={sortByHandler}
      />
    </Grid>
    <Grid item xs={2}>
      <SpectatorsHeaderHead
        title="Desconto aplicado em"
        prop="createdAt"
        onSortHead={sortByHandler}
      />
    </Grid>
    <Grid item xs={2}>
      <SpectatorsHeaderHead
        title="Recorrências restantes"
        prop="remainingRecurrences"
        onSortHead={sortByHandler}
      />
    </Grid>
    <Grid item xs={1}>
      <Typography
        sx={{
          color: 'white',
          fontSize: 14,
          fontWeight: 600,
          whiteSpace: 'nowrap',
        }}
      >
        Resposta
      </Typography>
    </Grid>
    <Grid item xs={2}>
      <Stack alignItems="center" direction="row" sx={{ width: 200 }}>
        <Typography
          sx={{
            color: 'white',
            fontSize: 14,
            fontWeight: 600,
            whiteSpace: 'nowrap',
          }}
        >
          E-mail
        </Typography>
      </Stack>
    </Grid>
    <Grid item xs />
  </Grid>
);

export default SpectatorsHeader;
