import { useQuery } from '@apollo/client';
import { InputAdornment, List, OutlinedInput } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { ChangeEvent, useState } from 'react';
import { useDialog } from '../../../components/Dialog';
import { Section } from '../../../interfaces/Section';
import { compareTerms } from '../../../utils/string';
import sectionsQuery from '../gql/sectionsQuery';
import SectionsDialogItem from './SectionsDialogItem';

interface SectionsDialogProps {
  selectedSection: Section;
  onSelect: (section: Section) => void;
}

const SectionsDialog = ({ selectedSection, onSelect }: SectionsDialogProps) => {
  const dialog = useDialog();
  const { loading, data } = useQuery(sectionsQuery);
  const [query, setQuery] = useState('');
  const queryHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setQuery(evt.target.value);
  if (loading) return <div>Carregando...</div>;
  const filteredSections = query
    ? data.sectionsOnly.filter((section: Section) =>
        compareTerms(section.title, query),
      )
    : data.sectionsOnly;
  const selectSectionHandler = (section: Section) => {
    onSelect(section);
    dialog.close();
  };
  const isSelected = (section: Section) => selectedSection?.id === section.id;
  return (
    <>
      <OutlinedInput
        fullWidth
        inputProps={{ maxLength: 160 }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon sx={{ color: 'info.main' }} />
          </InputAdornment>
        }
        placeholder="Busque por uma seção"
        value={query}
        sx={{ my: 4 }}
        onChange={queryHandler}
      />
      <List>
        {filteredSections.map((section: Section) => (
          <SectionsDialogItem
            section={section}
            isSelected={isSelected(section)}
            onSelect={selectSectionHandler}
          />
        ))}
      </List>
    </>
  );
};

export default SectionsDialog;
