import { DiscountType } from '../../../interfaces/Discount';
import createCollectiveDiscountMutation from '../gql/CreateCollectiveDiscountMutation';
import createIndividualDiscountMutation from '../gql/CreateIndividualDiscountMutation';
import createInternalDiscountMutation from '../gql/CreateInternalDiscountMutation';
import createRetentionDiscountMutation from '../gql/CreateRetentionDiscountMutation';
import collectiveDiscount from '../Validations/Collective';
import individualDiscount from '../Validations/Individual';
import internalDiscount from '../Validations/Internal';
import retentionDiscount from '../Validations/Retention';

export const getPropsByDiscountType = (type: DiscountType) => {
  switch (type) {
    case DiscountType.Internal:
      return {
        helperText: 'desconto interno',
        mutation: createInternalDiscountMutation,
        validationSchema: internalDiscount,
      };
    case DiscountType.Collective:
      return {
        helperText: 'desconto externo',
        mutation: createCollectiveDiscountMutation,
        validationSchema: collectiveDiscount,
      };
    case DiscountType.Retention:
      return {
        helperText: 'desconto retenção',
        mutation: createRetentionDiscountMutation,
        validationSchema: retentionDiscount,
      };
    default:
      return {
        helperText: 'cupom individual',
        mutation: createIndividualDiscountMutation,
        validationSchema: individualDiscount,
      };
  }
};
