import { gql } from '@apollo/client';

const planQuery = gql`
  query PlanQuery($id: ID!) {
    plan(id: $id) {
      id
      title
      description
      published
      price
      type
      membershipsCount
      unlimitedMemberships
      trialDays
      trialDaysIsActive
      installments
      nextSeasonDate
      nextSeasonDateIsActive
      createdAt
      updatedAt
      deletedAt
      paymentType
      multiSection {
        id
        title
      }
      sortableHighlightedContents {
        position
        highlightedContent {
          id
          title
          description
          fullHdPortrait
          position
          fullHdLandscape
          content {
            id
            thumbnail
          }
        }
      }
      partners {
        code
      }
      sections {
        id
        title
      }
    }
  }
`;

export default planQuery;
