import { Permission } from './Permission';

export enum ColorType {
  blue = 'blue',
  white = 'white',
  purple = 'purple',
  green = 'green',
  red = 'red',
  yellow = 'yellow',
  gray = 'gray',
}

export interface Role {
  id: string;
  title: string;
  color: ColorType;
  enabled: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  permissions: Permission[];
}
