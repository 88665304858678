import { useMutation, useQuery } from '@apollo/client';
import { List } from '@material-ui/core';
import { useAlert } from 'react-alert';
import { Tag } from '../../../../interfaces/Tag';
import { compareTerms } from '../../../../utils/string';
import { useDialog } from '../../../../components/Dialog';
import TagListItemDragLayer from '../TagListItemDragLayer';
import TagListItem from '../TagListItem';
import CardGroup from '../CardGroup';
import CreateTagForm from '../CreateTagForm';
import createTagMutation from './gql/createTagMutation';
import deleteTagMutation from './gql/deleteTagMutation';
import listTagsQuery from './gql/listTagsQuery';

const TagsContainer = () => {
  const alert = useAlert();
  const dialog = useDialog();
  const { loading, data, refetch } = useQuery(listTagsQuery);
  const [createTag] = useMutation(createTagMutation);
  const [deleteTag] = useMutation(deleteTagMutation);
  const createTagHandler = async () => {
    const isTagCreated = await dialog.open({
      title: 'Criar nova tag',
      element: <CreateTagForm />,
    });
    if (isTagCreated) {
      await refetch();
    }
  };
  const deleteTagHandler = async ({ id, name }: Tag) => {
    const shouldDeleteTag = await dialog.confirm({
      title: 'Você tem certeza?',
      message: `Se excluir a tag '${name}', ela será desvinculada de todas as categorias inclusas.`,
      confirmMessage: 'Excluir tag',
    });
    if (shouldDeleteTag) {
      await deleteTag({ variables: { id } });
      alert.success('Tag apagada com sucesso!');
      await refetch();
    }
  };
  const editTagHandler = async () => {
    alert.success('A tag foi editada com sucesso!');
    await refetch();
  };
  const createFromSuggestHandler = async (name: Tag['name']) => {
    await createTag({ variables: { input: { name, slug: name } } });
    alert.success('Tag criada com sucesso!');
    await refetch();
  };
  if (loading) {
    return <div>loading...</div>;
  }
  return (
    <>
      <TagListItemDragLayer />
      <CardGroup
        buttonLabel="Criar tag"
        inputProps={{ placeholder: 'Pesquisar tag' }}
        items={data.tags}
        ItemsWrapper={List}
        name="Tags"
        emptyHelperText="Organize tags dentro de categorias, algum texto explicando a função delas aqui nessa página."
        filterItems={(query, item) => compareTerms(item.name, query)}
        renderItems={(tag: Tag) => (
          <TagListItem
            key={tag.id}
            tag={tag}
            onDelete={deleteTagHandler}
            onEdit={editTagHandler}
          />
        )}
        onCreate={createTagHandler}
        onCreateFromSuggest={createFromSuggestHandler}
      />
    </>
  );
};

export default TagsContainer;
