import { gql } from '@apollo/client';

const managersQuery = gql`
  query ManagersQuery {
    managers {
      id
      name
      email
      enabled
      thumbnail
      createdAt
      updatedAt
      role {
        id
        title
        color
        enabled
      }
    }
  }
`;

export default managersQuery;
