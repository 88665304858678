import * as yup from 'yup';
import { PlanType } from '../../../interfaces/Plan';

const validationSchema = yup
  .object()
  .noUnknown()
  .shape({
    id: yup.string(),
    title: yup.string().required(),
    description: yup.string().required('Este campo é obrigatório.'),
    published: yup.boolean(),
    type: yup.mixed<PlanType>(),
    price: yup.mixed().when('type', {
      is: (value: string) => value === 'season' || value === 'monthly',
      then: yup
        .number()
        .min(0.01, 'O valor deve ser maior que 0')
        .required('Este campo é obrigatório.'),
    }),
    membershipsCount: yup.mixed(),
    unlimitedMemberships: yup.boolean(),
    nextSeasonDate: yup.mixed().when('type', {
      is: 'season',
      then: yup.mixed().required('Este campo é obrigatório.'),
    }),
    trialDays: yup.mixed().when('type', {
      is: (val: string) => val === 'season' || 'monthly',
      then: yup.number().when('trialDaysIsActive', {
        is: true,
        then: yup
          .number()
          .min(1, 'O valor deve ser maior que 0.')
          .required('Este campo é obrigatório.'),
      }),
    }),
    trialDaysIsActive: yup.mixed().when('type', {
      is: (value: string) => value === 'season',
      then: yup.boolean().required('Este campo é obrigatório.'),
    }),
    installments: yup.mixed().when('type', {
      is: (value: string) => value === 'season',
      then: yup.number().required('Este campo é obrigatório.'),
    }),
    paymentType: yup.mixed().when('type', {
      is: (value: string) => value === 'monthly' || value === 'season',
      then: yup
        .array()
        .of(yup.string())
        .min(1, 'Selecione pelo menos 1 forma de pagamento.'),
    }),
    sortableHighlightedContents: yup.array().of(
      yup
        .object()
        .noUnknown()
        .shape({
          position: yup.number().required(),
          highlightedContent: yup
            .object()
            .noUnknown()
            .shape({ id: yup.string().required() }),
        })
        .transform((element) => ({
          position: element.position,
          highlightedContent: { id: element.highlightedContent.id },
        })),
    ),
    multiSection: yup.object().noUnknown().shape({
      id: yup.string(),
    }).nullable(),
    partners: yup
      .array()
      .of(yup.object().noUnknown().shape({ code: yup.string() })),
    sections: yup.array().of(
      yup
        .object()
        .shape({ id: yup.string().required() })
        .transform(({ id }) => ({ id })),
    ),
  });

export default validationSchema;
