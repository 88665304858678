import { DiscountType } from '../../../interfaces/Discount';
import disableCollectiveDiscountMutation from "../gql/DisableCollectiveDiscountMutation";
import disableCouponMutation from '../gql/DisableIndividualDiscountMutation';
import disableInternalDiscountMutation from '../gql/DisableInternalDiscountMutation';
import disableRetentionDiscountMutation from '../gql/DisableRetentionDiscountMutation';

export const getDisableMutationByType = (type: DiscountType) => {
    switch (type) {
      case DiscountType.Internal:
        return {
          mutation: disableInternalDiscountMutation,
        };
      case DiscountType.Collective:
        return {
          mutation: disableCollectiveDiscountMutation,
        };
      case DiscountType.Retention:
        return {
          mutation: disableRetentionDiscountMutation,
        };
      default:
        return {
          mutation: disableCouponMutation,
        };
    }
  };
