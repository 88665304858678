import { Button, Grid, Typography } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PlanType } from '../../../interfaces/Plan';
import Context from '../Context';

const PlanTypeName = {
  [PlanType.monthly]: 'Mensal',
  [PlanType.season]: 'Temporada',
  [PlanType.partner]: 'Parceiros',
  [PlanType.free]: 'Gratuito',
};

export const getFriendlyNameByPlanType = (type: PlanType): string =>
  PlanTypeName[type];

const PaymentBonds = () => {
  const { form } = useContext(Context);
  const { pathname } = useLocation();
  const [isEditing, setIsEditing] = useState(false);
  const toggleSelectedBond = (type: PlanType) => {
    form.resetForm();
    form.setFieldValue('type', type);
  };
  const buttonIsSelected = (type: PlanType) => type === form.values.type;
  useEffect(() => {
    const cutPathname = pathname.split('/');
    const containsEdit = cutPathname[3] === 'edit';
    if (containsEdit) {
      setIsEditing(true);
    }
  }, []);
  return (
    <>
      <Typography sx={{ color: 'grey.100', fontWeight: '600', mt: 6 }}>
        Selecione um vínculo de pagamento:
      </Typography>
      <Grid container sx={{ gap: 4, mt: 6 }}>
        {Object.values(PlanType).map((type) => (
          <Grid item xs>
            <Button
              sx={{
                color: buttonIsSelected(type) ? 'grey.100' : 'grey.400',
                bgcolor: 'primary.main',
                height: 59,
                width: '100%',
              }}
              onClick={() => (isEditing ? null : toggleSelectedBond(type))}
            >
              {getFriendlyNameByPlanType(type)}
            </Button>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default PaymentBonds;
