import {
  Box,
  Card,
  Chip,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/lab';
import { useContext } from 'react';
import { ContentType } from '../../../interfaces/Content';
import Context from '../Context';

const FriendlyName = {
  [ContentType.vod]: 'On Demand',
  [ContentType.live]: 'Ao vivo',
};

const Scheduling = () => {
  const { form } = useContext(Context);
  const getFriendlyNameByContenType = (type: ContentType) => FriendlyName[type];
  const datePickerHandler = (typeDate: string) => (date: Date | null) =>
    form.handleChange({
      target: { name: typeDate, type: 'text', value: date },
    });
  return (
    <Card sx={{ height: 129, mt: 4, p: 4 }}>
      <Grid container>
        <Grid item xs={6}>
          <Typography sx={{ color: 'grey.100', fontWeight: 600, mb: 3 }}>
            Agendamento do destaque
          </Typography>
          <Typography
            sx={{ color: 'grey.300', fontSize: 14, mb: 4, width: 450 }}
          >
            Escolha a data de início e término do destaque, de acordo com o tipo
            de conteúdo. Conteúdos AO VIVO terão o término agendado para o mesmo
            dia.
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ color: 'grey.100', fontWeight: 600 }}>
            Tipo de Conteúdo
          </Typography>
          {form.values.content ? (
            <Chip
              label={getFriendlyNameByContenType(form.values.content.type)}
              sx={{
                bgcolor: 'grey.500',
                borderRadius: '4px',
                height: 30,
                mt: 2,
                width: 110,
              }}
            />
          ) : (
            <Typography
              sx={{
                color: 'grey.300',
                fontSize: 14,
                mb: 4,
                mt: 3,
                width: 450,
              }}
            >
              Adicione o conteúdo
            </Typography>
          )}
        </Grid>
        <Grid item xs>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Box sx={{ minHeight: 110 }}>
              <Typography sx={{ color: 'grey.300', fontSize: 14, mb: 2 }}>
                Início *
              </Typography>
              <DateTimePicker
                mask="__/__/__"
                inputFormat="dd/MM/yy HH:mm"
                minDate={new Date()}
                onChange={datePickerHandler('scheduleDate')}
                value={form.values.scheduleDate}
                renderInput={(params: any) => <TextField {...params} />}
              />
            </Box>
            <FormHelperText error>{form.errors.scheduleDate}</FormHelperText>
            <Box sx={{ minHeight: 110 }}>
              <Typography sx={{ color: 'grey.300', fontSize: 14, mb: 2 }}>
                Término *
              </Typography>
              <DateTimePicker
                mask="__/__/__"
                inputFormat="dd/MM/yy HH:mm"
                minDate={form.values.scheduleDate}
                onChange={datePickerHandler('finishDate')}
                value={form.values.finishDate}
                renderInput={(params: any) => <TextField {...params} />}
              />
              <FormHelperText error>{form.errors.finishDate}</FormHelperText>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Scheduling;
