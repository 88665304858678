import { gql } from '@apollo/client';

const updateHighlightedContentMutation = gql`
  mutation UpdateHighlightedContentMutation(
    $input: UpdateHighlightedContentInput!
  ) {
    updateHighlightedContent(input: $input) {
      id
    }
  }
`;

export default updateHighlightedContentMutation;
