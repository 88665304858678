import { gql } from '@apollo/client';

const updateMultiSectionItemMutation = gql`
  mutation updateMultiSectionItemMutation(
    $input: UpdateMultiSectionItemInput!
  ) {
    updateMultiSectionItem(input: $input)
  }
`;

export default updateMultiSectionItemMutation;
