import { Box, Stack } from '@material-ui/core';
import { Partner } from '../../../interfaces/Partner';

interface DialogPartnersProps {
  partners: Partner[];
}

const AvatarAffiliate = ({ logo }: { logo: Partner['logo'] }) => (
  <Box
    sx={{
      backgroundImage: `url(${logo})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      height: 60,
      width: 60,
    }}
  />
);

const DialogPartners = ({ partners }: DialogPartnersProps) => (
  <Stack
    alignItems="center"
    direction="row"
    flexWrap="wrap"
    justifyContent="space-between"
  >
    {partners.map((partner) => (
      <AvatarAffiliate logo={partner.logo} />
    ))}
  </Stack>
);

export default DialogPartners;
