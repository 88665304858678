import { Button, Stack } from '@material-ui/core';
import { useContext } from 'react';
import Context from '../context';

interface DynamicButtonProps {
  alignment: string;
  bgcolor: string;
  color: string;
  href: string;
  label: string;
}

const DynamicButton = ({
  alignment,
  bgcolor,
  color,
  href,
  label,
}: DynamicButtonProps) => {
  const { smartphoneScreen } = useContext(Context);
  return (
    <Stack
      className="dynamic-button"
      sx={{ alignItems: alignment, position: 'relative' }}
    >
      <Button
        href={href}
        sx={{
          bgcolor,
          color,
          fontSize: smartphoneScreen ? 16 : 20,
          height: smartphoneScreen ? 36 : 54,
          minWidth: smartphoneScreen ? 215 : 400,
          px: 5,
          transition: 'all .2s ease-in-out',
          '&:hover': {
            bgcolor,
            filter: 'brightness(1.2)',
          },
        }}
      >
        {label}
      </Button>
    </Stack>
  );
};

export default DynamicButton;
