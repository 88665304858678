import {
  Button,
  Card,
  CardContent,
  Divider,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { DateTimePicker } from '@material-ui/lab';
import { SxProps } from '@material-ui/system';
import { useFormik } from 'formik';
import { ReactNode, useState } from 'react';
import { DiscountType } from '../../../interfaces/Discount';
import StackWithLabel from '../../Spectators/components/StackWithLabel';
import Context from '../Context';
import { FilterAllDiscounts, InputFilters } from '../typings';

export interface FiltersBarProps {
  sx?: SxProps<Theme>;
  children: ReactNode;
}

const typings = {
  [DiscountType.Internal]: 'Interno',
  [DiscountType.Collective]: 'Coletivo',
  [DiscountType.Retention]: 'Retenção',
  [DiscountType.Coupon]: 'Cupom Individual',
};

const getDiscountTypeFriendlyName = (type: DiscountType) => typings[type];

const FiltersBar = ({ sx, children }: FiltersBarProps) => {
  const [input, setInput] = useState<InputFilters>({
    filters: {
      type: FilterAllDiscounts.All,
      status: true,
      createdAtGte: '',
      createdAtLte: '',
      term: '',
    },
    paginate: { page: 1 },
  });
  const getBooleanValueByStatusType = (status: string) => {
    switch (status) {
      case 'active':
        return true;
      default:
        return false;
    }
  };
  const form = useFormik<any>({
    initialValues: {
      createdAtGte: null,
      createdAtLte: null,
      type: FilterAllDiscounts.All,
      status: 'active',
      term: '',
    },
    onSubmit: async (filters) => {
      const newValueStatus = getBooleanValueByStatusType(filters.status);
      setInput((prevState: any) => ({
        ...prevState,
        filters: { ...filters, status: newValueStatus },
        paginate: { page: 1 },
      }));
    },
  });
  const resetFilter = () => {
    form.resetForm();
    form.handleSubmit();
  };
  return (
    <Context.Provider value={{ input, setInput }}>
      <Card sx={{ ...sx }}>
        <CardContent>
          <form>
            <OutlinedInput
              name="term"
              fullWidth
              value={form.values.term}
              placeholder="Busque aqui por um desconto"
              autoComplete="off"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'secondary.main' }} />
                </InputAdornment>
              }
              onChange={form.handleChange}
            />
            <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  sx={{ background: 'grey.500' }}
                  flexItem
                />
              }
              spacing={4}
              sx={{ mt: 5 }}
            >
              <Stack direction="row" spacing={4}>
                <StackWithLabel label="Tipo de desconto">
                  <Select
                    name="type"
                    value={form.values.type}
                    sx={{ minWidth: 156 }}
                    onChange={form.handleChange}
                  >
                    <MenuItem value={FilterAllDiscounts.All}>Todos</MenuItem>
                    {Object.values(DiscountType).map((currentType) => (
                      <MenuItem value={currentType}>
                        {getDiscountTypeFriendlyName(currentType)}
                      </MenuItem>
                    ))}
                  </Select>
                </StackWithLabel>
                <StackWithLabel label="Desde">
                  <DateTimePicker
                    mask="__/__/__"
                    inputFormat="dd/MM/yy HH:mm"
                    value={form.values.createdAtGte}
                    InputAdornmentProps={{
                      position: 'start',
                      sx: { maxWidth: 20, svg: { color: 'secondary.main' } },
                    }}
                    renderInput={(params) => (
                      <TextField sx={{ maxWidth: 160 }} {...params} />
                    )}
                    onChange={(value) =>
                      form.handleChange({
                        target: { name: 'createdAtGte', value },
                      })
                    }
                  />
                </StackWithLabel>
                <StackWithLabel label="Até">
                  <DateTimePicker
                    mask="__/__/__"
                    inputFormat="dd/MM/yy HH:mm"
                    minDate={form.values.createdAtGte}
                    minDateTime={form.values.createdAtGte}
                    value={form.values.createdAtLte}
                    InputAdornmentProps={{
                      position: 'start',
                      sx: { maxWidth: 20, svg: { color: 'secondary.main' } },
                    }}
                    renderInput={(params) => (
                      <TextField sx={{ maxWidth: 160 }} {...params} />
                    )}
                    onChange={(value) =>
                      form.handleChange({
                        target: { name: 'createdAtLte', value },
                      })
                    }
                  />
                </StackWithLabel>
                <StackWithLabel label="Status">
                  <Select
                    name="status"
                    displayEmpty
                    value={form.values.status}
                    sx={{ minWidth: 150 }}
                    onChange={form.handleChange}
                  >
                    <MenuItem value="active">Ativo</MenuItem>
                    <MenuItem value="inactive">Inativo</MenuItem>
                  </Select>
                </StackWithLabel>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={3} sx={{ mt: 5 }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: 'main',
                  borderColor: 'common.white',
                  color: 'common.white',
                }}
                onClick={(evt) => {
                  evt.preventDefault();
                  form.handleSubmit();
                }}
              >
                Pesquisar
              </Button>
              <Button variant="text" onClick={resetFilter}>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: 'secondary.main',
                    fontWeight: 600,
                  }}
                >
                  Limpar Filtros
                </Typography>
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
      {children}
    </Context.Provider>
  );
};

export default FiltersBar;
