import { gql } from '@apollo/client';

const getPlansReportsQuery = gql`
  query GetPlansReports($type: PlansFiltersType!) {
    getPlansReports(type: $type) {
      totalActivePlansGratis
      totalActivesByCredit
      totalActivesByDebit
      totalActivesByPix
      spectatorsAvgDuration
      totalActiveSpectators
      totalInternalDiscounts
      totalCollectiveDiscounts
      totalIndividualDiscounts
      totalRetentionDiscounts
      totalHired
      totalCancelled
      avgReactivated
      totalPlansByPartner {
        code 
        count
      }
      priceAvg
    }
  }
`;

export default getPlansReportsQuery;
