import { Accordion } from '@material-ui/core';
import { useState } from 'react';
import { HeartTeam } from '../../../interfaces/HeartTeam';
import TeamItemDetails from './TeamItemDetails';
import TeamItemSummary from './TeamItemSummary';

interface TeamItemProps {
  team: HeartTeam;
  refetch: () => void;
}

const TeamItem = ({ team, refetch }: TeamItemProps) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion disableGutters expanded={expanded} sx={{ mt: 2 }}>
      <TeamItemSummary
        expanded={expanded}
        setExpanded={setExpanded}
        refetch={refetch}
        team={team}
      />
      <TeamItemDetails team={team} refetch={refetch} />
    </Accordion>
  );
};

export default TeamItem;
