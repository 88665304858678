import { gql } from '@apollo/client';

const updateRetentionDiscountMutation = gql`
  mutation UpdateRetentionDiscountMutation($input: UpdateRetentionDiscountInput!) {
    updateRetentionDiscount(input: $input) {
      id
    }
  }
`;

export default updateRetentionDiscountMutation;