import {
  Button,
  Chip,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  Grid,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Plan } from '../../../interfaces/Plan';
import PlansContext from '../Context';

interface PlanItemProps {
  plan: Plan;
}

const PlanItem = ({ plan }: PlanItemProps) => {
  const { disablePlanHandler, isEnabled } = useContext(PlansContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isPlansMenuOpened = !!anchorEl;
  const formatedTrialDays =
    plan.trialDays > 1 ? `${plan.trialDays} Dias` : '1 Dia';
  const trialDaysLabel = plan.trialDays ? formatedTrialDays : '-';
  const closeMenuHandler = () => setAnchorEl(null);
  const openMenuHandler = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const deletePlan = () => {
    disablePlanHandler(plan.id);
    closeMenuHandler();
  };
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={isPlansMenuOpened}
        onClose={closeMenuHandler}
      >
        <Link to={`/plan/${plan.id}`}>
          <MenuItem data-testid="option-view-plan">
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Ver plano
            </Typography>
          </MenuItem>
        </Link>
        <Divider />
        <MenuItem
          data-testid="option-edit-plan"
          onClick={() => navigate(`/plan/${plan.id}/edit`)}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Editar plano
          </Typography>
        </MenuItem>
        {isEnabled && (
          <>
            <Divider />
            <MenuItem data-testid="option-delete-plan" onClick={deletePlan}>
              <ListItemIcon>
                <CloseIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Desativar plano
              </Typography>
            </MenuItem>
          </>
        )}
      </Menu>
      <Grid
        container
        sx={{
          alignItems: 'center',
          bgcolor: 'primary.main',
          borderRadius: 2,
          display: 'flex',
          height: 78,
          mb: 2,
          px: 4,
        }}
      >
        <Grid item xs>
          <Typography
            sx={{
              color: 'grey.100',
              fontSize: 14,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: 180,
            }}
          >
            {plan.title}
          </Typography>
        </Grid>
        <Grid item xs sx={{ textAlign: 'center' }}>
          <Typography
            sx={{
              color: 'grey.100',
              fontSize: 14,
            }}
          >
            R$ {plan.price}
          </Typography>
        </Grid>
        <Grid item xs sx={{ textAlign: 'center' }}>
          <Typography
            sx={{
              color: 'grey.100',
              fontSize: 14,
            }}
          >
            {trialDaysLabel}
          </Typography>
        </Grid>
        <Grid item xs sx={{ textAlign: 'center' }}>
          <Typography
            sx={{
              color: 'grey.100',
              fontSize: 14,
            }}
          >
            -
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: 'right' }}>
          <Chip
            variant="outlined"
            color={plan.published ? 'success' : 'error'}
            size="small"
            label={plan.published ? 'Ativo' : 'Inativo'}
            sx={{ borderRadius: 1 }}
          />
        </Grid>
        <Grid item xs sx={{ textAlign: 'right' }}>
          <Button
            size="large"
            endIcon={<MoreVertIcon sx={{ fontSize: 24, mr: 3 }} />}
            sx={{
              bgcolor: 'grey.500',
              color: 'grey.200',
              height: 40,
              width: 30,
            }}
            onClick={openMenuHandler}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PlanItem;
