import { gql } from '@apollo/client';

const createRetentionDiscountMutation = gql`
  mutation CreateRetentionDiscountMutation($input: CreateRetentionDiscountInput!) {
    createRetentionDiscount(input: $input) {
      id
    }
  }
`;

export default createRetentionDiscountMutation;