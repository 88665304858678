import * as yup from 'yup';
import { PermissionType } from '../../../interfaces/Permission';
import { ColorType } from '../../../interfaces/Role';

const validationSchema = yup
  .object()
  .noUnknown()
  .shape({
    thumbnail: yup.mixed(),
    name: yup
      .string()
      .required('Este campo é obrigatório.')
      .matches(/[\p{Letter}]+/gu, 'Nome inserido inválido'),
    email: yup
      .string()
      .email('Email inválido')
      .required('Este campo é obrigatório.'),
    confirmEmail: yup
      .string()
      .email('Email inválido')
      .oneOf([yup.ref('email'), null], 'Os emails não coincidem')
      .required('Este campo é obrigatório.'),
    password: yup
      .string()
      .required('Este campo é obrigatório.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*,-./:;<=>?@[\\\]^_`{|}~]{8,}$/,
        'A senha deve conter 8 caracteres, letra maiúscula, letra minuscula, número e caracter especial.',
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'As senhas não coincidem')
      .required('Este campo é obrigatório.'),
    role: yup
      .object()
      .noUnknown()
      .shape({
        id: yup.string(),
        title: yup.string().required(),
        color: yup.mixed().oneOf(Object.values(ColorType)),
        enabled: yup.boolean().required(),
        permissions: yup.array().of(
          yup
            .object()
            .noUnknown()
            .shape({
              id: yup.string(),
              feature: yup.mixed().oneOf(Object.values(PermissionType)),
              create: yup.boolean().required(),
              read: yup.boolean().required(),
              update: yup.boolean().required(),
              delete: yup.boolean().required(),
            }),
        ),
      }),
  });
export default validationSchema;
