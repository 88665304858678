import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';

import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { PageType } from '../../../interfaces/Plan';
import pagesQuery from '../gql/pagesQuery';
import Container from './components';
import Context from './context';
import { BuilderForm } from './typings';
import validationSchema from './validationSchema';

const createPageMutation = gql`
  mutation CreatePageMutation($input: CreatePageInput!) {
    createPage(input: $input) {
      id
    }
  }
`;

const HTMLPagesBuilder = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [createPage] = useMutation(createPageMutation);
  const { loading, data } = useQuery(pagesQuery);
  const form = useFormik<BuilderForm>({
    initialValues: {
      title: 'Nova Página',
      blocks: null,
      type: PageType.subscriptionSuccess,
      published: false,
      scheduleDateEnabled: false,
      finishDateEnabled: false,
      seoTitle: '',
      seoDescription: '',
      seoKeywords: [],
      scripts: [],
    },
    validationSchema,
    onSubmit: async (input) => {
      const res = await createPage({ variables: { input } });
      alert.success('Alterações salvas com sucesso');
      navigate(`/pages/${res.data.createPage.id}/builder`);
    },
  });
  if (loading) {
    return <div>loading...</div>;
  }
  return (
    <Context.Provider value={{ form, pages: data.pages }}>
      <Container />
    </Context.Provider>
  );
};

export default HTMLPagesBuilder;
