import * as yup from 'yup';

let startDate: Date | null = null;

const validationSchema = yup
  .object()
  .noUnknown()
  .shape({
    title: yup.string().required('Este campo é obrigatório.'),
    description: yup.string().required('Este campo é obrigatório.'),
    fullHdLandscape: yup.string().required('Este campo é obrigatório.'),
    fullHdPortrait: yup.string(),
    scheduleDate: yup.mixed(),
    finishDate: yup
      .date()
      .nullable()
      .when('scheduleDate', {
        is: (scheduleDate: Date | null) => {
          startDate = scheduleDate;
          return !!scheduleDate;
        },
        then: () =>
          yup
            .date()
            .nullable()
            .min(
              startDate,
              'A data de término deve ser maior que a data de início.',
            ),
      }),
    content: yup
      .object()
      .noUnknown()
      .shape({ id: yup.string().required('Este campo é obrigatório.') }),
  });

export default validationSchema;
