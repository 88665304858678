import { useMutation } from '@apollo/client';
import {
  AccordionDetails,
  Box,
  Button,
  Stack,
  Typography,
} from '@material-ui/core';
import { useAlert } from 'react-alert';
import { useDialog } from '../../../components/Dialog';
import { Avatar, HeartTeam } from '../../../interfaces/HeartTeam';
import AvatarItem from '../../CreateHeartTeam/components/AvatarItem';
import updateTeamMutation from '../../EditHeartTeam/gql/updateTeamMutation';

interface TeamItemDetailsProps {
  team: HeartTeam;
  refetch: () => void;
}

const TeamItemDetails = ({ team, refetch }: TeamItemDetailsProps) => {
  const alert = useAlert();
  const dialog = useDialog();
  const [updateTeam] = useMutation(updateTeamMutation);
  const updateTeamHandler = async (input: HeartTeam) => {
    try {
      await updateTeam({ variables: { input } });
      refetch();
      alert.success('Time editado com sucesso.');
    } catch {
      alert.error('Ocorre um erro ao editar o time.');
    }
  };
  const removeAvatarHandler = async (avatar: Avatar) => {
    const confirm = await dialog.confirm({
      title: 'Você tem certeza?',
      message:
        'Excluir um ícone afetará diretamente o perfil de alguns espectadores.',
    });
    if (confirm) {
      let avatarRemoved = team.avatars.filter(
        (currentAvatar) => currentAvatar.url !== avatar.url,
      );
      const stillHaveAvatars = avatarRemoved.length > 0;
      const isTheEnabledAvatar = avatar.enabled;
      const mustTagAnotherAvatar = stillHaveAvatars && isTheEnabledAvatar;
      if (mustTagAnotherAvatar) {
        avatarRemoved = avatarRemoved.map(
          (currentAvatar: Avatar, index: number) => {
            if (index === 0) {
              return { ...currentAvatar, enabled: true };
            }
            return currentAvatar;
          },
        );
      }
      const input = { ...team, avatars: avatarRemoved };
      await updateTeamHandler(input);
    }
  };
  const changeEnabledAvatar = async (avatar: Avatar) => {
    const newAvatars = team.avatars.map((currentAvatar: Avatar) => {
      const selectedAvatar = currentAvatar.url === avatar?.url;
      return {
        ...currentAvatar,
        enabled: selectedAvatar ? !currentAvatar.enabled : false,
      };
    });
    const input = { ...team, avatars: newAvatars };
    await updateTeamHandler(input);
  };
  const visitTeamUrlHandler = () => window.open(team.externalUrl, '_blank');
  return (
    <AccordionDetails sx={{ px: 0, py: 5 }}>
      <Box sx={{ color: 'common.white', px: 5 }}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography sx={{ fontSize: 16, fontWeight: 600, mb: 4 }}>
            Ícones adicionados
          </Typography>
          <Button onClick={visitTeamUrlHandler}>Acessar URL do time</Button>
        </Stack>
        <Typography sx={{ color: 'grey.300', fontSize: 14, mb: 4 }}>
          Imagens associadas ao time do coração.
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          spacing={3}
          sx={{ overflow: 'auto', pb: 3 }}
        >
          {team.avatars.map((avatar: any) => (
            <Box sx={{ width: 128 }}>
              <AvatarItem
                avatar={avatar}
                removeAvatarHandler={removeAvatarHandler}
                changeEnabledAvatar={changeEnabledAvatar}
              />
            </Box>
          ))}
        </Stack>
      </Box>
    </AccordionDetails>
  );
};

export default TeamItemDetails;
