// TODO: Find a better solution for default props
/* eslint-disable react/require-default-props */
import { useNode, Node, useEditor } from '@craftjs/core';
import { Add as AddIcon } from '@material-ui/icons';
import { Stack } from '@material-ui/core';

import EmptyStateCard from '../../../../../../components/EmptyStateCard';
import { StructuresSettings } from '../../Sidebar/settings/widgets';

interface HalfContainerProps {
  children?: React.ReactNode;
  parentId: string;
}

const HalfContainer = ({ children = null, parentId }: HalfContainerProps) => {
  const {
    connectors: { select },
  } = useEditor((state) => ({
    selectedNodeId: state.events.selected,
  }));
  const {
    connectors: { connect },
    hasNoChildren,
  } = useNode((node) => ({
    isClicked: node.events.selected,
    hasNoChildren: node.data.nodes.length < 1,
  }));
  return (
    <Stack ref={connect} height="100%" width="100%">
      <Stack
        ref={(ref: HTMLElement) => select(ref, parentId)}
        height="100%"
        sx={{
          border: hasNoChildren ? '2px dotted' : 'none',
          borderColor: 'grey.400',
          borderRadius: 1,
          gap: 4,
          justifyContent: 'flex-start',
          p: hasNoChildren ? 0 : 5,
          '& iframe': { height: 208 },
        }}
      >
        {hasNoChildren ? (
          <EmptyStateCard
            description="Depois de arrastar, você poderá editar as posições, incluir e organizar seus blocos e estruturas."
            icon={<AddIcon />}
            sx={{ height: 208, pointerEvents: 'none' }}
            title="Arraste blocos para cá"
          />
        ) : (
          children
        )}
      </Stack>
    </Stack>
  );
};

const allowedComponents = [
  'DynamicText',
  'DynamicButton',
  'DynamicImage',
  'DynamicVideo',
];

HalfContainer.craft = {
  related: { settings: StructuresSettings },
  rules: {
    canMoveIn: (incoming: Node) =>
      allowedComponents.includes(incoming.data.name),
  },
};

export default HalfContainer;
