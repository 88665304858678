import {
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { useContext, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { SubSection } from '../../../interfaces/Section';
import Context from '../Context';
import SortableContentList from './SortableContentList';
import SubSectionList from './SubSectionList';

const SliderEditor = () => {
  const { form, section, selectedSubSection, setSelectedSubSection } =
    useContext(Context);
  const [isCreatingSubSection, setIsCreatingSubSection] = useState(false);
  // TODO: add typing
  const addSubSectionHandler = async (evt: any) => {
    const enterWasNotPressed = evt.key !== 'Enter';
    if (enterWasNotPressed) {
      return;
    }
    const title = evt.target.value;
    const titleIsEmpty = !title;
    if (titleIsEmpty) {
      return;
    }
    const subSectionToAdd: Partial<SubSection> = {
      id: uuid(),
      title,
      position: 1,
      sortableContents: [],
    };
    const newSubSections = [subSectionToAdd, ...form.values.subSections!].map(
      (subSection, position) => ({ ...subSection, position: position + 1 }),
    );
    form.setFieldValue('subSections', newSubSections);
    setIsCreatingSubSection(false);
  };
  return (
    <Card>
      <CardContent sx={{ mb: 5 }}>
        <Typography
          sx={{ color: 'common.white', fontSize: 16, fontWeight: 600 }}
        >
          Ordenação das subseções
        </Typography>
        <Stack direction="row" spacing={3} sx={{ mt: 5 }}>
          <Button
            size="small"
            sx={{
              bgcolor: 'grey.500',
              border:
                selectedSubSection!.id === section.generalSubSection.id
                  ? '1px solid #EFE7FF'
                  : 'inherit',
            }}
            onClick={() => setSelectedSubSection(section.generalSubSection)}
          >
            Todos
          </Button>
          <Button
            color="info"
            size="small"
            sx={{ minWidth: 'auto', px: 1 }}
            onClick={() => setIsCreatingSubSection(true)}
          >
            <AddIcon />
          </Button>
          {isCreatingSubSection && (
            <ClickAwayListener
              onClickAway={() => setIsCreatingSubSection(false)}
            >
              <TextField
                autoFocus
                placeholder="O nome da sub seção..."
                size="small"
                onKeyUp={addSubSectionHandler}
              />
            </ClickAwayListener>
          )}
          <Droppable
            droppableId="subSectionList"
            type="subSection"
            direction="horizontal"
          >
            {(provided) => (
              <Stack
                ref={provided.innerRef}
                direction="row"
                spacing={4}
                sx={{ mt: 4 }}
                {...provided.droppableProps}
              >
                <SubSectionList subSections={form.values.subSections!} />
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </Stack>
        <SortableContentList />
      </CardContent>
    </Card>
  );
};

export default SliderEditor;
