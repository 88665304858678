import { useMutation, useQuery } from '@apollo/client';
import { Grid, Stack } from '@material-ui/core';
import { useAuthorize } from '@tshio/react-router-permissions';
import { formatDistance, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import { findIndex, sortBy } from 'lodash';
import { useContext, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useAlert } from 'react-alert';
import { useNavigate, useParams } from 'react-router-dom';
import { useDialog } from '../../components/Dialog';
import HasNoPermission from '../../components/HasNoPermission';
import ScheduleCard from '../../components/ScheduleCard';
import InlineTextEditor from '../../components/InlineTextEditor';
import Navbar from '../../components/UI/molecules/Navbar';
import TipAlert from '../../components/UI/molecules/TipAlert';
import TogglerCard from '../../components/UI/molecules/TogglerCard';
import FormFooter from '../../components/UI/templates/FormFooter';
import ContextToMe from '../../Context';
import { Content } from '../../interfaces/Content';
import { SortableHighlightedContents } from '../../interfaces/Highlight';
import { Section, SortableContent, SubSection } from '../../interfaces/Section';
import reorder from '../../utils/reorderList';
import deleteSectionMutation from './gql/deleteSectionMutation';
import getSectionQuery from './gql/getSectionQuery';
import updateSectionMutation from './gql/updateSectionMutation';
import SearchableContents from './components/SearchableContents';
import SliderEditor from './components/SliderEditor';
import SearchableHighlights from './components/SearchableHighlights';
import Context, { SectionForm } from './Context';
import validationSchema from './providers/validationSchema';

const EditSection = () => {
  const { me } = useContext(ContextToMe);
  const permissionForEverything = me.superAdmin;
  const permissionToUpdate =
    permissionForEverything || useAuthorize(['sections', 'update']);
  const permissionToDelete =
    permissionForEverything || useAuthorize(['sections', 'delete']);
  const alert = useAlert();
  const navigate = useNavigate();
  const { sectionId } = useParams();
  const dialog = useDialog();
  const [section, setSection] = useState<Section>();
  const [selectedSubSection, setSelectedSubSection] = useState<SubSection>();
  const [selectableContents, setSelectableContents] = useState<Content[]>();
  const [selectedContents, setSelectedContents] = useState<Content[]>([]);
  const { loading, data } = useQuery<{ contents: Content[]; section: Section }>(
    getSectionQuery,
    {
      variables: { id: sectionId },
      onCompleted: (completedData) => {
        setSelectableContents(completedData.contents);
        setSection(completedData.section);
        setSelectedSubSection(completedData.section.generalSubSection);
      },
    },
  );
  const [deleteSection] = useMutation(deleteSectionMutation);
  const [updateSection, { loading: isUpdating }] = useMutation(
    updateSectionMutation,
  );
  const form = useFormik<SectionForm>({
    enableReinitialize: true,
    initialValues: {
      id: data?.section.id,
      title: data?.section.title,
      scheduleDate: data?.section.scheduleDate,
      scheduleDateEnabled: data?.section.scheduleDateEnabled,
      finishDate: data?.section.finishDate,
      finishDateEnabled: data?.section.finishDateEnabled,
      position: data?.section.position,
      sortableHighlightedContents: data?.section.sortableHighlightedContents,
      published: data?.section.published,
      generalSubSection: data?.section.generalSubSection
        ? {
            ...data!.section.generalSubSection,
            sortableContents: sortBy(
              data!.section.generalSubSection.sortableContents,
              'position',
            ),
          }
        : undefined,
      subSections: data?.section.subSections
        ? sortBy(
            data!.section.subSections.map((subSection) => ({
              ...subSection,
              sortableContents: sortBy(subSection.sortableContents, 'position'),
            })),
            ['position'],
          )
        : [],
    },
    validationSchema,
    onSubmit: async (values) => {
      const input = validationSchema.cast(values);
      await updateSection({ variables: { input } });
      alert.success('Definições guardadas com sucesso!');
    },
  });
  const deleteSectionHandler = async () => {
    const shouldDelete = await dialog.confirm({
      title: 'Remover',
      message: 'Deseja realmente remover esta seção?',
    });
    if (shouldDelete) {
      await deleteSection({ variables: { id: sectionId } });
      navigate('/sections/order', { replace: true });
    }
  };
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.source.droppableId === 'subSectionList') {
      const reorderedSubSections = reorder<SubSection>(
        form.values.subSections!,
        result.source.index,
        result.destination.index,
      );
      const newSubSections = reorderedSubSections.map(
        (subSection, position: number) => ({
          ...subSection,
          position: position + 1,
        }),
      );
      form.setFieldValue('subSections', newSubSections);
      return;
    }
    if (
      result.source.droppableId === 'sortableContents' &&
      result.destination.droppableId === 'sortableContents'
    ) {
      const isGeneralSubSectionSelected =
        selectedSubSection!.id === section!.generalSubSection.id;
      if (isGeneralSubSectionSelected) {
        const reorderedSortableContents = reorder(
          form.values.generalSubSection!.sortableContents,
          result.source.index,
          result.destination.index,
        ).map((sortableContent: SortableContent, index: number) => ({
          ...sortableContent,
          position: index + 1,
        }));
        form.setFieldValue(
          'generalSubSection.sortableContents',
          reorderedSortableContents,
        );
        return;
      }
      const selectedSubSectionIndex = form.values.subSections!.findIndex(
        (subSection: SubSection) => subSection.id === selectedSubSection!.id,
      );
      const reorderedSortableContents = reorder(
        form.values.subSections![selectedSubSectionIndex].sortableContents,
        result.source.index,
        result.destination.index,
      ).map((sortableContent: SortableContent, index: number) => ({
        ...sortableContent,
        position: index + 1,
      }));
      form.setFieldValue(
        `subSections[${selectedSubSectionIndex}].sortableContents`,
        reorderedSortableContents,
      );
      return;
    }
    if (
      result.source.droppableId === 'contentList' &&
      result.destination.droppableId === 'sortableContents'
    ) {
      const isGeneralSubSectionSelected =
        selectedSubSection!.id === section!.generalSubSection.id;
      const contentIdx = findIndex(selectableContents, {
        id: result.draggableId.replace('content-', ''),
      });
      const content = selectableContents![contentIdx];
      const contentIsSelected = selectedContents.includes(content);
      if (isGeneralSubSectionSelected) {
        const newSortableContents = [
          ...form.values.generalSubSection!.sortableContents,
        ];
        if (contentIsSelected) {
          selectedContents.forEach((currentContent, index) => {
            newSortableContents.splice(
              result?.destination?.index as number,
              0,
              {
                content: currentContent,
                position: (result?.destination?.index as number) + 1 + index,
              },
            );
          });
          const orderedSortableContents = newSortableContents.map(
            (sortableContent: SortableContent, index: number) => ({
              ...sortableContent,
              position: index + 1,
            }),
          );
          form.setFieldValue(
            'generalSubSection.sortableContents',
            orderedSortableContents,
          );
          setSelectedContents([]);
          return;
        }
        newSortableContents.splice(result.destination.index, 0, {
          content,
          position: result.destination.index + 1,
        });
        const orderedSortableContents = newSortableContents.map(
          (sortableContent: SortableContent, index: number) => ({
            ...sortableContent,
            position: index + 1,
          }),
        );
        form.setFieldValue(
          'generalSubSection.sortableContents',
          orderedSortableContents,
        );
        return;
      }
      const selectedSubSectionIndex = form.values.subSections!.findIndex(
        (subSection: SubSection) => subSection.id === selectedSubSection!.id,
      );
      const newSortableContents = [
        ...form.values.subSections![selectedSubSectionIndex]!.sortableContents,
      ];
      if (contentIsSelected) {
        selectedContents.forEach((currentContent, index) => {
          newSortableContents.splice(result?.destination?.index as number, 0, {
            content: currentContent,
            position: (result?.destination?.index as number) + 1 + index,
          });
        });
        const orderedSortableContents = newSortableContents.map(
          (sortableContent: SortableContent, index: number) => ({
            ...sortableContent,
            position: index + 1,
          }),
        );
        form.setFieldValue(
          `subSections[${selectedSubSectionIndex}].sortableContents`,
          orderedSortableContents,
        );
        setSelectedContents([]);
        return;
      }
      newSortableContents.splice(result.destination.index, 0, {
        content,
        position: result.destination.index + 1,
      });
      const orderedSortableContents = newSortableContents.map(
        (sortableContent: SortableContent, index: number) => ({
          ...sortableContent,
          position: index + 1,
        }),
      );
      form.setFieldValue(
        `subSections[${selectedSubSectionIndex}].sortableContents`,
        orderedSortableContents,
      );
    }
    if (
      result.source.droppableId === 'highlightList' &&
      result.destination.droppableId === 'highlightList'
    ) {
      const reorderedHighlights = reorder(
        form.values!.sortableHighlightedContents!.sortableItems!,
        result.source.index,
        result.destination.index,
      ) as SortableHighlightedContents[];
      const newHighlights = reorderedHighlights.map((highlight, index) => ({
        ...highlight,
        position: index + 1,
      }));
      form.setFieldValue(
        'sortableHighlightedContents.sortableItems',
        newHighlights,
      );
    }
  };
  if (!permissionToUpdate) {
    return <HasNoPermission />;
  }
  if (loading || selectedSubSection === undefined) {
    return <div>loading...</div>;
  }
  const compareDistanceEditDate = formatDistance(
    parseISO(section!.updatedAt),
    parseISO(new Date().toISOString()),
    { locale: ptBR },
  );
  return (
    <Context.Provider
      value={{
        form,
        section: section as Section,
        selectedContents,
        setSelectedContents,
        selectedSubSection,
        setSelectedSubSection,
        selectableContents: selectableContents as Content[],
      }}
    >
      <Navbar route="/sections/order" title={section!.title} />
      <TipAlert sx={{ mt: 4 }}>
        Escolha até 10 conteúdos para importar simultaneamente via Spalla
        Streaming. Selecione-os abaixo, você poderá editar depois do envio.
      </TipAlert>
      <InlineTextEditor
        value={form.values.title!}
        TypographyProps={{ sx: { color: 'common.white', fontSize: 20 } }}
        sx={{ mt: 6 }}
        onChange={(title) => form.setFieldValue('title', title)}
      />
      <Grid container spacing={4} sx={{ mt: 0 }}>
        <Grid item xs={4}>
          <ScheduleCard
            name="scheduleDate"
            error={form.errors.scheduleDate}
            label="Agendar entrada?"
            disabled={!form.values.scheduleDateEnabled}
            value={form.values.scheduleDate}
            SwitchProps={{
              name: 'scheduleDateEnabled',
              checked: form.values.scheduleDateEnabled,
              onChange: form.handleChange,
            }}
            onChange={form.handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <ScheduleCard
            name="finishDate"
            error={form.errors.finishDate}
            label="Agendar saída?"
            disabled={!form.values.finishDateEnabled}
            value={form.values.finishDate}
            SwitchProps={{
              name: 'finishDateEnabled',
              checked: form.values.finishDateEnabled,
              onChange: form.handleChange,
            }}
            // @ts-ignore
            DateTimePickerProps={{ minDate: form.values.scheduleDate }}
            onChange={form.handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TogglerCard
            label="Seção ativa?"
            info="Se estiver ativa, sua seção estará visível para o público no momento da publicação ou data de agendamento."
            SwitchProps={{
              name: 'published',
              checked: form.values.published,
              onChange: form.handleChange,
            }}
            sx={{ height: '100%' }}
          />
        </Grid>
      </Grid>
      <DragDropContext onDragEnd={onDragEnd}>
        <Stack alignItems="center" direction="row" gap={4} sx={{ my: 4 }}>
          <SearchableContents sx={{ width: 667 }} />
          <SearchableHighlights />
        </Stack>
        <SliderEditor />
      </DragDropContext>
      <FormFooter
        LeftButtonProps={{
          children: 'Salvar alterações',
          type: 'submit',
          loading: isUpdating,
          onClick: () => form.handleSubmit(),
        }}
        helperText={`Última alteração: ${compareDistanceEditDate}`}
        RightButtonProps={{
          disabled: !permissionToDelete,
          children: permissionToDelete ? 'Excluir seção' : '',
          onClick: deleteSectionHandler,
        }}
        sx={{ mt: 4 }}
      />
    </Context.Provider>
  );
};

export default EditSection;
