import { Content as IContent } from './Content';
import { Section as ISection } from './Section';

export enum ItemType {
  Content = 'content',
  Section = 'section',
}

export interface HighlightSection {
  id: string;
  title: string;
  content: IContent;
  section: ISection;
  itemType: ItemType;
  scheduleDate: Date | null;
  finishDate: Date | null;
  thumbnail: string;
}
