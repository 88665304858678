import { OutlinedInput, Stack, Typography } from '@material-ui/core';

interface TextSettingProps {
  label: string;
  placeholder: string;
  required: boolean;
  value: string;
  onChange: (value: string) => void;
}

const TextSetting = ({
  label,
  placeholder,
  required,
  value,
  onChange,
}: TextSettingProps) => (
  <Stack>
    <Typography color="grey.300" fontSize={14}>
      {label}
      {required && (
        <Typography component="span" color="error">
          *
        </Typography>
      )}
    </Typography>
    <OutlinedInput
      placeholder={placeholder}
      value={value}
      sx={{ border: '1px solid', borderColor: 'grey.400', mt: 1 }}
      onChange={(evt) => onChange(evt.target.value)}
    />
  </Stack>
);

export default TextSetting;
