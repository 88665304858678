import * as yup from 'yup';

const validationSchema = yup.object().shape({
  title: yup.string().required().max(160),
  blocks: yup.object().required(),
  type: yup.string().required(),
  published: yup.boolean().required(),
  scheduleDate: yup.mixed().when('scheduleDateEnabled', {
    is: true,
    then: yup.date().required('scheduleDateEnabled'),
  }),
  finishDate: yup.mixed().when('finishDateEnabled', {
    is: true,
    then: yup.date().required('finishDateEnabled'),
  }),
  seoTitle: yup.string().max(160),
  seoDescription: yup.string().max(160),
  seoKeywords: yup.array().of(yup.string().max(160)),
  scripts: yup.array().of(yup.string().max(160))
});

export default validationSchema;
