import { Button, Tooltip, Typography, Switch } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { useContext, useState } from 'react';
import Navbar from '../../../components/UI/molecules/Navbar';
import { Section } from '../../../interfaces/Section';
import { SortableHighlightedContents } from '../../../interfaces/Highlight';
import Context from '../Context';
import { sortThePositions } from '../tools/sortThePositions';

interface HeaderProps {
  route: string;
  title: string;
  isEditing?: boolean;
}

const Header = ({ route, title, isEditing = false }: HeaderProps) => {
  const [copied, setCopied] = useState(false);
  const [pasted, setPasted] = useState(false);
  const { form } = useContext(Context);
  const copySectionAndHighlights = () => {
    localStorage.setItem(
      'highlights',
      JSON.stringify(form.values.sortableHighlightedContents),
    );
    localStorage.setItem('sections', JSON.stringify(form.values.sections));
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 4000);
  };
  const pasteSectionsAndHighlight = () => {
    const sections = JSON.parse(
      localStorage.getItem('sections') || '[]',
    ) as Section[];
    const highlights = JSON.parse(
      localStorage.getItem('highlights') || '[]',
    ) as SortableHighlightedContents[];
    const reorderedHighlight = sortThePositions(highlights);
    form.setFieldValue('sections', sections);
    form.setFieldValue('sortableHighlightedContents', reorderedHighlight);
    setPasted(true);
    setTimeout(() => {
      localStorage.removeItem('sections');
      localStorage.removeItem('highlights');
      setPasted(false);
    }, 4000);
  };
  return (
    <Navbar route={route} title={title}>
      <Button
        endIcon={
          <Tooltip title="Copie as informações referentes às Seções e Destaques.">
            <InfoIcon fontSize="small" sx={{ color: 'grey.100' }} />
          </Tooltip>
        }
        color={copied ? 'success' : 'primary'}
        sx={{ mr: 2 }}
        onClick={copySectionAndHighlights}
      >
        {copied ? 'Copiado' : 'Copiar dados'}
      </Button>
      <Button
        disabled={
          !localStorage.getItem('highlights') ||
          !localStorage.getItem('sections')
        }
        endIcon={
          <Tooltip title="Cole as informações referentes às Seções e Destaques.">
            <InfoIcon fontSize="small" sx={{ color: 'grey.100' }} />
          </Tooltip>
        }
        sx={{
          '&:disabled': {
            color: 'grey.400',
          },
        }}
        color={pasted ? 'success' : 'primary'}
        onClick={pasteSectionsAndHighlight}
      >
        {pasted ? 'Dados colados' : 'Colar dados'}
      </Button>
      {isEditing && (
        <>
          <Typography sx={{ color: 'grey.100', ml: 2 }}>Plano ativo</Typography>
          <Switch
            name="published"
            checked={form.values.published}
            onClick={form.handleChange}
            sx={{ ml: 2 }}
          />
        </>
      )}
    </Navbar>
  );
};

export default Header;
