import { useQuery } from '@apollo/client';
import {
  Avatar,
  Button,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { ChangeEvent, useState } from 'react';
import { useDialog } from '../../../../../../../components/Dialog';
import { normalizeString } from '../../../../../../../utils/string';
import contentsQuery from '../../../../../gql/contentsQuery';
import { Content, VideoModalProps } from './typings';
import { SortBy } from '../../../../../../../typings/sort';
import { sortByNewest, sortByOldest } from '../../../../utils/sort';

const VideoModal = ({ onChange }: VideoModalProps) => {
  const dialog = useDialog();
  const { loading, data } = useQuery<{ contents: Content[] }>(contentsQuery);
  const [query, setQuery] = useState('');
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.Newest);
  const changeQueryHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setQuery(evt.target.value);
  if (loading) return null;
  const contents = data!.contents.filter((content: any) =>
    normalizeString(content.title).includes(normalizeString(query)),
  );
  const sortByHandler = (evt: any) => setSortBy(evt.target.value);
  const sortedContents = [...contents].sort(
    sortBy === SortBy.Oldest ? sortByOldest : sortByNewest,
  );
  const changeVideoHandler = (thumbnail: string, id: string) => {
    onChange(thumbnail, id);
    dialog.close();
  };
  return (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: 'secondary.main' }} />
            </InputAdornment>
          }
          fullWidth
          placeholder="Pesquisar vídeos"
          value={query}
          sx={{ mr: 4, width: 385 }}
          onChange={changeQueryHandler}
        />
        <Select value={sortBy} sx={{ minWidth: 201 }} onChange={sortByHandler}>
          <MenuItem value={SortBy.Newest}>Mais recentes</MenuItem>
          <MenuItem value={SortBy.Oldest}>Mais antigos</MenuItem>
        </Select>
      </Stack>
      {sortedContents.map((content) => (
        <ListItem sx={{ height: 74, p: 0, width: '100%' }}>
          <ListItemButton key={content.id} dense sx={{ pl: 0 }}>
            <ListItemAvatar>
              <Avatar
                alt={content.title}
                src={content.thumbnail}
                variant="rounded"
                sx={{ width: 74, height: 42 }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={content.title}
              primaryTypographyProps={{
                color: 'grey.100',
                fontSize: 14,
                fontWeight: 600,
              }}
              secondary={
                <>
                  <Typography
                    sx={{
                      color: 'info.main',
                      display: 'inline',
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >
                    On demand
                  </Typography>
                  <Typography
                    sx={{
                      color: 'grey.300',
                      display: 'inline-block',
                      fontSize: 12,
                      ml: 3,
                    }}
                  >
                    {content.tags[0]}
                  </Typography>
                </>
              }
              sx={{ ml: 3 }}
            />
          </ListItemButton>
          <Button
            sx={{ fontSize: 12, fontWeight: 500, height: 30, minWidth: 80 }}
            onClick={() =>
              changeVideoHandler(content.thumbnail, content.spallaVodId)
            }
          >
            Adicionar
          </Button>
        </ListItem>
      ))}
    </>
  );
};

export default VideoModal;
