import { SortByDirection } from "../../typings/sort";

export enum SearchBy {
  informations = 'informations',
  spectators = 'spectators',
}

export interface SortByType {
  direction: SortByDirection;
  prop: any;
}

interface Actions {
  startedAt: Date;
  finishedAt: Date;
}
interface PlansLinkedByDiscountId {
  id: string;
  title: string;
  actions: Actions;
}

export interface DiscountAnalyse {
  plansLinkedByDiscountId: PlansLinkedByDiscountId[];
  spectatorsActivies: number;
  viewsDiscountRetentions: number;
  spectatorsWithActiveRetention: number;
  notUtilizedsDiscounts: number;
  durationAvg: string;
}
