import { useMutation } from '@apollo/client';
import { Stack } from '@material-ui/core';
import { memo } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Section } from '../../../interfaces/Section';
import reorder from '../../../utils/reorderList';
import DraggableSectionListItem from './DraggableSectionListItem';
import sortSectionsMutation from '../gql/sortSectionsMutation';

type Props = { sections: Section[]; refetch: () => void };

const MemoizedSectionList = memo(({ sections }: { sections: Section[] }) => (
  <>
    {sections.map((section: Section, index: number) => (
      <DraggableSectionListItem
        key={section.id}
        index={index}
        section={section}
      />
    ))}
  </>
));

const SectionList = ({ sections, refetch }: Props) => {
  const [sortSections] = useMutation(sortSectionsMutation);
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const newSections = reorder(
      sections,
      result.source.index,
      result.destination.index,
    );
    const items = newSections.map((item) => ({
      id: item.id,
      type: !item.generalSubSection ? 'multiSection' : 'section',
    }));
    await sortSections({ variables: { input: { items } } });
    refetch();
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <Stack
            ref={provided.innerRef}
            spacing={4}
            sx={{ mt: 4 }}
            {...provided.droppableProps}
          >
            <MemoizedSectionList sections={sections || []} />
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SectionList;
