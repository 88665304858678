// TODO: Remove when Craft.js support settings props without param reassign
/* eslint-disable no-param-reassign */
import { useNode } from '@craftjs/core';
import { MenuItem, Select, SelectChangeEvent, Typography } from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  RadioButtonUncheckedRounded as RadioButtonUncheckedRoundedIcon,
} from '@material-ui/icons';
import { useContext, useState } from 'react';
import { Page } from '../../../../../../../interfaces/Plan';
import Context from '../../../../context';
import ColorPicker from '../../components/ColorPicker';
import SidebarAccordion from '../../components/SidebarAccordion';
import TextSetting from '../../components/TextSetting';
import { buttonOptions as options, selectStyles } from './utils';
import { NodePropsType } from './typings'

interface ButtonProps {
  bgcolor?: string;
  color?: string;
  alignment?: string;
  href?: string;
  label?: string;
  type?: string;
}

const ButtonsSettings = () => {
  const { pages } = useContext(Context);
  const [expanded, setExpanded] = useState(true);
  const {
    actions: { setProp },
    props: { bgcolor, color, alignment, href, label, type },
  }: NodePropsType<ButtonProps> = useNode((node) => ({ props: node.data.props }));
  const alignmentChangeHandler = (evt: SelectChangeEvent<string>) => {
    setProp((currentProps) => { currentProps.alignment = evt.target.value })
  };
  const bgColorChangeHandler = (currentColor: string) => {
    setProp((currentProps) => { currentProps.bgcolor = currentColor });
  };
  const colorChangeHandler = (currentColor: string) => {
    setProp((currentProps) => { currentProps.color = currentColor })
  };
  const hrefChangeHandler = (currentHref: string) => {
    setProp((currentProps) => { currentProps.href = currentHref })
  };
  const labelChangeHandler = (currentLabel: string) => {
    setProp((currentProps) => { currentProps.label = currentLabel })
  };
  const typeChangeHandler = (evt: SelectChangeEvent<string>) => {
    setProp((currentProps) => { currentProps.type = evt.target.value })
  };
  return (
    <SidebarAccordion
      expanded={expanded}
      icon={
        <RadioButtonUncheckedRoundedIcon
          sx={{
            color: 'secondary.main',
            height: 24,
            width: 24,
          }}
        />
      }
      title="Opções de botão"
      onExpand={() => setExpanded(!expanded)}
    >
      <TextSetting
        label="Texto do botão"
        placeholder="Assine aqui..."
        required
        value={label || ''}
        onChange={labelChangeHandler}
      />
      <Typography color="grey.300" fontSize={14}>
        Alinhar botão
      </Typography>
      <Select
        displayEmpty
        IconComponent={ExpandMoreIcon}
        sx={{
          backgroundColor: 'grey.500',
          border: 1,
          borderColor: 'grey.400',
          color: 'grey.300',
          width: 222,
          '.MuiSelect-icon': { color: 'secondary.main' },
        }}
        value={alignment || ''}
        onChange={alignmentChangeHandler}
      >
        <MenuItem key="flex-start" value="flex-start">
          Esquerda
        </MenuItem>
        <MenuItem key="center" value="center">
          Centro
        </MenuItem>
        <MenuItem key="flex-end" value="flex-end">
          Direita
        </MenuItem>
      </Select>
      <Typography sx={{ color: 'grey.300', fontSize: 14, fontWeight: 400 }}>
        Tipo de link{' '}
        <Typography component="span" sx={{ color: 'error.main', fontSize: 14 }}>
          *
        </Typography>
      </Typography>
      <Select
        IconComponent={ExpandMoreIcon}
        sx={selectStyles}
        value={type}
        onChange={typeChangeHandler}
      >
        {options.map((option) => (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {type === 'Link externo' ? (
        <TextSetting
          label="Link do botão"
          value={href || ''}
          required
          placeholder="https://music.youtube.com..."
          onChange={hrefChangeHandler}
        />
      ) : null}
      {type === 'Página já existente' ? (
        <>
          <Typography sx={{ color: 'grey.300', fontSize: 14, fontWeight: 400 }}>
            Escolha a página
          </Typography>
          <Select
            IconComponent={ExpandMoreIcon}
            value={href}
            sx={selectStyles}
            onChange={(e) => hrefChangeHandler(e.target.value)}
          >
            {pages.map((page: Page) => (
              <MenuItem key={page.id} value={page.id}>
                {page.title}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : null}
      {type === 'Desconto de retenção' ? (
        <>
          <Typography sx={{ color: 'grey.300', fontSize: 14, fontWeight: 400 }}>
            Escolha o plano
          </Typography>
          <Select
            IconComponent={ExpandMoreIcon}
            value={href}
            sx={selectStyles}
            onChange={(e) => hrefChangeHandler(e.target.value)}
          >
            {options.map((option) => (
              <MenuItem key={option} value="#">
                Plano paulistão play
              </MenuItem>
            ))}
          </Select>
        </>
      ) : null}
      <ColorPicker
        color={color || ''}
        name="Cor do texto"
        onChange={colorChangeHandler}
      />
      <ColorPicker
        color={bgcolor || ''}
        name="Cor de fundo do botão"
        onChange={bgColorChangeHandler}
      />
    </SidebarAccordion>
  );
};

export default ButtonsSettings;
