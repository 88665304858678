import { gql } from '@apollo/client';

const updateContentMutation = gql`
  mutation UpdateContent($input: UpdateContentInput!) {
    updateContent(input: $input) {
      id
      title
      description
      authorizedOnMobile
      authorizedOnDesktop
      authorizedOnTv
      scheduleDate
      finishDate
      type
      thumbnail
      published
      chatEnable
      chatEmbed
      teams {
        id
        name
        slug
      }
    }
  }
`;

export default updateContentMutation;
