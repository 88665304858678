
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';

interface SettingsAccordionProps {
  children: React.ReactNode;
  expanded: boolean;
  title: string;
  onExpand: () => void;
}

const SettingsAccordion = ({
  children,
  expanded,
  title,
  onExpand,
}: SettingsAccordionProps) => (
  <Accordion
    expanded={expanded}
    sx={{
      backgroundColor: 'grey.600',
      borderRadius: 2,
      color: 'common.white',
      px: 3,
    }}
  >
    <AccordionSummary
      expandIcon={
        <ExpandMoreIcon
          sx={{
            backgroundColor: 'grey.500',
            borderRadius: 1,
            color: 'common.white',
            height: 40,
            width: 48,
          }}
        />
      }
      sx={{ height: 72 }}
      onClick={() => onExpand()}
    >
      <Typography sx={{ color: 'common.white', fontSize: 16, fontWeight: 600 }}>
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ pb: 5 }}>{children}</AccordionDetails>
  </Accordion>
);

export default SettingsAccordion;
