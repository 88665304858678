import { Avatar, Box, Stack, Typography } from '@material-ui/core';
import { DragIndicator as DragIndicatorIcon } from '@material-ui/icons';
import { Draggable } from 'react-beautiful-dnd';
import { SortableHighlightedContents } from '../../../interfaces/Highlight';

interface SearchableHighlightsItemProps {
  highlight: SortableHighlightedContents;
  index: number;
}

const SearchableHighlightsItem = ({
  highlight,
  index,
}: SearchableHighlightsItemProps) => (
  <Draggable draggableId={highlight.highlightedContent.id} index={index}>
    {(provided) => (
      <Box
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <Stack
          alignItems="center"
          direction="row"
          gap={3}
          sx={{
            bgcolor: 'grey.600',
            borderRadius: 2,
            height: 74,
            p: 4,
          }}
        >
          <DragIndicatorIcon
            sx={{
              cursor: 'grab',
              color: 'grey.400',
              height: 28,
              width: 28,
            }}
          />
          <Avatar
            src={highlight.highlightedContent.fullHdLandscape}
            variant="rounded"
            sx={{ height: 42, width: 74 }}
          />
          <Typography
            sx={{
              color: 'grey.100',
              fontWeight: 600,
              maxWidth: 250,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {highlight.highlightedContent.title}
          </Typography>
        </Stack>
      </Box>
    )}
  </Draggable>
);

export default SearchableHighlightsItem;
