import { useState } from 'react';
import Topbar from '../../components/Topbar';
import { DiscountType } from '../../interfaces/Discount';
import Container from './Components/Container';
import Filter from './Components/Filter';
import Context from './Context';

const FinancialDiscountReport = () => {
  const [discountType, setDiscountType] = useState(DiscountType.Internal);
  return (
    <Context.Provider
      value={{
        discountType,
        setDiscountType,
      }}
    >
      <Topbar
        title="Relatórios de desconto"
        description="Tenha acesso a todas as informações referentes aos descontos/cupons criados."
      />
      <Filter />
      <Container />
    </Context.Provider>
  );
};

export default FinancialDiscountReport;
