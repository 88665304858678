import { Box } from '@material-ui/core';
import { ReactNode } from 'react';
import Slider from 'react-slick';

const SlideBase = ({ children }: { children: ReactNode }) => (
  <Slider
    dots={false}
    infinite={false}
    speed={500}
    slidesToShow={5}
    slidesToScroll={1}
    variableWidth
    prevArrow={<Box sx={{ height: '100%', left: 0, top: 0, width: 54 }} />}
    nextArrow={<Box sx={{ height: '100%', right: 0, top: 0, width: 54 }} />}
  >
    {children}
  </Slider>
);

export default SlideBase;
