import { createContext } from 'react';
import { SortableLiveContents } from './typings';

interface ContextType {
  setEventDate: (date: Date) => void;
  sortableContents: SortableLiveContents[];
  setSortableContents: (sortableContents: SortableLiveContents[]) => void;
}

const Context = createContext({} as ContextType);

export default Context;
