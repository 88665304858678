import { Box, Grid, Stack } from '@material-ui/core';
import { Outlet } from 'react-router-dom';
import editPaulistao from '../../assets/png/editPaulistao.png';
import homePaulistao from '../../assets/png/homePaulistao.png';
import logoSpalla from '../../assets/png/logoSpalla.png';

const AuthTemplate = () => (
  <Grid container sx={{ height: '100vh', width: '100%' }}>
    <Grid item lg={5} xl={5} sx={{ bgcolor: 'grey.500' }}>
      <Outlet />
    </Grid>
    <Stack
      item
      component={Grid}
      lg={7}
      xl={7}
      sx={{
        alignItems: 'center',
        bgcolor: 'secondary.main',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${logoSpalla})`,
          height: 52,
          width: 177,
        }}
      />
      <Box sx={{ height: 373, mt: 10, position: 'relative', width: 667 }}>
        <Box
          sx={{
            backgroundImage: `url(${editPaulistao})`,
            borderRadius: 2,
            bottom: 0,
            height: 275,
            position: 'absolute',
            width: 444,
          }}
        />
        <Box
          sx={{
            backgroundImage: `url(${homePaulistao})`,
            borderRadius: 2,
            height: 312,
            position: 'absolute',
            right: 0,
            width: 483,
          }}
        />
      </Box>
    </Stack>
  </Grid>
);

export default AuthTemplate;
