import { DiscountType } from '../../../interfaces/Discount';

const getFriendlyNameByDiscountType = (type: DiscountType) => {
  switch (type) {
    case DiscountType.Internal:
      return 'Desconto Interno';
    case DiscountType.Collective:
      return 'Desconto Coletivo';
    case DiscountType.Retention:
      return 'Desconto De Retenção';
    default:
      return 'Desconto Externo';
  }
};

export default getFriendlyNameByDiscountType;
