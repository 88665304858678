import { useQuery } from '@apollo/client';
import { Timeline } from '@material-ui/lab';
import SpectatorHistoryCard from './SpectatorHistoryCard';
import userPlansHistories from '../gql/getHistoryQuery';
import { useSpectator } from '../Context';

const SpectatorHistory = () => {
  const { selectedSpectator } = useSpectator();
  const { loading, data } = useQuery(userPlansHistories, {
    variables: { id: selectedSpectator?.id },
  });
  if (loading) return <>Carregando...</>;
  const historys = data?.userPlansHistories;
  return (
    <>
      <Timeline
        sx={{
          alignSelf: 'flex-start',
          mt: 5,
          p: 0,
          width: '100%',
        }}
      >
        {historys!.map((history: any, index: number) => (
          <SpectatorHistoryCard
            history={history}
            isLastHistory={historys?.length - 1 === index}
          />
        ))}
      </Timeline>
    </>
  );
};

export default SpectatorHistory;
