import { useMutation } from '@apollo/client';
import { ChangeEvent } from '@craftjs/utils/node_modules/@types/react';
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  OutlinedInput,
  Stack,
  Switch,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import SmsTwoToneIcon from '@material-ui/icons/SmsTwoTone';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDialog } from '../../../components/Dialog';
import EmptyStateCard from '../../../components/EmptyStateCard';
import SearchBar from '../../../components/UI/molecules/SearchBar';
import { Tag } from '../../../interfaces/Tag';
import { compareTerms } from '../../../utils/string';
import createQuestionMutation from '../gql/createQuestionMutation';
import updateQuestionMutation from '../gql/updateQuestionMutation';
import { Question } from '../typings';
import validationSchema from '../validationSchema';

type CreateQuestionInput = Pick<
  Question,
  'title' | 'description' | 'answersEnabled' | 'published' | 'position' | 'tags' | 'required'
>;

const QuestionDetails = ({
  question,
  tags: clientTags,
}: {
  question: Question;
  tags: Tag[];
}) => {
  const alert = useAlert();
  const dialog = useDialog();
  const [search, setSearch] = useState('');
  const [createQuestion] = useMutation(createQuestionMutation);
  const [updateQuestion] = useMutation(updateQuestionMutation);
  const searchHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setSearch(evt.target.value);
  const filteredTags = search
    ? clientTags.filter((tag) => compareTerms(tag.name, search))
    : clientTags;
  const form = useFormik<CreateQuestionInput>({
    initialValues: {
      title: question.title,
      description: question.description,
      published: question.published,
      answersEnabled: question.answersEnabled,
      required: question.required,
      position: question.position,
      tags: question.tags,
    },
    validationSchema,
    onSubmit: async (values) => {
      const { tags } = values;
      const input = validationSchema.cast(values);
      if (question.id) {
        const { data } = await updateQuestion({
          variables: { input: { ...input, id: question.id,} },
        });
        alert.success('Alterações salvas com sucesso.');
        dialog.close({ ...data.updateQuestion, tags });
        return;
      }
      const { data } = await createQuestion({ variables: { input } });
      alert.success('Alterações salvas com sucesso.');
      dialog.close({ ...data.createQuestion, tags });
    },
  });
  const toggleTagsHandler = (tag: Tag) => {
    const tagIndex = form.values.tags.findIndex(
      (currentTag: Tag) => currentTag?.id === tag.id,
    );
    const tagNotExists = tagIndex !== -1;
    if (tagNotExists) {
      const newTagsSelecteds = form.values.tags.filter(
        (currentTag: Tag) => currentTag.id !== tag.id,
      );
      form.setFieldValue('tags', newTagsSelecteds);
      return;
    }
    form.setFieldValue('tags', [...form.values.tags, tag]);
  };
  const isTagSelected = (tag: Tag) => {
    const tagIndex = form.values.tags.findIndex(
      (currentTag: Tag) => currentTag.id === tag.id,
    );
    const isSelected = tagIndex !== -1;
    return isSelected;
  };
  const setAllTagsHandler = () => {
    const allTags = form.values.tags.length === clientTags.length;
    if (allTags) {
      form.setFieldValue('tags', []);
    } else {
      form.setFieldValue('tags', clientTags);
    }
  };
  const questionOrStepTitle = form.values.answersEnabled ? 'Pergunta' : 'Passo';
  const title = form.values.title
    ? form.values.title
    : `Novo(a) ${questionOrStepTitle}`;
  const titleInputLabel = `Título do(a) ${questionOrStepTitle}`;
  const descriptionInputLabel = `Descrição do(a) ${questionOrStepTitle}`;
  const publishedSwitchLabel = `${questionOrStepTitle} ativo(a)`;
  const buttonText = question.id
    ? 'Salvar Alterações'
    : `Criar ${questionOrStepTitle}`;
  const allTags = form.values.tags.length === clientTags.length;
  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      sx={{ overflowY: 'hidden' }}
    >
      <Stack
        sx={{
          bgcolor: 'grey.500',
          height: '100%',
          left: 1,
          position: 'absolute',
          pl: 6,
          pr: 7,
          pt: 6,
          top: 0,
        }}
      >
        <Typography
          sx={{
            color: 'common.white',
            fontSize: 16,
            fontWeight: 600,
            mb: 7,
            mt: 1,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ color: 'grey.300', fontSize: 14, fontWeight: 400, mb: 1 }}
        >
          {titleInputLabel}
          <Typography
            component="span"
            sx={{ color: 'error.main', fontSize: 14 }}
          >
            *
          </Typography>
        </Typography>
        <OutlinedInput
          name="title"
          placeholder="Faça uma pergunta aqui"
          value={form.values.title}
          error={!!form.errors.title}
          sx={{
            border: 1,
            borderColor: 'grey.400',
            color: 'grey.300',
            fontSize: 14,
            width: 385,
          }}
          onChange={form.handleChange}
        />
        <FormHelperText error>{form.errors.title}</FormHelperText>
        <Typography
          sx={{
            color: 'grey.300',
            fontSize: 14,
            fontWeight: 400,
            mb: 1,
            mt: 5,
          }}
        >
          {descriptionInputLabel}
          <Typography
            component="span"
            sx={{ color: 'error.main', fontSize: 14 }}
          >
            *
          </Typography>
        </Typography>
        <OutlinedInput
          name="description"
          multiline
          placeholder="Qual o time que você torce?"
          rows={4}
          value={form.values.description}
          error={!!form.errors.description}
          sx={{
            border: 1,
            borderColor: 'grey.400',
            borderRadius: 1,
            color: 'grey.300',
            fontSize: 14,
            height: 97,
            overflow: 'initial',
            padding: 3,
            width: 385,
          }}
          onChange={form.handleChange}
        />
        <FormHelperText error sx={{ mb: 4 }}>
          {form.errors.description}
        </FormHelperText>
        <Stack
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          sx={{ bottom: 16, left: 32, position: 'absolute', right: 40 }}
        >
          <Button
            sx={{ height: 44, width: 160 }}
            onClick={() => form.handleSubmit()}
          >
            {buttonText}
          </Button>
          <Switch
            checked={form.values.published}
            name="published"
            onChange={form.handleChange}
            sx={{ ml: 'auto' }}
          />
          <Typography sx={{ color: 'grey.300', fontSize: 12, ml: 2 }}>
            {publishedSwitchLabel}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        sx={{
          bgcolor: 'primary.main',
          height: '100%',
          position: 'absolute',
          pt: 6,
          pr: 6,
          right: 1,
          top: 0,
          width: 386,
        }}
      >
        <Stack alignItems="center" flexDirection="row" sx={{ ml: 4 }}>
          <Typography
            sx={{ color: 'common.white', fontSize: 16, fontWeight: 600, mr: 3 }}
          >
            Respostas
          </Typography>
          <Switch
            checked={form.values.answersEnabled}
            name="answersEnabled"
            onChange={form.handleChange}
          />
        </Stack>
        {!form.values.answersEnabled ? (
          <EmptyStateCard
            description="Este passo do onboarding vai ser apenas informativo, seu espectador não terá nenhuma escolha de resposta."
            icon={<SmsTwoToneIcon sx={{ color: 'info.main', fontSize: 50 }} />}
            title="Você desativou as respostas"
            sx={{ boxShadow: 'none', p: 2, width: 386 }}
          />
        ) : (
          <Stack sx={{ overflowY: 'hidden', px: 4, width: 386 }}>
            <Stack>
              <Typography
                sx={{
                  color: 'grey.300',
                  fontSize: 14,
                  fontWeight: 400,
                  mb: 1,
                  // ml: 4,
                  mt: 5,
                }}
              >
                Todas as respostas para essa pergunta são tags previamente
                criadas na área do painel de Tags e Categorias.
              </Typography>
              <SearchBar
                TextFieldProps={{
                  placeholder: 'Procure aqui por uma tag específica',
                  value: search,
                  onChange: searchHandler,
                  helperText: form.errors.tags || '',
                  error: !!form.errors.tags,
                }}
                sx={{ boxShadow: 'none', mt: 2 }}
                CardContentProps={{ sx: { p: 0 } }}
              />
              <PerfectScrollbar style={{ height: '20vh' }}>
                {filteredTags.map((tag: Tag) => (
                  <List data-testid="list-plans">
                    <ListItem sx={{ px: 0, py: 5 }}>
                      <ListItemText
                        primary={tag.name}
                        sx={{
                          color: 'common.white',
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      />
                      <ListItemSecondaryAction sx={{ mr: 2 }}>
                        <IconButton edge="end" aria-label="comments">
                          <Button
                            color={isTagSelected(tag) ? 'success' : 'primary'}
                            variant="outlined"
                            onClick={() => toggleTagsHandler(tag)}
                          >
                            {isTagSelected(tag) ? 'Adicionado' : 'Adicionar'}
                          </Button>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                ))}
              </PerfectScrollbar>
            </Stack>
          </Stack>
        )}
        <Button
          fullWidth
          sx={{
            bgcolor: allTags ? 'error.main' : 'secondary.main',
            minHeight: 44,
            mb: 4,
            mt: 'auto',
            mx: 4,
          }}
          onClick={() => setAllTagsHandler()}
        >
          {allTags ? 'Remover todos' : 'Adicionar todos'}
        </Button>
      </Stack>
    </Stack>
  );
};

export default QuestionDetails;
