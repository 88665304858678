import { Add as AddIcon } from '@material-ui/icons';
import CustomDragLayer from './CustomDragLayer';

const SingleContainerBlockDragLayer = () => (
  <CustomDragLayer height={80} type="SingleContainerBlock">
    <AddIcon sx={{ color: 'info.main', fontSize: 56 }} />
  </CustomDragLayer>
);

export default SingleContainerBlockDragLayer;
