import { TableCell, TableSortLabel } from '@material-ui/core';
import { useState } from 'react';
import { SortByDirection } from '../../../typings/sort';
import { SortByType } from '../typings';

interface DiscountProps {
  title: string;
  prop: SortByType['prop'];
  onSortHead?: (sortByType: SortByType) => void;
}

const Head = ({ title, prop, onSortHead }: DiscountProps) => {
  const [active, setActive] = useState(false);
  const toggleHeaderHandler = () => {
    setActive((prevState) => !prevState);
    onSortHead?.({
      direction: active ? SortByDirection.Asc : SortByDirection.Desc,
      prop,
    });
  };
  return (
    <TableCell
      sx={{ color: 'grey.100', ml: 10, p: 0, width: 150 }}
      onClick={toggleHeaderHandler}
    >
      <TableSortLabel
        active
        direction={active ? 'asc' : 'desc'}
        sx={{
          path: { color: 'grey.400' },
          '&.MuiButtonBase-root': { color: 'grey.100', fontWeight: 600 },
        }}
      >
        {title}
      </TableSortLabel>
    </TableCell>
  );
};

export default Head;
