import { useContext, useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  AccountBox as AccountIcon,
  Add as AddIcon,
  ExpandMore as ExpandMoreIcon,
  Favorite as HeartTeamIcon,
  LightModeRounded as LightModeIcon,
  Group as ManagersIcon,
  List as SectionsIcon,
  LocalOffer as TagsIcon,
  MoneyOff as MoneyIcon,
  PlayArrow as PlayArrowIcon,
  Person as ManagerIcon,
  QuestionAnswer as QuestionsIcon,
  Summarize as Reports,
  Star as StarIcon,
  Tv as TvIcon,
  VpnLock as RoleIcon,
  Web as WebIcon,
  WebAsset as WebAssetIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Context from '../../Context';
import hasAuthorization from '../../utils/hasAuthorization';

const menuItems = [
  {
    icon: <PlayArrowIcon sx={{ color: 'secondary.main' }} />,
    title: 'Conteúdos',
    feature: 'contents',
    roles: ['read'],
    subItems: [
      {
        icon: <TvIcon sx={{ color: 'secondary.light' }} />,
        title: 'Biblioteca',
        href: '/library',
        feature: 'contents',
        roles: ['read'],
      },
    ],
  },
  {
    icon: <LightModeIcon sx={{ color: 'secondary.main' }} />,
    title: 'Destaques',
    href: '/highlights',
  },
  {
    icon: <WebIcon sx={{ color: 'secondary.main' }} />,
    title: 'Páginas HTML',
    feature: 'pages',
    roles: ['read', 'create'],
    subItems: [
      {
        icon: <WebAssetIcon sx={{ color: 'secondary.light' }} />,
        title: 'Minhas páginas',
        href: '/pages',
        feature: 'pages',
        roles: ['read'],
      },
      {
        icon: <AddIcon sx={{ color: 'secondary.light' }} />,
        title: 'Nova página',
        href: '/pages/builder',
        feature: 'pages',
        roles: ['create'],
      },
    ],
  },
  {
    icon: <QuestionsIcon sx={{ color: 'secondary.main' }} />,
    title: 'Perguntas de Cadastro',
    href: '/plans/onboarding',
    feature: 'questions',
    roles: ['read'],
  },
  {
    icon: <SectionsIcon sx={{ color: 'secondary.main' }} />,
    title: 'Seções',
    feature: 'sections',
    roles: ['read'],
    subItems: [
      {
        icon: <AddIcon sx={{ color: 'secondary.light' }} />,
        title: 'Seções',
        href: '/sections/order',
        feature: 'sections',
        roles: ['read'],
      },
      {
        icon: <AddIcon sx={{ color: 'secondary.light' }} />,
        title: 'Super seção',
        href: '/section/highlights',
        feature: 'sections',
        roles: ['read'],
      },
    ],
  },
  {
    icon: <TagsIcon sx={{ color: 'secondary.main' }} />,
    title: 'Tags & Categorias',
    href: '/categories',
    feature: 'categoriesAndTags',
    roles: ['read'],
  },
  {
    icon: <StarIcon sx={{ color: 'secondary.main' }} />,
    title: 'Planos',
    href: '/plans/overview',
    feature: 'plans',
    roles: ['read'],
    subItems: [
      {
        icon: <AddIcon sx={{ color: 'secondary.light' }} />,
        title: 'Plano mensal',
        href: '/plans?type=monthly',
        feature: 'plans',
        roles: ['read'],
      },
      {
        icon: <AddIcon sx={{ color: 'secondary.light' }} />,
        title: 'Plano temporada',
        href: '/plans?type=season',
        feature: 'plans',
        roles: ['read'],
      },
      {
        icon: <AddIcon sx={{ color: 'secondary.light' }} />,
        title: 'Plano parceiros',
        href: '/plans?type=partner',
        feature: 'plans',
        roles: ['read'],
      },
      {
        icon: <AddIcon sx={{ color: 'secondary.light' }} />,
        title: 'Plano grátis',
        href: '/plans?type=free',
        feature: 'plans',
        roles: ['read'],
      },
    ],
  },
  {
    icon: <ManagersIcon sx={{ color: 'secondary.main' }} />,
    title: 'Gestores',
    feature: 'managers',
    roles: ['read'],
    subItems: [
      {
        icon: <ManagerIcon sx={{ color: 'secondary.light' }} />,
        title: 'Lista de gestores',
        href: '/managers',
        feature: 'managers',
        roles: ['read'],
      },
      {
        icon: <RoleIcon sx={{ color: 'secondary.light' }} />,
        title: 'Cargos',
        href: '/roles',
        feature: 'roles',
        roles: ['read'],
      },
    ],
  },
  {
    icon: <AccountIcon sx={{ color: 'secondary.main' }} />,
    title: 'Espectadores',
    href: '/spectators',
  },
  {
    icon: <MoneyIcon sx={{ color: 'secondary.main' }} />,
    title: 'Descontos',
    feature: 'discount',
    roles: ['read'],
    subItems: [
      {
        icon: <AddIcon sx={{ color: 'secondary.light' }} />,
        title: 'Desconto interno',
        href: '/discounts?type=internal',
        feature: 'discounts',
        roles: ['read'],
      },
      {
        icon: <AddIcon sx={{ color: 'secondary.light' }} />,
        title: 'Desconto externo',
        href: '/discounts?type=collective',
        feature: 'discounts',
        roles: ['read'],
      },
      {
        icon: <AddIcon sx={{ color: 'secondary.light' }} />,
        title: 'Desconto retenção',
        href: '/discounts?type=retention',
        feature: 'discounts',
        roles: ['read'],
      },
      {
        icon: <AddIcon sx={{ color: 'secondary.light' }} />,
        title: 'Cupom individual',
        href: '/discounts?type=coupon',
        feature: 'discounts',
        roles: ['read'],
      },
    ],
  },
  {
    icon: <HeartTeamIcon sx={{ color: 'secondary.main' }} />,
    title: 'Time do coração',
    href: '/teams',
  },
  {
    icon: <Reports sx={{ color: 'secondary.main' }} />,
    title: 'Relatórios',
    feature: 'report',
    roles: ['read'],
    subItems: [
      {
        icon: <AddIcon sx={{ color: 'secondary.light' }} />,
        title: 'Relatório de Descontos',
        href: '/discount/report/',
        feature: 'report',
        roles: ['read'],
      },
      {
        icon: <AddIcon sx={{ color: 'secondary.light' }} />,
        title: 'Relatório financeiro',
        href: '/financial/report',
        feature: 'report',
        roles: ['read'],
      },
      {
        icon: <AddIcon sx={{ color: 'secondary.light' }} />,
        title: 'Download de relatórios',
        href: '/download/report',
        feature: 'report',
        roles: ['read'],
      },
    ],
  },
];

const MenuItems = () => {
  const { me } = useContext(Context);
  const verifyPermissionFeature = useMemo(
    () => (featureName: string, roles: any[]) => {
      const { superAdmin, role } = me;
      if (superAdmin) {
        return true;
      }
      const hasNoPermissionRoles = featureName === 'roles';
      if (hasNoPermissionRoles) {
        return false;
      }
      const hasPermission = roles.some((currentRole) =>
        hasAuthorization(role.permissions, [featureName, currentRole]),
      );
      return hasPermission;
    },
    [me.name],
  );
  const renderMenu = useMemo(
    () => (
      <>
        {menuItems.map((menuItem) => {
          const hasPermission = verifyPermissionFeature(
            menuItem?.feature || '',
            menuItem?.roles || [],
          );
          return hasPermission ? (
            <Accordion
              key={menuItem.title}
              disabled={menuItem.title === 'Páginas HTML'}
              disableGutters
              sx={{
                bgcolor: 'grey.600',
                ':nth-child(2)': {},
              }}
            >
              {!menuItem?.subItems ? (
                <Link to={menuItem.href} style={{ textDecoration: 'none' }}>
                  <AccordionSummary
                    expandIcon={
                      menuItem.subItems ? (
                        <ExpandMoreIcon
                          sx={{ color: 'grey.400', fontSize: 14 }}
                        />
                      ) : (
                        ''
                      )
                    }
                  >
                    <ListItem>
                      <ListItemIcon>{menuItem.icon}</ListItemIcon>
                      <ListItemText
                        primary={menuItem.title}
                        primaryTypographyProps={{
                          color: 'grey.100',
                          fontSize: 14,
                          fontWeight: 600,
                          whiteSpace: 'nowrap',
                        }}
                      />
                    </ListItem>
                  </AccordionSummary>
                </Link>
              ) : (
                <AccordionSummary
                  expandIcon={
                    menuItem.subItems ? (
                      <ExpandMoreIcon
                        sx={{ color: 'grey.400', fontSize: 14 }}
                      />
                    ) : (
                      ''
                    )
                  }
                >
                  <ListItem>
                    <ListItemIcon>{menuItem.icon}</ListItemIcon>
                    <ListItemText
                      primary={menuItem.title}
                      primaryTypographyProps={{
                        color: 'grey.100',
                        fontSize: 14,
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                      }}
                    />
                  </ListItem>
                </AccordionSummary>
              )}
              {menuItem?.subItems ? (
                <AccordionDetails
                  sx={{
                    bgcolor: 'primary.main',
                    borderTop: '1px solid rgba(0, 0, 0, .125)', // TODO: export from theme
                    pl: 3,
                  }}
                >
                  {menuItem?.subItems?.map((subItem) => {
                    const hasPermisson = verifyPermissionFeature(
                      subItem.feature,
                      subItem.roles,
                    );
                    return hasPermisson ? (
                      <Link
                        key={subItem.title}
                        to={subItem.href}
                        style={{ textDecoration: 'none' }}
                      >
                        <List key={subItem.title}>
                          <ListItem button>
                            <ListItemIcon>{subItem.icon}</ListItemIcon>
                            <ListItemText
                              primary={subItem.title}
                              sx={{
                                color: 'primary.light',
                                whiteSpace: 'nowrap',
                              }}
                            />
                          </ListItem>
                        </List>
                      </Link>
                    ) : (
                      ''
                    );
                  })}
                </AccordionDetails>
              ) : (
                ''
              )}
            </Accordion>
          ) : (
            ''
          );
        })}
      </>
    ),
    [me.name],
  );
  return renderMenu;
};

export default MenuItems;
