import { SmartButton as SmartButtonIcon } from '@material-ui/icons';
import { DynamicButton } from '../../../BuildArea/components';
import CustomContentBlock from './CustomContentBlock';

const ButtonBlock = () => (
  <CustomContentBlock
    type="ButtonBlock"
    label="Botões"
    createdComponent={
      <DynamicButton
        alignment="flex-start"
        bgcolor="#DE2A2F"
        color="#FFFFFF"
        href="#"
        label="Assine agora!"
      />
    }
  >
    <SmartButtonIcon sx={{ fontSize: 32, color: 'info.main' }} />
  </CustomContentBlock>
);

export default ButtonBlock;
