// TODO: Find a better solution for default props
/* eslint-disable react/require-default-props */
import { useNode } from '@craftjs/core';
import { Stack } from '@material-ui/core';
import { ViewColumn as ViewColumnIcon } from '@material-ui/icons';

import EmptyStateCard from '../../../../../../components/EmptyStateCard';
import { PageFooter, PageHeader } from '.';

interface MainContainerProps {
  bgColor?: string;
  children?: React.ReactNode;
  footerBgColor?: string;
  footerFacebookLink?: string;
  footerIconsColor?: string;
  footerInstagramLink?: string;
  footerHasVerticalLayout?: boolean;
  footerIsInvisible?: boolean;
  footerTwitterLink?: string;
  footerYoutubeLink?: string;
  headerBgColor?: string;
  headerButtonColor?: string;
  headerButtonLink?: string;
  headerButtonText?: string;
  headerHasButton?: boolean;
  headerIsInvisible?: boolean;
  headerTextColor?: string;
}

const MainContainer = ({
  bgColor = '#141414',
  children = null,
  footerBgColor = '#171717',
  footerFacebookLink = '',
  footerIconsColor = '#FFFFFF',
  footerInstagramLink = '',
  footerHasVerticalLayout = true,
  footerIsInvisible = false,
  footerTwitterLink = '',
  footerYoutubeLink = '',
  headerBgColor = '#171717',
  headerButtonColor = '#DE2A2F',
  headerButtonLink = '',
  headerButtonText = 'Assine agora!',
  headerHasButton = false,
  headerIsInvisible = false,
  headerTextColor = '#FFFFFF',
}: MainContainerProps) => {
  const {
    connectors: { connect },
    hasChild,
  } = useNode((node) => ({
    hasChild: node.data.nodes.length > 0,
  }));
  return (
    <>
      <Stack sx={{ display: headerIsInvisible ? 'none' : 'flex' }}>
        <PageHeader
          bgColor={headerBgColor}
          buttonColor={headerButtonColor}
          buttonLink={headerButtonLink}
          buttonText={headerButtonText}
          hasButton={headerHasButton}
          textColor={headerTextColor}
        />
      </Stack>
      <Stack
        ref={connect}
        minHeight={386}
        width={873}
        sx={{
          bgcolor: bgColor,
          p: 5,
          gap: 5,
        }}
      >
        {!hasChild ? (
          <EmptyStateCard
            description="Depois de arrastar, você poderá editar as posições, incluir e organizar seus blocos e estruturas."
            icon={<ViewColumnIcon />}
            title="Arraste estruturas e blocos para cá"
          />
        ) : (
          children
        )}
      </Stack>
      <Stack sx={{ display: footerIsInvisible ? 'none' : 'flex' }}>
        <PageFooter
          bgColor={footerBgColor}
          facebookLink={footerFacebookLink}
          iconsColor={footerIconsColor}
          instagramLink={footerInstagramLink}
          hasVerticalLayout={footerHasVerticalLayout}
          twitterLink={footerTwitterLink}
          youtubeLink={footerYoutubeLink}
        />
      </Stack>
    </>
  );
};

export default MainContainer;
