/* eslint-disable jsx-a11y/label-has-associated-control */
// TODO: Find a better solution for default props
import { Avatar, Badge, IconButton, Input } from '@material-ui/core';
import {
  CameraAlt as CameraAltIcon,
  Image as ImageIcon,
} from '@material-ui/icons';
import { ChangeEvent, useState } from 'react';

interface ThumbnailProps {
  onBuilder?: boolean;
  src: string;
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
}

const Thumbnail = ({ onBuilder = false, src, onChange }: ThumbnailProps) => {
  const [imagePreview, setImagePreview] = useState<string>(src);
  const uploadHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    const hasNoFiles = evt.target.files?.length === 0;
    if (hasNoFiles) {
      return;
    }
    setImagePreview('');
    const image = Array.from(evt.target.files!)[0];
    const uploadedImagePreview = URL.createObjectURL(image);
    setImagePreview(uploadedImagePreview);
    onChange(evt);
  };
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <label htmlFor="icon-button-file">
          <Input
            id="icon-button-file"
            type="file"
            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
              uploadHandler(evt);
            }}
            sx={{ display: 'none' }}
          />
          <IconButton color="primary" component="span">
            <CameraAltIcon
              sx={{
                bgcolor: 'secondary.main',
                borderRadius: '50%',
                color: 'grey.100',
                height: 30,
                mb: onBuilder ? 5 : 0,
                p: 2,
                width: 30,
              }}
            />
          </IconButton>
        </label>
      }
    >
      <Avatar
        variant={onBuilder ? 'rounded' : 'circular'}
        src={imagePreview}
        sx={{
          bgcolor: onBuilder ? '#20133A' : 'grey.500', // TODO: export from theme
          height: onBuilder ? 120 : 80,
          width: onBuilder ? 192 : 80,
        }}
      >
        {onBuilder ? (
          <ImageIcon sx={{ color: 'info.main', height: 48, width: 48 }} />
        ) : null}
      </Avatar>
    </Badge>
  );
};

export default Thumbnail;
