import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import isLoggedIn from '../services/auth';

interface PrivateRouteProps {
  children: ReactElement;
}

const PrivateRoute = ({ children }: PrivateRouteProps) =>
  isLoggedIn() ? children : <Navigate to="/auth/login" />;

export default PrivateRoute;
