import { Avatar, Box, Stack, Typography, useTheme } from '@material-ui/core';
import { DragIndicator as DragIndicatorIcon } from '@material-ui/icons';
import { Draggable } from 'react-beautiful-dnd';
import { SortableHighlightedContents } from '../../../interfaces/Highlight';

interface ContentCardProps {
  sortableHighlightedContent: SortableHighlightedContents;
  index: number;
}

const ContentCard = ({ sortableHighlightedContent, index }: ContentCardProps) => {
  const { palette } = useTheme();
  return (
    <Draggable
      draggableId={sortableHighlightedContent.highlightedContent.id}
      index={index}
    >
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Stack
            alignItems="center"
            direction="row"
            gap={2}
            sx={{
              bgcolor: snapshot.isDragging
                ? 'rgba(137, 80, 252, 0.1)'
                : 'grey.600',
              border: snapshot.isDragging
                ? `2px solid ${palette.info.main}`
                : null,
              borderRadius: '8px',
              transform: snapshot.isDragging ? 'rotate(-1deg)' : 'rotate(0deg)',
              transition: 'transform .3s',
              mb: 2,
              p: 3,
              width: '100%',
            }}
          >
            <DragIndicatorIcon
              sx={{
                cursor: 'grab',
                color: 'grey.400',
                height: 28,
                width: 28,
              }}
            />
            <Avatar
              alt={sortableHighlightedContent.highlightedContent.title}
              src={sortableHighlightedContent.highlightedContent.fullHdLandscape}
              variant="rounded"
              sx={{ height: 42, width: 74 }}
            />
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
                color: snapshot.isDragging ? '#8950FC' : 'grey.100',
                width: 120,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {sortableHighlightedContent.highlightedContent.title}
            </Typography>
          </Stack>
        </Box>
      )}
    </Draggable>
  );
};

export default ContentCard;
