import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
} from '@material-ui/core';
import { useContext } from 'react';
import CoverImgEditor from '../../../components/UI/molecules/CoverImgEditor';
import Context from '../context';

const Details = () => {
  const { form } = useContext(Context);
  return (
    <Card sx={{ height: 357, mt: 4 }}>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Stack direction="row" spacing={4}>
              <Chip
                color={form.values.type === 'live' ? 'error' : 'info'}
                variant="outlined"
                label={form.values.type === 'live' ? 'Ao vivo' : 'On demand'}
                size="small"
              />
              <Chip
                color={form.values.published ? 'success' : 'warning'}
                label={form.values.published ? 'Publicado' : 'Privado'}
                variant="outlined"
                size="small"
              />
            </Stack>
            <Box sx={{ mt: 4 }}>
              <InputLabel
                shrink
                sx={{ color: 'grey.300', fontSize: 14, mt: 4 }}
              >
                Título do conteúdo *
              </InputLabel>
              <OutlinedInput
                name="title"
                data-testid="input-title"
                error={!!form.errors.title}
                value={form.values.title}
                sx={{ width: '100%' }}
                onChange={form.handleChange}
              />
            </Box>
            <InputLabel shrink sx={{ color: 'grey.300', fontSize: 14, mt: 4 }}>
              Descrição do conteúdo *
            </InputLabel>
            <OutlinedInput
              error={!!form.errors.description}
              name="description"
              data-testid="input-description"
              multiline
              rows={6}
              value={form.values.description}
              sx={{
                color: 'grey.300',
                height: 140,
                mt: 1,
                px: 3,
                py: 2,
                width: '100%',
                '.MuiInputBase-inputMultiline': {
                  fontSize: 12,
                },
              }}
              onChange={form.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CoverImgEditor sx={{ ml: 'auto' }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Details;
