import { useAuthorize } from '@tshio/react-router-permissions';
import { Grid } from '@material-ui/core';
import { useContext } from 'react';
import HasNoPermission from '../../components/HasNoPermission';
import Topbar from '../../components/Topbar';
import ContextToMe from '../../Context';
import CategoriesContainer from './components/CategoriesContainer';
import TagsContainer from './components/TagsContainer';
import Context from './Context';

const Categories = () => {
  const { me } = useContext(ContextToMe);
  const permissionForEverything = me.superAdmin;
  const permissionToCreate =
    permissionForEverything || useAuthorize(['categoriesAndTags', 'create']);
  const permissionToDelete =
    permissionForEverything || useAuthorize(['categoriesAndTags', 'delete']);
  const permissionToRead =
    permissionForEverything || useAuthorize(['categoriesAndTags', 'read']);
  const permissionToUpdate =
    permissionForEverything || useAuthorize(['categoriesAndTags', 'update']);
  if (!permissionToRead) return <HasNoPermission />;
  return (
    <Context.Provider
      value={{
        permissionToCreate,
        permissionToDelete,
        permissionToRead,
        permissionToUpdate,
      }}
    >
      <Topbar
        title="Categorias e tags"
        description="As categorias e tags serão o principal método de organização e
          ordenação de seus conteúdos e informações na plataforma."
      />
      <Grid container spacing={4} sx={{ mt: 0 }}>
        <Grid item xs={4}>
          <TagsContainer />
        </Grid>
        <Grid item xs={8}>
          <CategoriesContainer />
        </Grid>
      </Grid>
    </Context.Provider>
  );
};

export default Categories;
