import { gql } from '@apollo/client';

const teamQuery = gql`
  query teamQuery($id: ID!) {
    team(id: $id) {
      id
      name
      slug
      externalUrl
      avatars
    }
  }
`;

export default teamQuery;
