import { useQuery } from '@apollo/client';
import {
  CircularProgress,
  InputAdornment,
  List,
  OutlinedInput,
  Stack,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useState } from 'react';
import { HeartTeam } from '../../../interfaces/HeartTeam';
import { compareTerms } from '../../../utils/string';
import listTeamsQuery from '../../Spectators/gql/listTeamsQuery';
import EmptyTeams from './EmptyTeams';
import TeamItemList from './TeamItemList';

interface TeamsModalProps {
  teams: HeartTeam[];
  addTeamToContent: (teams: HeartTeam[]) => void;
}

const TeamsModal = ({
  teams: teamsProp = [],
  addTeamToContent,
}: TeamsModalProps) => {
  const [teams, setTeams] = useState(teamsProp);
  const { loading, data: listTeams } = useQuery(listTeamsQuery);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const searchChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(evt.target.value);
  const teamBelongsToContent = (team: HeartTeam) =>
    teams.some((current) => current.id === team.id);
  const onAddTeamHandler = async (team: HeartTeam) => {
    if (teamBelongsToContent(team)) {
      const newTeams = teams.filter(
        (currentTeam) => currentTeam.id !== team.id,
      );
      setTeams(newTeams);
      addTeamToContent(newTeams);
      return;
    }
    const addedTeams = [...teams, team];
    setTeams(addedTeams);
    addTeamToContent(addedTeams);
  };
  if (loading) {
    return <CircularProgress />;
  }
  const teamsList = listTeams!.teams.items;
  const renderedItems = searchQuery
    ? teamsList.filter((team: HeartTeam) =>
        compareTerms(team.name, searchQuery),
      )
    : teamsList;
  const sortedFavoriteTeams = [...renderedItems].sort(
    (TeamA: HeartTeam, TeamB: HeartTeam) =>
      TeamA.name.localeCompare(TeamB.name),
  );
  return (
    <Stack data-testid="content-teams-list">
      <OutlinedInput
        data-testid="input-tags-modal"
        fullWidth
        inputProps={{ maxLength: 160 }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon sx={{ color: 'info.main' }} />
          </InputAdornment>
        }
        placeholder="Adicione ou exclua um time"
        value={searchQuery}
        sx={{ color: 'secondary.light', my: 4 }}
        onChange={searchChangeHandler}
      />
      <PerfectScrollbar style={{ height: 240 }}>
        <List>
          {!sortedFavoriteTeams?.length ? (
            <EmptyTeams />
          ) : (
            sortedFavoriteTeams.map((team: HeartTeam) => (
              <TeamItemList
                key={team.id}
                checked={teamBelongsToContent(team)}
                team={team}
                onAddTeam={onAddTeamHandler}
              />
            ))
          )}
        </List>
      </PerfectScrollbar>
    </Stack>
  );
};

export default TeamsModal;
