import { gql } from '@apollo/client';

const ContentQuery = gql`
  query ContentQuery($id: ID!) {
    content(id: $id) {
      id
      spallaContentId
      title
      description
      authorizedOnMobile
      authorizedOnDesktop
      authorizedOnTv
      scheduleDate
      scheduleDateEnabled
      finishDate
      finishDateEnabled
      type
      published
      thumbnail
      sourceDuration
      sourceSize
      sourceResolution
      externalContent
      externalEmbed
      externalUrl
      createdAt
      updatedAt
      tags {
        id
        name
        slug
      }
    }
  }
`;

export default ContentQuery;
