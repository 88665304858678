import { createElement } from 'react';
import { useEditor } from '@craftjs/core';
import { Card } from '@material-ui/core';

const forbiddenComponents = ['MainContainer', 'PageFooter', 'PageHeader'];

const WidgetsSettings = () => {
  const { isEnabled, settings } = useEditor((state) => {
    let currentSettings;
    const currentNodeId = state.events.selected;
    if (currentNodeId) {
      const nodeName = state.nodes[currentNodeId].data.name;
      const componentIsAllowed = !forbiddenComponents.includes(nodeName);
      if (componentIsAllowed) {
        currentSettings = state.nodes[currentNodeId].related?.settings;
      }
    }
    return { settings: currentSettings, isEnabled: state.options.enabled };
  });
  return isEnabled && settings ? (
    <Card sx={{ mb: 3, width: 270 }}>
      {settings && createElement(settings)}
    </Card>
  ) : null;
};

export default WidgetsSettings;
