import { VideoLibrary as VideoLibraryIcon } from '@material-ui/icons';
import { DynamicVideo } from '../../../BuildArea/components';
import CustomContentBlock from './CustomContentBlock';

const VideoBlock = () => (
  <CustomContentBlock
    type="VideoBlock"
    label="Vídeos"
    createdComponent={<DynamicVideo />}
  >
    <VideoLibraryIcon sx={{ fontSize: 32, color: 'info.main' }} />
  </CustomContentBlock>
);

export default VideoBlock;
