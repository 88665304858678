import { gql } from '@apollo/client';

const usersPlansHistoryQuery = gql`
  query UsersPlansHistoryQuery($input: ListUsersPlansHistoryInput!) {
    usersPlansHistory(input: $input) {
      items {
        status
        user {
          id
          name
          email
          cpf
          phone
          createdAt
          updatedAt
          deletedAt
          signupAnswer {
            question {
              id
              title
            }
            tag {
              id
              name
            }
          }
        }
      }
      meta {
        totalItems
        itemCount
        totalPages
        currentPage
      }
    }
  }
`;

export default usersPlansHistoryQuery;
