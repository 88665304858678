import {
  Divider,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
  TextField,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/lab';
import { useContext } from 'react';
import TileCard5 from '../../../components/TileCard5';
import {
  getFriendlyNameThePaymentType,
  PaymentType,
  PlanType,
} from '../../../interfaces/Plan';
import Context from '../Context';
import Partners from './Partners';

const installments = () =>
  [...Array(12)].map((_, index) => (
    <MenuItem value={index + 1}>{index + 1}</MenuItem>
  ));

const quantityScreens = [2, 3, 4, 5];

const Payments = () => {
  const { form, isEditing } = useContext(Context);
  const typePlanIsPartners = form.values.type === PlanType.partner;
  const typePlanIsSeason = form.values.type === PlanType.season;
  const typePlanIsPartnersOrFree =
    typePlanIsPartners || form.values.type === PlanType.free;
  const toggleCheckedPaymentHandler = (evt: any) => {
    const paymentChecked = evt.target.checked;
    const payment = evt.target.value;
    if (paymentChecked) {
      form.setFieldValue('paymentType', [...form.values.paymentType, payment]);
      return;
    }
    form.setFieldValue(
      'paymentType',
      form.values.paymentType.filter(
        (currentPartner: any) => currentPartner !== payment,
      ),
    );
  };
  const paymentChecked = (payment: PaymentType) =>
    form.values?.paymentType?.find(
      (currentPartner: PaymentType) => currentPartner === payment,
    );
  const creditPaymentIsVerified = form.values?.paymentType?.includes(
    PaymentType.credit,
  );
  return (
    <Stack>
      {!typePlanIsPartnersOrFree && (
        <TileCard5
          title="Meios de Pagamento"
          sx={{ height: typePlanIsSeason ? 405 : 260, mb: 4, width: 482 }}
        >
          <Typography sx={{ color: 'grey.300', fontSize: 14, mt: 2 }}>
            Defina os meios de pagamento que poderá ser habilitados no plano.
          </Typography>
          <Divider sx={{ borderColor: 'grey.500', mt: 5 }} />
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            gap={2}
            sx={{ mt: 5, flexWrap: 'wrap' }}
          >
            {Object.values(PaymentType).map((payment) => (
              <Typography
                sx={{
                  alignItems: 'center',
                  color: 'grey.300',
                  display: 'flex',
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                {getFriendlyNameThePaymentType(payment)}
                <Switch
                  value={payment}
                  color="secondary"
                  disabled={isEditing}
                  checked={!!paymentChecked(PaymentType[payment])}
                  sx={{ ml: 4 }}
                  onClick={toggleCheckedPaymentHandler}
                />
              </Typography>
            ))}
          </Stack>
          {typePlanIsSeason && creditPaymentIsVerified && (
            <>
              <InputLabel
                sx={{ color: 'grey.400', fontSize: 14, mb: 2, mt: 3 }}
              >
                Selecionar parcelamento
              </InputLabel>
              <Select
                name="installments"
                value={form.values.installments}
                disabled={isEditing}
                sx={{ width: 188 }}
                onChange={form.handleChange}
              >
                {installments()}
              </Select>
            </>
          )}
          <FormHelperText error>
            {form.touched.paymentType && form.errors.paymentType}
          </FormHelperText>
        </TileCard5>
      )}
      {typePlanIsPartners && <Partners />}
      {typePlanIsSeason && (
        <TileCard5
          title="Próxima cobrança"
          tip=""
          titleProps={{ sx: { color: 'grey.100', fontSize: 16, mb: 4 } }}
          buttonVisible={false}
          sx={{ height: 195, mb: 4, width: 482 }}
        >
          <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
            Defina a data da recorrência
          </Typography>
          <InputLabel sx={{ color: 'grey.400', fontSize: 14, mb: 1, mt: 3 }}>
            Data de cobrança:
          </InputLabel>
          <DatePicker
            mask="__/__/__"
            inputFormat="dd/MM/yy"
            value={form.values.nextSeasonDate}
            disabled={isEditing}
            minDate={new Date()}
            InputAdornmentProps={{
              position: 'start',
              sx: { maxWidth: 20, svg: { color: 'secondary.main' } },
            }}
            renderInput={(params) => (
              <TextField sx={{ maxWidth: 140 }} {...params} />
            )}
            onChange={(value) =>
              form.handleChange({
                target: { name: 'nextSeasonDate', value },
              })
            }
          />
          <FormHelperText error>{form.errors.nextSeasonDate}</FormHelperText>
        </TileCard5>
      )}
      <TileCard5
        title="Telas simultâneas"
        tip=""
        titleProps={{ sx: { color: 'grey.400', fontSize: 16, mb: 4 } }}
        buttonVisible={false}
        sx={{ height: 195, mb: 4, position: 'relative', width: 482 }}
      >
        <Typography
          sx={{
            color: 'grey.400',
            fontSize: 14,
            position: 'absolute',
            right: 77,
            top: 25,
          }}
        >
          Inativo
        </Typography>
        <Switch disabled sx={{ position: 'absolute', right: 24, top: 24 }} />
        <Typography sx={{ color: 'grey.400', fontSize: 14 }}>
          Escolha o n° de telas disponíveis para transmissão simultânea.
        </Typography>
        <InputLabel sx={{ color: 'grey.400', fontSize: 14, mb: 2, mt: 3 }}>
          Telas simultâneas
        </InputLabel>
        <Select disabled sx={{ minWidth: 156 }}>
          {quantityScreens.map((screen) => (
            <MenuItem value={screen}>{screen} telas</MenuItem>
          ))}
        </Select>
      </TileCard5>
    </Stack>
  );
};

export default Payments;
