import { ApolloProvider } from '@apollo/client';
import { Alert, CssBaseline, ThemeProvider } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { positions, Provider as AlertProvider } from 'react-alert';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppTemplate from './components/AppTemplate';
import { DialogProvider } from './components/Dialog';
import PrivateRoute from './components/PrivateRoute';
import ScrollToTop from './components/ScrollToTop';
import theme from './components/Theme';
import Auth from './pages/auth';
import apolloClient from './providers/apolloClient';
import './styles.css';

// TODO: add typings
const AlertTemplate = ({ message, close, style, options }: any) => (
  <Alert severity={options.type} style={style} onClose={close}>
    {message}
  </Alert>
);

const App = () => (
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
          <AlertProvider
            template={AlertTemplate}
            position={positions.TOP_CENTER}
            timeout={2500}
          >
            <DialogProvider>
              <DndProvider backend={HTML5Backend}>
                <Routes>
                  <Route path="/auth*" element={<Auth />} />
                  <Route
                    path="/*"
                    element={
                      <PrivateRoute>
                        <AppTemplate />
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </DndProvider>
            </DialogProvider>
          </AlertProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </BrowserRouter>
  </ApolloProvider>
);

export default App;
