import { TextFields as TextFieldsIcon } from '@material-ui/icons';
import { DynamicText } from '../../../BuildArea/components';
import CustomContentBlock from './CustomContentBlock';

const TextBlock = () => (
  <CustomContentBlock
    type="TextBlock"
    label="Textos"
    createdComponent={
      <DynamicText
        color="#FFFFFF"
        fontFamily="Arial"
        fontSize={20}
        text="Digite seu texto"
      />
    }
  >
    <TextFieldsIcon sx={{ fontSize: 32, color: 'info.main' }} />
  </CustomContentBlock>
);

export default TextBlock;
