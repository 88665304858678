import {
  Box,
  Card,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/lab';
import { useContext } from 'react';
import Context from '../Context';

const ScheduleHighlight = () => {
  const { form } = useContext(Context);
  const changeDatePickerHandler = (name: string) => (date: Date | null) =>
    form.handleChange({ target: { name, value: date } });
  return (
    <Card sx={{ mt: 4, p: 6 }}>
      <Typography sx={{ color: 'grey.100', fontWeight: 600 }}>
        Agendamento do destaque
      </Typography>
      <Typography sx={{ color: 'grey.300', fontSize: 14, mt: 2 }}>
        Escolha a data de início e término do destaque. Se a seção for
        desativada antes do termino do agendamento o destaque é retirado
        automaticamente.
      </Typography>
      <Stack alignItems="center" direction="row" gap={5} sx={{ mt: 6 }}>
        <Box>
          <InputLabel sx={{ color: 'grey.300', fontSize: 14, mb: 1 }}>
            Início *
          </InputLabel>
          <DateTimePicker
            mask="__/__/__"
            inputFormat="dd/MM/yy HH:mm"
            value={form.values.scheduleDate}
            minDate={new Date()}
            onChange={changeDatePickerHandler('scheduleDate')}
            renderInput={(params) => (
              <TextField sx={{ width: 160 }} {...params} />
            )}
            InputAdornmentProps={{ sx: { width: 20 } }}
          />
        </Box>
        <Box>
          <InputLabel sx={{ color: 'grey.300', fontSize: 14, mb: 1 }}>
            Término *
          </InputLabel>
          <DateTimePicker
            mask="__/__/__"
            inputFormat="dd/MM/yy HH:mm"
            minDate={form.values.scheduleDate}
            value={form.values.finishDate}
            onChange={changeDatePickerHandler('finishDate')}
            renderInput={(params) => (
              <TextField sx={{ width: 160 }} {...params} />
            )}
            InputAdornmentProps={{ sx: { width: 20 } }}
          />
        </Box>
      </Stack>
    </Card>
  );
};

export default ScheduleHighlight;
