import { Box, Typography } from '@material-ui/core';

interface SlideCardProps {
  id: string;
  thumbnail: string;
  title: string;
}

const SlideCard = ({ id, thumbnail, title }: SlideCardProps) => (
  <Box key={id}>
    <Box sx={{ pl: '17.5px' }}>
      <Box
        sx={{
          backgroundImage: `url(${thumbnail})`,
          backgroundSize: 'cover',
          borderRadius: 1,
          height: 96,
          width: 170,
        }}
      />
      <Typography
        sx={{
          color: 'common.white',
          fontSize: 12,
          mt: 2,
          width: 170,
        }}
      >
        {title}
      </Typography>
    </Box>
  </Box>
);

export default SlideCard;
