import { useQuery } from '@apollo/client';
import {
  Button,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemText,
  OutlinedInput,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { ChangeEvent, useState } from 'react';
import { useDialog } from '../../../components/Dialog';
import { compareTerms } from '../../../utils/string';
import listMclsContents from '../gql/listMclsContents';
import { MclsContent } from '../../../interfaces/Content';

interface ModalToAddMCLSContentProps {
  selectedContent: any;
  onSelect: (content: MclsContent | null) => void;
}

const ModalToAddMCLSContent = ({
  selectedContent,
  onSelect,
}: ModalToAddMCLSContentProps) => {
  const dialog = useDialog();
  const { loading, data } = useQuery(listMclsContents);
  const [query, setQuery] = useState('');
  const changeQueryHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setQuery(evt.target.value);
  const contentsMcls = data?.mclsContents;
  const contents = query
    ? contentsMcls?.filter((content: any) => compareTerms(content.title, query))
    : contentsMcls;
  const selectContent = (content: any) => {
    if (content.id === selectedContent?.id) {
      onSelect(null);
      dialog.close();
      return;
    }
    onSelect(content);
    dialog.close();
  };
  const contentIsSelected = (content: any) =>
    selectedContent?.id === content.id;
  if (loading) return <>Carregando...</>;
  return (
    <>
      <OutlinedInput
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon sx={{ color: 'secondary.main' }} />
          </InputAdornment>
        }
        fullWidth
        placeholder="Pesquisar destaques"
        value={query}
        sx={{ mb: 6, mr: 4, width: '100%' }}
        onChange={changeQueryHandler}
      />
      {contents.map((content: any) => (
        <ListItem sx={{ height: 74, p: 0, width: '100%' }}>
          <ListItemButton key={content.id} dense sx={{ pl: 0 }}>
            <ListItemText
              primary={content.title}
              primaryTypographyProps={{
                color: 'grey.100',
                fontSize: 14,
                fontWeight: 600,
                width: 353,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              sx={{ ml: 3 }}
            />
          </ListItemButton>
          <Button
            sx={{
              bgcolor: contentIsSelected(content)
                ? 'success.main'
                : 'secondary',
              fontSize: 12,
              fontWeight: 500,
              height: 30,
              width: 77,
            }}
            onClick={() => selectContent(content)}
          >
            {contentIsSelected(content) ? 'Adicionado' : 'Adicionar'}
          </Button>
        </ListItem>
      ))}
    </>
  );
};

export default ModalToAddMCLSContent;
