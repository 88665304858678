import {
  FormHelperText,
  OutlinedInput,
  Stack,
  Switch,
  Typography,
} from '@material-ui/core';
import { memo, useContext } from 'react';
import TileCard5 from '../../../components/TileCard5';
import { PlanType } from '../../../interfaces/Plan';
import {
  normalizePrice,
  getFloatPrice,
  preventFloatingNumbers,
} from '../../../utils/number';
import Context from '../Context';

const MembershipsAndAmounts = memo(() => {
  const { form, isEditing } = useContext(Context);
  if (!form.values.title) {
    return <div>Carregando...</div>;
  }
  const typePlanIsPartners = form.values.type === PlanType.partner;
  const typePlanIsPartnersOrFree =
    typePlanIsPartners || form.values.type === PlanType.free;
  return (
    <>
      {!typePlanIsPartnersOrFree && (
        <TileCard5
          title="Custo do Plano"
          tip="É o valor cobrado do espectador em planos com vínculo de Pagamentos."
          titleProps={{
            sx: {
              color: 'grey.100',
              fontSize: 16,
              mb: 2,
            },
          }}
          sx={{ mb: 4 }}
        >
          <Typography
            sx={{
              color: 'grey.300',
              fontSize: 14,
            }}
          >
            Insira o valor que será cobrado do seu cliente.
          </Typography>
          <OutlinedInput
            data-testid="price-input"
            name="price"
            disabled={typePlanIsPartnersOrFree || isEditing}
            startAdornment="R$"
            error={form.touched.price && !!form.errors.price}
            type="number"
            value={normalizePrice(form.values.price)}
            sx={{
              height: 36,
              mt: 3,
              width: 115,
              input: { ml: 1 },
              '&.MuiInputBase-adornedStart': { color: 'grey.100' },
            }}
            onKeyDown={preventFloatingNumbers}
            onChange={(e) => {
              e.target.value = getFloatPrice(e.target.value);
              form.handleChange(e);
            }}
          />
          <FormHelperText error>
            {form.touched.price && form.errors.price}
          </FormHelperText>
        </TileCard5>
      )}
      <TileCard5
        title="Quantidade de Adesões"
        tip="É possível determinar um número de assinaturas liberadas ao plano ou torná-las ilimitadas."
        sx={{ mb: 4, width: 482 }}
      >
        <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
          Defina o número de planos a serem ofertados.
        </Typography>
        <Stack alignItems="center" direction="row" sx={{ mt: 3 }}>
          <OutlinedInput
            data-testid="memberships-input"
            name="membershipsCount"
            disabled={form.values.unlimitedMemberships || isEditing}
            error={!!form.errors.membershipsCount}
            type="number"
            value={form.values.membershipsCount}
            sx={{ height: 36, width: 92 }}
            onChange={form.handleChange}
            onKeyDown={preventFloatingNumbers}
          />
          <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
            <Switch
              data-testid="switch-unlimitedMemberships"
              name="unlimitedMemberships"
              color="secondary"
              disabled={isEditing}
              checked={form.values.unlimitedMemberships}
              sx={{ ml: 6, mr: 2 }}
              onClick={form.handleChange}
            />
            Quantidade ilimitada
          </Typography>
        </Stack>
        <FormHelperText error>{form.errors.membershipsCount}</FormHelperText>
      </TileCard5>
    </>
  );
});

export default MembershipsAndAmounts;
