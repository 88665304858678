export const SmallTitlesStyles = {
  color: 'grey.300',
  fontSize: 14,
  fontWeight: 400,
  '&:not(:first-child)': { mt: 4 },
};

export const SmallInputsStyles = {
  border: 1,
  borderColor: 'grey.400',
  borderRadius: 1,
  color: 'grey.300',
  fontSize: 14,
  height: 37,
  overflow: 'initial',
  px: 3,
  width: '100%',
};

export const LargeInputsStyles = {
  border: 1,
  borderColor: 'grey.400',
  borderRadius: 1,
  color: 'grey.300',
  fontSize: 14,
  height: 97,
  overflow: 'initial',
  padding: 3,
  width: '100%',
};