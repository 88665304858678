import { gql } from '@apollo/client';

const updateRoleMutation = gql`
  mutation UpdateRoleMutation($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      id
      title
      color
      enabled
      permissions {
        id
        create
        delete
        feature
        read
        update
      }
    }
  }
`;

export default updateRoleMutation;
