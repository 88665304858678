import { ChangeEvent, useState } from 'react';
import {
  List,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Typography,
} from '@material-ui/core';
import { Tv as TvIcon } from '@material-ui/icons';
import BulkActionController from '../../../../components/UI/templates/BulkActionController';
import { compareTerms } from '../../../../utils/string';
import { Content } from '../../../../interfaces/Content';
import ContentLisItem from '../../../../components/UI/molecules/SelectableContentLisItem';
import paginationAdapter from '../../../../adapters/pagination';
import SearchBar from '../../../../components/UI/molecules/SearchBar';
import { SelectableContentsProps } from './typings';
import { SortBy } from '../../../../typings/sort';
import TipAlert from '../../../../components/UI/molecules/TipAlert';

const SelectableContents = ({
  contents,
  selectedContents,
  onSelect,
  onSubmit,
}: SelectableContentsProps) => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [sortBy, setSortBy] = useState(SortBy.Newest);
  const selectedMomentHandler = (evt: SelectChangeEvent<SortBy>) =>
    setSortBy(evt.target.value as SortBy);
  const searchHandler = (evt: ChangeEvent<{ value: string }>) =>
    setQuery(evt.target.value);
  const paginationHandler = (_: ChangeEvent<unknown>, newPage: number) =>
    setPage(newPage);
  const sortByNewest = (prevContent: Content, currentContent: Content) => {
    const currentDateIsBigger =
      new Date(currentContent.createdAt).getTime() >
      new Date(prevContent.createdAt).getTime();
    if (currentDateIsBigger) {
      return -1;
    }
    const prevDateIsBigger =
      new Date(prevContent.createdAt).getTime() >
      new Date(currentContent.createdAt).getTime();
    if (prevDateIsBigger) {
      return 1;
    }
    return 0;
  };
  const sortByOldest = (prevContent: Content, currentContent: Content) => {
    const prevDateIsBigger =
      new Date(prevContent.createdAt).getTime() >
      new Date(currentContent.createdAt).getTime();
    if (prevDateIsBigger) {
      return -1;
    }
    const currentDateIsBigger =
      new Date(currentContent.createdAt).getTime() >
      new Date(prevContent.createdAt).getTime();
    if (currentDateIsBigger) {
      return 1;
    }
    return 0;
  };
  const sortedContent = (items: Content[]) => {
    const newItems = [...items];
    const isSortingByNewest = sortBy === SortBy.Newest;
    newItems.sort(isSortingByNewest ? sortByNewest : sortByOldest);
    return newItems;
  };
  const filteredContents = (items: Content[]) => {
    const sortedItems = sortedContent(items);
    const filteredItems = query
      ? sortedItems.filter((content) => compareTerms(content.title, query))
      : sortedItems;
    return filteredItems;
  };
  const onSelectHandler = (content: Content) => onSelect(content);
  const paginationItems = paginationAdapter<Content>({
    items: filteredContents(contents),
    page,
  });
  return (
    <>
      <TipAlert sx={{ mt: 4 }}>
        Escolha até 10 conteúdos para importar simultaneamente via Spalla
        Streaming. Selecione-os abaixo, você poderá editar depois do envio.
      </TipAlert>
      <SearchBar
        title="Escolha os conteúdos"
        TextFieldProps={{
          placeholder: 'Procure aqui por um conteúdo específico',
          value: query,
          onChange: searchHandler,
        }}
        sx={{ mt: 4 }}
      >
        <Select
          data-testid="sort-by"
          sx={{ minWidth: 161 }}
          value={sortBy}
          onChange={selectedMomentHandler}
        >
          <MenuItem value={SortBy.Newest}>Mais recentes</MenuItem>
          <MenuItem value={SortBy.Oldest}>Mais antigos</MenuItem>
        </Select>
      </SearchBar>
      <Typography
        sx={{
          color: 'grey.300',
          fontSize: 14,
          my: 5,
        }}
      >
        Exibindo
      </Typography>
      <List className="bulk-items" sx={{ mt: 4 }}>
        {paginationItems.items.map((content) => (
          <ContentLisItem
            key={content.id}
            content={content}
            selected={selectedContents.includes(content)}
            onToggle={onSelectHandler}
          />
        ))}
      </List>
      <Pagination
        data-testid="pagination"
        count={paginationItems.total}
        page={page}
        sx={{ mt: 4 }}
        onChange={paginationHandler}
      />
      <BulkActionController
        ButtonProps={{
          color: 'secondary',
          children: 'Enviar agora',
          onClick: () => onSubmit(selectedContents),
        }}
        iconItem={TvIcon}
        ListItemIconProps={{ color: 'secondary' }}
        listItem={selectedContents}
      />
    </>
  );
};

export default SelectableContents;
