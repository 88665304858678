import { Stack } from '@material-ui/core';
import { useContext } from 'react';
import Context from '../context';
import PageFooter from './PageFooter';
import PageHeader from './PageHeader';

interface MainContainerProps {
  children: React.ReactNode;
  bgColor: string;
  headerBgColor: string;
  headerButtonColor: string;
  headerHasButton: boolean;
  headerIsInvisible: boolean;
  headerTextColor: string;
  headerButtonText: string;
  headerButtonLink: string;
  footerHasVerticalLayout: boolean;
  footerIsInvisible: boolean;
  footerBgColor: string;
  footerIconsColor: string;
  footerTwitterLink: string;
  footerInstagramLink: string;
  footerFacebookLink: string;
  footerYoutubeLink: string;
}

const MainContainer = ({
  bgColor = '#141414',
  children = null,
  headerBgColor = '#171717',
  headerButtonColor = '#DE2A2F',
  headerHasButton = false,
  headerIsInvisible = false,
  headerTextColor = '#FFFFFF',
  headerButtonText = 'Assine agora!',
  headerButtonLink = '',
  footerHasVerticalLayout = true,
  footerIsInvisible = false,
  footerBgColor = '#171717',
  footerIconsColor = '#FFFFFF',
  footerTwitterLink = 'www.twitter.com',
  footerFacebookLink = 'www.facebook.com',
  footerInstagramLink = 'www.instagram.com',
  footerYoutubeLink = 'www.youtube.com',
}: MainContainerProps) => {
  const { smartphoneScreen } = useContext(Context);
  return (
    <Stack sx={{ border: '1px solid #e0e0e0', maxWidth: 1366 }}>
      <Stack sx={{ display: headerIsInvisible ? 'none' : 'flex' }}>
        <PageHeader
          bgColor={headerBgColor}
          buttonColor={headerButtonColor}
          hasButton={headerHasButton}
          textColor={headerTextColor}
          buttonText={headerButtonText}
          buttonLink={headerButtonLink}
        />
      </Stack>
      <Stack
        minHeight={386}
        sx={{
          bgcolor: bgColor,
          gap: smartphoneScreen ? 5 : 10,
          p: smartphoneScreen ? 5 : 10,
        }}
      >
        {children}
      </Stack>
      <Stack sx={{ display: footerIsInvisible ? 'none' : 'flex' }}>
        <PageFooter
          bgColor={footerBgColor}
          iconsColor={footerIconsColor}
          hasVerticalLayout={footerHasVerticalLayout}
          twitterLink={footerTwitterLink}
          instagramLink={footerInstagramLink}
          facebookLink={footerFacebookLink}
          youtubeLink={footerYoutubeLink}
        />
      </Stack>
    </Stack>
  );
};

export default MainContainer;
