import { gql } from '@apollo/client';

const updateTeamMutation = gql`
  mutation updateTeamMutation($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      id
    }
  }
`;

export default updateTeamMutation;
