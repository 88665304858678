import { Button, MenuItem, Select } from '@material-ui/core';
import { useContext, useState } from 'react';
import SearchBar from '../../../components/UI/molecules/SearchBar';
import Context, { SortPositionDirection } from '../Context';

const Filters = () => {
  const { setInput } = useContext(Context);
  const [filtersInput, setFiltersInput] = useState({
    filters: {
      sortPositionDirection: SortPositionDirection.minor,
      published: 'all',
      term: '',
    },
  });
  const checkPublishedValue = () => {
    switch (filtersInput.filters.published) {
      case 'all':
        return null;
      case 'active':
        return true;
      default:
        return false;
    }
  };
  const changeInputHandler = (evt: any) => {
    const fieldName = evt.target.name;
    const fieldValue = evt.target.value;
    setFiltersInput({
      ...filtersInput,
      filters: { ...filtersInput.filters, [fieldName]: fieldValue },
    });
  };
  const searchSectionsHandler = () => {
    const newValueOfPublished = checkPublishedValue();
    const newFilters =
      newValueOfPublished === null
        ? {
            sortPositionDirection: filtersInput.filters.sortPositionDirection,
            term: filtersInput.filters.term,
          }
        : {
            ...filtersInput.filters,
            published: newValueOfPublished,
          };
    const newInput = {
      filters: newFilters,
    };
    setInput(newInput);
  };
  return (
    <SearchBar
      TextFieldProps={{
        placeholder: 'Procure aqui por uma seção específica.',
        value: filtersInput.filters.term,
        name: 'term',
        onChange: changeInputHandler,
      }}
      sx={{ mt: 4 }}
    >
      <Select
        name="published"
        value={filtersInput.filters.published}
        defaultValue="all"
        displayEmpty
        sx={{ minWidth: 161 }}
        onChange={changeInputHandler}
      >
        <MenuItem value="all">Todos</MenuItem>
        <MenuItem value="active">Ativos</MenuItem>
        <MenuItem value="inactive">Inativos</MenuItem>
      </Select>
      <Select
        name="sortPositionDirection"
        value={filtersInput.filters.sortPositionDirection}
        sx={{ minWidth: 161 }}
        onChange={changeInputHandler}
      >
        <MenuItem value={SortPositionDirection.minor}>
          Posição mais recente
        </MenuItem>
        <MenuItem value={SortPositionDirection.larger}>
          Posição mais antiga
        </MenuItem>
      </Select>
      <Button onClick={searchSectionsHandler}>Pesquisar</Button>
    </SearchBar>
  );
};

export default Filters;
