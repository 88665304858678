import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/UI/molecules/Navbar';
import SelectableContents from './components/SelectableContents';
import { Content } from '../../interfaces/Content';
import spallaLivesQuery from './gql/spallaLivesQuery';
import importSpallaVodMutation from './gql/importSpallaVodMutation';
import importSpallaLiveMutation from './gql/importSpallaLiveMutation';
import spallaVodsQuery from './gql/spallaVodsQuery';

const ImportSpallaContent = () => {
  const { type } = useParams();
  const contentVod = type === 'vod';
  const { loading, data, refetch } = useQuery(
    contentVod ? spallaVodsQuery : spallaLivesQuery,
  );
  const [importSpallaVod] = useMutation<
    { importSpallaVod: Content },
    { spallaContentId: string }
  >(importSpallaVodMutation);
  const [importSpallaLive] = useMutation<
    { importSpallaLive: Content },
    { spallaContentId: string }
  >(importSpallaLiveMutation);
  const [selectedContents, setSelectedContents] = useState<Content[]>([]);
  const onSelectContentHandler = (content: Content) => {
    const contentIndex = selectedContents.findIndex(
      (currentContents) =>
        currentContents.spallaContentId === content.spallaContentId,
    );
    const contentNotExists = contentIndex !== -1;
    if (contentNotExists) {
      const newContentsSelecteds = selectedContents.filter(
        (currentContent) =>
          currentContent.spallaContentId !== content.spallaContentId,
      );
      setSelectedContents(newContentsSelecteds);
      return;
    }
    setSelectedContents((previousContents) => [content, ...previousContents]);
  };
  const onSubmitContentVodHandler = async () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const selectedContent of selectedContents) {
      // eslint-disable-next-line no-await-in-loop
      await importSpallaVod({
        variables: { spallaContentId: selectedContent.spallaContentId },
      });
    }
    await refetch();
    setSelectedContents([]);
  };
  const onSubmitContentLiveHandler = async () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const selectedContent of selectedContents) {
      // eslint-disable-next-line no-await-in-loop
      await importSpallaLive({
        variables: { spallaContentId: selectedContent.spallaContentId },
      });
    }
    await refetch();
    setSelectedContents([]);
  };
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Navbar route="/library" title="Adicionar conteúdo on demand" />
      <SelectableContents
        contents={contentVod ? data!.spallaVods : data!.spallaLives}
        selectedContents={selectedContents}
        onSelect={onSelectContentHandler}
        onSubmit={
          contentVod ? onSubmitContentVodHandler : onSubmitContentLiveHandler
        }
      />
    </>
  );
};

export default ImportSpallaContent;
