import { createContext } from 'react';
import { InputFilters } from './typings';

type ContextType = {
  input: InputFilters;
  setInput: (input: InputFilters) => void;
};

const Context = createContext({} as ContextType);

export default Context;
