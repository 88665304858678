import {
  Box,
  Button,
  Collapse,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowUp as ArrowUpIcon,
  Close as CloseIcon,
  ContentCopy as ContentCopyIcon,
  Edit,
  FiberManualRecord,
} from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../../components/Dialog';
import { ValueType } from '../../../interfaces/Coupon';
import { Discount, DiscountType } from '../../../interfaces/Discount';
import ModalToAddPlans from '../../CreateDiscount/Components/ModalToAddPlans';
import Context from '../Context';
import getCouponUsageQuery from '../gql/GetCouponUsageQuery';
import { getDisableMutationByType } from '../tools/getDisableMutationByType';
import { getUpdateMutationByType } from '../tools/getUpdateMutationByType';
import ModalPlan from './ModalPlan';

export interface DiscountCardProps {
  discount: Discount;
  refetch: () => void;
}

const DiscountCard = ({ discount, refetch }: DiscountCardProps) => {
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const { mutation: disableDiscount } = getDisableMutationByType(
    discount.type as DiscountType,
  );
  const { mutation: updateDiscount } = getUpdateMutationByType(
    discount.type as DiscountType,
  );
  const [disableDiscountMutation, { loading }] = useMutation(disableDiscount);
  const [updateDiscountMutation] = useMutation(updateDiscount);
  const dialog = useDialog();
  const alert = useAlert();
  const { isActive, title, type } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [amountUse, setAmountUse] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isContentMenuOpened = !!anchorEl;
  const havePlans = discount.plans.length > 0;
  const closeMenuHandler = () => setAnchorEl(null);
  const openMenuHandler = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const formatValue = (discountPrice: number) => {
    if (discount.valueType === ValueType.value)
      return `R$ ${Number(discountPrice).toFixed(2)}`.replace('.', ',');
    return `${discountPrice}%`.replace('.', ',');
  };
  const openModal = () => {
    dialog.open({
      title: 'Planos associados',
      element: <ModalPlan plans={discount.plans} />,
    });
  };
  const disableDiscountHandler = async () => {
    closeMenuHandler();
    const confirm = await dialog.confirm({
      title: 'Desativar desconto',
      message:
        'Você tem certeza que deseja desativar esse desconto? Essa ação não afetará quem já esiver utilizando este desconto.',
    });
    if (confirm) {
      try {
        const discountId = discount.id;
        await disableDiscountMutation({ variables: { id: discountId } });
        await refetch();
        alert.success('Desconto desativado com sucesso');
      } catch (error) {
        alert.error('Erro ao desativar desconto');
      }
    }
  };
  const openModalToAddPlan = async () => {
    closeMenuHandler();
    const addPlans = await dialog.open({
      title: 'Adicionar plano',
      element: <ModalToAddPlans plans={discount.plans} hasButton />,
      sx: { height: 490, width: 380 },
    });
    if (addPlans) {
      try {
        const newPlans = addPlans.map((plan: any) => ({
          id: plan.id,
        }));
        await updateDiscountMutation({
          variables: { input: { id: discount.id, plans: newPlans } },
        });
        await refetch();
        alert.success(`${title} atualizado com sucesso`);
      } catch {
        alert.error('Erro ao adicionar plano(s)');
      }
    }
  };
  const openCreationToCloneDiscount = () =>
    navigate(`/discount/create?type=${type}&id=${discount.id}`);
  const isTypeCoupon = type === 'coupon';
  if (loading) return <div>Loading...</div>;
  const getFriendlyNameRecurrence = (recurrence: number) => {
    switch (recurrence) {
      case 0 || null:
        return '-';
      case 1:
        return `${recurrence} mês`;
      default:
        return `${recurrence} meses`;
    }
  };
  const toggleAccordion = async () => {
    const isOpened = !open;
    setOpen(isOpened);
    if (isOpened && isTypeCoupon) {
      const params = {
        query: getCouponUsageQuery,
        variables: { discountId: discount.id },
      };
      try {
        const { data } = await apolloClient.query(params);
        setAmountUse(data.getCouponUsage);
      } catch {
        alert.error('Ocorreu um erro ao buscar os dados.');
      }
    }
  };
  return (
    <>
      <TableRow
        sx={{
          bgcolor: 'primary.main',
          borderRadius: 2,
          color: 'grey.100',
          fontSize: 12,
          maxWidth: 138,
          pl: 5,
        }}
      >
        <TableCell
          sx={{
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            color: 'grey.100',
            fontSize: 12,
            maxWidth: 138,
            pl: 5,
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: 14,
                maxWidth: 190,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {discount.title}
            </Typography>
          </Box>
        </TableCell>
        <TableCell
          sx={{ color: 'grey.100', fontSize: 14, textAlign: 'center' }}
        >
          {formatValue(discount.price)}
        </TableCell>
        <TableCell
          sx={{ color: 'grey.100', fontSize: 14, textAlign: 'center' }}
        >
          {discount.recurrence
            ? getFriendlyNameRecurrence(discount.recurrence)
            : '-'}
        </TableCell>
        <TableCell
          sx={{ color: 'grey.100', fontSize: 14, textAlign: 'center' }}
        >
          {getFriendlyNameRecurrence(discount.minimumPurchaseMonth)}
        </TableCell>
        <TableCell
          sx={{ color: 'grey.100', fontSize: 14, textAlign: 'center' }}
        >
          {discount.team ? discount.team.name : '-'}
        </TableCell>
        <TableCell
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconButton size="small" onClick={toggleAccordion}>
            {open ? (
              <Stack
                alignItems="center"
                direction="row"
                sx={{
                  bgcolor: 'grey.500',
                  borderRadius: 1,
                  color: 'white',
                  pl: 2,
                }}
              >
                <Typography sx={{ fontSize: 14 }}>Ocultar</Typography>
                <ArrowUpIcon
                  sx={{
                    bgcolor: 'grey.500',
                    borderRadius: 1,
                    color: 'white',
                    height: 36,
                    width: 42,
                  }}
                />
              </Stack>
            ) : (
              <Stack
                alignItems="center"
                direction="row"
                sx={{
                  bgcolor: 'grey.500',
                  borderRadius: 1,
                  color: 'white',
                  pl: 2,
                }}
              >
                <Typography sx={{ fontSize: 14 }}>Visualizar</Typography>
                <ArrowDownIcon
                  sx={{
                    bgcolor: 'grey.500',
                    borderRadius: 1,
                    color: 'white',
                    height: 36,
                    width: 42,
                  }}
                />
              </Stack>
            )}
          </IconButton>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            sx={{
              bgcolor: 'grey.500',
              borderRadius: 1,
              height: 40,
              width: '20%',
            }}
          >
            {isActive ? (
              <>
                <Edit
                  color="secondary"
                  sx={{ cursor: 'pointer' }}
                  onClick={(e: any) => openMenuHandler(e)}
                />
                <Menu
                  anchorEl={anchorEl}
                  open={isContentMenuOpened}
                  onClose={closeMenuHandler}
                >
                  <MenuItem onClick={openModalToAddPlan}>
                    <ListItemIcon>
                      <Edit color="secondary" />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                      Associar planos
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={disableDiscountHandler}>
                    <ListItemIcon>
                      <CloseIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                      Desativar
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <ContentCopyIcon
                color="secondary"
                cursor="pointer"
                onClick={openCreationToCloneDiscount}
              />
            )}
          </Stack>
        </TableCell>
        <TableCell
          sx={{ borderTopRightRadius: 4, borderBottomRightRadius: 4 }}
        />
      </TableRow>
      <TableRow>
        <TableCell colSpan={7} sx={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Stack
              sx={{
                bgcolor: 'primary.main',
                borderRadius: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                pl: 4,
                pt: 4,
              }}
            >
              <List
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  pb: 4,
                }}
              >
                <Typography sx={{ color: 'grey.300', fontSize: 14, mb: 2 }}>
                  Este é o resumo das especificidades que foram atribuídas ao
                  desconto.
                </Typography>
                <ListItemIcon
                  sx={{
                    alignItems: 'center',
                    pb: 2,
                  }}
                >
                  <FiberManualRecord sx={{ color: 'grey.400', fontSize: 10 }} />
                  <ListItemText>
                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        ml: 1,
                        width: '100%',
                      }}
                    >
                      <Typography
                        sx={{
                          color: 'white',
                          fontSize: 14,
                        }}
                      >
                        Título:
                      </Typography>
                      <Typography
                        sx={{
                          color: 'white',
                          fontSize: 14,
                          fontWeight: 600,
                          pl: 1,
                        }}
                      >
                        {discount.title}
                      </Typography>
                    </Stack>
                  </ListItemText>
                </ListItemIcon>
                {isTypeCoupon && (
                  <ListItemIcon
                    sx={{
                      alignItems: 'center',
                      pb: 2,
                    }}
                  >
                    <FiberManualRecord
                      sx={{ color: 'grey.400', fontSize: 10 }}
                    />
                    <ListItemText>
                      <Stack
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          ml: 1,
                          width: '100%',
                        }}
                      >
                        <Typography
                          sx={{
                            color: 'white',
                            fontSize: 14,
                          }}
                        >
                          Código:
                        </Typography>
                        <Typography
                          sx={{
                            color: 'white',
                            fontSize: 14,
                            fontWeight: 600,
                            pl: 1,
                          }}
                        >
                          {discount.code}
                        </Typography>
                      </Stack>
                    </ListItemText>
                  </ListItemIcon>
                )}
                {isTypeCoupon && (
                  <ListItemIcon
                    sx={{
                      alignItems: 'center',
                      pb: 2,
                    }}
                  >
                    <FiberManualRecord
                      sx={{ color: 'grey.400', fontSize: 10 }}
                    />
                    <ListItemText>
                      <Stack
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          ml: 1,
                          width: '100%',
                        }}
                      >
                        <Typography
                          sx={{
                            color: 'white',
                            fontSize: 14,
                          }}
                        >
                          Cupons utilizados:
                        </Typography>
                        <Typography
                          sx={{
                            color: 'white',
                            fontSize: 14,
                            fontWeight: 600,
                            pl: 1,
                          }}
                        >
                          {discount.usageUnlimited 
                            ? 'Ilimitado' 
                            : `${amountUse} de ${discount.usageLimit}`
                          }
                        </Typography>
                      </Stack>
                    </ListItemText>
                  </ListItemIcon>
                )}
                <ListItemIcon sx={{ alignItems: 'center', pb: 2 }}>
                  <FiberManualRecord sx={{ color: 'grey.400', fontSize: 10 }} />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      ml: 1,
                      width: '100%',
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'white',
                        fontSize: 14,
                      }}
                    >
                      Valor:
                    </Typography>
                    <Typography
                      sx={{
                        color: 'white',
                        fontSize: 14,
                        fontWeight: 600,
                        pl: 1,
                      }}
                    >
                      {formatValue(discount.price)}
                    </Typography>
                  </Stack>
                </ListItemIcon>
                <ListItemIcon sx={{ alignItems: 'center', pb: 2 }}>
                  <FiberManualRecord sx={{ color: 'grey.400', fontSize: 10 }} />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      ml: 1,
                      width: '100%',
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'white',
                        fontSize: 14,
                      }}
                    >
                      Para espectadores pagantes a partir de
                    </Typography>
                    <Typography
                      sx={{
                        color: 'white',
                        fontSize: 14,
                        fontWeight: 600,
                        pl: 1,
                      }}
                    >
                      {getFriendlyNameRecurrence(discount.minimumPurchaseMonth)}
                    </Typography>
                  </Stack>
                </ListItemIcon>
                <ListItemIcon sx={{ alignItems: 'center', pb: 2 }}>
                  <FiberManualRecord sx={{ color: 'grey.400', fontSize: 10 }} />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      ml: 1,
                      width: '100%',
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'white',
                        fontSize: 14,
                      }}
                    >
                      Para espectadores do
                    </Typography>
                    <Typography
                      sx={{
                        color: 'grey.100',
                        fontSize: 14,
                        fontWeight: 600,
                        pl: 1,
                      }}
                    >
                      {discount.team ? discount.team.name : '-'}
                    </Typography>
                  </Stack>
                </ListItemIcon>
                <ListItemIcon sx={{ alignItems: 'center', pb: 2 }}>
                  <FiberManualRecord sx={{ color: 'grey.400', fontSize: 10 }} />
                  <Stack
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      ml: 1,
                      width: '100%',
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'white',
                        fontSize: 14,
                      }}
                    >
                      Planos associados:
                    </Typography>
                    <Typography
                      sx={{
                        color: 'grey.100',
                        fontSize: 14,
                        fontWeight: 600,
                        pl: 1,
                      }}
                    >
                      {discount.plans.length}
                    </Typography>
                    {havePlans && (
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          borderColor: 'secondary.main',
                          color: 'white',
                          ml: 2,
                        }}
                        onClick={openModal}
                      >
                        <Typography sx={{ color: 'white', fontSize: 14 }}>
                          Visualizar
                        </Typography>
                      </Button>
                    )}
                  </Stack>
                </ListItemIcon>
              </List>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default DiscountCard;
