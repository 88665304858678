import { gql } from '@apollo/client';

const rolesQuery = gql`
  query RolesQuery {
    roles {
      id
      title
      color
      enabled
      permissions {
        id
        create
        delete
        feature
        read
        update
      }
    }
  }
`;

export default rolesQuery;
