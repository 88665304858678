import { Page } from '../../../../interfaces/Plan';

export const sortByNewest = (prevContent: Page, currentContent: Page) => {
  const prevContentDate = new Date(prevContent.createdAt).getTime();
  const currentContentDate = new Date(currentContent.createdAt).getTime();
  const dateAreEquals = prevContentDate === currentContentDate;
  if (dateAreEquals) {
    return 0;
  }
  const prevContentDateIsBigger = prevContentDate > currentContentDate;
  if (prevContentDateIsBigger) {
    return -1;
  }
  return 1;
};

export const sortByOldest = (prevContent: Page, currentContent: Page) => {
  const prevContentDate = new Date(prevContent.createdAt).getTime();
  const currentContentDate = new Date(currentContent.createdAt).getTime();
  const dateAreEquals = prevContentDate === currentContentDate;
  if (dateAreEquals) {
    return 0;
  }
  const prevContentDateIsBigger = prevContentDate > currentContentDate;
  if (prevContentDateIsBigger) {
    return 1;
  }
  return -1;
};
