import { Box, Button, Stack } from '@material-ui/core';
import {
  Add as AddIcon,
} from '@material-ui/icons';
import { useContext } from 'react';
import Slider from 'react-slick';
import EmptyStateCard from '../../../components/EmptyStateCard';
import Question from './Question';
import Context from '../context';
import { Question as IQuestion } from '../typings';

const QuestionList = () => {
  const { questions, moveQuestion, openCreateQuestionModal } = useContext(Context);
  return (
    <Stack>
      <Slider
        dots={false}
        infinite={false}
        speed={500}
        slidesToShow={3}
        slidesToScroll={1}
        swipe={false}
        variableWidth
        prevArrow={
          <Box
            sx={{
              height: 54,
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              width: 54,
              '&::before': {
                fontSize: 40,
                color: 'grey.300',
              },
            }}
          />
        }
        nextArrow={
          <Box
            sx={{
              height: 54,
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              width: 54,
              '&::before': {
                fontSize: 40,
                color: 'grey.300',
              },
            }}
          />
        }
      >
        {questions.map((question: IQuestion, index: number) => (
          <Question
            index={index}
            question={question}
            moveQuestion={moveQuestion}
          />
        ))}
        <Stack
          alignItems="center"
          sx={{
            bgcolor: 'primary.main',
            borderRadius: 2,
            height: 382,
            width: 300,
          }}
        >
          <EmptyStateCard
            description="Crie outra pergunta para o primeiro acesso do espectador."
            icon={<AddIcon sx={{ color: 'info.main', fontSize: 50 }} />}
            title="Nova pergunta"
            sx={{ boxShadow: 'none', width: 300 }}
          >
            <Button
              onClick={openCreateQuestionModal}
              variant="outlined"
              sx={{
                bgcolor: '#8950FC1A', // TODO: Add this color to theme
                borderColor: '#8950FC',
              }}
            >
              Nova pergunta
            </Button>
          </EmptyStateCard>
        </Stack>
      </Slider>
    </Stack>
  );
};

export default QuestionList;
