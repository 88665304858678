import { useApolloClient } from '@apollo/client';
import {
  Avatar,
  Button,
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Pagination,
} from '@material-ui/core';
import {
  MoreVert,
  Person as SpectatorIcon,
  FolderSpecial as SpectatorSubscriptionIcon,
} from '@material-ui/icons';
import { ChangeEvent, useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate, useParams } from 'react-router-dom';
import SearchBar from '../../../components/UI/molecules/SearchBar';
import { formatDateByFNS } from '../../../utils/date';
import { sortArrayBy } from '../utils/sort';
import SpectatorsHeader from './SpectatorsHeader';
import getSpectatorsByDiscountQuery from '../gql/getSpectatorsByDiscountQuery';
import { Meta } from '../../Plans/Context';

const Spectators = () => {
  const { id } = useParams();
  const alert = useAlert();
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const [sortBy, setSortBy] = useState<any>();
  const [selectedSpectator, setSelectedSpectator] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [spectators, setSpectators] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');
  const [meta, setMeta] = useState<Meta | null>(null);
  const [input, setInput] = useState({
    filters: { id, term },
    paginate: { page: 1 },
  });
  const open = Boolean(anchorEl);
  const changeTermHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setTerm(evt.target.value);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    spectator: any,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedSpectator(spectator);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedSpectator(null);
  };
  const getSpectatorsByDiscount = async () => {
    const params = {
      query: getSpectatorsByDiscountQuery,
      variables: { input },
    };
    try {
      setLoading(true);
      const { data } = await apolloClient.query(params);
      setSpectators(
        data.getDiscountReportsAnalyse.spectatorsByDiscountId.items,
      );
      setMeta(data.getDiscountReportsAnalyse.spectatorsByDiscountId.meta);
    } catch (err) {
      alert.error('Ocorreu um erro ao buscar os espectadores.');
    } finally {
      setLoading(false);
    }
  };
  const sortByHandler = (sortByType: any) => setSortBy(sortByType);
  const sortedSpectators = sortBy
    ? [...spectators].sort(sortArrayBy(sortBy))
    : spectators;
  const navigateToSpectatorList = (action: string) => {
    navigate(
      `/spectators?SpectatorId=${selectedSpectator.id}&Action=${action}`,
    );
  };
  const filterSpectatorsByButton = () =>
    setInput({ ...input, filters: { id, term }, paginate: { page: 1 } });
  const paginationHandler = (_: ChangeEvent<unknown>, page: number) =>
    setInput({ ...input, paginate: { page } });
  useEffect(() => {
    getSpectatorsByDiscount();
  }, [input]);
  if (loading) return <div>Carregando...</div>;
  return (
    <>
      <SearchBar
        TextFieldProps={{
          value: term,
          placeholder:
            'Pesquise por espectadores usando Nome, E-mail ou Telefone',
          onChange: changeTermHandler,
        }}
        sx={{ mt: 4 }}
      >
        <Button onClick={filterSpectatorsByButton}>Buscar</Button>
      </SearchBar>
      <Card sx={{ borderRadius: 2, mt: 4, p: 5 }}>
        <SpectatorsHeader sortByHandler={sortByHandler} />
        {sortedSpectators.map((spectator) => (
          <Grid
            container
            gap={4}
            sx={{
              borderColor: 'grey.500',
              borderStyle: 'solid',
              borderWidth: 0,
              borderBottomWidth: 1,
              height: '100%',
              mb: 2,
              pb: 2,
            }}
          >
            <Grid item xs={3}>
              <Stack direction="row">
                <Avatar alt="avatar" sx={{ width: 42, height: 42 }} />
                <Stack direction="column" sx={{ marginLeft: 4 }}>
                  <Typography
                    sx={{
                      color: 'grey.100',
                      maxWidth: 180,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {spectator.name}
                  </Typography>
                  <Typography sx={{ color: 'grey.400', fontSize: 12 }}>
                    {spectator.phone}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={2} sx={{ alignItems: 'center', display: 'flex' }}>
              <Typography
                sx={{
                  color: 'grey.100',
                  fontSize: 14,
                }}
              >
                {formatDateByFNS(spectator.createdAt, 'dd/MM/yy')}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ alignItems: 'center', display: 'flex' }}>
              <Typography
                sx={{
                  color: 'grey.100',
                  fontSize: 14,
                }}
              >
                {spectator.recurrenceUnlimited
                  ? 'Indeterminado'
                  : spectator.remainingRecurrences}
              </Typography>
            </Grid>
            <Grid item xs={1} sx={{ alignItems: 'center', display: 'flex' }}>
              <Typography
                sx={{
                  color: 'grey.100',
                  fontSize: 14,
                }}
              >
                {spectator.signupAnswer?.tag?.name}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ alignItems: 'center', display: 'flex' }}>
              <Typography
                sx={{
                  color: 'grey.100',
                  fontSize: 14,
                  maxWidth: 170,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {spectator.email}
              </Typography>
            </Grid>
            <Grid item xs>
              <Box
                bgcolor="grey.500"
                sx={{
                  display: 'flex',
                  marginLeft: 'auto',
                  width: 48,
                  height: 40,
                  p: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                }}
              >
                <IconButton onClick={(evt) => handleClick(evt, spectator)}>
                  <MoreVert sx={{ color: 'grey.300' }} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Card>
      <Pagination
        count={meta?.totalPages}
        page={input.paginate.page}
        sx={{ mt: 4 }}
        onChange={paginationHandler}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => navigateToSpectatorList('SPECTATOR_DATA')}>
          <ListItemIcon>
            <SpectatorIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">Dados do Espectador</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigateToSpectatorList('SUBSCRIPTION')}>
          <ListItemIcon>
            <SpectatorSubscriptionIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">Assinaturas</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Spectators;
