import { gql } from '@apollo/client';

const getSectionQuery = gql`
  query GetSectionQuery($id: ID!) {
    section(id: $id) {
      id
      title
      scheduleDate
      scheduleDateEnabled
      finishDate
      finishDateEnabled
      published
      updatedAt
      sortableHighlightedContents {
        sortableItems {
          position 
          highlightedContent {
            id
            title
            fullHdLandscape
            fullHdPortrait
          }
        }
      }
      generalSubSection {
        id
        title
        sortableContents {
          position
          content {
            id
            title
            thumbnail
          }
        }
      }
      subSections {
        id
        title
        position
        sortableContents {
          position
          content {
            id
            title
            thumbnail
          }
        }
      }
    }
    contents {
      id
      title
      description
      authorizedOnMobile
      authorizedOnDesktop
      authorizedOnTv
      scheduleDate
      scheduleDateEnabled
      finishDate
      finishDateEnabled
      type
      published
      thumbnail
      sourceDuration
      sourceSize
      sourceResolution
      createdAt
      updatedAt
      tags {
        id
        name
        slug
      }
    }
  }
`;

export default getSectionQuery;
