import { createContext } from 'react';
import { Plan } from '../../interfaces/Plan';

type ContextType = {
  plan: Plan;
  planId: string;
  refetch: () => void;
};

const PreviewPlanContext = createContext<ContextType>({} as ContextType);

export default PreviewPlanContext;
