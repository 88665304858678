import {
  Avatar,
  Button,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Stack,
} from '@material-ui/core';
import { Add as AddIcon, Check as CheckIcon } from '@material-ui/icons';
import { SelectableContentListItemProps } from './typings';

const SelectableContentLisItem = ({
  content,
  selected,
  onToggle,
}: SelectableContentListItemProps) => {
  const onToggleHandler = () => onToggle(content);
  return (
    <ListItem
      sx={{
        bgcolor: 'primary.main',
        borderRadius: 1,
        height: 96,
        mb: 5,
        py: 4,
        pl: 5,
      }}
    >
      <ListItemAvatar>
        <Avatar
          src={content.thumbnail}
          variant="rounded"
          sx={{ height: 64, width: 114 }}
        />
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{ sx: { fontSize: 16, fontWeight: 600 } }}
        primary={content.title}
        secondary={
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 2 }}>
            <Chip
              label={content.type === 'vod' ? 'On demand' : 'Ao vivo'}
              color={content.type === 'vod' ? 'info' : 'error'}
              variant="outlined"
              size="small"
            />
          </Stack>
        }
        sx={{ color: 'grey.100', fontSize: 1, height: '100%', ml: 3 }}
      />
      <ListItemSecondaryAction sx={{ right: 24 }}>
        <Button
          color={selected ? 'success' : 'primary'}
          variant="outlined"
          endIcon={selected ? <CheckIcon /> : <AddIcon />}
          onClick={onToggleHandler}
        >
          {selected ? 'Selecionado' : 'Selecionar'}
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default SelectableContentLisItem;
