import { Stack } from '@material-ui/core';
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  YouTube as YouTubeIcon,
} from '@material-ui/icons';
import { useMemo } from 'react';

interface PageFooterProps {
  bgColor: string;
  facebookLink: string;
  iconsColor: string;
  instagramLink: string;
  hasVerticalLayout: boolean;
  twitterLink: string;
  youtubeLink: string;
}

const PageFooter = ({
  bgColor,
  facebookLink,
  iconsColor,
  instagramLink,
  hasVerticalLayout,
  twitterLink,
  youtubeLink,
}: PageFooterProps) => {
  const hasNoIcon = useMemo(() => (
    !facebookLink && !instagramLink && !twitterLink && !youtubeLink
  ), [facebookLink, instagramLink, twitterLink, youtubeLink]);
  return (
    <Stack
      alignItems="center"
      flexDirection={hasVerticalLayout ? 'column' : 'row'}
      gap={2}
      minHeight={70}
      justifyContent={hasNoIcon ? 'center' : 'space-between'}
      sx={{ bgcolor: bgColor, borderRadius: 2, mt: 4, px: 8, py: 1 }}
    >
      <img
        alt="logo"
        src="https://uilogos.co/img/logomark/leaf.png"
        style={{ height: 44 }}
      />
      <Stack flexDirection="row" sx={{ mb: -2 }}>
        {twitterLink && (
          <span style={{ color: iconsColor }} title={twitterLink}>
            <TwitterIcon />
          </span>
        )}
        {facebookLink && (
          <span style={{ color: iconsColor }} title={facebookLink}>
            <FacebookIcon />
          </span>
        )}
        {instagramLink && (
          <span style={{ color: iconsColor }} title={instagramLink}>
            <InstagramIcon />
          </span>
        )}
        {youtubeLink && (
          <span style={{ color: iconsColor }} title={youtubeLink}>
            <YouTubeIcon />
          </span>
        )}
      </Stack>
    </Stack>
  );
};

export default PageFooter;
