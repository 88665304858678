import { gql } from '@apollo/client';

const sectionsPaginateQuery = gql`
  query SectionsPaginateQuery($input: ListSectionsPaginateInput!) {
    sectionsPaginate(input: $input) {
      id
      title
    }
  }
`;

export default sectionsPaginateQuery;
