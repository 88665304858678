// TODO: Remove when Craft.js support settings props without param reassign
/* eslint-disable no-param-reassign */
import { useEditor, useNode } from '@craftjs/core';
import { Stack, Switch, Typography } from '@material-ui/core';
import {
  ViewColumn as ViewColumnIcon,
} from '@material-ui/icons';
import { useMemo, useState } from 'react';
import ColorPicker from '../../components/ColorPicker';
import SidebarAccordion from '../../components/SidebarAccordion';
import { EditorType } from '../main/typings';
import { NodePropsType } from './typings';

interface StructureProps {
  bgColor?: string;
  parentId?: string;
};

const StructuresSettings = () => {
  const [expanded, setExpanded] = useState(true);
  const {
    actions: { setProp: setSelfProp },
    props: { bgColor, parentId },
  }: NodePropsType<StructureProps> = useNode((node) => ({
    props: node.data.props,
  }));
  const {
    actions: { setProp: setParentProp },
    nodes,
  }: EditorType<StructureProps> = useEditor((query) => ({ nodes: query.nodes }));
  if (!nodes) return null;
  const [parentNode] = useMemo(() =>
    Object.values(nodes).filter(
      (node) => node.id === parentId), [nodes]);
  const bgColorChangeHandler = (color: string) => {
    if (parentNode) {
      setParentProp(parentNode.id, (currentProps) => {
        currentProps.bgColor = color;
      });
    } else {
      setSelfProp((currentProps) => {
        currentProps.bgColor = color;
      });
    }
  };
  return (
    <SidebarAccordion
      expanded={expanded}
      icon={
        <ViewColumnIcon
          sx={{
            color: 'secondary.main',
            height: 24,
            width: 24,
          }}
        />
      }
      title="Opções de estruturas"
      onExpand={() => setExpanded(!expanded)}
    >
      <ColorPicker
        color={bgColor || parentNode?.data.props.bgColor || '#FFFFFF'}
        name="Cor de fundo da estrutura"
        onChange={bgColorChangeHandler}
      />
      <Stack direction="row">
        <Typography color="grey.300" fontSize={14}>
          Ordem das estruturas
        </Typography>
      </Stack>
      <Stack direction="row">
        <Typography
          sx={{
            color: 'grey.300',
            fontSize: 12,
            fontWeight: '400',
          }}
        >
          Inverter em dispositivos móveis
        </Typography>
        <Switch sx={{ ml: 3, mr: 5 }} />
      </Stack>
    </SidebarAccordion>
  );
};

export default StructuresSettings;
