import { gql } from '@apollo/client';

const getHighlightedContentQuery = gql`
  query GetHighlightedContentQuery($id: ID!) {
    highlightedContent(id: $id) {
      id
      title
      description
      fullHdPortrait
      fullHdLandscape
      scheduleDate
      finishDate
      position
      content {
        id
        title
        type
        thumbnail
      }
    }
  }
`;

export default getHighlightedContentQuery;
