import { Theme } from '@material-ui/core';
import { Components } from '@material-ui/core/styles/components';

const makeMuiOutlinedInput = (
  theme: Theme,
): Components['MuiOutlinedInput'] => ({
  styleOverrides: {
    root: {
      borderRadius: theme.shape.borderRadius,
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[500],
      },
      '&.Mui-focused .MuiOutlinedInput-input': {
        backgroundColor: theme.palette.primary.main,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[300],
      },
    },
  },
});

export default makeMuiOutlinedInput;
