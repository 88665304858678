import { Box, Card, Stack, Typography } from '@material-ui/core';
import { EmptyStateCardProps } from './typings';

const EmptyStateCard = ({
  icon,
  title,
  description,
  children,
  iconProps,
  sx,
}: EmptyStateCardProps) => (
  <Card sx={{ height: 328, ...sx }}>
    <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
      <Box
        sx={{
          m: 0,
          '& .MuiSvgIcon-root': { color: 'info.main', height: 48, width: 48 },
          ...iconProps,
        }}
      >
        {icon}
      </Box>
      <Typography
        sx={{ color: 'common.white', fontSize: 20, fontWeight: 600, mt: 5 }}
      >
        {title}
      </Typography>
      <Typography
        align="center"
        sx={{
          color: 'grey.300',
          fontSize: 14,
          maxWidth: 380,
          mb: 4,
          mt: 2,
          width: '100%',
        }}
      >
        {description}
      </Typography>
      {children}
    </Stack>
  </Card>
);

export default EmptyStateCard;
