import { gql } from '@apollo/client';

const createTagMutation = gql`
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      id
      name
      slug
    }
  }
`;

export default createTagMutation;
