import { Card, Stack, Typography } from '@material-ui/core';
import { Sensors as SensorsIcon } from '@material-ui/icons';

const EmptyGameList = () => (
  <Card sx={{ height: 328, mt: 4 }}>
    <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
      <SensorsIcon color="info"sx={{ fontSize: 40 }} />
      <Typography
        sx={{ color: 'grey.100', fontSize: 20, fontWeight: 600, mt: 3 }}
      >
        Nenhum jogo agendado
      </Typography>
      <Typography
        sx={{
          color: 'grey.300',
          fontSize: 14,
          mt: 2,
          textAlign: 'center',
          width: 380,
        }}
      >
        Não foi encontrado nenhum jogo agendado para a data escolhida.
      </Typography>
    </Stack>
  </Card>
);

export default EmptyGameList;
