import {
  Close as CloseIcon,
  Edit as EditIcon,
  MoreVert as OptionsIcon,
  VpnLock as WorldIcon,
} from '@material-ui/icons';
import {
  Box,
  Button,
  Card,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@material-ui/core';
import { MouseEvent, useContext, useState } from 'react';
import { Role } from '../../../interfaces/Role';
import { getPermissionsPhrase } from '../utils/permissions';
import Context from '../Context';

interface RoleItemProps {
  role: Role;
}

const RoleItem = ({ role }: RoleItemProps) => {
  const { editRoleHandler, deleteRoleHandler } = useContext(Context);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const closeMenuHandler = () => setAnchorEl(null);
  const openMenuHandler = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const isPlansMenuOpened = !!anchorEl;
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={isPlansMenuOpened}
        onClose={closeMenuHandler}
      >
        <MenuItem onClick={() => editRoleHandler(role)}>
          <ListItemIcon>
            <EditIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Editar cargo
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => deleteRoleHandler(role)}>
          <ListItemIcon>
            <CloseIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Excluir cargo
          </Typography>
        </MenuItem>
      </Menu>
      <Card sx={{ height: 460, mt: 6, p: 4, pt: 6, width: 369 }}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography
            sx={{
              alignItems: 'center',
              color: 'grey.100',
              display: 'flex',
              fontWeight: 600,
            }}
          >
            <WorldIcon color="secondary" sx={{ mr: 2 }} />
            {role.title}
          </Typography>
          <Button
            sx={{ bgcolor: 'grey.500', height: 40 }}
            onClick={openMenuHandler}
          >
            <OptionsIcon />
          </Button>
        </Stack>
        <Divider sx={{ bgcolor: 'grey.500', my: 4 }} />
        <Typography
          sx={{
            color: 'grey.400',
            fontSize: 12,
            fontWeight: 600,
            mb: 3,
            ml: 4,
          }}
        >
          PERMISSÕES DO CARGO
        </Typography>
        {role.permissions.map((permission) => {
          const phrasePermission = getPermissionsPhrase(permission);
          return (
            phrasePermission && (
              <>
                <Stack alignItems="center" direction="row">
                  <Box
                    sx={{
                      bgcolor: 'grey.500',
                      borderRadius: '50%',
                      height: 8,
                      mr: 2,
                      width: 8,
                    }}
                  />
                  <Typography sx={{ color: 'grey.100', fontSize: 12 }}>
                    {phrasePermission}
                  </Typography>
                </Stack>
                <Divider sx={{ bgcolor: 'grey.500', my: 3 }} />
              </>
            )
          );
        })}
      </Card>
    </>
  );
};

export default RoleItem;
