import {
  Button,
  Card,
  CardContent,
  Chip,
  FormHelperText,
  Grid,
  IconButton,
  OutlinedInput,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Computer as ComputerIcon,
  Info as InfoIcon,
  Smartphone as SmartphoneIcon,
  Tv as TvIcon,
} from '@material-ui/icons';
import { useContext } from 'react';
import { useDialog } from '../../../components/Dialog';
import ScheduleCard from '../../../components/ScheduleCard';
import { ContentType } from '../../../interfaces/Content';
import { HeartTeam } from '../../../interfaces/HeartTeam';
import Context from '../context';
import ModalToAddMCLSContent from './ModalToAddMCLSContent';
import TeamsModal from './TeamsModal';

const Setup = () => {
  const { form } = useContext(Context);
  const dialog = useDialog();
  const contentIsLive = form.values.type === ContentType.live;
  const hasChat = !!form.values.chatEnable;
  const addTeamToContent = (teams: HeartTeam[]) =>
    form.setFieldValue('teams', teams);
  const viewTagContent = () => {
    dialog.open({
      title: 'Times do conteúdo',
      element: (
        <TeamsModal
          teams={form.values.teams}
          addTeamToContent={addTeamToContent}
        />
      ),
    });
  };
  const addContentMCLS = async () => {
    await dialog.open({
      title: 'Adicionar conteúdo',
      element: (
        <ModalToAddMCLSContent
          selectedContent={form.values.mclsContent}
          onSelect={(content: any) => {
            form.setFieldValue('spallaProvider', !content);
            form.setFieldValue('mclsContent', content);
          }}
        />
      ),
      sx: { height: 580, width: 600 },
    });
  };
  const contentIsNotExternal = !form.values.externalContent;
  return (
    <>
      <Grid container spacing={4} sx={{ mt: 0 }}>
        <Grid item xs data-testid="schedule-date-card">
          <ScheduleCard
            name="scheduleDate"
            error={form.errors.scheduleDate}
            label="Agendar publicação?"
            value={form.values.scheduleDate}
            onChange={form.handleChange}
            SwitchProps={{
              name: 'scheduleDateEnabled',
              onChange: form.handleChange,
              checked: form.values.scheduleDateEnabled,
            }}
          />
        </Grid>
        <Grid item xs data-testid="end-date-card">
          <ScheduleCard
            name="finishDate"
            error={form.errors.finishDate}
            label="Agendar saída?"
            value={form.values.finishDate}
            onChange={form.handleChange}
            SwitchProps={{
              name: 'finishDateEnabled',
              onChange: form.handleChange,
              checked: form.values.finishDateEnabled,
            }}
            // @ts-ignore
            DateTimePickerProps={{
              minDate: form.values.scheduleDate,
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Card sx={{ height: 160, p: 5 }}>
            <Typography sx={{ color: 'grey.100', fontWeight: 600, mb: 2 }}>
              Times do conteúdo
            </Typography>
            <Typography sx={{ color: 'grey.300', fontSize: 14, mb: 5 }}>
              Adicione, remova e organize os times do seu conteúdo
            </Typography>
            <Button
              data-testid="button-show-teams"
              color="primary"
              component="span"
              variant="contained"
              onClick={viewTagContent}
            >
              Gerenciar times
            </Button>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={contentIsNotExternal ? 8 : 12}>
          <Card sx={{ height: 160, mt: 4 }}>
            <CardContent>
              <Typography sx={{ color: 'grey.100', fontWeight: 600, mb: 6 }}>
                Dispositivos autorizados
              </Typography>
              <Stack alignItems="center" direction="row" spacing={6}>
                <Stack alignItems="center" direction="row" spacing={4}>
                  <Switch
                    data-testid="device-mobile"
                    color="secondary"
                    name="authorizedOnMobile"
                    onChange={form.handleChange}
                    checked={form.values.authorizedOnMobile}
                  />
                  <Chip
                    icon={<SmartphoneIcon />}
                    label={
                      <Typography
                        sx={{
                          color: 'grey.100',
                          fontSize: 12,
                          fontWeight: 600,
                        }}
                      >
                        Mobile
                      </Typography>
                    }
                    sx={{
                      bgcolor: 'grey.500',
                      color: form.values.authorizedOnMobile
                        ? 'secondary.main'
                        : 'secondary.light',
                    }}
                  />
                </Stack>
                <Stack alignItems="center" direction="row" spacing={4}>
                  <Switch
                    data-testid="device-desktop"
                    name="authorizedOnDesktop"
                    checked={form.values.authorizedOnDesktop}
                    onChange={form.handleChange}
                  />
                  <Chip
                    icon={<ComputerIcon />}
                    label={
                      <Typography
                        sx={{
                          color: 'grey.100',
                          fontSize: 12,
                          fontWeight: 600,
                        }}
                      >
                        Desktop
                      </Typography>
                    }
                    sx={{
                      bgcolor: 'grey.500',
                      color: form.values.authorizedOnDesktop
                        ? 'secondary.main'
                        : 'secondary.light',
                    }}
                  />
                </Stack>
                <Stack alignItems="center" direction="row" spacing={4}>
                  <Switch
                    data-testid="device-smarttv"
                    name="authorizedOnTv"
                    checked={form.values.authorizedOnTv}
                    onChange={form.handleChange}
                  />
                  <Chip
                    icon={<TvIcon />}
                    label={
                      <Typography
                        sx={{
                          color: 'grey.100',
                          fontSize: 12,
                          fontWeight: 600,
                        }}
                      >
                        Smarttv
                      </Typography>
                    }
                    sx={{
                      bgcolor: 'grey.500',
                      color: form.values.authorizedOnTv
                        ? 'secondary.main'
                        : 'secondary.light',
                    }}
                  />
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        {contentIsNotExternal && (
          <Grid item xs={4}>
            <Card sx={{ height: 160, mt: 4 }}>
              <CardContent>
                <Typography sx={{ color: 'grey.100', fontWeight: 600, mb: 2 }}>
                  Adicionar conteúdo MCLS
                </Typography>
                {form.values.spallaProvider ? (
                  <Typography sx={{ color: 'grey.300', fontSize: 14, mb: 4 }}>
                    Nenhum conteúdo selecionado.
                  </Typography>
                ) : (
                  <Typography sx={{ color: 'grey.300', fontSize: 14, mb: 4 }}>
                    {form.values.mclsContent?.title}
                  </Typography>
                )}
                <Button onClick={addContentMCLS}>Adicionar conteúdo</Button>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <Stack alignItems="center" direction="row">
        {contentIsLive && (
          <Card sx={{ height: 72, mr: 4, mt: 4, width: 398 }}>
            <CardContent sx={{ height: '100%' }}>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                sx={{ height: '100%' }}
              >
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                  sx={{ padding: 0 }}
                >
                  <Typography sx={{ color: 'grey.100', fontWeight: 600 }}>
                    Converter live em VOD
                  </Typography>
                  <Tooltip title="Converter live em VOD">
                    <IconButton>
                      <InfoIcon fontSize="small" sx={{ color: 'grey.400' }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
                <Switch color="secondary" />
              </Stack>
            </CardContent>
          </Card>
        )}
        {contentIsNotExternal && (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ width: '100%', mt: 4 }}
          >
            <Card sx={{ height: 72, mr: 4, width: 224 }}>
              <CardContent>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography sx={{ color: 'grey.100', fontWeight: 600 }}>
                    Ativar chat
                  </Typography>
                  <Switch
                    name="chatEnable"
                    color="secondary"
                    checked={form.values.chatEnable}
                    value={form.values.chatEnable}
                    onChange={form.handleChange}
                  />
                </Stack>
              </CardContent>
            </Card>
            {hasChat && (
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  height: 72,
                  justifyContent: 'center',
                  p: 4,
                }}
              >
                <Typography sx={{ color: 'grey.100', fontWeight: 500 }}>
                  Embed do Chat
                </Typography>
                <OutlinedInput
                  name="chatEmbed"
                  error={!!form.errors.chatEmbed && form.touched.chatEmbed}
                  value={form.values.chatEmbed}
                  onChange={form.handleChange}
                  placeholder="Insira o embed do chat"
                  fullWidth
                />
                <FormHelperText>
                  {!!form.errors.chatEmbed && form.touched.chatEmbed}
                </FormHelperText>
              </Card>
            )}
          </Stack>
        )}
        {contentIsNotExternal && (
          <Card sx={{ height: 72, mr: 4, mt: 4, width: 398 }}>
            <CardContent sx={{ height: '100%' }}>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                sx={{ height: '100%' }}
              >
                <Typography sx={{ color: 'grey.100', fontWeight: 600 }}>
                  Conteúdo spalla
                </Typography>
                <Switch
                  name="spallaProvider"
                  checked={!!form.values.spallaProvider}
                  color="secondary"
                />
              </Stack>
            </CardContent>
          </Card>
        )}
      </Stack>
    </>
  );
};

export default Setup;
