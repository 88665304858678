import { Grid, Stack, Typography } from '@material-ui/core';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import {
  Star as AssociatedPlans,
  Check as CheckIcon,
} from '@material-ui/icons';
import { Discount, DiscountType } from '../../../interfaces/Discount';
import { formatDateByFNS } from '../../../utils/date';
import CardGroup from '../../FinancialDiscountReport/Components/Card';
import { ValueGroup } from '../../FinancialDiscountReport/Components/helpers';
import { DiscountAnalyse } from '../typings';
import getFriendlyNameByDiscountType from '../utils/getFriendlyNameByDiscountType';

interface PublicAndPlansProps {
  discount: Discount;
  discountAnalyse: DiscountAnalyse;
}

const PublicAndPlans = ({ discount, discountAnalyse }: PublicAndPlansProps) => {
  const discountIsRetentionType = discount.type === DiscountType.Retention;
  const discountIsExternalOrRetentionType =
    discount.type === DiscountType.Collective ||
    discount.type === DiscountType.Retention;
  const thereArePlans = discountAnalyse.plansLinkedByDiscountId.length > 0;
  return (
    <>
      <CardGroup
        icon={CheckIcon}
        title={`Público ${getFriendlyNameByDiscountType(discount.type)}`}
        sx={{ height: 210 }}
      >
        <Stack alignItems="center" direction="row" sx={{ mt: 4 }}>
          <ValueGroup
            title="Período como assinante"
            value={
              discountIsExternalOrRetentionType
                ? `${discountAnalyse.durationAvg} meses`
                : '-'
            }
          />
          <ValueGroup
            title="Resposta da Pergunta obrigatória"
            value={discountIsRetentionType ? discount.team?.name : '-'}
            sx={{ ml: '74px' }}
          />
        </Stack>
        <Stack alignItems="center" direction="row" sx={{ mt: 5 }}>
          <ValueGroup
            title="Espectadores oferecidos"
            value={discountAnalyse.viewsDiscountRetentions}
          />
          <ValueGroup
            title="Espectadores com desconto ativo"
            value={discountAnalyse.spectatorsActivies}
            sx={{ ml: '56px' }}
          />
        </Stack>
      </CardGroup>
      <CardGroup
        icon={AssociatedPlans}
        title="Planos associados"
        sx={{ height: 360, mt: 4 }}
      >
        <Grid container sx={{ mb: 2, mt: 4 }}>
          <Grid item xs={5} sx={{ color: 'grey.400' }}>
            Plano
          </Grid>
          <Grid item xs sx={{ color: 'grey.400' }}>
            Ações
          </Grid>
          {thereArePlans && (
            <Grid item xs sx={{ color: 'grey.400' }}>
              Data
            </Grid>
          )}
        </Grid>
        <Grid container sx={{ maxHeight: 249, overflowY: 'auto' }}>
          {thereArePlans ? (
            discountAnalyse.plansLinkedByDiscountId.map((analyse: any) => (
              <>
                <Grid item xs={5}>
                  <Typography
                    sx={{
                      color: 'grey.100',
                      fontSize: 14,
                      fontWeight: 600,
                      maxWidth: 174,
                      mt: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {analyse.title}
                  </Typography>
                </Grid>
                <Grid item xs sx={{ color: 'grey.400' }}>
                  <Timeline sx={{ alignSelf: 'flex-start', m: 0, p: 0 }}>
                    {analyse.actions.map((action: any) => (
                      <>
                        {action.startedAt && (
                          <TimelineItem
                            sx={{
                              minHeight: 50,
                              '::before': {
                                display: 'none',
                              },
                            }}
                          >
                            <TimelineSeparator>
                              <TimelineDot
                                sx={{
                                  bgcolor: 'secondary.main',
                                  height: '1px !important',
                                  p: '2px !important',
                                  width: '1px !important',
                                }}
                              />
                              {action.finishedAt && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent
                              sx={{ color: 'grey.200', fontSize: 14 }}
                            >
                              Adicionado
                            </TimelineContent>
                          </TimelineItem>
                        )}
                        {action.finishedAt && (
                          <TimelineItem
                            sx={{
                              minHeight: 50,
                              '::before': {
                                display: 'none',
                              },
                            }}
                          >
                            <TimelineSeparator>
                              <TimelineDot
                                sx={{
                                  bgcolor: 'grey.400',
                                  height: '1px !important',
                                  width: '1px !important',
                                  p: '2px !important',
                                }}
                              />
                            </TimelineSeparator>
                            <TimelineContent
                              sx={{ color: 'grey.200', fontSize: 14 }}
                            >
                              Removido
                            </TimelineContent>
                          </TimelineItem>
                        )}
                      </>
                    ))}
                  </Timeline>
                </Grid>
                <Grid item xs sx={{ color: 'grey.400' }}>
                  <Timeline sx={{ alignSelf: 'flex-start', m: 0, p: 0 }}>
                    {analyse.actions.map((action: any) => (
                      <>
                        {action.startedAt && (
                          <TimelineItem
                            sx={{
                              minHeight: 50,
                              '::before': {
                                display: 'none',
                              },
                            }}
                          >
                            <TimelineContent
                              sx={{ color: 'grey.200', fontSize: 14, pl: 0 }}
                            >
                              {formatDateByFNS(action.startedAt, 'dd/MM/yy')}
                            </TimelineContent>
                          </TimelineItem>
                        )}
                        {action.finishedAt && (
                          <TimelineItem
                            sx={{
                              minHeight: 50,
                              '::before': {
                                display: 'none',
                              },
                            }}
                          >
                            <TimelineContent
                              sx={{ color: 'grey.200', fontSize: 14, pl: 0 }}
                            >
                              {formatDateByFNS(action.finishedAt, 'dd/MM/yy')}
                            </TimelineContent>
                          </TimelineItem>
                        )}
                      </>
                    ))}
                  </Timeline>
                </Grid>
              </>
            ))
          ) : (
            <>
              <Grid item xs={5} sx={{ color: 'grey.400' }}>
                -
              </Grid>
              <Grid item xs sx={{ color: 'grey.400' }}>
                -
              </Grid>
            </>
          )}
        </Grid>
      </CardGroup>
    </>
  );
};

export default PublicAndPlans;
