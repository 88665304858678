import { Card, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface FinancialReportCardProps {
  title: string;
  description: string;
  link: string;
}

const FinancialReportCard = ({
  title,
  description,
  link,
}: FinancialReportCardProps) => (
  <Link to={link}>
    <Card sx={{ height: 135, p: 5, width: 580 }}>
      <Typography sx={{ color: 'common.white', fontSize: 24, fontWeight: 600 }}>
        {title}
      </Typography>
      <Typography sx={{ color: 'grey.300', fontSize: 14, mt: 2 }}>
        {description}
      </Typography>
    </Card>
  </Link>
);

export default FinancialReportCard;
