import { gql } from '@apollo/client';

const createCollectiveDiscountMutation = gql`
  mutation CreateCollectiveDiscountMutation(
    $input: CreateCollectiveDiscountInput!
  ) {
    createCollectiveDiscount(input: $input) {
      id
    }
  }
`;

export default createCollectiveDiscountMutation;
