import { gql } from '@apollo/client';

const importSpallaLive = gql`
  mutation ImportSpallaLive($spallaContentId: ID!) {
    importSpallaLive(spallaContentId: $spallaContentId) {
      spallaContentId
    }
  }
`;

export default importSpallaLive;