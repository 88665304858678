import { Card, Stack, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { TileCard4Props } from './typings';

const TileCard4 = ({
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  title,
  description,
  children,
  sx,
  link,
  disabled
}: TileCard4Props) => (
  <Card sx={sx}>
    <Stack>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <LeftIcon sx={{ color: disabled ? 'grey.400' : 'secondary.main' }} />
        {RightIcon && (
          disabled ? (
            <RightIcon sx={{ color: 'grey.400' }} />
          ): (
          <Link to={`${link}`}>
            <RightIcon sx={{ color: 'common.white' }} />
          </Link>
          )
        )}
      </Stack>
      <Typography
        sx={{ color: disabled ? 'grey.400' : 'grey.100', fontSize: 16, fontWeight: 600, mb: 2 }}
      >
        {title}
      </Typography>
      <Typography sx={{ color: disabled ? 'grey.400' : 'grey.300', fontSize: 14, mb: 5 }}>
        {description}
      </Typography>
    </Stack>
    {children}
  </Card>
);

export default TileCard4;
