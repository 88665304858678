import { useQuery } from '@apollo/client';
import { useAuthorize } from '@tshio/react-router-permissions';
import { useContext } from 'react';
import HasNoPermission from '../../components/HasNoPermission';
import Topbar from '../../components/Topbar';
import TipAlert from '../../components/UI/molecules/TipAlert';
import ContextToMe from '../../Context';
import Context from './Context';
import getAllActiveDiscountsCountQuery from './gql/getAllValuesFromOverview';
import PaymentAndPartners from './Components/PaymentAndPartners';
import PlansAndMemberships from './Components/PlansAndMemberships';
import SettingsPlans from './Components/SettingsPlans';

const Overview = () => {
  const { me } = useContext(ContextToMe);
  const permissionForEverything = me.superAdmin;
  const permissionToCreatePlans =
    permissionForEverything || useAuthorize(['plans', 'create']);
  const permissionToReadPlans =
    permissionForEverything || useAuthorize(['plans', 'read']);
  const permissionsToReadSpectators =
    permissionForEverything || useAuthorize(['spectators', 'read']);
  if (!permissionToReadPlans) return <HasNoPermission />;
  const { loading, data } = useQuery(getAllActiveDiscountsCountQuery);
  if (loading) {
    return <div>Carregando...</div>;
  }
  return (
    <Context.Provider
      value={{
        valuesOverview: data,
        permissionToCreatePlans,
        permissionToReadPlans,
        permissionsToReadSpectators,
      }}
    >
      <Topbar
        title="Central dos Planos"
        description="Configure cada etapa do espectador na plataforma, criando planos, definindo afiliados e marcas e o processo de onboarding."
      />
      <TipAlert sx={{ mt: 4 }}>
        Acompanhe as métricas dos seus planos e entenda como melhor se conectar
        com os seus espectadores!
      </TipAlert>
      <PlansAndMemberships />
      <PaymentAndPartners />
      <SettingsPlans />
    </Context.Provider>
  );
};

export default Overview;
