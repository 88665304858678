import { useMutation, useQuery } from '@apollo/client';
import { Box, Stack } from '@material-ui/core';
import { formatDistance, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import { useAuthorize } from '@tshio/react-router-permissions';
import { useContext } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate, useParams } from 'react-router-dom';
import { useDialog } from '../../components/Dialog';
import HasNoPermission from '../../components/HasNoPermission';
import TipAlert from '../../components/UI/molecules/TipAlert';
import FormFooter from '../../components/UI/templates/FormFooter';
import ContextToMe from '../../Context';
import { PlanType } from '../../interfaces/Plan';
import { Section } from '../../interfaces/Section';
import Context from '../CreatePlan/Context';
import Details from '../CreatePlan/Components/Details';
import ScreensAndTrialPeriod from '../CreatePlan/Components/ScreensAndTrialPeriod';
import MembershipsAndAmounts from '../CreatePlan/Components/MembershipsAndAmounts';
import SectionsAndContents from '../CreatePlan/Components/SectionsAndContents';
import deletePlanMutation from '../Plans/gql/deletePlanMutation';
import PlanQuery from '../PreviewPlan/gql/planQuery';
import Header from '../CreatePlan/Components/Header';
import Payments from '../CreatePlan/Components/Payments';
import getMultiSectionQuery from '../HighlightSections/gql/getMultiSectionQuery';
import updatePlantMutation from './gql/updatePlanMutation';
import PaymentBonds from './PaymentBond';
import validationSchema from './providers/validationSchema';

const EditPlan = () => {
  const { me } = useContext(ContextToMe);
  const permissionForEverything = me.superAdmin;
  const permissionToUpdate =
    permissionForEverything || useAuthorize(['plans', 'update']);
  const hasNoPermissionToUpdate = !permissionToUpdate;
  if (hasNoPermissionToUpdate) return <HasNoPermission />;
  const dialog = useDialog();
  const alert = useAlert();
  const navigate = useNavigate();
  const { planId } = useParams();
  const { loading: loadingMultiSections, data: multiSection } =
    useQuery(getMultiSectionQuery);
  const { loading, data } = useQuery(PlanQuery, { variables: { id: planId } });
  const [updatePlan, { loading: isUpdating }] =
    useMutation(updatePlantMutation);
  const [deletePlan] = useMutation(deletePlanMutation);
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.plan.id,
      title: data?.plan.title,
      description: data?.plan.description,
      published: data?.plan.published,
      price: data?.plan.price,
      membershipsCount: data?.plan.membershipsCount,
      unlimitedMemberships: data?.plan.unlimitedMemberships,
      trialDays: data?.plan.trialDays,
      trialDaysIsActive: data?.plan.trialDaysIsActive,
      installments: data?.plan.installments,
      nextSeasonDate: data?.plan.nextSeasonDate,
      type: data?.plan.type,
      sortableHighlightedContents: data?.plan.sortableHighlightedContents,
      paymentType: data?.plan.paymentType,
      multiSection: data?.plan.multiSection,
      partners: data?.plan.partners,
      sections: data?.plan.multiSection
        ? [...data?.plan.sections, data?.plan.multiSection]
        : data?.plan.sections,
    },
    validationSchema,
    onSubmit: async (values) => {
      const multiSectionIsSelected = values.sections.some(
        (section: Section) => section.id === multiSection?.multiSection.id,
      );
      const removeMultiSection = values.sections.filter(
        (section: Section) => section.id !== multiSection?.multiSection.id,
      );
      const newSections = multiSectionIsSelected
        ? removeMultiSection
        : values.sections;
      const newMultiSection = multiSectionIsSelected
        ? { id: multiSection?.multiSection.id }
        : null;
      try {
        const newValues = {
          ...values,
          sections: newSections,
          multiSection: newMultiSection,
        };
        const input = validationSchema.cast(newValues);
        await updatePlan({ variables: { input } });
        alert.success('Plano editado com sucesso.');
      } catch (err: any) {
        const planAlreadyExists =
          !!err.graphQLErrors?.[0].message.includes('planAlreadyExists');
        if (planAlreadyExists) {
          form.setFieldError('title', 'Um plano com este nome já existe.');
        }
      }
    },
  });
  const compareDistanceEditDate = () =>
    formatDistance(
      parseISO(new Date(data.plan.updatedAt).toISOString()),
      parseISO(new Date().toISOString()),
      { locale: ptBR },
    );
  const deletePlanHandler = async () => {
    const confirm = await dialog.confirm({
      title: 'Excluir plano',
      message:
        'Excluir um plano o remove permanentemente da sua biblioteca. Não será possível recuperá-lo.',
    });
    if (confirm) {
      await deletePlan({ variables: { id: planId } });
      navigate(`/plans?type=${data.plan.type}`);
      alert.success('Plano deletado com sucesso.');
    }
  };
  if (loading || !data || !form.values.id || loadingMultiSections) {
    return <div>loading...</div>;
  }
  const hasErrors = !form.isValid;
  const planTypeIsNotPartners = form.values.type !== PlanType.partner;
  const planTypeIsNotFreeOrPartners =
    form.values.type !== PlanType.free && planTypeIsNotPartners;
  const isEditing = !!form.values.id && !form.values.published;
  return (
    <Context.Provider value={{ form, isEditing }}>
      <>
        <Header
          route={`/plans?type=${data.plan.type}`}
          title="Edição de Plano"
          isEditing
        />
        <PaymentBonds />
        <TipAlert sx={{ mt: 4 }}>
          {isEditing
            ? 'No plano inativo só podemos alterar os Destaques e as Seções.'
            : 'Crie os planos necessários para cada oferta de serviço e selecione as páginas necessárias para cada etapa do espectador na plataforma.'}
        </TipAlert>
        <Stack direction="row" gap={4} sx={{ mt: 6 }}>
          <Box>
            <Details />
            <SectionsAndContents />
            {planTypeIsNotFreeOrPartners && <ScreensAndTrialPeriod />}
          </Box>
          <Box>
            <MembershipsAndAmounts />
            <Payments />
          </Box>
        </Stack>
        <FormFooter
          LeftButtonProps={{
            children: 'Salvar alterações',
            type: 'submit',
            loading: isUpdating,
            onClick: () => form.handleSubmit(),
          }}
          helperText={
            hasErrors
              ? 'Verifique os campos acima '
              : `Última alteração: ${compareDistanceEditDate()}`
          }
          helperTextProps={{ color: hasErrors ? 'error.main' : 'grey.100' }}
          RightButtonProps={{
            children: 'Excluir plano',
            onClick: () => deletePlanHandler(),
          }}
          sx={{ mt: 6 }}
        />
      </>
    </Context.Provider>
  );
};

export default EditPlan;
