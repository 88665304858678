import { useQuery } from '@apollo/client';
import {
  Avatar,
  Button,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  SelectChangeEvent,
  Typography,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { ChangeEvent, useState } from 'react';
import { useDialog } from '../../../components/Dialog';
import { Content } from '../../../interfaces/Content';
import { SortBy } from '../../../typings/sort';
import { compareTerms } from '../../../utils/string';
import { sortByNewest, sortByOldest } from '../../PreviewPlan/utils/sort';
import contentsQuery from '../../CreatePlan/gql/contentsQuery';

interface DialogContentsProps {
  onSelect: (content: Content) => void;
  selectedContent: Content;
}

const DialogContents = ({ selectedContent, onSelect }: DialogContentsProps) => {
  const dialog = useDialog();
  const { loading, data } = useQuery(contentsQuery);
  const [query, setQuery] = useState('');
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.Newest);
  const changeQueryHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setQuery(evt.target.value);
  const sortByHandler = (evt: SelectChangeEvent<SortBy>) =>
    setSortBy(evt.target.value as SortBy);
  if (loading) return <div>Carregando...</div>;
  const contents = query
    ? data.contents.filter((content: Content) =>
        compareTerms(content.title, query),
      )
    : data.contents;
  const sortedContents = [...contents].sort(
    sortBy === SortBy.Oldest ? sortByOldest : sortByNewest,
  );
  const selectContent = (content: Content) => {
    onSelect(content);
    dialog.close();
  };
  const contentIsSelected = (content: Content) =>
    selectedContent?.id === content.id;
  const getContentType = (content: Content) => {
    if (content.type === 'live') {
      return { color: 'error.main', label: 'Ao vivo' };
    }
    return { color: 'info.main', label: 'On demand' };
  };
  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ mb: 7 }}
      >
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: 'secondary.main' }} />
            </InputAdornment>
          }
          fullWidth
          placeholder="Pesquisar destaques"
          value={query}
          sx={{ mr: 4, width: 385 }}
          onChange={changeQueryHandler}
        />
        <Select value={sortBy} sx={{ minWidth: 201 }} onChange={sortByHandler}>
          <MenuItem value={SortBy.Newest}>Mais recentes</MenuItem>
          <MenuItem value={SortBy.Oldest}>Mais antigos</MenuItem>
        </Select>
      </Stack>
      {sortedContents.map((content: Content) => (
        <ListItem sx={{ height: 74, p: 0, width: '100%' }}>
          <ListItemButton key={content.id} dense sx={{ pl: 0 }}>
            <ListItemAvatar>
              <Avatar
                alt={content.title}
                src={content.thumbnail}
                variant="rounded"
                sx={{ width: 74, height: 42 }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={content.title}
              primaryTypographyProps={{
                color: 'grey.100',
                fontSize: 14,
                fontWeight: 600,
                width: 353,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              secondary={
                <>
                  <Typography
                    sx={{
                      color: getContentType(content).color,
                      display: 'inline',
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >
                    {getContentType(content).label}
                  </Typography>
                </>
              }
              sx={{ ml: 3 }}
            />
          </ListItemButton>
          <Button
            sx={{
              bgcolor: contentIsSelected(content)
                ? 'success.main'
                : 'secondary',
              fontSize: 12,
              fontWeight: 500,
              height: 30,
              width: 77,
            }}
            onClick={() => selectContent(content)}
          >
            {contentIsSelected(content) ? 'Adicionado' : 'Adicionar'}
          </Button>
        </ListItem>
      ))}
    </>
  );
};

export default DialogContents;
