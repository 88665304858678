import * as yup from 'yup';

const validationSchema = yup
  .object()
  .noUnknown()
  .shape({
    id: yup.string().required('Este campo é obrigatório.'),
    title: yup.string().required('Este campo é obrigatório.'),
    description: yup.string().required('Este campo é obrigatório.'),
    fullHdLandscape: yup.string().required('Este campo é obrigatório.'),
    fullHdPortrait: yup.string(),
    scheduleDate: yup.mixed(),
    finishDate: yup.mixed(),
    position: yup.number(),
    content: yup
      .object()
      .noUnknown()
      .shape({ id: yup.string().required('Este campo é obrigatório.') }),
  });

export default validationSchema;
