import { Box, Grid, Stack, Typography } from '@material-ui/core';

interface HighlightedContentProps {
  highlightedContent: any;
}

const HighlightDetail = ({ highlightedContent }: HighlightedContentProps) => (
  <Grid container>
    <Grid item xs={7}>
      <Typography sx={{ color: 'grey.100', fontSize: 14, fontWeight: 600 }}>
        Descrição do destaque
      </Typography>
      <Typography sx={{ color: 'grey.300', fontSize: 14, mt: 2, width: 492 }}>
        {highlightedContent.description}
      </Typography>
      <Typography
        sx={{ color: 'grey.100', fontSize: 14, fontWeight: 600, mt: 8 }}
      >
        Conteúdo associado ao destaque
      </Typography>
      <Typography
        sx={{ color: 'grey.300', fontSize: 20, fontWeight: 600, mt: 2 }}
      >
        {highlightedContent?.content?.title}
      </Typography>
    </Grid>
    <Grid item xs direction="row">
      <Stack
        alignItems="center"
        direction="row"
        gap={4}
        justifyContent="flex-end"
      >
        <Box>
          <Box
            sx={{
              backgroundImage: `url(${highlightedContent.fullHdLandscape})`,
              backgroundSize: 'cover',
              borderRadius: 1,
              height: 165,
              width: 302,
            }}
          />
          <Typography
            sx={{ color: 'grey.100', fontSize: 12, fontWeight: 600, mt: 3 }}
          >
            Imagem horizontal
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              backgroundImage: `url(${highlightedContent.fullHdPortrait})`,
              backgroundSize: 'cover',
              borderRadius: 1,
              height: 165,
              width: 110,
            }}
          />
          <Typography
            sx={{ color: 'grey.100', fontSize: 12, fontWeight: 600, mt: 3 }}
          >
            Imagem Vertical
          </Typography>
        </Box>
      </Stack>
    </Grid>
  </Grid>
);

export default HighlightDetail;
