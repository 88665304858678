import { useQuery } from '@apollo/client';
import {
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Typography,
} from '@material-ui/core';
import { useContext, useMemo } from 'react';
import { DiscountType } from '../../../interfaces/Discount';
import { HeartTeam } from '../../../interfaces/HeartTeam';
import listTeamsQuery from '../../Spectators/gql/listTeamsQuery';
import Context from '../Context';
import { sortTags } from '../tools/sort';
import AssociatedPlans from './AssociatedPlans';
import FormCard from './FormCard';
import RecurrenceCard from './RecurrenceCard';

const DiscountAudience = () => {
  const { form, type } = useContext(Context);
  const isTypeDiscountRetention = DiscountType.Retention === type;
  const isTypeCoupon = DiscountType.Coupon === type;
  const isTypeDiscountCollective = DiscountType.Collective === type;
  const isCollectiveDicountOrCoupon = isTypeDiscountCollective || isTypeCoupon;
  const isRetentionDiscountOrInternal =
    isTypeDiscountRetention || DiscountType.Internal === type;
  const isRetentionDiscountCollectiveOrCoupon =
    isTypeDiscountRetention || isTypeDiscountCollective || isTypeCoupon;
  const { loading, data } = useQuery<{ teams: { items: HeartTeam[] } }>(
    listTeamsQuery,
  );
  const numberOfMonths = useMemo(
    () =>
      [...Array(13)].map((_, index) => (
        <MenuItem value={index}>{index}</MenuItem>
      )),
    [],
  );
  if (loading) return <div>Loading...</div>;
  const teams = data!.teams.items || [];
  const sortedTeamsByAsc = [...teams].sort(sortTags);
  return (
    <>
      {isCollectiveDicountOrCoupon && <RecurrenceCard />}
      {isTypeCoupon && (
        <FormCard
          title="Quantidade"
          description="Defina a quantidade de cupons liberados para os espectadores."
          isRequired
          sx={{ mt: 4 }}
        >
          <Stack alignItems="center" direction="row" sx={{ mt: 4 }}>
            <OutlinedInput
              name="usageLimit"
              value={form.values.usageLimit}
              disabled={!!form.values.usageUnlimited}
              sx={{
                borderColor: 'grey.400',
                borderStyle: 'solid',
                borderWidth: '1px',
                height: 37,
                width: 103,
              }}
              onChange={form.handleChange}
            />
            <Switch
              name="usageUnlimited"
              checked={form.values.usageUnlimited}
              sx={{ ml: 4 }}
              onChange={form.handleChange}
            />
            <Typography sx={{ color: 'grey.300', fontSize: 14, ml: 2 }}>
              Ilimitada
            </Typography>
          </Stack>
          <FormHelperText error>{form.errors.usageLimit}</FormHelperText>
        </FormCard>
      )}
      {isRetentionDiscountOrInternal && (
        <>
          <FormCard
            title="Time do coração"
            description="Selecione o público a partir do seu time do coração escolhido no cadastro."
          >
            <InputLabel
              sx={{
                color: 'grey.300',
                fontSize: 14,
                mt: 4,
                mb: 1,
              }}
            >
              Selecione um time
            </InputLabel>
            <Select
              name="team"
              value={form.values.team}
              sx={{
                borderColor: 'grey.400',
                borderStyle: 'solid',
                borderWidth: '1px',
                width: 229,
              }}
              onChange={form.handleChange}
            >
              {sortedTeamsByAsc.map((team) => (
                <MenuItem value={team.id}>{team.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText error>{form.errors.team}</FormHelperText>
          </FormCard>
          <FormCard
            title="Período como assinante"
            description="Selecione o público a partir de quantos meses os usuários pagam pelos serviços."
            sx={{ mt: 4 }}
          >
            <InputLabel
              sx={{
                color: 'grey.300',
                fontSize: 14,
                mt: 4,
                mb: 1,
              }}
            >
              Quantidade de meses
            </InputLabel>
            <Select
              name="minimumPurchaseMonth"
              value={form.values.minimumPurchaseMonth}
              sx={{
                borderColor: 'grey.400',
                borderStyle: 'solid',
                borderWidth: '1px',
                width: 76,
              }}
              onChange={form.handleChange}
            >
              {numberOfMonths}
            </Select>
          </FormCard>
        </>
      )}
      {isRetentionDiscountCollectiveOrCoupon && <AssociatedPlans />}
    </>
  );
};

export default DiscountAudience;
