import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import { useParams } from 'react-router-dom';
import pagesQuery from '../gql/pagesQuery';
import Container from './components';
import Context from './context';
import { BuilderForm } from './typings';
import validationSchema from './validationSchema';

const pageQuery = gql`
  query PageQuery($id: ID!) {
    page(id: $id) {
      id
      title
      blocks
      type
      published
      scheduleDate
      scheduleDateEnabled
      finishDate
      finishDateEnabled
      seoTitle
      seoDescription
      seoKeywords
      scripts
    }
  }
`;

const updatePageMutation = gql`
  mutation UpdatePageMutation($input: UpdatePageInput!) {
    updatePage(input: $input) {
      id
      blocks
    }
  }
`;

const Form = ({ data }: any) => {
  const alert = useAlert();
  const { pageId } = useParams();
  const [updatePage] = useMutation(updatePageMutation);
  const { loading, data: pagesData } = useQuery(pagesQuery);
  const form = useFormik<BuilderForm>({
    initialValues: {
      title: data.page.title,
      blocks: data.page.blocks,
      type: data.page.type,
      published: data.page.published,
      scheduleDate: data.page.scheduleDate,
      scheduleDateEnabled: data.page.scheduleDateEnabled,
      finishDate: data.page.finishDate,
      finishDateEnabled: data.page.finishDateEnabled,
      seoTitle: data.page.seoTitle,
      seoDescription: data.page.seoDescription,
      seoKeywords: data.page.seoKeywords,
      scripts: data.page.scripts,
    },
    validationSchema,
    onSubmit: async (input) => {
      await updatePage({ variables: { input: { ...input, id: pageId } } });
      alert.success('Alterações salvas com sucesso');
    },
  });
  if (loading) {
    return <div>loading...</div>;
  }
  return (
    <Context.Provider value={{ form, pages: pagesData.pages }}>
      <Container />
    </Context.Provider>
  );
};

const UpdatePage = () => {
  const { pageId } = useParams();
  const { data, loading } = useQuery(pageQuery, { variables: { id: pageId } });
  if (loading) {
    return <div>loading...</div>;
  }
  return <Form data={data} />;
};

export default UpdatePage;
