import { gql } from '@apollo/client';

const updateQuestionMutation = gql`
  mutation UpdateQuestionMutation($input: UpdateQuestionInput!) {
    updateQuestion(input: $input) {
      id
      title
      description
      published
      answersEnabled
      required
      position
      tags {
        id
      }
    }
  }
`;

export default updateQuestionMutation;