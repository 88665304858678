import { gql } from '@apollo/client';

const getLeadsQuery = gql`
  query GetLeadsQuery($input: ListLeadsInput!) {
    leads(input: $input) {
      items {
        id
        name
        email
        phone
        createdAt
        answer{
          id,
          name
        }
      }
      meta {
        totalItems
        itemCount
        totalPages
        currentPage
      }
    }
  }
`;

export default getLeadsQuery;
