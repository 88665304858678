import { ApolloQueryResult } from '@apollo/client';
import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
} from 'react';
import { Tag } from '../../interfaces/Tag';
import { Question } from './typings';

interface IOnboardingContext {
  cardsRef: MutableRefObject<Question[]>;
  questions: Question[];
  setQuestions: Dispatch<SetStateAction<Question[]>>;
  tags: Tag[];
  refetch: () => Promise<
    ApolloQueryResult<{ questions: Question[]; tags: Tag[] }>
  >;
  moveQuestion: (dragIndex: number, hoverIndex: number) => void;
  openCreateQuestionModal: () => void;
}

const Context = createContext({} as IOnboardingContext);

export default Context;
