import { Stack } from '@material-ui/core';
import { useState, useMemo } from 'react';
import { JavaScriptEmbedding, PageOptions, SEO } from './components';

const SettingsComponents = {
  PageOptions,
  SEO,
  JavaScriptEmbedding,
}

const PageSettings = () => {
  const [openedSetting, setOpenedSetting] = useState('');
  const toggleSettingsHandler = (name: string) => {
    const currentOpenedSetting = openedSetting === name ? '' : name;
    setOpenedSetting(currentOpenedSetting);
  }
  const renderedSettings = useMemo(() => (
    Object.values(SettingsComponents)
      .map((Component) => (
        <Component
          expanded={Component.name === openedSetting}
          onExpand={() => toggleSettingsHandler(Component.name)}
        />
      ))
  ), [openedSetting]);
  return (
    <Stack sx={{ gap: 2, mt: 4, width: '100%' }}>
      {renderedSettings}
    </Stack>
  );
};

export default PageSettings;
