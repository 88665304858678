import { Card, Typography } from '@material-ui/core';
import { SxProps, Theme } from '@material-ui/system';
import { ReactNode } from 'react';

interface FormCardProps {
  title: string;
  description: string;
  isRequired?: boolean;
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

const FormCard = ({
  title,
  description,
  isRequired = false,
  children,
  sx,
}: FormCardProps) => (
  <Card sx={{ height: 215, p: 6, ...sx }}>
    <Typography
      sx={{
        color: 'grey.100',
        display: 'flex',
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 'center',
      }}
    >
      {title}
      {isRequired && (
        <Typography
          sx={{ color: 'error.main', fontSize: 14, fontWeight: 600, ml: 1 }}
        >
          *
        </Typography>
      )}
    </Typography>
    <Typography sx={{ color: 'grey.300', fontSize: 14, mt: 2 }}>
      {description}
    </Typography>
    {children}
  </Card>
);

export default FormCard;
