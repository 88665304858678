import { createContext } from 'react';
import { IDialogOptions } from './IDialogOptions';
import { IMessageOptions } from './IMessageOptions';

export interface IDialogContext {
  openAsConfirmation: (options: IDialogOptions) => Promise<unknown>;
  openAsDialog: (options: IMessageOptions) => Promise<unknown>;
  close: (result?: unknown) => void;
}

const DEFAULT_CONTEXT: IDialogContext = {
  openAsConfirmation: async () => {},
  openAsDialog: async () => {},
  close: () => {},
};

export const DialogContext = createContext(DEFAULT_CONTEXT);
