import { useContext } from 'react';
import { Element, useNode } from '@craftjs/core';
import { Stack } from '@material-ui/core';
import Context from '../context';
import HalfContainer from './HalfContainer';

interface DoubleContainerProps {
  bgColor: string;
}

const DoubleContainer = ({ bgColor = '#141414' }: DoubleContainerProps) => {
  const { smartphoneScreen } = useContext(Context);
  const { id } = useNode();
  return (
    <Stack
      alignItems="center"
      flexDirection={smartphoneScreen ? 'column' : 'row'}
      gap={5}
      justifyContent="space-between"
      minHeight={400}
      position="relative"
      sx={{
        bgcolor: bgColor,
        p: smartphoneScreen ? 5 : 10,
        mx: smartphoneScreen ? -5 : -10,
      }}
    >
      <Element canvas id={`${id}-left-column`} is={HalfContainer} />
      <Element canvas id={`${id}-right-column`} is={HalfContainer} />
    </Stack>
  );
};

export default DoubleContainer;
