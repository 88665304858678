import { FormikProps } from 'formik';
import { createContext } from 'react';
import { HeartTeam } from '../../interfaces/HeartTeam';

export type FormValues = Pick<HeartTeam, 'name' | 'slug' | 'externalUrl' | 'avatars'>;

interface ContextProps {
  form: FormikProps<FormValues> | FormikProps<HeartTeam>;
}

const Context = createContext({} as ContextProps);

export default Context;
