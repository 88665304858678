import { useApolloClient, useMutation } from '@apollo/client';
import { Button, MenuItem, Select } from '@material-ui/core';
import { FolderSpecial as FolderSpecialIcon } from '@material-ui/icons/';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Link, useSearchParams } from 'react-router-dom';
import SearchBar from '../../../components/UI/molecules/SearchBar';
import { useDialog } from '../../../components/Dialog';
import EmptyStateCard from '../../../components/EmptyStateCard';
import { PlanType } from '../../../interfaces/Plan';
import unpublishPlanMutation from '../gql/unpublishPlanMutation';
import plansQuery from '../gql/plansQuery';
import Context from '../Context';
import { PlansInput } from '../typings';
import PlansList from './PlansList';

const Container = () => {
  const alert = useAlert();
  const apolloClient = useApolloClient();
  const dialog = useDialog();
  const [params] = useSearchParams();
  const type = params.get('type') as PlanType;
  const [isEnabled, setIsEnabled] = useState('active');
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState(null);
  const [title, setTitle] = useState('');
  const [input, setInput] = useState<PlansInput>({
    filters: {
      published: isEnabled === 'active',
      type,
      title: '',
    },
    paginate: { page: 1 },
  });
  const getListPlans = async () => {
    try {
      setLoading(true);
      const queryParams = {
        query: plansQuery,
        variables: { input: { ...input, filters: { ...input.filters, type } } },
      };
      const res = await apolloClient.query(queryParams);
      setPlans(res.data.plans.items);
      setMeta(res.data.plans.meta);
    } catch {
      alert.error('Ops, não foi possível carregar os planos...');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getListPlans();
  }, [input, type]);
  const queryHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setTitle(evt.target.value);
  const [deletePlan] = useMutation(unpublishPlanMutation);
  if (loading) return <div>Carregando...</div>;
  const disablePlanHandler = async (id: string) => {
    const confirmed = await dialog.confirm({
      title: 'Você tem certeza?',
      message: 'O seu plano será desativado e não poderá reativar novamente.',
    });
    if (confirmed) {
      await deletePlan({ variables: { id } });
      getListPlans();
      alert.success('Plano desabilitado com sucesso.');
    }
  };
  const filterDiscountsByButton = () =>
    setInput({
      ...input,
      filters: { published: isEnabled === 'active', type, title },
      paginate: { page: 1 },
    });
  return (
    <Context.Provider
      value={{
        isEnabled,
        plans,
        type,
        meta,
        input,
        setInput,
        disablePlanHandler,
      }}
    >
      <SearchBar
        TextFieldProps={{
          placeholder: 'Procure aqui por um plano específico',
          value: title,
          onChange: queryHandler,
        }}
        sx={{ mt: 4 }}
      >
        <Select
          value={isEnabled}
          sx={{ height: 38, width: 156 }}
          onChange={(evt) => setIsEnabled(evt.target.value)}
        >
          <MenuItem value="active">Ativo</MenuItem>
          <MenuItem value="disabled">Inativo</MenuItem>
        </Select>
        <Button onClick={filterDiscountsByButton}>Filtrar Pesquisa</Button>
      </SearchBar>
      {plans.length ? (
        <PlansList />
      ) : (
        <EmptyStateCard
          icon={
            <FolderSpecialIcon sx={{ color: 'secondary.dark', fontSize: 40 }} />
          }
          title="Crie seu primeiro plano"
          description="Você vai definir as seções, categorias, tags liberadas para o cliente, assim como valores e páginas associadas ao plano."
          sx={{ mt: 6 }}
        >
          <Link to={`/plan/create?type=${type}`}>
            <Button variant="outlined" color="info">
              Criar novo plano
            </Button>
          </Link>
        </EmptyStateCard>
      )}
    </Context.Provider>
  );
};

export default Container;
