import { Box, Button, Card, FormHelperText, Stack } from '@material-ui/core';
import { useContext } from 'react';
import { useAlert } from 'react-alert';
import highlightsMobile from '../../../assets/png/highlightsMobile.png';
import getAssetUri from '../../../utils/getAssetUri';
import uploadFile from '../../ManagersList/services/uploadFile';
import Context from '../Context';

const ChooseImage = () => {
  const { form } = useContext(Context);
  const alert = useAlert();
  const uploadHandler = async (evt: any) => {
    const res = await uploadFile<any>(evt.target.files[0]);
    const hasErrors = !!res.data.errors;
    const formatUnsupported =
      hasErrors && res.data?.errors[0]?.message.includes('is unsupported');
    if (formatUnsupported) {
      alert.error('Formato de imagem não suportado.');
      return;
    }
    if (res.data?.data.fileUpload) {
      const fileSrc = getAssetUri(res.data?.data.fileUpload);
      form.setFieldValue('thumbnail', fileSrc);
    }
  };
  return (
    <Card sx={{ height: 580, p: 6 }}>
      <Stack alignItems="center" justifyContent="center">
        <Box
          sx={{
            backgroundImage: `url(${
              form.values.thumbnail || highlightsMobile
            })`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            borderRadius: 2,
            height: 460,
            width: '100%',
          }}
        />
        <label htmlFor="highlightImage">
          <input
            id="highlightImage"
            accept="image/*"
            multiple
            type="file"
            style={{ display: 'none' }}
            onChange={uploadHandler}
          />
          <Button component="span" sx={{ mt: 4 }}>
            Adicionar imagem
          </Button>
        </label>
      </Stack>
      <FormHelperText error>{form.errors.thumbnail}</FormHelperText>
    </Card>
  );
};

export default ChooseImage;
