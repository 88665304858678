import { Button, Stack } from '@material-ui/core';
import { SearchBy } from '../typings';

interface FilterProps {
  searchBy: SearchBy
  setSearchBy: (searchBy: SearchBy) => void
}

const Filter = ({searchBy, setSearchBy}: FilterProps) => {
  const searchByInformations = searchBy === SearchBy.informations;
  return (
  <Stack direction="row" sx={{ gap: 4, mt: 6 }}>
    <Button
      sx={{
        bgcolor: 'primary.main',
        color: searchByInformations ? 'grey.100' : 'grey.400',
        height: 59,
        width: 248,
      }}
      onClick={() => setSearchBy(SearchBy.informations)}
    >
      Informações
    </Button>
    <Button
      sx={{
        bgcolor: 'primary.main',
        color: searchByInformations ? 'grey.400' : 'grey.100',
        height: 59,
        width: 248,
      }}
      onClick={() => setSearchBy(SearchBy.spectators)}
    >
      Espectadores
    </Button>
  </Stack>
);
    }

export default Filter;
