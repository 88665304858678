import { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQuery } from '@apollo/client';
import { IconButton, ListItem, ListItemIcon, Tooltip } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
} from '@material-ui/icons';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import InlineTextEditor from '../../../../components/InlineTextEditor';
import { Tag } from '../../../../interfaces/Tag';
import updateTagMutation from '../TagsContainer/gql/updateTagMutation';
import { TagListItemProps } from './typings';
import Context from '../../Context';
import listTagsQuery from '../TagsContainer/gql/listTagsQuery';

const TagListItem = ({ tag, onDelete, onEdit }: TagListItemProps) => {
  const { data } = useQuery(listTagsQuery);
  const { permissionToDelete, permissionToUpdate } = useContext(Context);
  const alert = useAlert();
  const [updateTag] = useMutation(updateTagMutation);
  const [tagName, setTagName] = useState(tag.name);
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const [{ isDragging }, drag, preview] = useDrag<
    Tag,
    unknown,
    { isDragging: boolean }
  >(() => ({
    type: 'TagListItem',
    item: tag,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  }));
  const deleteTagHandler = () => onDelete(tag);
  const formatTagHandler = (tagToFormat: string) => {
    const formatedTag = tagToFormat
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    return formatedTag;
  };
  const editTagHandler = async (name: string) => {
    const trimmedName = name.trim();
    const toLowerCaseAndNormalizedTag = trimmedName
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const allTagsNormalized = data.tags.map((newTag: Tag) =>
      formatTagHandler(newTag.name),
    );
    const duplicatedTag = allTagsNormalized.filter(
      (tagToTest: String) => tagToTest === toLowerCaseAndNormalizedTag,
    );
    if (duplicatedTag.length > 0) {
      alert.error('Uma tag com este nome já existe');
    } else {
      try {
        const {
          data: {
            updateTag: { id },
          },
        } = await updateTag({
          variables: {
            input: { id: tag.id, name: trimmedName, slug: trimmedName },
          },
        });
        if (id) {
          setTagName(trimmedName);
          onEdit();
        }
      } catch (error) {
        alert.error('Erro inesperado');
      }
    }
  };
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  const permissionToDeleteAndIconVisible = isMouseEnter && permissionToDelete;
  return (
    <ListItem
      ref={drag}
      data-testid={`tag-list-item-${tag.id}`}
      secondaryAction={
        <>
          {permissionToDeleteAndIconVisible && (
            <>
              <Tooltip title="Excluir">
                <IconButton
                  edge="end"
                  sx={{
                    color: 'grey.400',
                    ':hover': { color: 'error.main' },
                  }}
                  onClick={deleteTagHandler}
                >
                  <DeleteIcon sx={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </>
      }
      sx={{
        cursor: isDragging ? 'grabbing' : 'grab',
        height: 53, // TODO: export from palette
        opacity: isDragging ? 0.3 : 1,
        px: 5,
        ':nth-child(even)': {
          bgcolor: 'rgba(30, 30, 30, 0.33)', // TODO: export from palette
        },
      }}
      onMouseEnter={() => setIsMouseEnter(true)}
      onMouseLeave={() => setIsMouseEnter(false)}
    >
      <ListItemIcon sx={{ minWidth: 28 }}>
        <DragIndicatorIcon sx={{ color: 'grey.400' }} />
      </ListItemIcon>
      <InlineTextEditor
        data-testid="BorderColorIcon"
        value={tagName}
        hideIcon={!permissionToUpdate}
        IconProps={{
          sx: { color: 'info.main', cursor: 'pointer', fontSize: 16, ml: 2 },
        }}
        TypographyProps={{ sx: { color: 'common.white', fontSize: 14 } }}
        onChange={(name) => editTagHandler(name)}
      />
    </ListItem>
  );
};
export default TagListItem;
