import { Input } from '@material-ui/core';
import { useContext } from 'react';
import BuilderContext from '../../../../context';
import { Group as GroupProps } from '../../../Sidebar/settings/main/typings';
import { getArrayFromInputString, getStringValuesFromArray } from './utils';
import SettingsAccordion from './SettingsAccordion';
import { LargeInputsStyles } from './styles';

const JavaScriptEmbedding = ({ expanded, onExpand }: GroupProps) => {
  const { form } = useContext(BuilderContext);
  return (
    <SettingsAccordion
      title="Incorporar Javascript"
      expanded={expanded}
      onExpand={onExpand}
    >
      <Input
        multiline
        name="scripts"
        placeholder="Cole aqui as tags de sites como Google Analitcs e Hotjar para acompanhar as métricas de performance da sua página."
        rows={4}
        sx={{ ...LargeInputsStyles, height: 97, padding: 4 }}
        value={getStringValuesFromArray(form.values.scripts)}
        onChange={(e) => {
          form.setFieldValue('scripts', getArrayFromInputString(e.target.value));
        }}
      />
    </SettingsAccordion>
  );
};

export default JavaScriptEmbedding;
