import { Card, FormHelperText, Typography } from '@material-ui/core';
import { useContext } from 'react';
import InlineTextEditor from '../../../components/InlineTextEditor';
import Context from '../Context';
import ChooseHighlightType from './ChooseHighlightType';
import ScheduleHighlight from './ScheduleHighlight';
import SelectSectionOrContent from './SelectSectionOrContent';

const Details = () => {
  const { form } = useContext(Context);
  return (
    <>
      <Typography sx={{ color: 'grey.100', fontWeight: 600 }}>
        Selecione o que será vinculado ao destaque:
      </Typography>
      <ChooseHighlightType />
      <Card
        sx={{ alignItems: 'center', display: 'flex', height: 78, mt: 4, pl: 6 }}
      >
        <InlineTextEditor
          value={form.values.title}
          TypographyProps={{
            color: 'grey.100',
            fontSize: 20,
            fontWeight: 600,
          }}
          onChange={(title) => form.setFieldValue('title', title)}
        />
        <FormHelperText error>{form.errors.title}</FormHelperText>
      </Card>
      <ScheduleHighlight />
      <SelectSectionOrContent />
    </>
  );
};

export default Details;
