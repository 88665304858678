/* eslint-disable no-param-reassign */
import { useMutation } from '@apollo/client';
import {
  Button,
  Chip,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  DragIndicator as DragIndicatorIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import update from 'immutability-helper';
import { useContext, useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useDrag, useDrop, XYCoord } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDialog } from '../../../components/Dialog';
import sortItems from '../../../utils/sortItems';
import Context from '../context';
import deleteQuestionMutation from '../gql/deleteQuestionMutation';
import updateQuestionMutation from '../gql/updateQuestionMutation';
import { Question as IQuestion } from '../typings';
import QuestionDetails from './QuestionDetails';

interface QuestionItem {
  index: number;
  question: IQuestion;
}

interface QuestionProps extends QuestionItem {
  moveQuestion: (dragIndex: number, hoverIndex: number) => void;
}

const Question = ({ index, moveQuestion, question }: QuestionProps) => {
  const alert = useAlert();
  const {
    cardsRef,
    tags: clientTags,
    setQuestions,
    refetch,
  } = useContext(Context);
  const dialog = useDialog();
  const [updateQuestion] = useMutation(updateQuestionMutation);
  const ref = useRef<HTMLDivElement>(null);
  const [deleteQuestion] = useMutation(deleteQuestionMutation);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isPlansMenuOpened = !!anchorEl;
  const closeMenuHandler = () => setAnchorEl(null);
  const openMenuHandler = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'Question',
    item: { index, question },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  }));
  const [{ isOver }, drop] = useDrop({
    accept: 'Question',
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
      isOver: monitor.isOver(),
    }),
    drop: async (item: QuestionItem) => {
      await updateQuestion({
        variables: { input: { id: question.id, position: item.index + 1 } },
      });
      return undefined;
    },
    hover: (item: QuestionItem, monitor) => {
      if (!ref.current) {
        return;
      }
      if (item.index === index) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;
      if (item.index < index && hoverClientX < hoverMiddleX) {
        return;
      }
      if (item.index > index && hoverClientX > hoverMiddleX) {
        return;
      }
      moveQuestion(item.index, index);
      item.index = index;
    },
  });
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  const deleteQuestionHandler = async () => {
    const confirmed = await dialog.confirm({
      title: 'Você tem certeza?',
      message: `Excluir uma pergunta a remove permanentemente do onboarding. Não será possível recuperá-los.`,
    });
    if (confirmed) {
      await deleteQuestion({ variables: { id: question.id } });
      closeMenuHandler();
      alert.success('Pergunta excluída com sucesso.');
      cardsRef.current = update(cardsRef.current, { $splice: [[index, 1]] });
      setQuestions(cardsRef.current);
    }
  };
  const editQuestionHandler = async () => {
    const updatedQuestion = await dialog.open({
      element: <QuestionDetails question={question} tags={clientTags} />,
      sx: {
        height: 480,
        width: 852,
      },
    });
    if (updatedQuestion) {
      closeMenuHandler();
      cardsRef.current = update(cardsRef.current, {
        [index]: { $set: updatedQuestion },
      });
      setQuestions(cardsRef.current);
    }
  };
  const orderQuestionsByPosition = (data: IQuestion[]) => {
    const orderedQuestions = sortItems(data, 'position');
    cardsRef.current = orderedQuestions;
    setQuestions(orderedQuestions);
  };
  const toggleRequiredHandler = async () => {
    const { data } = await refetch();
    const currentQuestionIsRequired = question.required;
    const findCurrentQuestion = data.questions.find(
      (currentQuestion: IQuestion) => currentQuestion.id === question.id,
    );
    if (findCurrentQuestion?.required && !currentQuestionIsRequired) {
      orderQuestionsByPosition(data.questions);
    } else {
      const hasRequiredQuestions =
        data.questions.filter(
          (currentQuestion: IQuestion) => currentQuestion.required,
        ).length > 0;
      const canBeSwitched = !hasRequiredQuestions || currentQuestionIsRequired;
      if (canBeSwitched) {
        await updateQuestion({
          variables: {
            input: {
              required: !currentQuestionIsRequired,
              id: question.id,
            },
          },
        });
        cardsRef.current = update(cardsRef.current, {
          [index]: {
            $set: { ...question, required: !currentQuestionIsRequired },
          },
        });
        setQuestions(cardsRef.current);
      } else {
        orderQuestionsByPosition(data.questions);
        dialog.confirm({
          title: 'Já existe pergunta obrigatória',
          message: 'Escolha apenas uma pergunta para ser obrigatória.',
        });
      }
    }
  };
  const chipColor = question.published ? 'success' : 'error';
  const chipLabel = question.published ? 'Ativo' : 'Inativo';
  const currentPosition = index + 1;
  const descriptionLimit = (text: string) => {
    const isTextOnLimit = text.length > 100;
    const description = isTextOnLimit
      ? `${text.substr(0, 99).trimEnd()}...`
      : text;
    return description;
  };
  const visibleTags = question.tags.slice(0, 4);
  const nonVisibleTags = question.tags.slice(5);
  const isNonVisibleTagsEmpty = nonVisibleTags.length < 1;
  const getQuestionLabel = () => {
    const formatedQuestionLabel =
      nonVisibleTags.length === 1 ? 'resposta' : 'respostas';
    const label = `+${nonVisibleTags.length} ${formatedQuestionLabel}`;
    return label;
  };
  drag(drop(ref));
  return (
    <Stack>
      <Stack
        ref={ref}
        sx={{
          bgcolor: 'primary.main',
          borderColor: isOver ? 'info.main' : 'transparent',
          borderRadius: question.required ? '8px 8px 0 0' : 2,
          borderStyle: 'solid',
          borderWidth: 2,
          cursor: isDragging ? 'grabbing' : 'grab',
          height: 382,
          mr: 9,
          opacity: isDragging ? 0.3 : 1,
          padding: 4,
          width: 300,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <DragIndicatorIcon
            sx={{
              color: 'grey.300',
              fontSize: 30,
              mr: 3,
            }}
          />
          <Typography
            sx={{
              alignItems: 'center',
              backgroundColor: 'grey.600',
              borderRadius: 2,
              color: 'grey.100',
              display: 'flex',
              fontSize: 24,
              fontWeight: 600,
              height: 40,
              justifyContent: 'center',
              minWidth: 40,
              mr: 'auto',
              px: 2,
            }}
          >
            {currentPosition}
          </Typography>
          <Button
            variant="contained"
            onClick={openMenuHandler}
            sx={{
              bgcolor: 'grey.500',
              height: 40,
              width: 40,
            }}
          >
            <MoreVertIcon sx={{ color: 'grey.300' }} />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={isPlansMenuOpened}
            onClose={closeMenuHandler}
          >
            <MenuItem onClick={editQuestionHandler}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Editar
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={deleteQuestionHandler}>
              <ListItemIcon>
                <CloseIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Excluir
              </Typography>
            </MenuItem>
          </Menu>
        </Stack>
        <Divider sx={{ bgcolor: 'grey.500', my: 4 }} />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Stack alignItems="center" direction="row">
            <Switch
              checked={question.required}
              value={question.required}
              onChange={toggleRequiredHandler}
            />
            <Typography sx={{ color: 'grey.300', fontSize: 12, ml: 2 }}>
              Passo obrigatório
            </Typography>
          </Stack>
          <Chip
            color={chipColor}
            label={chipLabel}
            size="small"
            variant="outlined"
            sx={{
              borderRadius: 1,
              fontSize: 12,
              height: 26,
              ml: 9,
            }}
          />
        </Stack>
        <Typography sx={{ color: 'grey.400', fontSize: 10, mt: 4 }}>
          Pergunta:
        </Typography>
        <Typography sx={{ color: 'common.white', fontSize: 16, mt: 1 }}>
          {question.title}
        </Typography>
        <Typography sx={{ color: 'grey.400', fontSize: 10, mt: 2 }}>
          Descrição:
        </Typography>
        <Typography
          title={question.description}
          sx={{
            color: 'common.white',
            fontSize: 12,
            mt: 1,
            overflow: 'hidden',
            wordWrap: 'break-word',
          }}
        >
          {descriptionLimit(question.description)}
        </Typography>
        <Typography sx={{ color: 'grey.400', fontSize: 10, my: 2 }}>
          Respostas:
        </Typography>
        <Stack direction="row" flexWrap="wrap" gap={2}>
          {visibleTags.map((tag) => (
            <Tooltip title={tag.name}>
              <Chip
                key={tag.id}
                color="default"
                label={tag.name}
                size="small"
                variant="outlined"
                sx={{
                  bgcolor: 'grey.500',
                  border: 'none',
                  borderRadius: 1,
                  color: 'grey.100',
                  fontSize: 12,
                  height: 18,
                  py: 3,
                }}
              />
            </Tooltip>
          ))}
          {isNonVisibleTagsEmpty ? (
            <></>
          ) : (
            <Chip
              color="default"
              label={getQuestionLabel()}
              size="small"
              variant="outlined"
              sx={{
                bgcolor: 'grey.500',
                border: 'none',
                borderRadius: 1,
                color: 'grey.100',
                fontSize: 12,
                height: 18,
                py: 3,
              }}
            />
          )}
        </Stack>
      </Stack>
      {question.required && (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            bgcolor: 'secondary.main',
            borderRadius: '0 0 8px 8px',
            color: 'grey.200',
            fontSize: 12,
            height: 34,
            width: 300,
            px: 2,
          }}
        >
          Pergunta obrigatória do cadastro
        </Stack>
      )}
    </Stack>
  );
};

export default Question;
