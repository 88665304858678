import { gql } from '@apollo/client';

const LoginMutation = gql`
  mutation LoginMutation($input: LoginInput!) {
    login(input: $input) {
      accessToken
      expiresIn
    }
  }
`;

export default LoginMutation;
