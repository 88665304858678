import { gql } from '@apollo/client';

const createIndividualDiscountMutation = gql`
  mutation CreateIndividualDiscountMutation(
    $input: CreateIndividualDiscountInput!
  ) {
    createIndividualDiscount(input: $input) {
      id
    }
  }
`;

export default createIndividualDiscountMutation;
