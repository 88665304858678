import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../components/Dialog';
import Navbar from '../../components/UI/molecules/Navbar';
import TipAlert from '../../components/UI/molecules/TipAlert';
import FormFooter from '../../components/UI/templates/FormFooter';
import AddImageSection from './Components/AddImageSection';
import Details from './Components/Details';
import Scheduling from './Components/Scheduling';
import Context from './Context';
import createHighlightedContentMutation from './gql/createHighlightedContent';
import validationSchema from './providers/validationSchema';

const CreateHighlights = () => {
  const [createHighlightedContent, { loading: isCreating }] = useMutation(
    createHighlightedContentMutation,
  );
  const alert = useAlert();
  const dialog = useDialog();
  const navigate = useNavigate();
  const form = useFormik({
    initialValues: {
      title: 'Titulo para o destaque',
      description: '',
      fullHdPortrait: '',
      fullHdLandscape: '',
      scheduleDate: null,
      finishDate: null,
      content: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const input = validationSchema.cast(values);
        await createHighlightedContent({ variables: { input } });
        alert.success('Destaque criado com sucesso');
        navigate('/highlights');
      } catch {
        alert.error('Ops! Algo deu errado, tente novamente.');
      }
    },
  });
  const cancelHighlightCreation = async () => {
    const confirm = await dialog.confirm({
      title: 'Cancelar criação',
      message: 'Você tem certeza que deseja cancelar a criação deste destaque?',
    });
    if (confirm) {
      navigate('/highlights');
    }
  };
  return (
    <Context.Provider value={{ form }}>
      <Navbar route="/highlights" title="Criar destaque" />
      <TipAlert sx={{ mt: 6 }}>
        Salve as edições para que o seu conteúdo seja publicado
      </TipAlert>
      <Details />
      <Scheduling />
      <AddImageSection />
      <FormFooter
        LeftButtonProps={{
          children: 'Salvar destaque',
          type: 'submit',
          loading: isCreating,
          onClick: () => form.handleSubmit(),
        }}
        helperText=""
        helperTextProps={{ color: 'error.main' }}
        RightButtonProps={{
          children: 'Cancelar criação',
          onClick: () => cancelHighlightCreation(),
        }}
        sx={{ mt: 6 }}
      />
    </Context.Provider>
  );
};

export default CreateHighlights;
