import { createContext } from 'react';
import { Discount } from '../../interfaces/Discount';

type ContextType = {
  form: any;
  helperText: string;
  discount: Discount;
  type: string;
};

const Context = createContext({} as ContextType);

export default Context;
