import { gql } from '@apollo/client';

const updateSectionMutation = gql`
  mutation UpdateSectionMutation($input: UpdateSectionInput!) {
    updateSection(input: $input) {
      id
      title
    }
  }
`;

export default updateSectionMutation;
