import { gql } from '@apollo/client';

const userQuery = gql`
  query UserQuery($id: ID!) {
    user(id: $id) {
      id
      name
      email
      phone
      cpf
      createdAt
      updatedAt
      signupAnswer {
        createdAt
        question {
          id
          title
        }
        tag {
          id
          name
        }
      }
      userPlansHistory {
        status
      }
      userPlans {
        id
        createdAt
        deletedAt
        userPlanHistory {
          status
          netValue
          paymentHistory {
            paymentMethod
            lastFourCardDigits
          }
        }
        plan {
          id
          title
          price
          type
          published
        }
      }
    }
  }
`;

export default userQuery;
