import { Box } from '@material-ui/core';
import { ChangeEvent, useContext, useState } from 'react';
import SearchBar from '../../components/UI/molecules/SearchBar';
import { compareTerms } from '../../utils/string';
import RoleItem from './Components/RoleItem';
import Context from './Context';

const SearchContainer = () => {
  const [query, setQuery] = useState('');
  const { roles } = useContext(Context);
  const queryHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setQuery(evt.target.value);
  const filteredRoles = query
    ? roles.filter((role) => compareTerms(role.title, query))
    : roles;
  return (
    <>
      <SearchBar
        TextFieldProps={{
          placeholder: 'Procure aqui por um cargo específico',
          value: query,
          onChange: queryHandler,
        }}
        sx={{ mt: 6 }}
      />
      <Box display="grid" gap={6} gridTemplateColumns="repeat(3, 1fr)">
        {filteredRoles.map((role) => (
          <RoleItem key={role.title} role={role} />
        ))}
      </Box>
    </>
  );
};

export default SearchContainer;
