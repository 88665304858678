import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import React from 'react';
import { Plan } from '../../../interfaces/Plan';
import { getFriendlyNameByPlanType } from '../../EditPlan/PaymentBond';

interface ModalPlanProps {
  plans: Plan[];
}

const ModalPlan = ({ plans }: ModalPlanProps) => (
  <Box>
    <Typography
      sx={{
        color: 'common.white',
        fontSize: 14,
        fontWeight: 400,
        pb: 4,
      }}
    >
      Esta é a lista dos planos que foram associados ao desconto:
    </Typography>
    <Divider sx={{ borderColor: 'grey.500' }} />
    <List
      sx={{
        alignItems: 'center',
        pb: 4,
        pt: 2,
      }}
    >
      {plans.map((plan) => (
        <ListItem>
          <FiberManualRecord sx={{ color: 'grey.400', fontSize: 10 }} />
          <ListItemText>
            <Stack
              direction="row"
              sx={{
                ml: 1,
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  color: 'white',
                  fontSize: 14,
                  fontWeight: 600,
                  pl: 1,
                }}
              >
                {plan.title}:
              </Typography>
              <Typography
                sx={{
                  color: 'white',
                  fontSize: 14,
                  fontWeight: 600,
                  pl: 1,
                }}
              >
                {getFriendlyNameByPlanType(plan.type)}
              </Typography>
            </Stack>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  </Box>
);

export default ModalPlan;
