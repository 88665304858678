import { useMemo, useState } from 'react';
import { Stack, Card } from '@material-ui/core';
import { Appearance, Block, Footer, Header, Structure } from './main';

const SettingsComponents = {
  Header,
  Footer,
  Structure,
  Block,
  Appearance,
}

const MainSettings = () => {
  const [openedSetting, setOpenedSetting] = useState('');
  const toggleSettingsHandler = (name: string) => {
    const currentOpenedSetting = openedSetting === name ? '' : name;
    setOpenedSetting(currentOpenedSetting);
  }
  const renderedSettings = useMemo(() => (
    Object.values(SettingsComponents)
      .map((Component) => (
        <Component
          expanded={Component.name === openedSetting}
          onExpand={() => toggleSettingsHandler(Component.name)}
        />
      ))
  ), [openedSetting]);
  return (
    <Stack sx={{ position: 'relative', width: 270 }}>
      <Card>
        {renderedSettings}
      </Card>
    </Stack>
  );
};

export default MainSettings;
