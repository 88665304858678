// TODO: Remove when Craft.js support settings props without param reassign
/* eslint-disable no-param-reassign */
import { Node, useEditor } from '@craftjs/core';
import { Palette as PaletteIcon } from '@material-ui/icons';
import { useMemo } from 'react';
import ColorPicker from '../../components/ColorPicker';
import SidebarAccordion from '../../components/SidebarAccordion';
import { EditorType, Group as GroupProps } from './typings';

interface AppearanceProps extends Object {
  bgColor?: string;
}

const Appearance = ({ expanded, onExpand }: GroupProps) => {
  const {
    actions: { setProp },
    nodes,
  }: EditorType<AppearanceProps> = useEditor((query) => ({
    nodes: query.nodes,
  }));
  if (!nodes) return null;
  const [firstNode] = useMemo(
    () =>
      Object.values(nodes).filter(
        (node: Node) => node.data.name === 'MainContainer',
      ),
    [nodes],
  );
  if (!firstNode) return null;
  const bgColorChangeHandler = (color: string) => {
    setProp(firstNode.id, (currentProps) => {
      currentProps.bgColor = color;
    });
  };
  return (
    <SidebarAccordion
      title="Aparência"
      expanded={expanded}
      icon={
        <PaletteIcon
          sx={{
            color: 'secondary.main',
            height: 24,
            width: 24,
          }}
        />
      }
      onExpand={onExpand}
    >
      <ColorPicker
        color={firstNode.data.props.bgColor || '#141414'}
        name="Cor de fundo da página"
        onChange={bgColorChangeHandler}
      />
    </SidebarAccordion>
  );
};

export default Appearance;
