/* eslint-disable react/require-default-props */
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ChangeEvent } from 'react';
import { useFormik } from 'formik';
import { Role } from '../../../interfaces/Role';
import { Manager } from '../../../interfaces/Manager';
import { useDialog } from '../../../components/Dialog';
import Thumbnail from '../../../components/Thumbnail';
import getAssetUri from '../../../utils/getAssetUri';
import rolesQuery from '../../Roles/gql/rolesQuery';
import validationSchema from '../utils/validationSchema';
import InputText from './InputText';
import InputPassword from './InputPassword';

interface ManagerModalProps {
  manager: Manager;
  managers?: [];
  titleButton?: string;
}

type ManagerInput = Pick<
  Manager,
  'name' | 'email' | 'password' | 'thumbnail' | 'enabled'
> & {
  role: Pick<Role, 'id'>;
};

type ManagerFormInput = ManagerInput & {
  confirmEmail: string;
  confirmPassword: string;
};

const managerFormInputToManagerInput = ({
  thumbnail,
  enabled,
  name,
  email,
  password,
  role,
}: ManagerFormInput): ManagerInput => ({
  thumbnail,
  enabled,
  name,
  email,
  password,
  role: { id: role.id },
});

const ManagerModal = ({
  manager,
  managers,
  titleButton = 'Cadastrar novo gestor',
}: ManagerModalProps) => {
  const { loading, data } = useQuery(rolesQuery);
  const dialog = useDialog();
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...manager,
      role: manager.role || data?.roles[0],
      confirmEmail: '',
      confirmPassword: '',
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: (values: ManagerFormInput) => {
      dialog.close(managerFormInputToManagerInput(values));
    },
  });
  const changeThumbnailHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    form.setFieldValue('thumbnail', evt!.target!.files![0]);
  const changeSelectHandler = (evt: SelectChangeEvent<string>) => {
    const selectedValue = evt.target.value;
    const selectedRole = data.roles.find(
      (role: Role) => role.title === selectedValue,
    );
    form.setFieldValue('role', selectedRole);
  };
  const sortedRoles = data?.roles.slice().sort(
    (a: Role, b: Role) => a.title.localeCompare(b.title),
  );
  if (loading) return <div>Carregando...</div>;
  return (
    <PerfectScrollbar style={{ height: '75vh', paddingRight: 4 }}>
      <Box sx={{ pr: 5, pb: 6 }}>
        <Stack alignItems="center" sx={{ mb: 6 }}>
          <Thumbnail
            src={getAssetUri(manager.thumbnail)}
            onChange={changeThumbnailHandler}
          />
        </Stack>
        <Box sx={{ mb: 6 }}>
          <InputText
            name="name"
            value={form.values.name}
            label="Nome de Usuário: *"
            error={form.errors.name}
            onChange={form.handleChange}
          />
        </Box>
        <Box sx={{ mb: 6 }}>
          <InputText
            name="email"
            value={form.values.email}
            label="Email de recuperação: *"
            error={form.errors.email}
            onChange={(e) => {
              const emailExists = managers?.find(
                (obj: any) => obj.email === e.target.value,
              );
              if (emailExists) {
                form.errors.email =
                  'Email já existente, por favor adicione outro';
              }
              form.handleChange(e);
            }}
          />
        </Box>
        <Box sx={{ mb: 6 }}>
          <InputText
            name="confirmEmail"
            value={form.values.confirmEmail}
            label="Confirmar Email: *"
            error={form.errors.confirmEmail}
            onChange={form.handleChange}
          />
        </Box>
        <Box sx={{ mb: 6 }}>
          <InputPassword
            name="password"
            value={form.values.password}
            label="Senha: *"
            error={form.errors.password}
            onChange={form.handleChange}
          />
          <Typography sx={{ color: 'grey.400', fontSize: 12, mt: 2 }}>
            Use 8 ou mais caracteres utilizando letras e números
          </Typography>
        </Box>
        <Box sx={{ mb: 6 }}>
          <InputPassword
            name="confirmPassword"
            value={form.values.confirmPassword}
            label="Confirmar senha: *"
            error={form.errors.confirmPassword}
            onChange={form.handleChange}
          />
        </Box>
        <Box sx={{ mb: 6 }}>
          <InputLabel
            htmlFor="confirmPassword"
            sx={{ color: 'grey.100', fontSize: 14, mb: 2 }}
          >
            Cargo: *
          </InputLabel>
          <Select
            name="role"
            value={
              form.values.role ? form.values.role.title : data.roles[0].title
            }
            fullWidth
            onChange={changeSelectHandler}
            sx={{
              borderColor: 'grey.400',
              borderStyle: 'solid',
              borderWidth: 1,
            }}
          >
            {sortedRoles.map((role: Role) => (
              <MenuItem value={role.title}>{role.title}</MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          fullWidth
          sx={{ height: 44 }}
          onClick={() => form.handleSubmit()}
        >
          {titleButton}
        </Button>
      </Box>
    </PerfectScrollbar>
  );
};

export default ManagerModal;
