import { gql } from '@apollo/client';

const createTeamMutation = gql`
  mutation createTeamMutation($input: CreateTeamInput!) {
    createTeam(input: $input) {
      id
    }
  }
`;

export default createTeamMutation;
