import { Editor, Frame, Element } from '@craftjs/core';
import { Stack } from '@material-ui/core';
import { useContext } from 'react';
import Context from '../context';
import Header from './Header';
import {
  DoubleContainer,
  DynamicButton,
  DynamicImage,
  DynamicText,
  DynamicVideo,
  HalfContainer,
  PageFooter,
  PageHeader,
  SingleContainer,
} from './BuildArea/components';
import MainContainer from './BuildArea/components/MainContainer';
import WidgetsSettings from './Sidebar/settings/WidgetsSettings';
import MainSettings from './Sidebar/settings/MainSettings';
import ButtonBlockDragLayer from './Sidebar/settings/widgets/ButtonBlockDragLayer';
import ImageBlockDragLayer from './Sidebar/settings/widgets/ImageBlockDragLayer';
import TextBlockDragLayer from './Sidebar/settings/widgets/TextBlockDragLayer';
import VideoBlockDragLayer from './Sidebar/settings/widgets/VideoBlockDragLayer';
import SingleContainerBlockDragLayer from './Sidebar/settings/widgets/SingleContainerBlockDragLayer';
import DoubleContainerBlockDragLayer from './Sidebar/settings/widgets/DoubleContainerBlockDragLayer';
import PageSettings from './BuildArea/PageSettings';

const Container = () => {
  const { form } = useContext(Context);
  return (
    <Stack gap={4}>
      <Editor
        resolver={{
          DoubleContainer,
          DynamicButton,
          DynamicImage,
          DynamicText,
          DynamicVideo,
          HalfContainer,
          MainContainer,
          PageFooter,
          PageHeader,
          SingleContainer,
        }}
        indicator={{ success: '#8950FC' }}
      >
        <Header />
        <Stack direction="row">
          <Stack flexShrink={0} sx={{ position: 'fixed', height: 'calc(100vh - 30vh)', overflowY: 'auto' }}>
            <Stack>
              <WidgetsSettings />
              <MainSettings />
            </Stack>
          </Stack>
          <Stack sx={{ ml: '302px' }}>
            <Frame
              data={
                form.values.blocks ? JSON.stringify(form.values.blocks) : ''
              }
            >
              <Element canvas is={MainContainer} />
            </Frame>
            <PageSettings />
          </Stack>
        </Stack>
      </Editor>
      <ButtonBlockDragLayer />
      <ImageBlockDragLayer />
      <TextBlockDragLayer />
      <VideoBlockDragLayer />
      <SingleContainerBlockDragLayer />
      <DoubleContainerBlockDragLayer />
    </Stack>
  );
};

export default Container;
