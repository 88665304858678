import { DiscountType } from '../../interfaces/Discount';


export enum FilterAllDiscounts {
  All = 'all',
}

export type DiscountTypeToDiscountReport = FilterAllDiscounts | DiscountType;
export interface InputFilters {
  filters: {
    type: DiscountTypeToDiscountReport;
    status: boolean;
    createdAtGte: string | null;
    createdAtLte: string | null;
    term: string;
  };
  paginate: { page: number };
}
