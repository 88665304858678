import { createContext } from 'react';
import { Permission } from '../../interfaces/Permission';

type ContextType = {
  permissionToCreate: Permission['create'];
  permissionToDelete: Permission['delete'];
  permissionToRead: Permission['read'];
  permissionToUpdate: Permission['update'];
};

const Context = createContext({} as ContextType);

export default Context;
