import { gql } from '@apollo/client';

const meQuery = gql`
  query MeQuery {
    me {
      id
      name
      superAdmin
      role {
        id
        title
        color
        enabled
        permissions {
          id
          feature
          create
          read
          update
          delete
        }
      }
    }
  }
`;

export default meQuery;
