import { gql } from '@apollo/client';

const importSpallaVod = gql`
  mutation ImportSpallaVod($spallaContentId: ID!) {
    importSpallaVod(spallaContentId: $spallaContentId) {
      spallaContentId
    }
  }
`;

export default importSpallaVod;
