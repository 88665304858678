import { Link as LinkIcon } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { Button } from '@material-ui/core';
import { useAuthorize } from '@tshio/react-router-permissions';
import { useContext } from 'react';
import { useAlert } from 'react-alert';
import { Link, useParams } from 'react-router-dom';
import HasNoPermission from '../../components/HasNoPermission';
import Navbar from '../../components/UI/molecules/Navbar';
import TipAlert from '../../components/UI/molecules/TipAlert';
import Context from '../../Context';
import Pages from './components/Pages';
import Contents from './components/Contents';
import Details from './components/Details';
import PreviewPlanContext from './Context';
import planQuery from './gql/planQuery';

const PreviewPlan = () => {
  const alert = useAlert();
  const { me } = useContext(Context);
  const permissionForEverything = me.superAdmin;
  const permissonToUpdatePlans =
    permissionForEverything || useAuthorize(['plans', 'update']);
  const permissionToReadPlans =
    permissionForEverything || useAuthorize(['plans', 'read']);
  if (!permissionToReadPlans) return <HasNoPermission />;
  const { planId } = useParams();
  const { loading, data, refetch } = useQuery(planQuery, {
    variables: { id: planId },
  });
  if (loading) {
    return <div>Carregando...</div>;
  }
  const planLink = `https://paulistaoplay.com.br/auth/register?plan=${planId}`;
  const copyPlanLink = async () => {
    await navigator.clipboard.writeText(planLink);
    alert.success('Link copiado com sucesso!');
  };
  return (
    <PreviewPlanContext.Provider
      value={{
        plan: data.plan,
        planId,
        refetch,
      }}
    >
      <Navbar title={data.plan.title} route={`/plans?type=${data.plan.type}`}>
        <Button
          endIcon={<LinkIcon />}
          sx={{
            border: '1px solid',
            borderColor: 'secondary.main',
            bgcolor: 'transparent',
            mr: 4,
          }}
          onClick={copyPlanLink}
        >
          Copiar URL
        </Button>
        {permissonToUpdatePlans && data.plan.published && (
          <Link to={`/plan/${planId}/edit`}>
            <Button>Editar plano</Button>
          </Link>
        )}
      </Navbar>
      <TipAlert sx={{ my: 4 }}>
        Selecione as páginas necessárias para cada etapa do ciclo de utilização
        do seu cliente.
      </TipAlert>
      <Details />
      <Contents />
      <Pages />
    </PreviewPlanContext.Provider>
  );
};

export default PreviewPlan;
