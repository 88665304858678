import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/UI/molecules/Navbar';
import getDiscountQuery from './gql/getDiscountQuery';
import Container from './Components/Container';
import Filter from './Components/Filter';
import { SearchBy } from './typings';

const PreviewDiscountReport = () => {
  const { id } = useParams();
  const [searchBy, setSearchBy] = useState(SearchBy.informations);
  const { loading, data: discountData } = useQuery(getDiscountQuery, {
    variables: { id },
  });
  if (loading) return <>Carregando...</>;
  const { discount } = discountData;
  return (
    <>
      <Navbar title={discount.title} route="/discount/report/" />
      <Filter searchBy={searchBy} setSearchBy={setSearchBy} />
      <Container searchBy={searchBy} discount={discount} />
    </>
  );
};

export default PreviewDiscountReport;
