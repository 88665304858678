import { useApolloClient } from '@apollo/client';
import {
  Button,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Star as StarIcon } from '@material-ui/icons';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SearchBar from '../../components/UI/molecules/SearchBar';
import EmptyStateCard from '../../components/EmptyStateCard';
import Topbar from '../../components/Topbar';
import { Discount, DiscountType as Type } from '../../interfaces/Discount';
import { DiscountInput } from '../Plans/typings';
import DiscountCard from './components/DiscountCard';
import Head from './components/Head';
import discountsQuery from './gql/ListDiscountsQuery';
import { getPropsByDiscountType } from './utils/discountType';
import sortArrayBy from './utils/sort';
import Context, { Meta } from './Context';
import { SortByType } from './typings';

const DiscountList = () => {
  const alert = useAlert();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(true);
  const [params] = useSearchParams();
  const type = params.get('type') as string;
  const [sortBy, setSortBy] = useState<SortByType>();
  const { title, description } = getPropsByDiscountType(type as Type);
  const [isEnabled, setIsEnabled] = useState('active');
  const [term, setTerm] = useState('');
  const [discounts, setDiscounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState<Meta | null>(null);
  const [input, setInput] = useState<DiscountInput>({
    filters: {
      enabled: isEnabled === 'active',
      type,
      title: '',
    },
    paginate: { page: 1 },
  });
  const getListDiscounts = async () => {
    try {
      setLoading(true);
      const queryParams = {
        query: discountsQuery,
        variables: { input: { ...input, filters: { ...input.filters, type } } },
      };
      const res = await apolloClient.query(queryParams);
      setDiscounts(res.data.discounts.items);
      setMeta(res.data.discounts.meta);
    } catch {
      alert.error('Ops, não foi possível carregar os planos...');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getListDiscounts();
  }, [input, type]);
  const queryHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setTerm(evt.target.value);
  const paginationHandler = (_: ChangeEvent<unknown>, page: number) =>
    setInput({ ...input, paginate: { page } });
  const sortByHandler = (sortByType: SortByType) => setSortBy(sortByType);
  const sortedDiscounts = sortBy
    ? [...discounts]?.sort(sortArrayBy(sortBy))
    : [...discounts];
  if (loading) return <div>Loading...</div>;
  const thereAreDiscounts = discounts?.length > 0;
  const thereIsNoActiveDiscounts = isActive && discounts?.length === 0;
  const thereIsNoDiscounts = !isActive && discounts?.length === 0;
  const filterDiscountsByButton = () =>
    setInput({
      ...input,
      filters: { enabled: isEnabled === 'active', type, title: term },
      paginate: { page: 1 },
    });
  const renderEmptyListActiveDiscount = thereIsNoActiveDiscounts && (
    <EmptyStateCard
      icon={<StarIcon color="info" fontSize="large" />}
      title={`Crie o primeiro ${title}`}
      description="Você definirá o público, valores, recorrências e mais detalhes
  importantes para os descontos."
      sx={{ mt: 4 }}
    />
  );
  const renderEmptyListInactiveDiscount = thereIsNoDiscounts && (
    <EmptyStateCard
      icon={<StarIcon color="info" fontSize="large" />}
      title={`Nenhum ${title} Inativo`}
      description={`Você precisa desabilitar um ${title} para que ele seja listado.`}
      sx={{ mt: 4 }}
    />
  );
  return (
    <Context.Provider
      value={{
        isActive,
        meta,
        discounts,
        title,
        type,
        setIsActive,
        setInput,
      }}
    >
      <Topbar title={title} description={description}>
        <Button
          sx={{
            bgcolor: 'secondary.main',
            borderColor: 'common.white',
            color: 'common.white',
          }}
          onClick={() => navigate(`/discount/create?type=${type}`)}
        >
          Criar desconto
        </Button>
      </Topbar>
      {thereAreDiscounts && (
        <SearchBar
          TextFieldProps={{
            placeholder: 'Procure aqui por um desconto específico',
            value: term,
            onChange: queryHandler,
          }}
          sx={{ mt: 4 }}
        >
          <Select
            value={isEnabled}
            sx={{ height: 38, width: 156 }}
            onChange={(evt) => setIsEnabled(evt.target.value)}
          >
            <MenuItem value="active">Ativo</MenuItem>
            <MenuItem value="disabled">Inativo</MenuItem>
          </Select>
          <Button onClick={filterDiscountsByButton}>Filtrar Pesquisa</Button>
        </SearchBar>
      )}
      {renderEmptyListActiveDiscount}
      {renderEmptyListInactiveDiscount}
      {thereAreDiscounts && (
        <>
          <TableContainer>
            <Table
              sx={{
                borderCollapse: 'separate',
                borderRadius: 1,
                borderSpacing: '0px 5px',
                minWidth: 650,
                mt: 2,
                overflow: 'hidden',
              }}
              size="small"
            >
              <TableHead sx={{ bgcolor: 'primary.main' }}>
                <TableRow sx={{ borderRadius: 2 }}>
                  <TableCell
                    sx={{
                      color: 'grey.100',
                      pr: 0,
                    }}
                  >
                    Título do Desconto
                  </TableCell>
                  <Head title="Valor" prop="price" onSortHead={sortByHandler} />
                  <Head
                    title="Recorrência"
                    prop="recurrence"
                    onSortHead={sortByHandler}
                  />
                  <Head
                    title="Pagante desde"
                    prop="minimumPurchaseMonth"
                    onSortHead={sortByHandler}
                  />
                  <Head
                    title="Time"
                    prop="team"
                    onSortHead={sortByHandler}
                  />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedDiscounts.map((discount: Discount) => (
                  <DiscountCard
                    discount={discount}
                    refetch={getListDiscounts}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={meta?.totalPages}
            page={input.paginate.page}
            sx={{ mt: 4 }}
            onChange={paginationHandler}
          />
        </>
      )}
    </Context.Provider>
  );
};

export default DiscountList;
