import {
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import {
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@material-ui/lab';
import { History } from '../../../interfaces/History';
import { formatDate } from '../utils/date';
import { getFriendlyNameToDealStatus } from '../utils/getFriendlyNameToDealStatus';

interface SpectatorHistoryCardProps {
  history: History;
  isLastHistory: boolean;
}

const SpectatorHistoryCard = ({
  history,
  isLastHistory,
}: SpectatorHistoryCardProps) => {
  const formatedDate = formatDate(history.createdAt, 'dd LLL yyyy');
  const hasDiscount = history.discountPlanHistories.length > 0;
  const getSymbolByValueType = (type: 'value' | 'percentage') =>
    type === 'value' ? 'R$' : '%';
  const formatPrice = (price: number, type: 'value' | 'percentage') => {
    const symbol = getSymbolByValueType(type);
    const symbolIsValue = type === 'value';
    if (symbolIsValue) {
      return `${price.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`;
    }
    return `${price} ${symbol}`;
  };
  const dealStatusIsNotCanceled = !!history.dealStatus;
  return (
    <TimelineItem
      sx={{
        mb: 3,
        minHeight: hasDiscount ? 75 : 50,
        '::before': {
          display: 'none',
        },
      }}
    >
      <TimelineSeparator
        sx={{
          alignItems: 'center',
          display: 'flex',
          marginRight: 6,
          minWidth: 75,
        }}
      >
        <Typography
          sx={{
            color: 'grey.200',
            fontSize: 12,
            textAlign: 'center',
            textTransform: 'uppercase',
            width: 'auto',
          }}
        >
          <span title={history.createdAt.toLocaleString()}>{formatedDate}</span>
        </Typography>
        {!isLastHistory && (
          <>
            <TimelineConnector
              sx={{
                backgroundColor: 'grey.400',
                height: hasDiscount ? 15 : 10,
                mt: 1,
              }}
            />
            <TimelineDot
              sx={{
                alignSelf: 'center',
                backgroundColor: 'grey.400',
                border: 0,
                height: '4px !important',
                margin: '4px 0',
                width: '4px !important',
              }}
            />
            <TimelineConnector
              sx={{
                backgroundColor: 'grey.400',
                height: hasDiscount ? 15 : 10,
              }}
            />
          </>
        )}
      </TimelineSeparator>
      <TimelineContent sx={{ height: '100%', p: 0 }}>
        <Divider
          sx={{
            bgcolor: 'grey.500',
            mb: 3,
            mt: -4,
          }}
        />
        <Stack
          alignItems={{ xs: 'flex-start', lg: 'center' }}
          direction="row"
          justifyContent="space-between"
        >
          <Typography
            sx={{
              color: 'grey.200',
              fontSize: 14,
              width: 'auto',
              textTransform: 'uppercase',
            }}
          >
            {getFriendlyNameToDealStatus(history.dealStatus)}{' '}
            <b>{history.plan.title}</b>
          </Typography>
          <Stack alignItems="center" direction="row">
            {dealStatusIsNotCanceled && (
              <Typography
                sx={{
                  color: hasDiscount ? 'grey.400' : 'grey.200',
                  fontSize: hasDiscount ? 10 : 14,
                  fontWeight: 600,
                  textDecorationLine: hasDiscount ? 'line-through' : 'none',
                }}
              >
                {formatPrice(history.plan.price, 'value')}
              </Typography>
            )}
            <IconButton
              onClick={() => null}
              sx={{ height: 0, width: 0, bgcolor: 'red', p: 0, ml: 2 }}
            >
              <MoreVertIcon sx={{ color: 'grey.400', mr: -4 }} />
            </IconButton>
          </Stack>
        </Stack>
        {hasDiscount &&
          history.discountPlanHistories.map((discountPlanHistory) => (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              sx={{ mt: 4 }}
            >
              <Stack alignItems="center" direction="row">
                <Typography
                  sx={{
                    color: 'grey.200',
                    fontSize: 10,
                  }}
                >
                  DESCONTO APLICADO:
                </Typography>
                <Chip
                  label={discountPlanHistory.discount.title}
                  sx={{
                    backgroundColor: 'grey.400',
                    borderRadius: '4px',
                    color: 'white',
                    fontSize: 10,
                    height: 24,
                    minWidth: 54,
                    ml: 4,
                  }}
                />
                <Chip
                  label={formatPrice(
                    discountPlanHistory.discount.price || 0,
                    discountPlanHistory.discount.valueType,
                  )}
                  sx={{
                    backgroundColor: 'grey.400',
                    borderRadius: '4px',
                    color: 'white',
                    fontSize: 10,
                    height: 24,
                    minWidth: 54,
                    ml: 2,
                  }}
                />
              </Stack>
              <Typography
                sx={{
                  color: 'grey.200',
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                {formatPrice(history.netValue, 'value')}
              </Typography>
            </Stack>
          ))}
      </TimelineContent>
    </TimelineItem>
  );
};

export default SpectatorHistoryCard;
