import {
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import {
  FolderSpecial as FolderIcon,
  Timeline as HistoryIcon,
  Person as PersonIcon,
  SupportAgent as SupportIcon,
} from '@material-ui/icons';
import { useSpectator, DrawerType } from '../Context';

const SpectatorMenu = () => {
  const { anchorEl, setAnchorEl, setSelectedSpectator, setDrawerType } =
    useSpectator();
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedSpectator(null);
  };
  const handleOpenDrawer = (type: DrawerType) => {
    setAnchorEl(null);
    setDrawerType(type);
  };
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      PaperProps={{
        sx: {
          p: 4,
        },
      }}
    >
      <MenuItem
        sx={{ p: 0 }}
        onClick={() => handleOpenDrawer('SPECTATOR_DATA')}
      >
        <ListItemIcon>
          <PersonIcon fontSize="medium" sx={{ color: 'grey.300' }} />
        </ListItemIcon>
        <Typography variant="inherit" noWrap color="grey.400">
          Dados do Espectador
        </Typography>
      </MenuItem>
      <Divider />
      <MenuItem sx={{ p: 0 }} onClick={() => handleOpenDrawer('SUBSCRIPTION')}>
        <ListItemIcon>
          <FolderIcon fontSize="medium" sx={{ color: 'grey.300' }} />
        </ListItemIcon>
        <Typography variant="inherit" noWrap color="grey.400">
          Assinaturas
        </Typography>
      </MenuItem>
      <Divider />
      <MenuItem
        sx={{ p: 0 }}
        onClick={() => handleOpenDrawer('FINANCE_HISTORY')}
      >
        <ListItemIcon>
          <HistoryIcon fontSize="medium" sx={{ color: 'grey.300' }} />
        </ListItemIcon>
        <Typography variant="inherit" noWrap color="grey.400">
          Histórico Financeiro
        </Typography>
      </MenuItem>
      <MenuItem
        sx={{ p: 0 }}
        onClick={() => handleOpenDrawer('SPECTATOR_MANAGEMENT')}
      >
        <ListItemIcon>
          <SupportIcon fontSize="medium" sx={{ color: 'grey.300' }} />
        </ListItemIcon>
        <Typography variant="inherit" noWrap color="grey.400">
          Gestão do Espectador
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default SpectatorMenu;
