import { Stack } from '@material-ui/core';
import Topbar from '../../components/Topbar';
import FinancialReportCard from './Components/FinancialReportCard';

const FinancialReport = () => (
  <>
    <Topbar
      title="Relatórios Financeiros"
      description="Tenha acesso a todas as informações financeiras referentes à descontos/cupons e planos."
    />
    <Stack alignItems="center" direction="row" gap={4} sx={{ mt: 6 }}>
      <FinancialReportCard
        title="Relatório Financeiro de Desconto"
        description="Informações sobre valores de descontos, duração média e mais."
        link="/financial/report/discount"
      />
      <FinancialReportCard
        title="Relatório Financeiro de Plano"
        description="Informações sobre quantidade de planos, método de pagamentos e mais."
        link="/financial/report/plan"
      />
    </Stack>
  </>
);

export default FinancialReport;
