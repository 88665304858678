import { useMutation, useQuery } from '@apollo/client';
import { Button, Grid, List, Pagination, Stack } from '@material-ui/core';
import {
  OpenInNew as OpenInNewIcon,
  Videocam as VideocamIcon,
} from '@material-ui/icons';
import { useAlert } from 'react-alert';
import { useAuthorize } from '@tshio/react-router-permissions';
import { ChangeEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { compareTerms } from '../../utils/string';
import Context from './Context';
import { Content } from '../../interfaces/Content';
import ContentListItem from './components/ContentListItem';
import contentsQuery from './gql/contentsQuery';
import deleteContentMutation from './gql/deleteContentMutation';
import HasNoPermission from '../../components/HasNoPermission';
import NewContentTile from '../../components/UI/molecules/NewContentTile';
import paginationAdapter from '../../adapters/pagination';
import { sortByNewest, sortByOldest } from './utils/sort';
import { SortBy as SortByDate } from '../../typings/sort';
import Topbar from '../../components/Topbar';
import { useDialog } from '../../components/Dialog';
import ContextToMe from '../../Context';
import FiltersBar from './components/FiltersBar';
import { VideoState, VideoType } from './typings';

const LibraryPage = () => {
  const { me } = useContext(ContextToMe);
  const permissionForEverything = me.superAdmin;
  const permissionToCreate =
    permissionForEverything || useAuthorize(['contents', 'create']);
  const permissionToDelete =
    permissionForEverything || useAuthorize(['contents', 'delete']);
  const permissionToRead =
    permissionForEverything || useAuthorize(['contents', 'read']);
  const permissionToUpdate =
    permissionForEverything || useAuthorize(['contents', 'update']);
  if (!permissionToRead) return <HasNoPermission />;
  const dialog = useDialog();
  const alert = useAlert();
  const navigate = useNavigate();
  const [deleteContent] = useMutation(deleteContentMutation);
  const { loading, data, refetch } = useQuery<{ contents: Content[] }>(
    contentsQuery,
  );
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [filterByType, setFilterByType] = useState<VideoType | 'default'>(
    'default',
  );
  const [filterByTeam, setFilterByTeam] = useState<string>('default');
  const [filterByPublished, setFilterByPublished] = useState<
    VideoState | 'default'
  >('default');
  const [sortByDate, setSortByDate] = useState<SortByDate>(SortByDate.Newest);
  const deleteContentHandler = async ({ id }: Content) => {
    try {
      const shouldDelete = await dialog.confirm({
        title: 'Apagar conteúdo',
        message: 'Deseja realmente apagar o conteúdo?',
      });
      if (shouldDelete) {
        await deleteContent({ variables: { id } });
        await refetch();
      }
    } catch (err: any) {
      const contentIsLinkedToAHighlight =
        err.graphQLErrors?.[0].message.includes(
          'HasAttachedHighlightedContent',
        );
      if (contentIsLinkedToAHighlight) {
        alert.error('Este conteúdo está vinculado a um destaque.');
      }
    }
  };
  const paginationHandler = (_: ChangeEvent<unknown>, nextPage: number) =>
    setPage(nextPage);
  if (loading) {
    return <div>loading...</div>;
  }
  const filteredContents = query
    ? data!.contents.filter((content) => compareTerms(content.title, query))
    : data!.contents;
  const filteredByType = filteredContents.filter(
    (content) => filterByType === 'default' || content.type === filterByType,
  );
  const filteredByPublished = filteredByType.filter((content) => {
    const hasValueTrue = filterByPublished === 'true';
    return (
      filterByPublished === 'default' || content.published === hasValueTrue
    );
  });
  const filteredByTeam = filteredByPublished.filter(
    (content) =>
      filterByTeam === 'default' ||
      content.teams.some((team) => team.id === filterByTeam),
  );
  const sortedContentsByDate = [...filteredByTeam].sort(
    sortByDate === SortByDate.Oldest ? sortByOldest : sortByNewest,
  );
  const paginatedContents = paginationAdapter<Content>({
    items: sortedContentsByDate,
    itemsPerPage: 10,
    page,
  });
  const redirectCreateContentHandler = (typeContent: string) =>
    typeContent === 'live'
      ? window.open(process.env.REACT_APP_LIVE_CREATE_URL, '_blank')
      : window.open(process.env.REACT_APP_VOD_CREATE_URL, '_blank');
  return (
    <Context.Provider
      value={{
        permissionToDelete,
        permissionToRead,
        permissionToUpdate,
      }}
    >
      <Topbar
        title="Biblioteca de conteúdos"
        description="A biblioteca de conteúdo permite listar e analisar os seus conteúdos"
      />
      <Grid container spacing={4} sx={{ mt: 0 }}>
        <Grid item xs>
          <NewContentTile icon={VideocamIcon} title="Novo conteúdo ao vivo">
            <Stack direction="row" spacing={4}>
              {permissionToCreate && (
                <Button
                  endIcon={<OpenInNewIcon />}
                  onClick={() => redirectCreateContentHandler('live')}
                >
                  Criar conteúdo
                </Button>
              )}
            </Stack>
          </NewContentTile>
        </Grid>
        <Grid item xs>
          <NewContentTile icon={VideocamIcon} title="Novo conteúdo sob demanda">
            <Stack direction="row" spacing={4}>
              {permissionToCreate && (
                <Button
                  endIcon={<OpenInNewIcon />}
                  onClick={() => redirectCreateContentHandler('vod')}
                >
                  Criar conteúdo
                </Button>
              )}
            </Stack>
          </NewContentTile>
        </Grid>
        <Grid item xs>
          <NewContentTile icon={VideocamIcon} title="Novo conteúdo externo">
            <Stack direction="row" spacing={4}>
              {permissionToCreate && (
                <Button
                  endIcon={<OpenInNewIcon />}
                  onClick={() => navigate('/content/create')}
                >
                  Criar conteúdo
                </Button>
              )}
            </Stack>
          </NewContentTile>
        </Grid>
      </Grid>
      <FiltersBar
        filterByPublished={filterByPublished}
        filterByType={filterByType}
        filterByTeam={filterByTeam}
        query={query}
        setFilterByPublished={setFilterByPublished}
        setFilterByType={setFilterByType}
        setFilterByTeam={setFilterByTeam}
        setPage={setPage}
        setQuery={setQuery}
        setSortByDate={setSortByDate}
        sortByDate={sortByDate}
      />
      <List>
        {paginatedContents.items.map((content) => (
          <ContentListItem
            key={content.id}
            content={content}
            onDelete={deleteContentHandler}
          />
        ))}
      </List>
      <Pagination
        count={paginatedContents.total}
        page={page}
        sx={{ mt: 4 }}
        onChange={paginationHandler}
      />
    </Context.Provider>
  );
};

export default LibraryPage;
