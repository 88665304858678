import { Avatar, Stack, Typography } from '@material-ui/core';
import { SortableHighlightedContents } from '../../../interfaces/Highlight';

interface HighlightProps {
  sortableHighlightedContent: SortableHighlightedContents;
}

const HighlightCard = ({ sortableHighlightedContent }: HighlightProps) => (
  <Stack sx={{ mb: 4, mr: 4 }}>
    <Avatar
      alt={sortableHighlightedContent.highlightedContent.title}
      src={sortableHighlightedContent.highlightedContent.fullHdLandscape}
      variant="rounded"
      sx={{ height: 82, width: 120 }}
    />
    <Typography
      sx={{
        fontSize: 12,
        color: 'grey.100',
        mt: 2,
        width: 120,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {sortableHighlightedContent.highlightedContent.title}
    </Typography>
  </Stack>
);

export default HighlightCard;
