import { Theme } from '@material-ui/core';
import { Components } from '@material-ui/core/styles/components';

const makeMuiMenu = (theme: Theme): Components['MuiMenu'] => ({
  styleOverrides: {
    paper: {
      backgroundColor: `${theme.palette.common.white} !important`,
      borderRadius: `${theme.shape.borderRadius}px !important`,
    },
  },
});

export default makeMuiMenu;
