import { gql } from '@apollo/client';

const createHighlightedContentMutation = gql`
  mutation CreateHighlightedContentMutation(
    $input: CreateHighlightedContentInput!
  ) {
    createHighlightedContent(input: $input) {
      id
    }
  }
`;

export default createHighlightedContentMutation;
