export interface ReportsDownloadFilters {
  planId: string;
  createdAtGte: Date | null;
  createdAtLte: Date | null;
  term: string;
}

export enum DownloadReportsPlan {
  'totalLinkedUsersInPlan' = 'totalLinkedUsersInPlan',
  'totalOferted' = 'totalOferted',
  'totalCancelledPlans' = 'totalCancelledPlans',
  'totalActiviesPlans' = 'totalActiviesPlans',
  'getIntervalRetentionDiscount' = 'getIntervalRetentionDiscount',
  'credit' = 'credit',
  'debit' = 'debit',
  'pix' = 'pix',
  'trialDays' = 'trialDays',
  'countTrialDaysCancelled' = 'countTrialDaysCancelled',
}

export const DownloadReportsPlanJson = {
  [DownloadReportsPlan.totalLinkedUsersInPlan]: 'Planos retidos',
  [DownloadReportsPlan.totalOferted]: 'Planos ofertados',
  [DownloadReportsPlan.totalCancelledPlans]: 'Planos cancelados',
  [DownloadReportsPlan.totalActiviesPlans]: 'Planos ativos',
  [DownloadReportsPlan.getIntervalRetentionDiscount]:
    'Tempo minimo para desconto de retenção',
  [DownloadReportsPlan.credit]: 'Vendas com cartão de crédito',
  [DownloadReportsPlan.debit]: 'Total de compras com cartão de débito',
  [DownloadReportsPlan.pix]: 'Total de compras por pix',
  [DownloadReportsPlan.trialDays]: 'Dias de teste para o plano',
  [DownloadReportsPlan.countTrialDaysCancelled]:
    'Cancelamentos antes do fim do periodo de teste',
};
