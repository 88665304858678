import { Content } from '../interfaces/Content';

export const normalizeString = (str: string): string =>
  str
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase();

export const compareTerms = (term: string, termToCompare: string): boolean => {
  const normalizedTerm = normalizeString(term.toLowerCase());
  const normalizedTermToCompare = normalizeString(termToCompare.toLowerCase());
  const didMatch = normalizedTerm.includes(normalizedTermToCompare);
  return didMatch;
};

export const stringToSingular = (str: string): string =>
  str.slice(0, -1).toLowerCase();

export const filterContents = (
  contents: Content[],
  title: string,
  type: string,
) => {
  const filteredContents = contents
    .filter((content: Content) => compareTerms(content.title, title))
    .filter((content: Content) => compareTerms(content.type, type));
  return filteredContents;
};

export const formatQuantityResult = (value: number, sigularName: string) => {
  const lengthValueVerify = value > 1;
  if (lengthValueVerify) return `${value} ${sigularName}s`;
  return `${value} ${sigularName}`;
};
