// TODO: Find a better solution for default props
/* eslint-disable react/require-default-props */
import { useNode, Node } from '@craftjs/core';
import AddIcon from '@material-ui/icons/Add';
import { useEffect, useMemo } from 'react';
import { Stack } from '@material-ui/core';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import EmptyStateCard from '../../../../../../components/EmptyStateCard';
import StructuresSettings from '../../Sidebar/settings/widgets/StructuresSettings';
import DeleteBlockButton, { DeletableBlocks } from './DeleteBlockButton';

interface SingleContainerProps {
  bgColor: string;
  children?: React.ReactNode;
}

const SingleContainer = ({
  bgColor,
  children,
}: SingleContainerProps) => {
  const {
    connectors: { connect, drag: craftDrag },
    id,
    isClicked,
    hasChild,
  } = useNode((node) => ({
    isClicked: node.events.selected,
    hasChild: node.data.nodes.length > 0,
  }));
  const [{ isDragging }, drag, preview] = useDrag<
    any,
    unknown,
    { isDragging: boolean }
  >(() => ({
    type: 'SingleContainerBlock',
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  }));
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  const borderStyle = useMemo(() => {
    if (isClicked) return '4px solid';
    if (!hasChild) return '2px dotted'
    return 'none'
  }, [isClicked, hasChild]);
  return (
    <Stack
      ref={drag}
      position="relative"
      width={820}
      sx={{
        bgcolor: !hasChild ? '#171717' : bgColor,
        border: borderStyle,
        borderColor: isClicked ? 'info.main' : 'grey.400',
        borderRadius: 1,
        cursor: 'grab',
        minHeight: 208,
        opacity: isDragging ? 0.3 : 1,
        p: !hasChild ? 0 : 5,
      }}
    >
      <Stack gap={4} ref={(ref: HTMLElement) => connect(craftDrag(ref))} height="100%">
        {!hasChild ? (
          <EmptyStateCard
            description="Depois de arrastar, você poderá editar as posições, incluir e organizar seus blocos e estruturas."
            icon={<AddIcon />}
            title="Arraste blocos para cá"
            sx={{ height: 208 }}
          />
        ) : (
          children
        )}
        {isClicked ? <DeleteBlockButton id={id} name={DeletableBlocks.structure} /> : null}
      </Stack>
    </Stack>
  );
};

const allowedComponents = [
  'DynamicButton',
  'DynamicImage',
  'DynamicText',
  'DynamicVideo',
];

SingleContainer.craft = {
  related: { settings: StructuresSettings },
  rules: {
    canMoveIn: (incoming: Node) =>
      allowedComponents.includes(incoming.data.name),
  },
};

export default SingleContainer;
