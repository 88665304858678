import { gql } from '@apollo/client';

const listMclsContentQuery = gql`
  query listMclsContentQuery {
    mclsContents {
      createdAt
      updatedAt
      deletedAt
      id
      embed
      title
    }
  }
`;

export default listMclsContentQuery;
