import {
  createContext,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useContext,
} from 'react';
import { Plan } from '../../interfaces/Plan';
import { Spectator } from '../../interfaces/Spectator';
import { SpectatorsFilters } from './typings';

interface BillingInfo {
  document: string;
  neighborhood: string;
  street: string;
  addressNumber: string;
  city: string;
  complement: string;
  zipcode: string;
  state: string;
}

export interface Subscription {
  title: string;
  status: string;
  signatureDate: string;
  recurrenceInterval: string;
  subscriptionValue: number;
  paymentMethod: string;
  lastDigits?: string;
  billingInfo: BillingInfo;
}

export type DrawerType =
  | 'SPECTATOR_DATA'
  | 'SUBSCRIPTION'
  | 'FINANCE_HISTORY'
  | 'SPECTATOR_MANAGEMENT'
  | null;

type SelectedSpectator = Spectator | null;

export type SpectatorContextData = {
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  handleOpenMenu: (
    event: MouseEvent<HTMLButtonElement>,
    data: Spectator,
  ) => void;
  drawerType: DrawerType;
  listLeads: boolean;
  listSpectators: any[];
  searchByRecords: boolean;
  setSearchByRecords: Dispatch<SetStateAction<boolean>>;
  setDrawerType: Dispatch<SetStateAction<DrawerType>>;
  selectedSpectator: SelectedSpectator;
  setSelectedSpectator: Dispatch<SetStateAction<SelectedSpectator>>;
  setData: (spectators: any) => void;
  input: { filters: SpectatorsFilters; paginate: { page: number } };
  setInput: Dispatch<SetStateAction<SpectatorContextData['input']>>;
  setListLeads: Dispatch<SetStateAction<boolean>>;
  plans: Plan[];
};

export const Context = createContext({} as SpectatorContextData);

export const useSpectator = (): SpectatorContextData => {
  const context = useContext(Context);
  return context;
};
