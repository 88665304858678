import { Box } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import { Section } from '../../../interfaces/Section';
import SectionListAccordion from './SectionListAccordion';

interface SectionListItemProps {
  section: Section;
  index: number;
}

const DraggableSectionListItem = ({ index, section }: SectionListItemProps) => (
  <Draggable draggableId={section.id} index={index}>
    {(provided, snapshot) => (
      <Box
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <SectionListAccordion section={section} snapshot={snapshot} />
      </Box>
    )}
  </Draggable>
);

export default DraggableSectionListItem;
