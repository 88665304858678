import { Button, Theme } from '@material-ui/core';
import { Download } from '@material-ui/icons';
import { SxProps } from '@material-ui/system';
import { downloadFile } from '../../utils/download/downloadFile';

export interface DownloadButtonProps {
  uri: string;
  fileName: string;
  sx: SxProps<Theme>;
  buttonTitle: string;
}

const DownloadButton = ({
  uri,
  fileName,
  sx,
  buttonTitle,
}: DownloadButtonProps) => (
  <Button
    onClick={() => downloadFile(uri, fileName)}
    sx={sx}
    endIcon={<Download />}
  >
    {buttonTitle}
  </Button>
);

export default DownloadButton;
