import { Avatar, Box, Card, Chip, Stack, Typography } from '@material-ui/core';
import { DragIndicator as DragIndicatorIcon } from '@material-ui/icons';
import { Draggable } from 'react-beautiful-dnd';
import { SortableLiveContents } from '../typings';

interface LiveSectionItemProps {
  sortableContent: SortableLiveContents;
  index: number;
}

const LiveSectionItem = ({ sortableContent, index }: LiveSectionItemProps) => (
  <Draggable draggableId={sortableContent.content.id} index={index}>
    {(provided) => (
      <Card
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        sx={{
          height: 96,
          mb: 4,
          p: 4,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          gap={4}
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          <DragIndicatorIcon
            sx={{ color: 'grey.400', fontSize: 30, cursor: 'grab' }}
          />
          <Box
            sx={{
              alignItems: 'center',
              bgcolor: 'grey.500',
              borderRadius: '4px',
              color: 'grey.100',
              display: 'flex',
              fontSize: 24,
              justifyContent: 'center',
              height: 40,
              width: 40,
            }}
          >
            {index + 1}
          </Box>
          <Avatar
            src={sortableContent.content.thumbnail}
            variant="rounded"
            sx={{ height: 64, width: 114 }}
          />
          <Stack justifyContent="space-between" sx={{ height: '100%' }}>
            <Typography sx={{ color: 'grey.100', fontSize: 16 }}>
              {sortableContent.content.title}
            </Typography>
            <Chip
              color="error"
              label="Ao vivo"
              sx={{ fontSize: 12, height: 26, maxWidth: 81 }}
            />
          </Stack>
        </Stack>
      </Card>
    )}
  </Draggable>
);

export default LiveSectionItem;
