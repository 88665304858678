import { Button } from '@material-ui/core';
import { useAuthorize } from '@tshio/react-router-permissions';
import { useContext, useState } from 'react';
import HasNoPermission from '../../components/HasNoPermission';
import Topbar from '../../components/Topbar';
import TipAlert from '../../components/UI/molecules/TipAlert';
import ContextToMe from '../../Context';
import Container from './components/Container';
import Filters from './components/Filters';
import Context, { SortPositionDirection } from './Context';

const SectionList = () => {
  const { me } = useContext(ContextToMe);
  const permissionForEverything = me.superAdmin;
  const permissionToCreate =
    permissionForEverything || useAuthorize(['sections', 'create']);
  const permissionToRead =
    permissionForEverything || useAuthorize(['sections', 'read']);
  const permissionToUpdate =
    permissionForEverything || useAuthorize(['sections', 'update']);
  if (!permissionToRead) return <HasNoPermission />;
  const [input, setInput] = useState({
    filters: {
      sortPositionDirection: SortPositionDirection.minor,
      term: '',
    },
  });
  const [isCreatingSection, setIsCreatingSection] = useState(false);
  const createSectionHandler = () => setIsCreatingSection(true);
  const permissionToCreateAndIsNotCreating =
    permissionToCreate && !isCreatingSection;
  return (
    <Context.Provider
      value={{
        isCreatingSection,
        setIsCreatingSection,
        permissionToUpdate,
        input,
        setInput,
      }}
    >
      <Topbar
        title="Seções"
        description="Cria e ordena por prioridade as tuas seções"
      >
        {permissionToCreateAndIsNotCreating && (
          <Button color="info" size="large" onClick={createSectionHandler}>
            Nova Seção
          </Button>
        )}
      </Topbar>
      <TipAlert sx={{ mt: 4 }}>
        Escolha até 10 conteúdos para importar simultaneamente via Spalla
        Streaming. Selecione-os abaixo, você poderá editar depois do envio.
      </TipAlert>
      <Filters />
      <Container />
    </Context.Provider>
  );
};

export default SectionList;
