import { gql } from '@apollo/client';

const updateSectionMutation = gql`
  mutation UpdateSectionMutation($input: UpdateSectionInput!) {
    updateSection(input: $input) {
      id
      title
      scheduleDate
      scheduleDateEnabled
      finishDate
      finishDateEnabled
      published
      updatedAt
      generalSubSection {
        id
        title
        sortableContents {
          position
          content {
            id
            title
            thumbnail
          }
        }
      }
      subSections {
        id
        title
        position
        sortableContents {
          position
          content {
            id
            title
            thumbnail
          }
        }
      }
    }
  }
`;

export default updateSectionMutation;
