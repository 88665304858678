import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Plan } from '../../interfaces/Plan';
import { ReportsDownloadFilters } from './typings';

export type ReportsDownloadContext = {
  input: { filters: ReportsDownloadFilters; paginate: { page: number } };
  setInput: Dispatch<SetStateAction<ReportsDownloadContext['input']>>;
  plans: Plan[];
};

export const Context = createContext({} as ReportsDownloadContext);

export const useSpectator = (): ReportsDownloadContext => {
  const context = useContext(Context);
  return context;
};
