import { Chip, Grid, Stack, Typography } from '@material-ui/core';
import { useContext, useMemo } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TileCard3 from '../../../components/TileCard3';
import { SortableHighlightedContents } from '../../../interfaces/Highlight';
import sortByPosition from '../../CreatePlan/tools/sortHighlightByPosition';
import PreviewPlanContext from '../Context';
import HighlightCard from './HighlightedCard';

const Contents = () => {
  const { plan } = useContext(PreviewPlanContext);
  const sortHighlightByPosition = useMemo(
    () =>
      plan.sortableHighlightedContents
        ? [...plan.sortableHighlightedContents].sort(sortByPosition)
        : [],
    [plan.sortableHighlightedContents],
  );
  return (
    <>
      <Typography
        sx={{ color: 'grey.100', fontSize: 20, fontWeight: 600, mb: 6, mt: 8 }}
      >
        Conteúdos disponíveis no plano
      </Typography>
      <Grid container direction="row" spacing={4}>
        <Grid item xs={6}>
          <TileCard3
            title="Seções liberadas"
            tip="Para liberar conteúdos para o espectador, associe ao plano seções já criadas."
            sx={{ height: 189 }}
          >
            <Stack
              direction="row"
              flexWrap="wrap"
              sx={{ maxHeight: 110, overflowY: 'auto' }}
            >
              {plan.sections.map((section) => (
                <Chip
                  label={
                    <Typography
                      sx={{
                        color: 'grey.100',
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      {section.title}
                    </Typography>
                  }
                  sx={{ bgcolor: 'grey.500', mb: 4, mr: 4 }}
                />
              ))}
            </Stack>
          </TileCard3>
        </Grid>
        <Grid item xs>
          <TileCard3 title="Destaques" tip="" sx={{ height: 189 }}>
            <PerfectScrollbar style={{ height: 125, width: '100%' }}>
              <Stack
                direction="row"
                flexWrap="wrap"
                sx={{
                  maxHeight: 120,
                  width: '100%',
                }}
              >
                {sortHighlightByPosition.map(
                  (sortableHighlightedContent: SortableHighlightedContents) => (
                    <HighlightCard sortableHighlightedContent={sortableHighlightedContent} />
                  ),
                )}
              </Stack>
            </PerfectScrollbar>
          </TileCard3>
        </Grid>
      </Grid>
    </>
  );
};

export default Contents;
