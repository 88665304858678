import { InsertPhoto as InsertPhotoIcon } from '@material-ui/icons';
import { DynamicImage } from '../../../BuildArea/components';
import CustomContentBlock from './CustomContentBlock';

const ImageBlock = () => (
  <CustomContentBlock
    type="ImageBlock"
    label="Imagens"
    createdComponent={
      <DynamicImage alt="" objectFit="cover" objectPosition="center" url="" />
    }
  >
    <InsertPhotoIcon sx={{ fontSize: 32, color: 'info.main' }} />
  </CustomContentBlock>
);

export default ImageBlock;
