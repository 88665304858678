import { SxProps, Theme } from '@material-ui/system';
import { ReactElement } from 'react';

export interface IDialogOptions {
  title?: string | ReactElement;
  message?: string;
  element?: ReactElement;
  type?: 'dialog' | 'confirmation';
  callback?: any;
  onOk?: () => void;
  onCancel?: () => void;
  sx?: SxProps<Theme>;
}

export const DEFAULT_DIALOG_OPTIONS: IDialogOptions = {};
