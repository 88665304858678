import { InsertPhoto as InsertPhotoIcon } from '@material-ui/icons';
import CustomDragLayer from './CustomDragLayer';

const ImageBlockDragLayer = () => (
  <CustomDragLayer type="ImageBlock">
    <InsertPhotoIcon sx={{ color: 'info.main', fontSize: 56 }} />
  </CustomDragLayer>
);

export default ImageBlockDragLayer;
