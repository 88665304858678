/* eslint-disable */
import { Component, createRef } from 'react';
import './styles.css';

class Player extends Component {
  _player: any = null;

  _el = createRef();

  componentDidMount() {
    // @ts-ignore
    const { source, onPlay } = this.props;
    const wrapper = document.getElementById('bm-player');

    if (this._el.current) {
      // @ts-ignore
      this._player = new window.THEOplayer.Player(this._el.current, {
        libraryLocation:
          'https://cdn.myth.theoplayer.com/a2e6496a-2cc2-4800-ae85-6feae325581c',
        license:
          'sZP7IYe6T6fkID0kCKfZ3Ok10lAgFSatIlh-CKao0mke3KhoIDBi3L0zIKB6FOPlUY3zWokgbgjNIOf9flxl3lBL3o46FS5tTSe-3uaoTOz_TQCtFS11CLBZ3u36ISe_06fVfK4_bQgZCYxNWoryIQXzImf90SCt3S5t0lRi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3lac0Sez0L0oTuh_FOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkIYz',
      });
      this._player.source = source;
      this._player.addEventListener('play', onPlay);
      const player = this._player;

      // @ts-ignore
      function rewind() {
        player.currentTime -= 5;
      }

      // @ts-ignore
      function forward() {
        player.currentTime += 5;
      }

      // @ts-ignore
      function toggleMute() {
        player.muted = !player.muted;
      }

      // @ts-ignore
      function togglePlay() {
        if (player.paused) {
          player.play();
        } else {
          player.pause();
        }
      }

      // @ts-ignore
      function preventStandardHotKeyActions(event) {
        event.stopPropagation();
        event.preventDefault();
      }

      // @ts-ignore
      function getPressedKey(event) {
        const pressedKey = event.key;
        let action;

        const keyActionsMapper = {
          ' ': togglePlay,
          ArrowLeft: rewind,
          ArrowRight: forward,
          m: toggleMute,
        };

        // @ts-ignore
        const isNotActionkey = !keyActionsMapper[pressedKey];

        if (isNotActionkey) {
          return;
        }

        // @ts-ignore
        action = keyActionsMapper[pressedKey];

        if (
          action &&
          pressedKey !== 'Control' &&
          pressedKey !== 'Alt' &&
          pressedKey !== 'Shift'
        ) {
          action();
          preventStandardHotKeyActions(event);
        }
      }

      // @ts-ignore
      function playerFocused() {
        // @ts-ignore
        if (wrapper.contains(document.activeElement)) {
          document.addEventListener('keydown', getPressedKey);
        } else {
          document.removeEventListener('keydown', getPressedKey);
        }
      }

      // @ts-ignore
      function mouseInPlayer() {
        document.addEventListener('keydown', getPressedKey);
        // @ts-ignore
        wrapper.addEventListener('mouseleave', mouseOutPlayer);
      }

      // @ts-ignore
      function mouseOutPlayer() {
        // @ts-ignore
        wrapper.removeEventListener('mouseleave', mouseOutPlayer);
        document.removeEventListener('keydown', getPressedKey);
      }

      // @ts-ignore
      function enableShortcuts(method) {
        switch (method) {
          case 'mouseOver':
            // @ts-ignore
            wrapper.addEventListener('mouseenter', mouseInPlayer);
            break;
          case 'playerFocused': // If the param reads 'playerFocused' shortcuts are only enabled when the player is focused.
            document.addEventListener('focus', playerFocused, true);
            break;
          default:
            document.addEventListener('keydown', getPressedKey); // Else player shortcuts are always enabled.
        }
      }

      enableShortcuts('mouseOver');
    }
  }

  componentWillUnmount() {
    if (this._player) {
      this._player.destroy();
    }
  }

  render() {
    return (
      <div
        id="bm-player"
        className="theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer"
        // @ts-ignore
        ref={this._el}
      />
    );
  }
}

export default Player;
