import { List, MenuItem, Pagination, Select } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useContext, useMemo, useState } from 'react';
import paginationAdapter from '../../../../adapters/pagination';
import SearchBar from '../../../../components/UI/molecules/SearchBar';
import BulkActionController from '../../../../components/UI/templates/BulkActionController';
import {
  getPageTypeFriendlyName,
  Page,
  PageType,
} from '../../../../interfaces/Plan';
import { compareTerms } from '../../../../utils/string';
import Context from '../context';
import PagesListItem from './PagesListItem';
import { SortBy } from '../../../../typings/sort';
import { sortByNewest, sortByOldest } from '../utils/sort';

const PagesList = ({ pages }: { pages: Page[] }) => {
  const [selectedPages, setSelectedPages] = useState<Page[]>([]);
  const [query, setQuery] = useState('');
  const [filterBy, setFilterBy] = useState<PageType | 'none'>('none');
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.Newest);
  const { deletePageHandler, permissionToDelete } = useContext(Context);
  const queryHandler = (evt: any) => setQuery(evt.target.value);
  const filterByHandler = (evt: any) => setFilterBy(evt.target.value);
  const paginationHandler = (_: any, nextPage: number) => setPage(nextPage);
  const sortByHandler = (evt: any) => setSortBy(evt.target.value);
  const filteredPages = query
    ? pages.filter((currentPage) => compareTerms(currentPage.title, query))
    : pages;
  const filteredByType = filteredPages.filter((currentPage) => {
    if (filterBy === 'none') {
      return currentPage;
    }
    if (filterBy === currentPage.type) {
      return currentPage.type;
    }
    return !currentPage.type;
  });
  const sortedContents = [...filteredByType].sort(
    sortBy === SortBy.Oldest ? sortByOldest : sortByNewest,
  );
  const paginatedPages = paginationAdapter<Page>({
    items: sortedContents,
    itemsPerPage: 10,
    page,
  });
  const toggleSelectedPages = (selectedPage: Page) => {
    const pageIndex = selectedPages.findIndex(
      (currentPage) => currentPage?.id === selectedPage.id,
    );
    const pageNotExists = pageIndex !== -1;
    if (pageNotExists) {
      const newContentsSelecteds = selectedPages.filter(
        (currentPage) => currentPage.id !== selectedPage.id,
      );
      setSelectedPages(newContentsSelecteds);
      return;
    }
    setSelectedPages((previousContents) => [selectedPage, ...previousContents]);
  };
  const deleteSelectedPagesHandler = async () => {
    await deletePageHandler(selectedPages);
    setSelectedPages([]);
  };
  const pageTypeList = useMemo(
    () =>
      Object.values(PageType).map((type) => (
        <MenuItem key={type} value={type}>
          {getPageTypeFriendlyName(type)}
        </MenuItem>
      )),
    [],
  );
  return (
    <>
      <SearchBar
        TextFieldProps={{
          placeholder: 'Procure aqui por uma página específica',
          value: query,
          onChange: queryHandler,
        }}
        sx={{ mt: 4 }}
      >
        <Select
          data-testid="filter-by"
          sx={{ minWidth: 161 }}
          value={filterBy}
          onChange={filterByHandler}
        >
          <MenuItem value="none">Todos:</MenuItem>
          <MenuItem value="free">Página livre</MenuItem>
          {pageTypeList}
        </Select>
        <Select value={sortBy} sx={{ minWidth: 161 }} onChange={sortByHandler}>
          <MenuItem value={SortBy.Newest}>Mais recentes</MenuItem>
          <MenuItem value={SortBy.Oldest}>Mais antigos</MenuItem>
        </Select>
      </SearchBar>
      <List data-testid="list-plans">
        {paginatedPages.items.map((currentPage) => (
          <PagesListItem
            selected={selectedPages.includes(currentPage)}
            onSelect={toggleSelectedPages}
            page={currentPage}
          />
        ))}
      </List>
      <Pagination
        count={paginatedPages.total}
        sx={{ mt: 4 }}
        onChange={paginationHandler}
      />
      {permissionToDelete && (
        <BulkActionController
          ButtonProps={{
            color: 'error',
            children: 'Excluir',
            onClick: deleteSelectedPagesHandler,
          }}
          iconItem={DeleteIcon}
          ListItemIconProps={{ color: 'error' }}
          listItem={selectedPages}
        />
      )}
    </>
  );
};

export default PagesList;
