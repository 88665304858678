
import { VpnLock as WorldIcon } from '@material-ui/icons';
import {
  Box,
  Button,
  Divider,
  List,
  RadioGroup,
  Stack,
  Switch,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { useDialog } from '../../../components/Dialog';
import InlineTextEditor from '../../../components/InlineTextEditor';
import { Permission, PermissionType } from '../../../interfaces/Permission';
import { ColorType, Role } from '../../../interfaces/Role';
import validationSchema from '../utils/validationSchema';
import getColorByName from '../utils/getColorRole';
import RadioButton from '../Components/RadioButton';
import PermissionItem from '../Components/PermissionItem';
import { ActionType } from '../typings';

const makePermission = (
  action: ActionType,
  feature: PermissionType,
  value: boolean,
) => ({
  feature,
  create: false,
  read: false,
  update: false,
  delete: false,
  [action]: value,
});

export type RoleInput = Omit<Role, 'id'>;

interface CreateRoleProps {
  role: RoleInput;
  // eslint-disable-next-line react/require-default-props
  titleButton?: string;
}

const CreateRole = ({
  role,
  titleButton = 'Criar novo cargo',
}: CreateRoleProps) => {
  const dialog = useDialog();
  const form = useFormik({
    initialValues: { ...role },
    validationSchema,
    onSubmit: (input: RoleInput) => {
      dialog.close(validationSchema.cast(input));
    },
  });
  const findPermissionsData = (
    permissionFeature: PermissionType,
  ): Permission | null => {
    const hasPermissions = !!form.values.permissions.length;
    if (hasPermissions) {
      const filteredPermission = form.values.permissions.find(
        (permission) => permission.feature === permissionFeature,
      );
      if (filteredPermission) return filteredPermission;
    }
    return null;
  };
  const changePermissionsHandler = (
    action: ActionType,
    feature: PermissionType,
    valueAction: boolean,
  ) => {
    const filteredPermisisonByFeature = findPermissionsData(feature);
    if (!filteredPermisisonByFeature) {
      const newPermission = makePermission(action, feature, valueAction);
      form.setFieldValue('permissions', [
        ...form.values.permissions,
        newPermission,
      ]);
      return;
    }
    const newKeyPermission = {
      [action]: valueAction,
    };
    const newPermisions = form.values.permissions.map((currentPermission) =>
      currentPermission.feature === feature
        ? { ...currentPermission, ...newKeyPermission }
        : currentPermission,
    );
    form.setFieldValue('permissions', newPermisions);
  };
  const permissions = useMemo(
    () =>
      Object.values(PermissionType).map((currentPermission) => {
        const permissionData = findPermissionsData(currentPermission);
        return (
          <PermissionItem
            title={currentPermission}
            permission={permissionData}
            onChangePermission={changePermissionsHandler}
          />
        );
      }),
    [form.values.permissions],
  );
  const colorsRoles = useMemo(
    () =>
      Object.values(ColorType).map((colorName) => {
        const colorHex = getColorByName(colorName);
        return <RadioButton value={colorName} colorRadio={colorHex} />;
      }),
    [form.values.color],
  );
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <InlineTextEditor
          value={form.values.title}
          TypographyProps={{
            sx: {
              color: 'grey.100',
              fontSize: 20,
              maxWidth: 300,
            },
          }}
          onChange={(title) => {
            if (title) {
              form.setFieldValue('title', title);
            }
          }}
        />
        <Stack>
          <Typography sx={{ color: 'grey.100', fontSize: 12 }}>
            ESCOLHA UMA COR PARA O CARGO:
          </Typography>
          <RadioGroup
            name="radios-buttons-colors"
            value={form.values.color}
            sx={{ display: 'flex', flexDirection: 'row' }}
            onChange={(cor) => form.setFieldValue('color', cor.target.value)}
          >
            {colorsRoles}
          </RadioGroup>
        </Stack>
      </Stack>
      <Divider sx={{ bgcolor: 'grey.500', mb: 4, mt: 3 }} />
      <Typography
        sx={{
          alignItems: 'center',
          color: 'grey.300',
          display: 'flex',
          mb: 6,
        }}
      >
        <WorldIcon sx={{ mr: 4 }} />
        DEFINA AS PERMISSÕES DO CARGO:
      </Typography>
      <List>{permissions}</List>
      <Divider sx={{ bgcolor: 'grey.500', mb: 4, mt: 3 }} />
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Box>
          <Button type="submit" onClick={() => form.handleSubmit()}>
            {titleButton}
          </Button>
        </Box>
        <Stack alignItems="center" direction="row">
          <Typography sx={{ color: 'grey.400', fontSize: 14 }}>
            Esconder gestores com este cargo
          </Typography>
          <Switch
            name="enabled"
            checked={form.values.enabled}
            sx={{ ml: 4 }}
            onChange={form.handleChange}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default CreateRole;
