import {
  Box,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  List,
  OutlinedInput,
  Stack,
  Typography,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { ChangeEvent, useContext, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { normalizeString } from '../../../utils/string';
import Context from '../Context';
import SelectableContentList from './SelectableContentList';

interface SearchableContentsProps {
  sx: any; // TODO: add typing
}

const SearchableContents = ({ sx }: SearchableContentsProps) => {
  const { selectableContents } = useContext(Context);
  const [query, setQuery] = useState('');
  const changeQueryHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setQuery(evt.target.value);
  const contents = query
    ? selectableContents.filter((content) =>
        normalizeString(content.title).includes(normalizeString(query)),
      )
    : selectableContents;
  return (
    <Card sx={sx}>
      <CardContent sx={{ height: 571, p: 0 }}>
        <Grid container item direction="column" sx={{ height: '100%' }}>
          <Grid item>
            <Box sx={{ p: 5 }}>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  sx={{ color: 'grey.100', fontSize: 20, fontWeight: 600 }}
                >
                  Selecione os conteúdos
                </Typography>
              </Stack>
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'secondary.main' }} />
                  </InputAdornment>
                }
                fullWidth
                placeholder="Pesquisar conteúdos da seção"
                value={query}
                sx={{ mt: 4 }}
                onChange={changeQueryHandler}
              />
            </Box>
          </Grid>
          <Grid item xs sx={{ overflow: 'auto', width: '100%' }}>
            <List>
              <Droppable
                droppableId="contentList"
                type="content"
                isDropDisabled
                renderClone={(provided, _, rubric) => {
                  const content = contents[rubric.source.index];
                  return (
                    <Box
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      sx={{
                        backgroundImage: `url(${content.thumbnail})`,
                        backgroundSize: 'cover',
                        borderRadius: 1,
                        height: 96,
                        width: 170,
                      }}
                    />
                  );
                }}
              >
                {(provided) => (
                  <Stack
                    ref={provided.innerRef}
                    sx={{ mt: 4 }}
                    {...provided.droppableProps}
                  >
                    <SelectableContentList contents={contents} />
                  </Stack>
                )}
              </Droppable>
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SearchableContents;
