import { Button, Stack } from '@material-ui/core';
import { useAlert } from 'react-alert';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { useContext, useState } from 'react';
import EditContentContext from '../../../../pages/EditContent/context';
import uploadFile from '../../../../pages/ManagersList/services/uploadFile';
import getAssetUri from '../../../../utils/getAssetUri';
import { CoverImageEditorProps } from './typings';

const CoverImgEditor = ({ sx }: CoverImageEditorProps) => {
  const { form } = useContext(EditContentContext);
  const alert = useAlert();
  const [imagePreview, setImagePreview] = useState<string>(
    form.values.thumbnail,
  );
  const changeThumbnailHandler = async (evt: any) => {
    const res = await uploadFile<any>(evt.target.files[0]);
    const hasErrors = !!res.data.errors;
    const formatUnsupported =
      hasErrors && res.data?.errors[0]?.message.includes('is unsupported');
    if (formatUnsupported) {
      alert.error('Formato de imagem não suportado.');
      return;
    }
    if (res.data.data.fileUpload) {
      const fileSrc = getAssetUri(res.data.data.fileUpload);
      form.setFieldValue('thumbnail', fileSrc);
      setImagePreview(fileSrc);
    }
  };
  return (
    <Stack
      alignItems="flex-end"
      justifyContent="flex-end"
      sx={{
        backgroundImage: `url(${imagePreview || form.values.thumbnail})`,
        backgroundColor: !imagePreview ? 'grey.300' : '',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: 2,
        height: 293,
        padding: 4,
        position: 'relative',
        width: 520,
        ...sx,
      }}
    >
      <label htmlFor="contained-button-file">
        <input
          data-testid="input-file"
          accept="image/*"
          id="contained-button-file"
          multiple
          type="file"
          style={{ display: 'none' }}
          onChange={changeThumbnailHandler}
        />
        <Button
          variant="contained"
          color="primary"
          component="span"
          endIcon={<CameraAltIcon />}
        >
          Alterar capa do vídeo
        </Button>
      </label>
    </Stack>
  );
};

export default CoverImgEditor;
