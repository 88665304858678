import { useQuery } from '@apollo/client';
import {
  Button,
  InputAdornment,
  List,
  ListItem,
  Typography,
  OutlinedInput,
  Stack,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSearchParams } from 'react-router-dom';
import { useDialog } from '../../../components/Dialog';
import { Plan } from '../../../interfaces/Plan';
import { compareTerms } from '../../../utils/string';
import getPlansForDiscountQuery from '../gql/GetPlansForDiscount';

interface ModalToAddPlansProps {
  plans: Plan[];
  hasButton?: boolean;
  onChange?: (plans: Plan[]) => void;
}

const ModalToAddPlans = ({
  plans,
  hasButton = false,
  onChange,
}: ModalToAddPlansProps) => {
  const dialog = useDialog();
  const [params] = useSearchParams();
  const type = params.get('type') as string;
  const [query, setQuery] = useState('');
  const [selectedPlans, setSelectedPlans] = useState<Plan[]>(plans);
  const { loading, data } = useQuery(getPlansForDiscountQuery, {
    variables: { discountType: type },
  });
  const queryHandler = (evt: any) => setQuery(evt.target.value);
  const filteredPlans = query
    ? data?.availablePlansForDiscount.filter((plan: Plan) =>
        compareTerms(plan.title, query),
      )
    : data?.availablePlansForDiscount;
  if (loading) return <div>Loading...</div>;
  const submitedByButton = !hasButton && onChange;
  const toggleSelectedPlan = (plan: Plan) => {
    const findPlanById = selectedPlans.find(
      (currentPlan) => currentPlan.id === plan.id,
    );
    const isNotSelected = !findPlanById;
    const newSelectedPlans = [...selectedPlans];
    if (isNotSelected) {
      const newPlans = [...selectedPlans, plan];
      setSelectedPlans(newPlans);
      if (submitedByButton) {
        onChange(newPlans);
      }
      return;
    }
    const newPlans = newSelectedPlans.filter(
      (currentPlan) => currentPlan.id !== plan.id,
    );
    if (submitedByButton) {
      onChange(newPlans);
    }
    setSelectedPlans(newPlans);
  };
  const isSelected = (plan: Plan) => {
    const planSelectIndex = selectedPlans.findIndex(
      (current) => current.id === plan.id,
    );
    const planSelect = planSelectIndex !== -1;
    return !!planSelect;
  };
  const allPlansAreSelected =
    data?.availablePlansForDiscount.length === selectedPlans.length;
  const addAllPlans = () => {
    if (allPlansAreSelected) {
      setSelectedPlans([]);
      if (submitedByButton) {
        onChange([]);
      }
      return;
    }
    if (submitedByButton) {
      onChange(data?.availablePlansForDiscount as Plan[]);
    }
    setSelectedPlans(data?.availablePlansForDiscount as Plan[]);
  };
  const submitNewTags = () => dialog.close(selectedPlans);
  return (
    <Stack sx={{ position: 'relative', overflow: 'hidden' }}>
      <OutlinedInput
        fullWidth
        value={query}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon sx={{ color: 'info.main' }} />
          </InputAdornment>
        }
        placeholder="Adicione ou exclua um plano"
        sx={{ color: 'secondary.light', my: 4 }}
        onChange={queryHandler}
      />
      <Button
        fullWidth
        color={allPlansAreSelected ? 'error' : 'primary'}
        sx={{ fontSize: 12, fontWeight: 500, height: 30 }}
        onClick={addAllPlans}
      >
        {allPlansAreSelected
          ? 'Remover todos os planos'
          : 'Adicionar todos os planos'}
      </Button>
      <List sx={{ height: 245, mt: 2, overflow: 'hidden' }}>
        <PerfectScrollbar style={{ height: 245 }}>
          {filteredPlans!.map((plan: Plan) => (
            <ListItem
              sx={{
                height: 53,
                px: 0,
                '.MuiListItemSecondaryAction-root': { right: 0 },
              }}
              secondaryAction={
                <Button
                  color={isSelected(plan) ? 'success' : 'secondary'}
                  sx={{ fontSize: 12, fontWeight: 500, height: 30, mr: 3, width: 77 }}
                  onClick={() => toggleSelectedPlan(plan)}
                >
                  {isSelected(plan) ? 'Adicionado' : 'Adicionar'}
                </Button>
              }
            >
              <Typography
                sx={{
                  color: 'grey.100',
                  fontSize: 14,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: 203,
                }}
              >
                {plan.title}
              </Typography>
            </ListItem>
          ))}
        </PerfectScrollbar>
      </List>
      {hasButton && (
        <Button
          fullWidth
          sx={{ bottom: 2, mt: 2, position: 'sticky', zIndex: 10 }}
          onClick={submitNewTags}
        >
          Salvar alterações
        </Button>
      )}
    </Stack>
  );
};

export default ModalToAddPlans;
