import { InputLabel, OutlinedInput, Typography } from '@material-ui/core';
import { ChangeEvent } from 'react';

interface InputTextProps {
  name: string;
  value: string | number;
  label: string;
  error?: string;
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
}

const InputText = ({ name, value, label, error, onChange }: InputTextProps) => (
  <>
    <form autoComplete="off">
      <InputLabel
        htmlFor={name}
        sx={{ color: 'grey.100', fontSize: 14, mb: 2 }}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        name={name}
        id={name}
        value={value}
        fullWidth
        inputProps={{ maxLength: 160 }}
        onChange={onChange}
        sx={{
          borderColor: error ? 'error.main' : 'grey.400',
          borderStyle: 'solid',
          borderWidth: 1,
          height: '100%',
        }}
      />
      <Typography sx={{ color: 'error.main', fontSize: 12, mt: 2 }}>
        {error}
      </Typography>
    </form>
  </>
);

export default InputText;
