import { Theme } from '@material-ui/core';
import { Components } from '@material-ui/core/styles/components';

const makeMuiListItem = (theme: Theme): Components['MuiListItem'] => ({
  styleOverrides: {
    root: {
      height: theme.shape.sizes.large,
      '&:hover': { color: theme.palette.secondary.main },
    },
  },
});

export default makeMuiListItem;
