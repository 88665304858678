import { Theme } from '@material-ui/core';
import { Components } from '@material-ui/core/styles/components';

const makeMuiPagination = (theme: Theme): Components['MuiPagination'] => ({
  defaultProps: {
    siblingCount: 6,
    shape: 'rounded',
    showFirstButton: true,
    showLastButton: true,
  },
  styleOverrides: {
    root: {
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius * 2,
      display: 'flex',
      height: 76,
      justifyContent: 'center',
    },
    ul: {
      justifyContent: 'center',
      li: {
        '&:first-child': { marginLeft: theme.spacing(5) },
        '&:last-child': { marginRight: theme.spacing(5) },
        '&:nth-child(3)': { marginLeft: 'auto' },
        '&:nth-last-child(2)': { marginLeft: 'auto' },
      },
      width: '100%',
    },
  },
});

export default makeMuiPagination;
