import { Button, MenuItem, Select } from '@material-ui/core';
import { useContext, useState } from 'react';
import SearchBar from '../../../components/UI/molecules/SearchBar';
import { SortBy } from '../../../typings/sort';
import Context from '../Context';

const Filters = () => {
  const [filtersInput, setFiltersInput] = useState({
    filters: {
      name: '',
      sort: SortBy.Newest,
    },
  });
  const { setInput } = useContext(Context);
  const changeInputHandler = (evt: any) => {
    const fieldName = evt.target.name;
    const fieldValue = evt.target.value;
    setFiltersInput({
      ...filtersInput,
      filters: { ...filtersInput.filters, [fieldName]: fieldValue },
    });
  };
  const searchSectionsHandler = () =>
    setInput({ ...filtersInput, paginate: { page: 1 } });
  return (
    <SearchBar
      TextFieldProps={{
        name: 'name',
        placeholder: 'Busque aqui por um time',
        value: filtersInput.filters.name,
        onChange: changeInputHandler,
      }}
      sx={{ mt: 4 }}
    >
      <Select
        name="sort"
        value={filtersInput.filters.sort}
        sx={{ height: 38, width: 156 }}
        onChange={changeInputHandler}
      >
        <MenuItem value={SortBy.Newest}>Mais recentes</MenuItem>
        <MenuItem value={SortBy.Oldest}>Mais antigos</MenuItem>
      </Select>
      <Button onClick={searchSectionsHandler}>Pesquisar</Button>
    </SearchBar>
  );
};

export default Filters;
