import { useEditor } from '@craftjs/core';
import { Card, Stack, Typography } from '@material-ui/core';
import { useContext } from 'react';
import {
  PanoramaWideAngleTwoTone as PanoramaWideAngleTwoToneIcon,
  SmartphoneTwoTone as SmartphoneTwoToneIcon,
} from '@material-ui/icons';
import { useDialog } from '../../../../../components/Dialog';
import InlineTextEditor from '../../../../../components/InlineTextEditor';
import Viewer from '../../../Viewer/Viewer';
import BuilderContext from '../../context';

const DynamicTopBar = () => {
  const { form } = useContext(BuilderContext);
  const dialog = useDialog();
  const { query } = useEditor();
  const openPreview = async (title: string, width: number) => {
    const json = query.serialize();
    const view = await dialog.open({
      title,
      element: <Viewer json={json} />,
      sx: { width },
    });
    return view;
  };
  return (
    <Card sx={{ height: 84, padding: 6 }}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ height: '100%', width: '100%' }}
      >
        <InlineTextEditor
          hideIcon={false}
          value={form.values.title}
          TypographyProps={{
            color: 'common.white',
            fontSize: 20,
            fontWeight: 600,
          }}
          onChange={form.handleChange('title')}
        />
        <Stack alignItems="center" direction="row">
          <Typography
            sx={{ color: 'common.white', fontSize: 16, fontWeight: 600 }}
          >
            Visualizar
          </Typography>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: 1,
              backgroundColor: 'grey.500',
              color: 'common.white',
              cursor: 'pointer',
              fontSize: 16,
              fontWeight: 600,
              height: 36,
              ml: 3,
              width: 44,
              '&:hover': { color: 'info.main' },
            }}
          >
            <PanoramaWideAngleTwoToneIcon onClick={
              () => openPreview('Visualização da Página: Desktop', 1250)
            } />
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: 1,
              backgroundColor: 'grey.500',
              color: 'common.white',
              cursor: 'pointer',
              fontSize: 16,
              fontWeight: 600,
              height: 36,
              ml: 3,
              width: 44,
              '&:hover': { color: 'info.main' },
            }}
          >
            <SmartphoneTwoToneIcon onClick={
              () => openPreview('Visualização da Página: Mobile', 408)
            } />
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default DynamicTopBar;
