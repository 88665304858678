import { Drawer, Stack } from '@material-ui/core';
import { useSpectator } from '../Context';
import SpectatorDataDrawer from './SpectatorDataDrawer';
import SpectatorHistoryDrawer from './SpectatorHistoryDrawer';
import SpectatorSubscriptionDrawer from './SpectatorSubscriptionDrawer';
import SpectatorManagement from './SpectatorManagement';

const SpectatorDrawer = () => {
  const { drawerType, setDrawerType, setSelectedSpectator } = useSpectator();
  const closeDrawer = () => {
    setDrawerType(null);
    setSelectedSpectator(null);
  };
  const renderDrawerContent = () => {
    switch (drawerType) {
      case 'SPECTATOR_DATA':
        return <SpectatorDataDrawer />;
      case 'FINANCE_HISTORY':
        return <SpectatorHistoryDrawer />;
      case 'SUBSCRIPTION':
        return <SpectatorSubscriptionDrawer />;
      case 'SPECTATOR_MANAGEMENT':
        return <SpectatorManagement />;
      default:
        return <></>;
    }
  };
  return (
    <Drawer anchor="right" open={!!drawerType} onClose={closeDrawer}>
      <Stack sx={{ width: '45vw' }}>
        {drawerType && renderDrawerContent()}
      </Stack>
    </Drawer>
  );
};

export default SpectatorDrawer;
