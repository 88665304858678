import { Box, Stack } from '@material-ui/core';
import { useContext } from 'react';
import Context from '../Context';
import { HighlightType } from '../typings';

interface HighlightTypeItemProps {
  itemType: HighlightType;
}

const HighlightTypeItem = ({ itemType }: HighlightTypeItemProps) => {
  const { form } = useContext(Context);
  const itemSelected = form.values.itemType === itemType;
  return (
    <Box
      sx={{
        alignItems: 'center',
        bgcolor: itemSelected ? 'grey.600' : 'primary.main',
        borderRadius: 2,
        color: itemSelected ? 'grey.100' : 'grey.400',
        cursor: 'pointer',
        display: 'flex',
        fontWeight: itemSelected ? 600 : 'normal',
        justifyContent: 'center',
        height: 59,
        transition: 'all 0.2s ease-in-out',
        width: 322,
      }}
      onClick={() => form.setFieldValue('itemType', itemType)}
    >
      {itemType === 'content' ? 'Conteúdo' : 'Seção'}
    </Box>
  );
};

const ChooseHighlightType = () => (
  <Stack alignItems="center" direction="row" gap={4} sx={{ mt: 6 }}>
    <HighlightTypeItem itemType={HighlightType.content} />
    <HighlightTypeItem itemType={HighlightType.section} />
  </Stack>
);

export default ChooseHighlightType;
