import { gql } from '@apollo/client';

const spallaVodsQuery = gql`
  query SpallaVods {
    spallaVods {
      spallaContentId
      title
      description
      type
      published
      thumbnail
      sourceDuration
      sourceSize
      sourceResolution
      createdAt
      updatedAt
    }
  }
`;

export default spallaVodsQuery;
