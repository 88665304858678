import { Stack, Typography } from '@material-ui/core';
import { Check as CheckIcon, Star as StarIcon } from '@material-ui/icons';
import { useContext } from 'react';
import { DiscountType } from '../../../interfaces/Discount';
import { FinancialDiscountReport } from '../../../interfaces/FinancialReport';
import Context from '../Context';
import formatNumber from '../utils/formatNumber';
import Card from './Card';
import { ValueGroup } from './helpers';

interface TotalAndValuesProps {
  financialDiscountReport: FinancialDiscountReport;
}

const TotalAndValues = ({ financialDiscountReport }: TotalAndValuesProps) => {
  const { discountType } = useContext(Context);
  const isRetentionDiscount = discountType === DiscountType.Retention;
  const isIndividualDiscount = discountType === DiscountType.Coupon;
  const discountDurationLabel =
    financialDiscountReport.durationAvg === 1 ? 'mês' : 'meses';
  return (
    <>
      <Card icon={StarIcon} title="Total de Descontos" sx={{ height: 148 }}>
        <Stack alignItems="center" direction="row" gap="70px" sx={{ mt: 4 }}>
          <ValueGroup
            title="Ativo"
            value={financialDiscountReport.totalActive}
          />
          <ValueGroup
            title="Inativo"
            value={financialDiscountReport.totalInactive}
          />
          <ValueGroup
            title="Total de descontos criados"
            value={financialDiscountReport.totalCreated}
          />
        </Stack>
      </Card>
      <Card
        icon={CheckIcon}
        title="Valores e Duração "
        sx={{ height: 225, mt: 6 }}
      >
        <Stack
          alignItems="center"
          direction="row"
          sx={{ flexWrap: 'wrap', mt: 4 }}
        >
          <ValueGroup
            title="Porcentagem média de desconto"
            value={`${formatNumber(
              financialDiscountReport.percetangeValueAvg,
            )}%`}
          />
          <ValueGroup
            title="Duração média dos descontos"
            value={`${financialDiscountReport.durationAvg} ${discountDurationLabel}`}
            sx={{ ml: '40px' }}
          />
          <ValueGroup
            title="Valor médio de descontos - $"
            value={`R$ ${formatNumber(financialDiscountReport.priceValueAvg)}`}
            sx={{ mt: isRetentionDiscount ? 0 : 5 }}
          />
          {isRetentionDiscount && (
            <ValueGroup
              title="Taxa de Sucesso"
              value={`${financialDiscountReport.successRate}% de retenção`}
              sx={{ ml: '69px', mt: 5 }}
            >
              <Typography sx={{ color: 'grey.300', fontSize: 14, mt: 1 }}>
                {financialDiscountReport.spectatorsWithActiveRetention} aceitos
                / {financialDiscountReport.viewsDiscountRetentions} oferecidos
              </Typography>
            </ValueGroup>
          )}
          {isIndividualDiscount && (
            <ValueGroup
              title="Média de cupons utilizados"
              value={`${financialDiscountReport.usageAvg}%`}
              sx={{ ml: '69px', mt: 5 }}
            />
          )}
        </Stack>
      </Card>
    </>
  );
};

export default TotalAndValues;
