import { memo } from 'react';
import { Content } from '../../../interfaces/Content';
import SelectableContent from './SelectableContent';

interface SelectableContentListProps {
  contents: Content[];
}

const SelectableContentList = memo(
  ({ contents }: SelectableContentListProps) => (
    <>
      {contents.map((content, index: number) => (
        <SelectableContent key={content.id} index={index} content={content} />
      ))}
    </>
  ),
);

export default SelectableContentList;
