import { VpnLock as WorldIcon } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { useAlert } from 'react-alert';
import { useDialog } from '../../components/Dialog';
import HasNoPermission from '../../components/HasNoPermission';
import ContextMe from '../../Context';
import Topbar from '../../components/Topbar';
import TipAlert from '../../components/UI/molecules/TipAlert';
import EmptyStateCard from '../../components/EmptyStateCard';
import Details from './Components/Details';
import { ColorType, Role } from '../../interfaces/Role';
import ModalEditAndCreate from './ModalEditAndCreate';
import Context from './Context';
import SearchContainer from './SearchContainer';
import updateRoleMutation from './gql/updateRoleMutation';
import rolesQuery from './gql/rolesQuery';
import deleteRoleMutation from './gql/deleteRoleMutation';
import createRoleMutation from './gql/createRoleMutation';

const role = {
  title: 'Título do cargo',
  color: ColorType.blue,
  enabled: false,
  permissions: [],
};

const Roles = () => {
  const { me } = useContext(ContextMe);
  const isNotSuperAdmin = !me.superAdmin;
  if (isNotSuperAdmin) return <HasNoPermission />;
  const alert = useAlert();
  const dialog = useDialog();
  const { loading, data, refetch } = useQuery(rolesQuery);
  const [createRole] = useMutation(createRoleMutation);
  const [updateRole] = useMutation(updateRoleMutation);
  const [deleteRole] = useMutation(deleteRoleMutation);
  const deleteRoleHandler = async (roleToDelete: Role) => {
    const confirmed = await dialog.confirm({
      title: 'Você tem certeza?',
      message:
        'Ao excluir um cargo, os gestores associados à ele perderão seus acessos. Defina novos cargos antes de realizar a exclusão.',
    });
    if (confirmed) {
      try {
        await deleteRole({ variables: { id: roleToDelete.id } });
        await refetch();
        alert.success('Cargo deletado com sucesso!');
      } catch (err: any) {
        const roleAlreadyAssociated = err.graphQLErrors?.[0].message.includes(
          'violates foreign key constraint',
        );
        if (roleAlreadyAssociated) {
          alert.error('Cargo está associado à um gestor.');
        }
      }
    }
  };
  const createRoleHandler = async () => {
    const values = await dialog.open({
      title: 'Novo cargo',
      element: <ModalEditAndCreate role={role} />,
      sx: { height: 702, width: 652 },
    });
    if (values) {
      await createRole({ variables: { input: values } });
      await refetch();
      alert.success('Cargo criado com sucesso!');
    }
  };
  const editRoleHandler = async (roleEdit: Role) => {
    const values = await dialog.open({
      title: 'Editar cargo',
      element: (
        <ModalEditAndCreate role={roleEdit} titleButton="Salvar alterações" />
      ),
      sx: { height: 702, width: 652 },
    });
    if (values) {
      await updateRole({ variables: { input: values } });
      await refetch();
    }
  };
  if (loading) return <div>carregando...</div>;
  const hasRoles = data.roles.length;
  return (
    <Context.Provider
      value={{ editRoleHandler, deleteRoleHandler, roles: data.roles }}
    >
      <Topbar
        title="Cargos"
        description="Organize as permissões dos gestores da plataforma ao designa-los cargos."
      >
        <Button color="info" onClick={createRoleHandler}>
          Novo cargo
        </Button>
      </Topbar>
      <TipAlert sx={{ mt: 4 }}>
        Permita que cargos específicos visualizem, editem e excluam itens da
        plataforma de acordo com as permissões.
      </TipAlert>
      {hasRoles ? (
        <>
          <Details />
          <SearchContainer />
        </>
      ) : (
        <EmptyStateCard
          description="Crie cargos com diferentes permissões e simplifique o acesso de gestores à plataforma."
          icon={<WorldIcon color="secondary" />}
          title="Cargos"
          sx={{ height: 328, mt: 4 }}
        >
          <Button variant="outlined" color="info" onClick={createRoleHandler}>
            Novo cargo
          </Button>
        </EmptyStateCard>
      )}
    </Context.Provider>
  );
};

export default Roles;
