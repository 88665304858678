export enum ValueType {
  value = 'value',
  percentage = 'percentage',
}

const SymbolValue = {
  [ValueType.value]: 'R$',
  [ValueType.percentage]: '%',
};

export const getSymbolByValue = (valueType: ValueType): string =>
  SymbolValue[valueType];

export interface Coupon {
  id: string;
  title: string;
  discountPrice: number;
  discountType: ValueType;
  usageLimit: number;
  published?: boolean;
  usageUnlimited?: boolean;
  recurrenceInterval: number;
  scheduleDate?: Date;
  finishDate?: Date;
}
