import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from '@material-ui/core';
import { useContext, useMemo } from 'react';
import Context from '../Context';
import FormCard from './FormCard';

const RecurrenceCard = () => {
  const { form } = useContext(Context);
  const numberOfMonths = useMemo(
    () =>
      [...Array(13)].map((_, index) => (
        <MenuItem value={index}>{index}</MenuItem>
      )),
    [],
  );
  return (
    <FormCard
      title="Recorrência"
      description="Defina o número de renovações disponíveis ao desconto. "
      isRequired
      sx={{ mt: 4 }}
    >
      <Stack
        alignItems="center"
        direction="row"
        sx={{ gap: 5, mt: 6, width: '100%' }}
      >
        <Box sx={{ width: '100%' }}>
          <InputLabel
            sx={{
              color: 'grey.300',
              fontSize: 14,
              mb: 1,
            }}
          >
            Recorrência do desconto
          </InputLabel>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Stack alignItems="center" direction="row">
              <Select
                name="recurrence"
                value={form.values.recurrence}
                disabled={!!form.values.recurrenceUnlimited}
                sx={{
                  borderColor: 'grey.400',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  width: 76,
                }}
                onChange={form.handleChange}
              >
                {numberOfMonths}
              </Select>
              <Typography sx={{ color: 'grey.300', fontSize: 14, ml: 1 }}>
                Meses
              </Typography>
            </Stack>
            <Stack alignItems="center" direction="row">
              <Typography sx={{ color: 'grey.300', fontSize: 14, mr: 3 }}>
                Desconto por tempo indeterminado
              </Typography>
              <Switch
                name="recurrenceUnlimited"
                checked={form.values.recurrenceUnlimited}
                onChange={(event) => {
                  const { checked } = event.target;
                  if (checked) {
                    form.setFieldValue('recurrence', 0);
                  }
                  form.handleChange(event);
                }}
              />
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </FormCard>
  );
};

export default RecurrenceCard;
