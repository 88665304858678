import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { Discount } from '../../../interfaces/Discount';
import discountReportsAnalyse from '../gql/discountReportAnalyseQuery';
import PublicAndPlans from './PublicAndPlans';
import RulesAndRetentions from './RulesAndRetentions';

interface InformationsProps {
  discount: Discount;
}

const Informations = ({ discount }: InformationsProps) => {
  const { loading: loadingAnalyse, data: discountAnalyse } = useQuery(
    discountReportsAnalyse,
    { variables: { input: { filters: { id: discount.id } } } },
  );
  if (loadingAnalyse) return <>Carregando...</>;
  return (
    <Grid container direction="row" spacing={2} sx={{ mt: 4 }}>
      <Grid item xs>
        <RulesAndRetentions
          discount={discount}
          discountAnalyse={discountAnalyse.getDiscountReportsAnalyse}
        />
      </Grid>
      <Grid item xs>
        <PublicAndPlans
          discount={discount}
          discountAnalyse={discountAnalyse.getDiscountReportsAnalyse}
        />
      </Grid>
    </Grid>
  );
};

export default Informations;
