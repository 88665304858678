import { gql } from '@apollo/client';

const getMultiSectionItemQuery = gql`
  query GetMultiSectionItemQuery($id: ID!) {
    multiSectionItem(id: $id) {
      id
      title
      itemType
      scheduleDate
      finishDate
      title
      content {
        id
        title
        thumbnail
      }
      section {
        id
        title
        generalSubSection {
          id
          title
          position
          sortableContents {
            position
            content {
              id
              thumbnail
              title
            }
          }
        }
        subSections {
          id
          position
          title
          sortableContents {
            position
            content {
              id
              thumbnail
              title
            }
          }
        }
      }
      thumbnail
    }
  }
`;

export default getMultiSectionItemQuery;
