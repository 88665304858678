import { useQuery } from '@apollo/client';
import { Button, Grid, Stack, Typography } from '@material-ui/core';
import {
  Launch as LaunchIcon,
  Person as ManagerIcon,
  VpnLock as WorldIcon,
} from '@material-ui/icons';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TileCard5 from '../../../components/TileCard5';
import TileCard6 from '../../../components/TileCard6';
import Context from '../Context';
import getAllManagersCountQuery from '../gql/getAllManagersCountQuery';

const Details = () => {
  const navigate = useNavigate();
  const { loading, data } = useQuery(getAllManagersCountQuery);
  const { roles } = useContext(Context);
  if (loading) return <div>Carregando...</div>;
  return (
    <Grid container spacing={8} sx={{ mt: 0 }}>
      <Grid item xs={3}>
        <TileCard6
          value={roles.length}
          secondValue="Cargos criados"
          helperText=""
          buttonVisible={false}
          icon={WorldIcon}
        />
      </Grid>
      <Grid item xs={3}>
        <TileCard6
          value={data.getAllManagersCount}
          secondValue="Gestores na plataforma"
          helperText=""
          buttonVisible={false}
          icon={ManagerIcon}
        />
      </Grid>
      <Grid item xs={6}>
        <TileCard5
          title="Atribua os cargos aos gestores"
          link="/managers"
          rightIcon={LaunchIcon}
          titleProps={{ sx: { color: 'grey.100', fontSize: 16, mb: 4 } }}
          buttonVisible={false}
          sx={{ height: 177 }}
        >
          <>
            <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
              Após criar os cargos, o próximo passo é associá-los aos gestores.
            </Typography>
            <Stack alignItems="center" direction="row" sx={{ mt: 4 }}>
              <Button onClick={() => navigate('/managers')}>
                Acessar biblioteca de gestores
              </Button>
              <Button
                onClick={() => navigate('/managers')}
                sx={{ bgcolor: 'grey.500', ml: 4 }}
              >
                Criar um novo gestor
              </Button>
            </Stack>
          </>
        </TileCard5>
      </Grid>
    </Grid>
  );
};

export default Details;
