import { useMutation } from '@apollo/client';
import { MenuItem, Switch, Select, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/UI/molecules/Navbar';
import TipAlert from '../../components/UI/molecules/TipAlert';
import FormFooter from '../../components/UI/templates/FormFooter';
import { Content } from '../../interfaces/Content';
import Context from '../EditContent/context';
import Container from './Components/Container';
import CreateContentMutation from './gql/createContentMutation';
import validationSchema from './Providers/validationSchema';

type CreateContentInput = Omit<
  Content,
  'id' | 'spallaContentId' | 'createdAt' | 'updatedAt' | 'deletedAt'
>;

const CreateExternalContent = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [createContent, { loading: isUpdating }] = useMutation(
    CreateContentMutation,
  );
  const form = useFormik({
    initialValues: {
      title: 'Título do conteúdo',
      description: '',
      thumbnail: '',
      type: 'vod',
      authorizedOnMobile: false,
      authorizedOnDesktop: false,
      authorizedOnTv: false,
      published: true,
      scheduleDate: null,
      scheduleDateEnabled: false,
      finishDate: null,
      finishDateEnabled: false,
      externalContent: true,
      externalEmbed: '',
      externalUrl: '',
      tags: [],
      teams: [],
      chatEnable: false,
      chatEmbed: '',
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values: CreateContentInput) => {
      const input = validationSchema.cast(values);
      await createContent({ variables: { input } });
      navigate('/library');
      alert.success('Conteúdo criado com sucesso.');
    },
  });
  const hasErrors = !form.isValid;
  return (
    <Context.Provider value={{ form }}>
      <Navbar route="/library" title={form.values.title}>
        <Select
          name="type"
          value={form.values.type}
          onChange={form.handleChange}
        >
          <MenuItem value="vod">VOD</MenuItem>
          <MenuItem value="live">LIVE</MenuItem>
        </Select>
        <Typography sx={{ color: 'grey.100', ml: 5 }}>
          Publicado
          <Switch
            name="published"
            checked={form.values.published}
            color="secondary"
            sx={{ ml: 2 }}
            onChange={form.handleChange}
          />
        </Typography>
      </Navbar>
      <TipAlert sx={{ mt: 4 }}>
        Salve as edições para que o seu conteúdo seja publicado
      </TipAlert>
      <Container />
      <FormFooter
        LeftButtonProps={{
          children: 'Salvar conteúdo',
          type: 'submit',
          loading: isUpdating,
          onClick: () => form.handleSubmit(),
        }}
        helperText={hasErrors ? 'Verifique os campos acima.' : ''}
        helperTextProps={{ color: 'error.main' }}
        RightButtonProps={{
          children: 'Excluir conteúdo',
        }}
        sx={{ mt: 4 }}
      />
    </Context.Provider>
  );
};

export default CreateExternalContent;
