import { LinearProgress, Stack, Typography } from '@material-ui/core';
import { Equalizer as EqualizerIcon } from '@material-ui/icons';
import PlanAdoptionList from '../../../components/PlanAdoptionList';

const PaymentAndPartners = () => (
  <PlanAdoptionList
    title="Vínculos mais Utilizados"
    buttonIsVisible={false}
    leftIcon={EqualizerIcon}
    sx={{ height: 177, width: '100%', mt: 4 }}
  >
    <Stack direction="row" alignItems="center" sx={{ mt: 4 }}>
      <Typography
        sx={{
          color: 'grey.100',
          fontSize: 12,
          fontWeight: 600,
          mr: 2,
          width: 75,
        }}
      >
        Pagamentos
      </Typography>
      <LinearProgress
        sx={{
          bgcolor: 'grey.500',
          borderRadius: 2,
          height: 11,
          width: '100%',
          '.MuiLinearProgress-bar': { bgcolor: 'secondary.main' },
        }}
        variant="determinate"
        value={80}
      />
      <Typography sx={{ color: 'grey.100', fontSize: 12 }}>80%</Typography>
    </Stack>
    <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 4 }}>
      <Typography
        sx={{
          color: 'grey.100',
          fontSize: 12,
          fontWeight: 600,
          mr: 2,
          width: 75,
        }}
      >
        Afiliados e Marcas
      </Typography>
      <LinearProgress
        sx={{
          bgcolor: 'grey.500',
          borderRadius: 2,
          height: 11,
          width: '100%',
          '.MuiLinearProgress-bar': { bgcolor: 'secondary.main' },
        }}
        variant="determinate"
        value={20}
      />
      <Typography sx={{ color: 'grey.100', fontSize: 12 }}>20%</Typography>
    </Stack>
  </PlanAdoptionList>
);

export default PaymentAndPartners;
