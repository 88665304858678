import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
} from '@material-ui/core';
import { Delete as DeleteIcon, Search as SearchIcon } from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useState } from 'react';
import { Category } from '../../../../interfaces/Category';
import { Tag } from '../../../../interfaces/Tag';
import { normalizeString } from '../../../../utils/string';
import listCategoriesQuery from '../CategoriesContainer/gql/listCategoriesQuery';
import removeTagFromCategoryMutation from '../CategoriesContainer/gql/removeTagFromCategoryMutation';
import { Permission } from '../../../../interfaces/Permission';

interface TagsModalProps {
  category: Category;
  permissionToDelete: Permission['delete'];
}

const TagsModal = ({ category, permissionToDelete }: TagsModalProps) => {
  const { loading, data, refetch } = useQuery(listCategoriesQuery);
  const [removeTagFromCategory] = useMutation(removeTagFromCategoryMutation);
  const [searchQuery, setSearchQuery] = useState('');
  if (loading) return <div>Carregando...</div>;
  const currentCategory = data.categories.filter(
    (prev: Category) => prev.id === category.id,
  )[0];
  const searchChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(evt.target.value);
  const filteredTags = currentCategory.tags.filter((tag: any) =>
    normalizeString(tag.name).includes(normalizeString(searchQuery)),
  );
  const deleteTagHandler = async (tag: Tag) => {
    await removeTagFromCategory({
      variables: { tagId: tag.id, categoryId: currentCategory.id },
    });
    await refetch();
  };
  return (
    <>
      <OutlinedInput
        data-testid="input-tags-modal"
        fullWidth
        placeholder="Pesquisar tag"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon sx={{ color: 'info.main' }} />
          </InputAdornment>
        }
        sx={{ color: 'secondary.light', mb: 4 }}
        onChange={searchChangeHandler}
      />
      <PerfectScrollbar style={{ height: 240 }}>
        <Box sx={{ pb: 3, pr: 6 }}>
          <List>
            {filteredTags.map((tag: any) => (
              <ListItem data-testid={`tag-modal-item-${tag.id}`} sx={{ p: 0 }}>
                <ListItemText
                  primaryTypographyProps={{
                    sx: { color: 'white', fontSize: 14 },
                  }}
                  primary={tag.name}
                />
                {permissionToDelete && (
                  <IconButton edge="end" data-testid="icon-delete-tag">
                    <DeleteIcon
                      fontSize="small"
                      sx={{ color: 'white', ':hover': { color: 'error.main' } }}
                      onClick={() => deleteTagHandler(tag)}
                    />
                  </IconButton>
                )}
              </ListItem>
            ))}
          </List>
        </Box>
      </PerfectScrollbar>
    </>
  );
};

export default TagsModal;
