export type StatusColor = 'active' | 'suspended' | 'canceled' | 'pre-cancelled';

export const getChipColor = (status: StatusColor) => {
  const styles = {
    success: {
      bgColor: 'rgba(0, 197, 114, 0.1)',
      border: 'success.main',
      label: 'Ativo',
    },
    warning: {
      bgColor: 'rgba(241, 174, 0, 0.1)',
      border: 'warning.main',
      label: 'Suspenso',
    },
    preWarning: {
      bgColor: 'rgba(241, 174, 0, 0.1)',
      border: 'warning.main',
      label: 'Pré-cancelado',
    },
    error: {
      bgColor: 'rgba(255, 84, 94, 0.1)',
      border: 'error.main',
      label: 'Cancelado',
    },
  };
  switch (status) {
    case 'active':
      return styles.success;
    case 'suspended':
      return styles.warning;
    case 'pre-cancelled':
      return styles.preWarning;
    default:
      return styles.error;
  }
};
