import {
  Card,
  CardContent,
  Stack,
  Typography,
} from '@material-ui/core';
import { TagsCardProps } from './typings';

const TileCard3 = ({ children, title, sx }: TagsCardProps) => (
  <Card sx={sx}>
    <CardContent>
      <Stack direction="row" sx={{ mb: 4 }}>
        <Typography
          sx={{ color: 'grey.100', fontSize: 16, fontWeight: 600, mr: 1 }}
        >
          {title}
        </Typography>
      </Stack>
      <Stack flexDirection="row" sx={{ flexWrap: 'wrap' }}>
        {children}
      </Stack>
    </CardContent>
  </Card>
);

export default TileCard3;
