import { gql } from '@apollo/client';

const updateIndividualDiscountMutation = gql`
  mutation UpdateIndividualDiscountMutation(
    $input: UpdateIndividualDiscountInput!
  ) {
    updateIndividualDiscount(input: $input) {
      id
    }
  }
`;

export default updateIndividualDiscountMutation;
