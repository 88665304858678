/* eslint-disable react/require-default-props */
import {
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import { ChangeEvent, useState } from 'react';

interface InputPasswordProps {
  name: string;
  value: string | number;
  label: string;
  error?: string;
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
}

const InputPassword = ({
  error,
  label,
  name,
  value,
  onChange,
}: InputPasswordProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const clickShowPasswordHandler = () =>
    setShowPassword((prevState) => !prevState);
  return (
    <>
      <form autoComplete="off">
        <InputLabel
          htmlFor={name}
          sx={{ color: 'grey.100', fontSize: 14, mb: 2 }}
        >
          {label}
        </InputLabel>
        <OutlinedInput
          id={name}
          name={name}
          type={showPassword ? 'text' : 'password'}
          value={value}
          inputProps={{ maxLength: 160 }}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end" onClick={clickShowPasswordHandler}>
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          }
          onChange={onChange}
          sx={{
            borderColor: error ? 'error.main' : 'grey.400',
            borderStyle: 'solid',
            borderWidth: 1,
            height: '100%',
          }}
        />
        <Typography sx={{ color: 'error.main', fontSize: 12, mt: 2 }}>
          {error}
        </Typography>
      </form>
    </>
  );
};

export default InputPassword;
