import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormHelperText,
  Stack,
  Typography,
} from '@material-ui/core';
import { useContext } from 'react';
import { useDialog } from '../../../components/Dialog';
import { Avatar } from '../../../interfaces/HeartTeam';
import Context from '../Context';
import AvatarItem from './AvatarItem';

const AvatarList = () => {
  const dialog = useDialog();
  const { form } = useContext(Context);
  const removeAllAvatars = async () => {
    const confirm = await dialog.confirm({
      title: 'Você tem certeza?',
      message:
        'Excluir todos os ícones afetará diretamente o perfil de alguns espectadores.',
    });
    if (confirm) {
      form.setFieldValue('avatars', []);
    }
  };
  const thereAreAvatars = form.values.avatars.length > 0;
  const changeEnabledAvatar = async (avatar: Avatar) => {
    const newAvatars = form.values.avatars.map((currentAvatar: Avatar) => {
      const selectedAvatar = currentAvatar.url === avatar?.url;
      return {
        ...currentAvatar,
        enabled: selectedAvatar ? !currentAvatar.enabled : false,
      };
    });
    form.setFieldValue('avatars', newAvatars);
  };
  const removeAvatarHandler = async (avatar: Avatar) => {
    const confirm = await dialog.confirm({
      title: 'Você tem certeza?',
      message:
        'Excluir um ícone afetará diretamente o perfil de alguns espectadores.',
    });
    if (confirm) {
      const avatarRemoved = form.values.avatars.filter(
        (currentAvatar) => currentAvatar.url !== avatar.url,
      );
      const stillHaveAvatars = avatarRemoved.length > 0;
      const isTheEnabledAvatar = avatar?.enabled;
      const mustTagAnotherAvatar = stillHaveAvatars && isTheEnabledAvatar;
      form.setFieldValue('avatars', avatarRemoved);
      if (mustTagAnotherAvatar) {
        form.setFieldValue('avatars[0]', {
          ...avatarRemoved[0],
          enabled: true,
        });
      }
    }
  };
  return (
    <Card>
      <CardContent>
        <Typography sx={{ color: 'grey.100', fontSize: 16, mb: 4 }}>
          Ícones adicionados
        </Typography>
        <Typography sx={{ color: 'grey.300', fontSize: 14, mb: 4 }}>
          Imagens adicionadas serão listadas abaixo, defina uma imagem para
          representar o time no fluxo de cadastro do espectador.
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          spacing={3}
          sx={{ overflow: 'auto', pb: 3, width: 600 }}
        >
          {thereAreAvatars ? (
            form.values.avatars.map((avatar: any) => (
              <Box sx={{ width: 128 }}>
                <AvatarItem
                  avatar={avatar}
                  changeEnabledAvatar={changeEnabledAvatar}
                  removeAvatarHandler={removeAvatarHandler}
                />
              </Box>
            ))
          ) : (
            <AvatarItem />
          )}
        </Stack>
        <Divider sx={{ bgcolor: 'grey.400', mb: 3, mt: 6 }} />
        <FormHelperText error sx={{ mb: 3 }}>
          {form.errors.avatars}
        </FormHelperText>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Button onClick={removeAllAvatars}>Excluir ícones</Button>
          <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
            Excluir todos os ícones criados
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default AvatarList;
