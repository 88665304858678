import { gql } from '@apollo/client';

const contentQuery = gql`
  query ContentQuery($id: ID!) {
    content(id: $id) {
      id
      title
      description
      authorizedOnMobile
      authorizedOnDesktop
      authorizedOnTv
      eventDate
      scheduleDate
      scheduleDateEnabled
      finishDate
      finishDateEnabled
      type
      published
      thumbnail
      sourceDuration
      sourceSize
      sourceResolution
      externalContent
      externalEmbed
      externalUrl
      createdAt
      updatedAt
      chatEnable
      chatEmbed
      mclsContent {
        id
        title
      }
      tags {
        id
        name
        slug
      }
      teams {
        id
        name
        slug
      }
    }
  }
`;

export default contentQuery;
