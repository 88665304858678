import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const todaysDate = (locale: string) => new Date().toLocaleDateString(locale);
const localeDate = (locale: string, date: string, time: string) =>
  new Date(`${date} ${time}`).toLocaleDateString(locale);

export const formatDate = (date: string, time: string) => {
  const pickedDate = date
    ? localeDate('pt-br', date, time)
    : todaysDate('pt-br');
  const pickedTime = time ? `${time}` : '00:00';

  return `${pickedDate} ${pickedTime}`;
};

export const getDurationFromSecs = (secs: number) => {
  const secsInMillis = secs * 1000;
  const duration = new Date(secsInMillis).toISOString().substr(11, 8);
  return duration;
};

const normalizeDateNumber = (value: number) =>
  value < 10 ? `0${value}` : value;

export const splitDate = (timeStamp: number) => {
  const date = new Date(timeStamp);
  const day = normalizeDateNumber(date.getDate());
  const month = normalizeDateNumber(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = normalizeDateNumber(date.getHours());
  const minutes = normalizeDateNumber(date.getMinutes());

  return [`${year}-${month}-${day}`, `${hours}:${minutes}`];
};

export const formatDateByFNS = (date: Date, dateFormat: string): string =>
  format(parseISO(new Date(date).toISOString()), dateFormat, {
    locale: ptBR,
  });
