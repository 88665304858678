import {
  Button,
  Card,
  Stack,
  SvgIconProps,
  Typography,
} from '@material-ui/core';
import { SxProps, Theme } from '@material-ui/system';
import { ComponentType, ReactNode } from 'react';

interface PlanListProps {
  buttonIsVisible: boolean;
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode;
  leftIcon: ComponentType<SvgIconProps>;
  title: string;
  // eslint-disable-next-line react/require-default-props
  onClickButton?: () => void;
  // eslint-disable-next-line react/require-default-props
  sx?: SxProps<Theme>;
}

const PlanAdoptionList = ({
  buttonIsVisible,
  children,
  leftIcon: LeftIcon,
  title,
  onClickButton,
  sx,
}: PlanListProps) => (
  <Card sx={{ borderRadius: 2, p: 5, ...sx }}>
    <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ mb: 4 }}>
      <Stack alignItems="center" direction="row">
        <LeftIcon
          sx={{ color: 'secondary.main', height: 32, mr: 4, width: 32 }}
        />
        <Typography
          sx={{ color: 'common.white', fontSize: 20, fontWeight: 600 }}
        >
          {title}
        </Typography>
      </Stack>
      {buttonIsVisible && (
        <Button
          variant="outlined"
          sx={{ borderRadius: 1, fontSize: 12, fontWeight: 500, mr: 1 }}
          onClick={onClickButton}
        >
          Confira todos
        </Button>
      )}
    </Stack>
    {children}
  </Card>
);

export default PlanAdoptionList;
