import {
  Avatar,
  Button,
  Chip,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Https as HttpsIcon,
  MoreVert as OptionsIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Context from '../Context';

const ContentListItem = ({ content, onDelete }: any) => {
  const { permissionToDelete, permissionToRead, permissionToUpdate } =
    useContext(Context);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const isContentMenuOpened = !!anchorEl;
  const closeMenuHandler = () => setAnchorEl(null);
  const openMenuHandler = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const onDeleteHandler = () => {
    setAnchorEl(null);
    onDelete(content);
  };
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={isContentMenuOpened}
        onClose={closeMenuHandler}
      >
        {permissionToRead && (
          <MenuItem
            data-testid="view-content"
            onClick={() => navigate(`/content/${content.id}`)}
          >
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Ver conteúdo
            </Typography>
          </MenuItem>
        )}
        {permissionToUpdate && (
          <>
            <MenuItem
              data-testid="edit-content"
              onClick={() => navigate(`/content/${content.id}/edit`)}
            >
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Editar conteúdo
              </Typography>
            </MenuItem>
            <Divider />
          </>
        )}
        {permissionToDelete && (
          <MenuItem data-testid="exclude-content" onClick={onDeleteHandler}>
            <ListItemIcon>
              <CloseIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Excluir conteúdo
            </Typography>
          </MenuItem>
        )}
      </Menu>
      <ListItem
        key={content.id}
        className="content-item"
        data-testid={`content-item-${content.id}`}
        secondaryAction={
          <Button
            variant="outlined"
            endIcon={<OptionsIcon />}
            onClick={openMenuHandler}
          >
            Opções
          </Button>
        }
        sx={{
          bgcolor: 'primary.main',
          borderRadius: 2,
          height: 'auto',
          mt: 4,
          p: 4,
        }}
      >
        <ListItemAvatar>
          <Avatar
            variant="rounded"
            alt="Remy Sharp"
            src={content.thumbnail}
            sx={{ height: 64, width: 114 }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={content.title}
          primaryTypographyProps={{
            sx: {
              color: 'common.white',
              fontSize: 16,
              fontWeight: 600,
              mb: 3,
            },
          }}
          secondary={
            <>
              {content.type === 'live' ? (
                <Chip
                  color="error"
                  variant="outlined"
                  label="Ao vivo"
                  size="small"
                />
              ) : (
                <Chip
                  color="info"
                  variant="outlined"
                  label="On demand"
                  size="small"
                />
              )}
              <Chip
                color={content.published ? 'success' : 'warning'}
                label={content.published ? 'Publicado' : 'Privado'}
                variant="outlined"
                size="small"
                icon={content.published ? <CheckIcon /> : <HttpsIcon />}
                sx={{
                  ml: 3,
                  pl: 2,
                }}
              />
            </>
          }
          sx={{ m: 0, ml: 4 }}
        />
      </ListItem>
    </>
  );
};

export default ContentListItem;
