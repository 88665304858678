import { Manager } from '../../../interfaces/Manager';
import { SortBy } from '../typings';

export const sortByDate = (
  prevContent: Manager,
  currentContent: Manager,
  sortType: SortBy,
) => {
  const prevContentDate = new Date(prevContent.createdAt).getTime();
  const currentContentDate = new Date(currentContent.createdAt).getTime();
  const dateAreEquals = prevContentDate === currentContentDate;
  if (dateAreEquals) {
    return 0;
  }
  const prevContentDateIsBigger = prevContentDate > currentContentDate;
  if (prevContentDateIsBigger) {
    return sortType === SortBy.Newest ? -1 : 1;
  }
  return sortType === SortBy.Newest ? 1 : -1;
};

export default sortByDate;
