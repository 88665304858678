import * as yup from 'yup';

const validationSchema = yup
  .object()
  .noUnknown()
  .shape({
    id: yup.string(),
    itemType: yup.string().required(),
    title: yup.string().required('Este campo é obrigatório'),
    content: yup.mixed().when('itemType', {
      is: 'content',
      then: yup
        .mixed()
        .required('Este campo é obrigatório')
        .transform((element) => element.id),
    }),
    finishDate: yup.mixed(),
    section: yup.mixed().when('itemType', {
      is: 'section',
      then: yup
        .mixed()
        .required('Este campo é obrigatório')
        .transform((element) => element.id),
    }),
    scheduleDate: yup.mixed(),
    thumbnail: yup.string().required('Este campo é obrigatório.'),
  });

export default validationSchema;
