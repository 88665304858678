import { Button, Card, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useContext, useState } from 'react';
import { DiscountType } from '../../../interfaces/Discount';
import Context from '../Context';

const typings = {
  [DiscountType.Internal]: 'Interno',
  [DiscountType.Collective]: 'Coletivo',
  [DiscountType.Retention]: 'Retenção',
  [DiscountType.Coupon]: 'Cupom Individual',
};

const getDiscountTypeFriendlyName = (type: DiscountType) => typings[type];

const Filter = () => {
  const [type, setType] = useState(DiscountType.Internal);
  const { setDiscountType } = useContext(Context);
  return (
    <Card sx={{ height: 110, mt: 4, p: 6, position: 'relative' }}>
      <InputLabel sx={{ color: 'grey.300', fontSize: 14, mb: 2 }}>
        Tipo de desconto
      </InputLabel>
      <Select
        value={type}
        sx={{ minWidth: 156 }}
        onChange={(evt) => setType(evt.target.value as DiscountType)}
      >
        {Object.values(DiscountType).map((currentType) => (
          <MenuItem value={currentType}>
            {getDiscountTypeFriendlyName(currentType)}
          </MenuItem>
        ))}
      </Select>
      <Button
        sx={{ bottom: 24, position: 'absolute', right: 24 }}
        onClick={() => setDiscountType(type)}
      >
        Atualizar
      </Button>
    </Card>
  );
};

export default Filter;
