import { gql } from '@apollo/client';

const createContentMutation = gql`
  mutation CreateContentMutation($input: CreateContentInput!) {
    createContent(input: $input) {
      id
    }
  }
`;

export default createContentMutation;
