import {
  Box,
  Checkbox,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import { useContext } from 'react';
import { Content } from '../../../interfaces/Content';
import Context from '../Context';

interface SelectableContentProps {
  content: Content;
  index: number;
}

const Item = ({
  content,
  index,
  innerRef,
  isDragging,
  style,
  toggleContent,
  draggableProps,
  dragHandleProps,
  checked,
  ...props
}: any) => (
  <Box {...props} style={{ ...style }}>
    <ListItemButton key={content.id} dense sx={{ px: 5 }}>
      <ListItemIcon sx={{ minWidth: 28 }}>
        <Checkbox
          edge="start"
          checked={checked}
          onClick={toggleContent}
          sx={{ color: 'grey.400', '&.Mui-checked': { color: 'info.main' } }}
        />
      </ListItemIcon>
      <ListItemAvatar sx={{ height: 42, width: 74 }}>
        <div
          ref={innerRef}
          {...draggableProps}
          style={{ width: 170, height: 90, ...draggableProps.style }}
        >
          <img
            {...dragHandleProps}
            alt={content.title}
            src={content.thumbnail}
            loading="lazy"
            style={{ height: 42, width: 74 }}
          />
        </div>
      </ListItemAvatar>
      <ListItemText
        primary={content.title}
        primaryTypographyProps={{
          color: 'grey.100',
          fontSize: 14,
          fontWeight: 600,
        }}
        secondary={
          <>
            <Typography
              sx={{
                color: content.type === 'live' ? 'error.main' : 'info.main',
                display: 'inline',
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              {content.type === 'live' ? 'Ao vivo' : 'On demand'}
            </Typography>
            <Typography
              sx={{
                color: 'grey.300',
                display: 'inline-block',
                fontSize: 12,
                ml: 3,
              }}
            >
              {content.title}
            </Typography>
          </>
        }
        sx={{ ml: 3 }}
      />
    </ListItemButton>
  </Box>
);

const SelectableContent = ({ index, content }: SelectableContentProps) => {
  const { selectedContents, setSelectedContents } = useContext(Context);
  const toggleContent = () => {
    const currentIndex = selectedContents.indexOf(content);
    const newSelectedContents = [...selectedContents];
    const isNotSelected = currentIndex === -1;
    if (isNotSelected) {
      newSelectedContents.push(content);
    } else {
      newSelectedContents.splice(currentIndex, 1);
    }
    setSelectedContents(newSelectedContents);
  };
  const contentIsSelected = selectedContents.includes(content);
  return (
    <Draggable
      key={content.id}
      draggableId={`content-${content.id}`}
      index={index}
    >
      {(provided, snapshot) => (
        <Item
          innerRef={provided.innerRef}
          draggableProps={provided.draggableProps}
          dragHandleProps={provided.dragHandleProps}
          content={content}
          index={index}
          isDragging={snapshot.isDragging}
          toggleContent={toggleContent}
          checked={contentIsSelected}
          style={provided.draggableProps.style}
        />
      )}
    </Draggable>
  );
};

export default SelectableContent;
