import { Input, Stack, Typography } from '@material-ui/core';
import { useContext, useMemo } from 'react';
import SettingsAccordion from './SettingsAccordion';
import { normalizeString } from '../../../../../../../utils/string';
import BuilderContext from '../../../../context';
import { Group as GroupProps } from '../../../Sidebar/settings/main/typings';
import {
  LargeInputsStyles,
  SmallInputsStyles,
  SmallTitlesStyles,
} from './styles';
import { getArrayFromInputString, getStringValuesFromArray } from './utils';

const SEOSettings = ({
  expanded,
  onExpand,
}: GroupProps) => {
  const { form } = useContext(BuilderContext);
  const seoTitleView = useMemo(() => (
    form.values.seoTitle === ''
      ? 'titulo-da-pagina'
      : form.values.seoTitle
  ), [form.values.seoTitle]);
  const seoDescriptionView = useMemo(() => (
    form.values.seoDescription === ''
      ? 'Aqui aparecerá a meta-descrição definida para esta página, conforme prévia do ferramenta de buscas do Google. Tente mantê-la com o máximo de 160 caracteres.'
      : form.values.seoDescription
  ), [form.values.seoDescription]);
  return (
    <SettingsAccordion
      expanded={expanded}
      title="Configurações de SEO"
      onExpand={onExpand}
    >
      <Stack flexDirection="row" width="100%">
        <Stack width="50%">
          <Typography sx={SmallTitlesStyles}>
            Título do SEO{' '}
            <Typography
              component="span"
              sx={{ color: 'error.main', fontSize: 14 }}
            >
              *
            </Typography>
          </Typography>
          <Input
            name="seoTitle"
            placeholder="Digite o título que aparecerá nas buscas"
            sx={SmallInputsStyles}
            value={form.values.seoTitle}
            onChange={form.handleChange}
          />
          <Typography sx={SmallTitlesStyles}>
            Palavras-chave{' '}
            <Typography
              component="span"
              sx={{ color: 'error.main', fontSize: 14 }}
            >
              *
            </Typography>
          </Typography>
          <Input
            name="seoKeywords"
            placeholder="Digite uma palavra-chave foco da sua página"
            value={getStringValuesFromArray(form.values.seoKeywords)}
            sx={SmallInputsStyles}
            onChange={(e) => form.setFieldValue(
              'seoKeywords',
              getArrayFromInputString(e.target.value),
            )}
          />
          <Typography sx={SmallTitlesStyles}>
            Descrição do SEO{' '}
            <Typography
              component="span"
              sx={{ color: 'error.main', fontSize: 14 }}
            >
              *
            </Typography>
          </Typography>
          <Input
            name="seoDescription"
            multiline
            placeholder="Aqui aparecerá a meta-descrição definida para esta página, conforme prévia do ferramenta de buscas do Google.&#10;Tente mantê-la com o máximo de 160 caracteres."
            rows={6}
            sx={{ ...LargeInputsStyles, height: 159 }}
            value={form.values.seoDescription}
            onChange={form.handleChange}
          />
        </Stack>
        <Stack ml={6} width="50%">
          <Typography sx={{ color: 'common.white' }}>
            Prévia do Google:
          </Typography>
          <Stack
            sx={{ backgroundColor: 'grey.500', borderRadius: 2, padding: 3 }}
          >
            <Typography
              sx={{
                color: 'secondary.main',
                fontSize: 14,
                textDecoration: 'underline',
              }}
            >
              {seoTitleView}
            </Typography>
            <Typography sx={{ color: 'success.main', fontSize: 12 }}>
              https//www.nomedosite.com/
              {normalizeString(seoTitleView.replaceAll(' ', '-'))}
            </Typography>
            <Typography
              sx={{ color: 'grey.200', fontSize: 14, fontWeight: 400 }}
            >
              {seoDescriptionView}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </SettingsAccordion>
  );
};

export default SEOSettings;
