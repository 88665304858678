import { Box } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Plan } from '../../../interfaces/Plan';
import { sortByElement } from '../../PreviewPlan/utils/sort';
import MembershipsItem from './MembershipsItem';

interface DialogListPlansProps {
  listPlansByMemberships: Plan[];
}

const DialogListPlans = ({ listPlansByMemberships }: DialogListPlansProps) => {
  const sortedOrderList = [...listPlansByMemberships]
    .sort(sortByElement('membershipsCount'))
    .reverse();
  const renderListPlansByMemberships = sortedOrderList.map((item, index) => (
    <MembershipsItem item={item} index={index} />
  ));
  return (
    <PerfectScrollbar style={{ paddingRight: 16 }}>
      <Box sx={{ height: 280 }}>{renderListPlansByMemberships}</Box>
    </PerfectScrollbar>
  );
};

export default DialogListPlans;
