// TODO: Remove when Craft.js support settings props without param reassign
/* eslint-disable no-param-reassign */
import { useNode } from '@craftjs/core';
import { Button, Card, Stack, Typography } from '@material-ui/core';
import { SlowMotionVideoOutlined as SlowMotionVideoOutlinedIcon } from '@material-ui/icons';
import { useState } from 'react';
import { useDialog } from '../../../../../../../components/Dialog';
import SidebarAccordion from '../../components/SidebarAccordion';
import { NodePropsType } from './typings';
import VideoModal from './VideoModal';

interface VideoProps extends Object {
  thumbnail?: string;
  videoId?: string;
}

const VideosSettings = () => {
  const [expanded, setExpanded] = useState(true);
  const {
    actions: { setProp },
    props: { thumbnail, videoId },
  }: NodePropsType<VideoProps> = useNode((node) => ({
    props: node.data.props,
  }));
  const changeVideoHandler = (newThumbnail: string, newVideoId: string) => {
    setProp((currentProps) => {
      currentProps.videoId = newVideoId;
    });
    setProp((currentProps) => {
      currentProps.thumbnail = newThumbnail;
    });
  };
  const dialog = useDialog();
  const openVideoModalHandler = async () => {
    await dialog.open({
      title: 'Adicionar vídeo on demand',
      element: <VideoModal onChange={changeVideoHandler} />,
      sx: { height: 677, width: 580 },
    });
  };
  return (
    <SidebarAccordion
      expanded={expanded}
      icon={
        <SlowMotionVideoOutlinedIcon
          sx={{
            color: 'secondary.main',
            height: 24,
            width: 24,
          }}
        />
      }
      title="Opções de conteúdo"
      onExpand={() => setExpanded(!expanded)}
    >
      {!videoId ? (
        <Stack alignItems="center">
          <SlowMotionVideoOutlinedIcon
            sx={{ color: 'secondary.main', fontSize: 60, fontWeight: 600 }}
          />
          <Typography color="common.white" fontSize={16}>
            Escolha um conteúdo{' '}
          </Typography>
          <Typography sx={{ color: 'grey.300', fontSize: 14, fontWeight: 400 }}>
            Via{' '}
            <Typography
              component="span"
              sx={{ fontSize: 14, textDecoration: 'underline' }}
            >
              biblioteca de conteúdos
            </Typography>
          </Typography>
          <Button
            component="label"
            sx={{ my: 4 }}
            onClick={() => openVideoModalHandler()}
          >
            Procurar conteúdo
          </Button>
        </Stack>
      ) : (
        <Stack>
          <Card
            sx={{
              backgroundColor: 'grey.400',
              backgroundImage: `url(${thumbnail})`,
              borderRadius: 1,
              color: 'secondary.main',
              height: 132,
              my: 1,
              textAlign: 'center',
              width: 222,
            }}
          />
          <Button
            sx={{ my: 3, width: 222 }}
            onClick={() => openVideoModalHandler()}
          >
            Alterar conteúdo
          </Button>
        </Stack>
      )}
    </SidebarAccordion>
  );
};

export default VideosSettings;
