import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Edit as EditIcon,
  MoreVert as OptionsIcon,
} from '@material-ui/icons';
import { useContext, useState } from 'react';
import { Manager } from '../../../interfaces/Manager';
import getAssetUri from '../../../utils/getAssetUri';
import getColorRole from '../../Roles/utils/getColorRole';
import formatDate from '../utils/date';
import Context from '../Context';

interface ManagerItemProps {
  manager: Manager;
}

const ManagerItem = ({ manager }: ManagerItemProps) => {
  const {
    editManagerHandler,
    deleteManagerHandler,
    changeEnabledManagerHandler,
    checkboxSelectedManager,
    permissionToUpdate,
    permissionToDelete,
  } = useContext(Context);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [managerEnabled, setManagerEnabled] = useState(manager.enabled);
  const closeMenuHandler = () => setAnchorEl(null);
  const openMenuHandler = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const deleteManager = () => {
    deleteManagerHandler([manager]);
    closeMenuHandler();
  };
  const changeEnabledHandler = (evt: any) => {
    const { checked } = evt.target;
    setManagerEnabled(checked);
    changeEnabledManagerHandler(checked, manager.id);
  };
  const isPlansMenuOpened = !!anchorEl;
  const hasPermissionEditOrDelete = permissionToUpdate || permissionToDelete;
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={isPlansMenuOpened}
        onClose={closeMenuHandler}
      >
        {permissionToUpdate && (
          <MenuItem onClick={() => editManagerHandler(manager)}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Editar Gestor
            </Typography>
          </MenuItem>
        )}
        {permissionToDelete && (
          <>
            <Divider />
            <MenuItem onClick={deleteManager}>
              <ListItemIcon>
                <CloseIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Excluir Gestor
              </Typography>
            </MenuItem>
          </>
        )}
      </Menu>
      <ListItem
        secondaryAction={
          hasPermissionEditOrDelete && (
            <Button
              variant="contained"
              endIcon={<OptionsIcon sx={{ color: 'grey.100' }} />}
              sx={{ bgcolor: 'grey.500' }}
              onClick={openMenuHandler}
            >
              Opções
            </Button>
          )
        }
        sx={{
          bgcolor: 'primary.main',
          borderRadius: 2,
          height: 'auto',
          mt: 4,
          p: 4,
        }}
      >
        {permissionToDelete && (
          <Checkbox
            color="secondary"
            value={manager}
            onClick={() => checkboxSelectedManager(manager)}
          />
        )}
        <Avatar
          src={getAssetUri(manager.thumbnail)}
          sx={{
            color: 'grey.300',
            height: 48,
            m: 2,
            mr: 6,
            width: 48,
          }}
        />
        <ListItemText
          sx={{ maxWidth: 250 }}
          primaryTypographyProps={{
            sx: {
              align: 'center',
              color: 'grey.100',
              fontSize: 16,
              fontWeight: 600,
              mb: 2,
            },
          }}
          secondary={manager.email}
          secondaryTypographyProps={{
            sx: { color: 'grey.400', fontSize: 12 },
          }}
        >
          {manager.name}
        </ListItemText>
        <Divider
          sx={{
            borderStyle: 'solid',
            borderWidth: 1,
            bgcolor: 'grey.500',
            height: 80,
            marginRight: 6,
          }}
        />
        <ListItemText
          sx={{ maxWidth: 150 }}
          primaryTypographyProps={{
            sx: { align: 'center', color: 'grey.300', fontSize: 10, mb: 3 },
          }}
          secondary={formatDate(manager.createdAt, 'dd/MM/yyyy')}
          secondaryTypographyProps={{
            sx: { color: 'grey.100', fontSize: 14 },
          }}
        >
          GESTOR DESDE:
        </ListItemText>
        <ListItemText
          sx={{ margin: 0, maxWidth: 300 }}
          primary="CARGO:"
          primaryTypographyProps={{
            sx: { color: 'grey.300', fontSize: 10, mb: 2 },
          }}
          secondary={
            <Tooltip title={manager.role.title}>
              <Chip
                variant="outlined"
                label={manager.role.title}
                size="small"
                sx={{
                  bgcolor: getColorRole(manager.role.color),
                  borderRadius: 2,
                  color:
                    getColorRole(manager.role.color) === '#DAE0FF'
                      ? 'grey.500'
                      : 'grey.200',
                  fontSize: 12,
                  fontWeight: '600',
                  maxWidth: 150,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              />
            </Tooltip>
          }
        />
        <Divider
          sx={{
            borderStyle: 'solid',
            borderWidth: 1,
            bgcolor: 'grey.500',
            height: 80,
          }}
        />
        <ListItemText
          primary="STATUS:"
          primaryTypographyProps={{
            sx: { color: 'grey.300', fontSize: 10, mb: 2 },
          }}
          secondary="Ativo"
          secondaryTypographyProps={{
            sx: { color: 'common.white', fontSize: 14 },
          }}
          sx={{ ml: 6, maxWidth: 50 }}
        />
        <Switch
          name="Ativo"
          color="info"
          disabled={!permissionToUpdate}
          checked={managerEnabled}
          onChange={changeEnabledHandler}
          sx={{ mt: 5 }}
        />
      </ListItem>
    </>
  );
};

export default ManagerItem;
