import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { DateTimePicker } from '@material-ui/lab';
import { SxProps } from '@material-ui/system';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import DownloadButton from '../../../components/DownloadButton';
import { Partner } from '../../../interfaces/Partner';
import { Plan, PlanType } from '../../../interfaces/Plan';
import {
  convertJsonArrayToCsv,
  createUriAndNameToFile,
  CreateUriAndNameToFileReturn,
} from '../../../utils/download/convertFiles';
import partnersQuery from '../../CreatePlan/gql/partnersQuery';
import { Context } from '../Context';
import { SpectatorsFilters } from '../typings';
import { mountObjectSpectatorsForDownload } from '../utils/mountObjectSpectatorsForDownload';
import StackWithLabel from './StackWithLabel';

export interface FiltersBarProps {
  sx?: SxProps<Theme>;
}

const FiltersBar = ({ sx }: FiltersBarProps) => {
  const {
    plans,
    setInput,
    listSpectators,
    searchByRecords,
    setSearchByRecords,
  } = useContext(Context);
  const [spectatorsToDownload, setSpectatorsToDownload] = useState({
    name: '',
    uri: '',
  });
  const [disabledInputs, setDisabledInputs] = useState({
    gatewayPayment: false,
    partner: false,
  });
  const { loading, data } = useQuery(partnersQuery);
  const formik = useFormik<SpectatorsFilters>({
    initialValues: {
      planId: '',
      createdAtGte: null,
      createdAtLte: null,
      gatewayPayment: '',
      partner: '',
      status: '',
      term: '',
    },
    onSubmit: async (filters) =>
      setInput((prevState) => ({
        ...prevState,
        filters,
        paginate: { page: 1 },
      })),
  });
  const resetFilter = () => {
    formik.resetForm();
    setInput((prevState) => ({
      ...prevState,
      filters: formik.initialValues,
      paginate: { page: 1 },
    }));
  };
  useEffect(() => {
    resetFilter();
  }, []);
  useEffect(() => {
    const thereAreAlreadySpectators =
      listSpectators && listSpectators.length > 0;
    const newSpectators = thereAreAlreadySpectators
      ? mountObjectSpectatorsForDownload(listSpectators)
      : [];
    const csv =
      newSpectators.length > 0 ? convertJsonArrayToCsv(newSpectators) : null;
    if (csv) {
      const createUriAndNameToFileProps = {
        file: csv,
        fileName: 'Lista de espectadores',
        fileType: 'csv',
      };
      const createUriAndNameToFileReturn: CreateUriAndNameToFileReturn =
        createUriAndNameToFile(createUriAndNameToFileProps);
      setSpectatorsToDownload(createUriAndNameToFileReturn);
    }
  }, [listSpectators]);
  const changePlanHandler = (value: string) => {
    const findPlan = plans.find((plan) => plan.id === value);
    const planTypeIsPartner = findPlan?.type === PlanType.partner;
    const planTypeIsFree = findPlan?.type === PlanType.free;
    if (findPlan) {
      if (planTypeIsPartner) {
        setDisabledInputs((prevState) => ({
          ...prevState,
          gatewayPayment: true,
          partner: false,
        }));
        formik.setFieldValue('gatewayPayment', '');
        return;
      }
      if (planTypeIsFree) {
        setDisabledInputs((prevState) => ({
          ...prevState,
          gatewayPayment: true,
          partner: true,
        }));
        formik.setFieldValue('gatewayPayment', '');
        formik.setFieldValue('partner', '');
        return;
      }
      setDisabledInputs((prevState) => ({
        ...prevState,
        gatewayPayment: false,
        partner: true,
      }));
      formik.setFieldValue('partner', '');
      return;
    }
    setDisabledInputs((prevState) => ({
      ...prevState,
      gatewayPayment: false,
      partner: false,
    }));
    formik.setFieldValue('partner', '');
    formik.setFieldValue('gatewayPayment', '');
  };
  if (loading) return <div>Carregando...</div>;
  return (
    <Card sx={{ ...sx }}>
      <CardContent>
        <form>
          <OutlinedInput
            name="term"
            fullWidth
            value={formik.values.term}
            placeholder="Pesquise por espectadores usando Nome, Email ou CPF"
            autoComplete="off"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'secondary.main' }} />
              </InputAdornment>
            }
            onChange={formik.handleChange}
          />
          <Stack
            direction="row"
            divider={
              <Divider
                orientation="vertical"
                sx={{ background: 'grey.500' }}
                flexItem
              />
            }
            spacing={4}
            sx={{ mt: 5 }}
          >
            <StackWithLabel label="Planos">
              <Select
                name="planId"
                displayEmpty
                value={formik.values.planId}
                sx={{ minWidth: 150 }}
                disabled={searchByRecords}
                onChange={(evt) => {
                  const { value } = evt.target;
                  if (value === '') {
                    formik.setFieldValue('partner', '');
                  }
                  formik.handleChange(evt);
                  changePlanHandler(value);
                }}
              >
                <MenuItem value="">Todos</MenuItem>
                {plans.map((plan: Plan) => (
                  <MenuItem key={plan.id} value={plan.id}>
                    {plan.title}
                  </MenuItem>
                ))}
              </Select>
            </StackWithLabel>
            <Stack direction="row" spacing={4}>
              <StackWithLabel label="Desde">
                <DateTimePicker
                  mask="__/__/__"
                  inputFormat="dd/MM/yy HH:mm"
                  value={formik.values.createdAtGte}
                  InputAdornmentProps={{
                    position: 'start',
                    sx: { maxWidth: 20, svg: { color: 'secondary.main' } },
                  }}
                  renderInput={(params) => (
                    <TextField sx={{ maxWidth: 160 }} {...params} />
                  )}
                  onChange={(value) =>
                    formik.handleChange({
                      target: { name: 'createdAtGte', value },
                    })
                  }
                />
              </StackWithLabel>
              <StackWithLabel label="Até">
                <DateTimePicker
                  mask="__/__/__"
                  inputFormat="dd/MM/yy HH:mm"
                  minDate={formik.values.createdAtGte}
                  minDateTime={formik.values.createdAtGte}
                  value={formik.values.createdAtLte}
                  InputAdornmentProps={{
                    position: 'start',
                    sx: { maxWidth: 20, svg: { color: 'secondary.main' } },
                  }}
                  renderInput={(params) => (
                    <TextField sx={{ maxWidth: 160 }} {...params} />
                  )}
                  onChange={(value) =>
                    formik.handleChange({
                      target: { name: 'createdAtLte', value },
                    })
                  }
                />
              </StackWithLabel>
            </Stack>
            <StackWithLabel label="Pagamento">
              <Select
                name="gatewayPayment"
                displayEmpty
                value={formik.values.gatewayPayment}
                disabled={disabledInputs.gatewayPayment}
                sx={{ minWidth: 150 }}
                onChange={formik.handleChange}
              >
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value="guiteway">Grátis</MenuItem>
                <MenuItem value="mobiaccess">MobiAccess</MenuItem>
                <MenuItem value="pagarme">Pagarme</MenuItem>
                <MenuItem value="stripe">Stripe</MenuItem>
              </Select>
            </StackWithLabel>
            <StackWithLabel label="Status">
              <Select
                name="status"
                displayEmpty
                value={formik.values.status}
                sx={{ minWidth: 150 }}
                onChange={formik.handleChange}
              >
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value="active">Ativo</MenuItem>
                <MenuItem value="suspended">Suspenso</MenuItem>
                <MenuItem value="cancelled">Cancelado</MenuItem>
                <MenuItem value="preCancelled">Pré-cancelado</MenuItem>
              </Select>
            </StackWithLabel>
            <StackWithLabel label="Parceiros">
              <Select
                name="partner"
                displayEmpty
                value={formik.values.partner}
                disabled={disabledInputs.partner}
                sx={{ minWidth: 150 }}
                onChange={formik.handleChange}
              >
                <MenuItem value="">Todos</MenuItem>
                {data.partners.map((partner: Partner) => (
                  <MenuItem value={partner.code}>
                    {partner.description}
                  </MenuItem>
                ))}
              </Select>
            </StackWithLabel>
            <StackWithLabel label="Registros">
              <Checkbox
                name="records"
                checked={searchByRecords}
                onClick={() => {
                  if (!searchByRecords) {
                    formik.setFieldValue('planId', '');
                    formik.setFieldValue('partner', '');
                    formik.setFieldValue('gatewayPayment', '');
                  }
                  setSearchByRecords(!searchByRecords);
                }}
              />
            </StackWithLabel>
          </Stack>
          <Stack direction="row" spacing={3} sx={{ mt: 5 }}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: 'main',
                borderColor: 'common.white',
                color: 'common.white',
              }}
              onClick={(evt) => {
                evt.preventDefault();
                formik.handleSubmit();
              }}
            >
              Pesquisar
            </Button>
            <Button variant="text" onClick={resetFilter}>
              <Typography
                sx={{ fontSize: 14, color: 'secondary.main', fontWeight: 600 }}
              >
                Limpar Filtros
              </Typography>
            </Button>
            <DownloadButton
              uri={spectatorsToDownload.uri}
              fileName={spectatorsToDownload.name}
              buttonTitle="Baixar relatório"
              sx={{ bgcolor: 'success.main' }}
            />
          </Stack>
        </form>
      </CardContent>
    </Card>
  );
};

export default FiltersBar;
