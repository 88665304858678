import {
  Button,
  Checkbox,
  Chip,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@material-ui/core';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Folder as FolderIcon,
  MoreVert as OptionsIcon,
} from '@material-ui/icons';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { getPageTypeFriendlyName, Page } from '../../../../interfaces/Plan';
import { PageStatus } from '../typings';
import PagesContext from '../context';

interface PageItemProps {
  page: Page;
  selected: boolean;
  onSelect: (page: Page) => void;
}

const PlansListItem = ({ page, selected, onSelect }: PageItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    deletePageHandler,
    permissionToDelete,
    permissionToRead,
    permissionToUpdate,
  } = useContext(PagesContext);
  const isPlansMenuOpened = !!anchorEl;
  const closeMenuHandler = () => setAnchorEl(null);
  const openMenuHandler = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const selectPageHandler = () => onSelect(page);
  const verifyPageStatus = (currentPage: Page): PageStatus => {
    if (currentPage.published) {
      return {
        color: 'success',
        state: 'Publicado',
      };
    }
    if (currentPage.scheduleDateEnabled) {
      return {
        color: 'secondary',
        state: 'Agendado',
      };
    }
    return {
      color: 'warning',
      state: 'Rascunho',
    };
  };
  const deletePage = () => {
    deletePageHandler([page]);
    closeMenuHandler();
  };
  const hasNoPermission = permissionToDelete || permissionToUpdate;
  const pagePublishedAndPermissionToRead = page.published && permissionToRead;
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={isPlansMenuOpened}
        onClose={closeMenuHandler}
      >
        {permissionToUpdate && (
          <MenuItem data-testid="option-edit-plan">
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <Link to={`/pages/${page.id}/builder`}>
              <Typography variant="inherit" noWrap>
                Editar página
              </Typography>
            </Link>
          </MenuItem>
        )}
        {permissionToDelete && (
          <>
            <Divider />
            <MenuItem data-testid="option-delete-plan" onClick={deletePage}>
              <ListItemIcon>
                <CloseIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Excluir página
              </Typography>
            </MenuItem>
          </>
        )}
      </Menu>
      <ListItem
        data-testid="plan-item"
        secondaryAction={
          hasNoPermission && (
            <Button
              data-testid="menu-plan"
              variant="outlined"
              endIcon={<OptionsIcon />}
              onClick={openMenuHandler}
            >
              Opções
            </Button>
          )
        }
        sx={{
          bgcolor: 'primary.main',
          border: 1,
          borderColor: selected ? 'secondary.main' : 'none',
          borderRadius: 2,
          height: 'auto',
          mt: 4,
          p: 4,
        }}
      >
        {permissionToDelete && (
          <Checkbox
            data-testid="checkbox-plan-item"
            color="secondary"
            checked={selected}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            onChange={selectPageHandler}
          />
        )}
        <ListItemText
          primary={
            pagePublishedAndPermissionToRead ? (
              <Link to={`/pages/${page.id}/builder`} style={{ color: 'white' }}>
                {page.title}
              </Link>
            ) : (
              page.title
            )
          }
          primaryTypographyProps={{
            sx: {
              color: 'common.white',
              fontSize: 16,
              fontWeight: 600,
              mb: 3,
            },
          }}
          secondary={
            <Stack
              alignItems="center"
              flexDirection="row"
              sx={{
                color: 'common.white',
                fontSize: 12,
                fontWeight: 600,
                maxWidth: 400,
                mx: 2,
              }}
            >
              <Typography variant="inherit" noWrap>
                {getPageTypeFriendlyName(page.type)}
              </Typography>
              <Chip
                color={verifyPageStatus(page).color}
                label={verifyPageStatus(page).state}
                variant="outlined"
                size="small"
                icon={page.published ? <CheckIcon /> : <FolderIcon />}
                sx={{
                  borderRadius: 1,
                  mx: 3,
                  pl: 2,
                }}
              />
            </Stack>
          }
          sx={{ m: 0, ml: 4 }}
        />
      </ListItem>
    </>
  );
};

export default PlansListItem;
