import axiosHelper from '../../../helpers/axios';

const uploadFile = <T>(file: File) => {
  const formData = new FormData();
  formData.append(
    'operations',
    '{ "query": "mutation ($file: Upload!) { fileUpload(file: $file) }", "variables": { "file": null } }',
  );
  formData.append('map', '{ "0": ["variables.file"] }');
  formData.append('0', file);
  const jwt = localStorage.getItem('accessToken') || '';
  return axiosHelper.post<T>('', formData, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default uploadFile;
