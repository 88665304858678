import { Box, Stack, Typography } from '@material-ui/core';
import { WbSunny as TipIcon } from '@material-ui/icons';
import { TipsAlertProps } from './typings';

const TipAlert = ({ children, sx }: TipsAlertProps) => (
  <Box
    sx={{
      position: 'relative',
      '::before': {
        background:
          'linear-gradient(to left, #2F49D1, #8C66F5, #CD5DA2, #FF4B7C, #F2A35F)',
        borderRadius: 2,
        bottom: 0,
        content: '" "',
        left: 0,
        maskComposite: 'exclude',
        p: '1px',
        position: 'absolute',
        right: 0,
        top: 0,
        WebkitMask:
          'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'destination-out',
      },
      ...sx,
    }}
  >
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        background:
          'linear-gradient(269.64deg, rgba(47, 73, 209, 0.1) -34.5%, rgba(140, 102, 245, 0.1) 11.32%, rgba(205, 93, 162, 0.1) 183.32%)',
        height: 56,
        p: 4,
      }}
    >
      <Stack alignItems="center" direction="row" spacing={4}>
        <TipIcon sx={{ color: 'common.white' }} />
        <Typography sx={{ color: 'grey.200', fontSize: 14 }}>
          <b>Dica:</b> {children}
        </Typography>
      </Stack>
    </Stack>
  </Box>
);

export default TipAlert;
