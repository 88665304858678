import { Button, Chip, Grid, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Discount } from '../../../interfaces/Discount';

interface DiscountItemProps {
  discount: Discount;
}

const DiscountItem = ({ discount }: DiscountItemProps) => {
  const navigate = useNavigate();
  const getSymbolByValueType =
    discount.valueType === 'value'
      ? `R$ ${discount.price}`
      : `${discount.price}%`;
  return (
    <>
      <Grid
        container
        sx={{
          alignItems: 'center',
          bgcolor: 'primary.main',
          borderRadius: 2,
          display: 'flex',
          height: 78,
          mb: 2,
          px: 4,
        }}
      >
        <Grid item sx={{ width: 200 }}>
          <Typography
            sx={{
              color: 'grey.100',
              fontSize: 14,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: 180,
            }}
          >
            {discount.title}
          </Typography>
        </Grid>
        <Grid item xs sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs sx={{ textAlign: 'center' }}>
            <Typography
              sx={{
                color: 'grey.100',
                fontSize: 14,
              }}
            >
              {discount.activies}
            </Typography>
          </Grid>
          <Grid item xs sx={{ textAlign: 'center' }}>
            <Typography
              sx={{
                color: 'grey.100',
                fontSize: 14,
              }}
            >
              {getSymbolByValueType}
            </Typography>
          </Grid>
          <Grid item xs sx={{ textAlign: 'center' }}>
            <Chip
              variant="outlined"
              color={discount.enabled ? 'success' : 'error'}
              size="small"
              label={discount.enabled ? 'Ativo' : 'Inativo'}
              sx={{ borderRadius: 1 }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            sx={{
              bgcolor: 'grey.500',
              color: 'grey.200',
              height: 40,
              width: 94,
            }}
            onClick={() => navigate(`/discount/report/${discount.id}`)}
          >
            Visualizar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DiscountItem;
