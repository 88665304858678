import { Box, Stack, StackProps, Typography } from '@material-ui/core';
import { SxProps, Theme } from '@material-ui/system';
import { Plan } from '../../../interfaces/Plan';
import { getFriendlyNameByPlanType } from '../../CreatePlan/Components/PaymentBonds';

interface MembershipsItemProps {
  index: number;
  item: Plan;
  stackProps?: StackProps;
  sx?: SxProps<Theme>;
}

const MembershipsItem = ({
  index,
  item,
  stackProps,
  sx,
}: MembershipsItemProps) => (
  <Stack
    direction="row"
    spacing={8}
    sx={{ height: 40, pl: 0, ...sx }}
    {...stackProps}
  >
    <Box sx={{ bgcolor: 'grey.500', height: 24, width: 24 }}>
      <Typography sx={{ color: 'grey.100', textAlign: 'center' }}>
        {index + 1}
      </Typography>
    </Box>
    <Typography
      sx={{
        color: 'grey.100',
        fontSize: 12,
        whiteSpace: 'nowrap',
        width: 177,
      }}
    >
      {item.title}
    </Typography>
    <Typography
      sx={{
        color: 'grey.100',
        fontSize: 12,
        whiteSpace: 'nowrap',
        width: 110,
      }}
    >
      {getFriendlyNameByPlanType(item.type)}
    </Typography>
    <Typography sx={{ color: 'grey.100', fontSize: 12, whiteSpace: 'nowrap', textAlign: 'right' }}>
      {item.membershipsCount} adesões
    </Typography>
  </Stack>
);

export default MembershipsItem;
