import { Button } from '@material-ui/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { SortBy } from '../../typings/sort';
import Topbar from '../../components/Topbar';
import Filters from './components/Filters';
import Container from './components/Container';
import Context from './Context';
import { Input } from './typings';

const HeartTeamList = () => {
  const [input, setInput] = useState<Input>({
    filters: {
      name: '',
      sort: SortBy.Newest,
    },
    paginate: { page: 1 },
  });
  return (
    <Context.Provider value={{ input, setInput }}>
      <Topbar
        title="Time do coração"
        description="Personalize a experiência de seus espectadores."
      >
        <Link to="/team/create">
          <Button color="primary">Criar novo time</Button>
        </Link>
      </Topbar>
      <Filters />
      <Container />
    </Context.Provider>
  );
};

export default HeartTeamList;
