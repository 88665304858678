import { Button, Stack } from '@material-ui/core';


interface PageHeaderProps {
  bgColor: string;
  buttonColor: string;
  hasButton: boolean;
  buttonLink: string;
  buttonText: string;
  textColor: string;
}

const PageHeader = ({
  bgColor,
  buttonColor,
  hasButton,
  buttonLink,
  buttonText,
  textColor,
}: PageHeaderProps) => (
  <>
    <Stack
      alignItems="center"
      flexDirection="row"
      justifyContent={hasButton ? 'space-between' : 'center'}
      sx={{ bgcolor: bgColor, borderRadius: 2, height: 70, mb: 4, px: 8 }}
    >
      <img
        alt="logo"
        src="https://uilogos.co/img/logomark/leaf.png"
        style={{ maxHeight: 38 }}
      />
      {hasButton ? (
        <Button
          title={buttonLink}
          sx={{
            bgcolor: buttonColor,
            color: textColor,
            '&:hover': { bgcolor: buttonColor },
          }}
        >
          {buttonText}
        </Button>
      ) : (
        <></>
      )}
    </Stack>
  </>
);

export default PageHeader;
