import { Stack } from '@material-ui/core';
import { BarChart as StatisticsIcon } from '@material-ui/icons';
import { FinancialDiscountReport } from '../../../interfaces/FinancialReport';
import Card from './Card';
import { ValueGroup } from './helpers';

interface DiscountAndSpectatorsProps {
  financialDiscountReport: FinancialDiscountReport;
}

const DiscountAndSpectators = ({
  financialDiscountReport,
}: DiscountAndSpectatorsProps) => (
  <Card
    icon={StatisticsIcon}
    title="Descontos e espectadores"
    sx={{ height: 202 }}
  >
    <Stack gap={4} sx={{ mt: 4 }}>
      <ValueGroup
        title="Espectadores com desconto ativo"
        value={financialDiscountReport.totalActiveUsers}
      />
      <ValueGroup
        title="Total de espectadores que aderiram desconto (ativos e inativos)"
        value={financialDiscountReport.totalSignedUsers}
      />
    </Stack>
  </Card>
);

export default DiscountAndSpectators;
