import { TableCell, TableSortLabel } from '@material-ui/core';
import { useState } from 'react';
import { SortByDirection } from '../../../typings/sort';

const Head = ({ title, prop, onSortHead }: any) => {
  const [active, setActive] = useState(false);
  const toggleHeaderHandler = () => {
    setActive((prevState) => !prevState);
    onSortHead?.({
      direction: active ? SortByDirection.Asc : SortByDirection.Desc,
      prop,
    });
  };
  return (
    <TableCell
      data-testid="item-head"
      sx={{
        color: 'grey.100',
        ml: '17px',
        p: 0,
      }}
      onClick={toggleHeaderHandler}
    >
      <TableSortLabel
        active
        direction={active ? 'asc' : 'desc'}
        sx={{
          path: { color: 'grey.400' },
          '&.MuiButtonBase-root': { color: 'grey.100' },
        }}
      >
        {title}
      </TableSortLabel>
    </TableCell>
  );
};

export default Head;
