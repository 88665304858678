import {
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Switch,
  Typography,
} from '@material-ui/core';
import { useContext } from 'react';
import TileCard5 from '../../../components/TileCard5';
import Context from '../Context';

const ScreensAndTrialPeriod = () => {
  const { form, isEditing } = useContext(Context);
  return (
    <Stack>
      <TileCard5
        title="Período de teste"
        tip=""
        titleProps={{ sx: { color: 'grey.100', fontSize: 16, mb: 4 } }}
        buttonVisible={false}
        sx={{ height: 195, mb: 4, position: 'relative', width: 679 }}
      >
        <Typography
          sx={{
            color: 'grey.400',
            fontSize: 14,
            position: 'absolute',
            right: 77,
            top: 25,
          }}
        >
          Ativo
        </Typography>
        <Switch
          name="trialDaysIsActive"
          checked={form.values.trialDaysIsActive}
          disabled={isEditing}
          sx={{ position: 'absolute', right: 24, top: 24 }}
          onClick={form.handleChange}
        />
        <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
          {form.values.trialDaysIsActive
            ? 'Defina quantos dias de teste estarão disponíveis para novos assinantes.'
            : 'Defina se estará disponível um período de testes para novos assinantes.'}
        </Typography>
        <InputLabel sx={{ color: 'grey.400', fontSize: 14, mb: 2, mt: 3 }}>
          Período de teste
        </InputLabel>
        <OutlinedInput
          name="trialDays"
          value={form.values.trialDays}
          disabled={!form.values.trialDaysIsActive || isEditing}
          error={!!form.errors.trialDays}
          type="number"
          sx={{ height: 36, width: 92 }}
          onChange={form.handleChange}
        />
        <FormHelperText error>{form.errors.trialDays}</FormHelperText>
      </TileCard5>
    </Stack>
  );
};

export default ScreensAndTrialPeriod;
