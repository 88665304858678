import { gql } from '@apollo/client';

const listQuestionsQuery = gql`
  query ListQuestionsQuery {
    questions {
      id
      title
      required
      tags{
        id
        name
      }
    }
  }
`;

export default listQuestionsQuery;
