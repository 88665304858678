import {
  Box,
  Card,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@material-ui/core';

const HasNoPermission = () => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      height: '90vh',
      justifyContent: 'center',
      ml: -10,
    }}
  >
    <Card sx={{ width: 342 }}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ height: 72 }}
      >
        <DialogTitle
          sx={{
            color: 'common.white',
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          Sem permissão
        </DialogTitle>
      </Stack>
      <DialogContent sx={{ pt: 0 }}>
        <DialogContentText sx={{ color: 'grey.200', fontSize: 14 }}>
          Você não tem permissão para acessar esta área.
        </DialogContentText>
      </DialogContent>
    </Card>
  </Box>
);

export default HasNoPermission;
