import { Stack } from '@material-ui/core';
import { ReactNode, useContext } from 'react';
import Context from '../context';

interface SingleContainerProps {
  bgColor: string;
  children: ReactNode;
}

const SingleContainer = ({
  bgColor,
  children,
}: SingleContainerProps) => {
  const { smartphoneScreen } = useContext(Context);
  return (
    <Stack
      height="auto"
      minHeight={400}
      position="relative"
      sx={{
        bgcolor: bgColor,
        gap: smartphoneScreen ? 2 : 5,
        p: smartphoneScreen ? 5 : 10,
        mx: smartphoneScreen ? -5 : -10,
      }}
    >
      {children}
    </Stack>
  );
};

export default SingleContainer;
