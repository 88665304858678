import { Container, Grid } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { PermissionsProvider } from '@tshio/react-router-permissions';
import { Outlet, Route, Routes } from 'react-router-dom';
import Categories from '../pages/Categories';
import ContentPreview from '../pages/ContentPreview';
import CreateDiscount from '../pages/CreateDiscount';
import CreateExternalContent from '../pages/CreateExternalContent';
import CreateHeartTeam from '../pages/CreateHeartTeam';
import CreateHighlights from '../pages/CreateHighlights';
import CreateHighlightSections from '../pages/CreateHighlightSections';
import CreatePlan from '../pages/CreatePlan';
import DiscountList from '../pages/Discount';
import DiscountReport from '../pages/DiscountReport';
import FinancialDiscountReport from '../pages/FinancialDiscountReport';
import FinancialReport from '../pages/FinancialReport';
import FinancialPlanReport from '../pages/FinancialPlanReport';
import EditContent from '../pages/EditContent';
import EditHeartTeam from '../pages/EditHeartTeam';
import EditHighlights from '../pages/EditHighlights';
import EditHighlightSection from '../pages/EditHighlightSections';
import EditPlan from '../pages/EditPlan';
import EditSection from '../pages/EditSection';
import HeartTeamList from '../pages/HeartTeamList';
import HighlightSections from '../pages/HighlightSections';
import { HTMLPagesBuilder, HTMLPagesList } from '../pages/HTMLPages';
import UpdatePage from '../pages/HTMLPages/Builder/UpdatePage';
import ImportSpallaContent from '../pages/ImportSpallaContent';
import Highlights from '../pages/Highlights';
import Library from '../pages/Library';
import LiveSection from '../pages/LiveSection';
import Onboarding from '../pages/Onboarding';
import PlansOverview from '../pages/Overview';
import Plans from '../pages/Plans';
import PreviewDiscountReport from '../pages/PreviewDiscountReport';
import PreviewPlan from '../pages/PreviewPlan';
import ReportsDownload from '../pages/ReportsDownload';
import Roles from '../pages/Roles';
import SectionList from '../pages/SectionList';
import ManagersList from '../pages/ManagersList';
import Context from '../Context';
import meQuery from '../gql/meQuery';
import Spectators from '../pages/Spectators';
import hasAuthorization from '../utils/hasAuthorization';
import SideMenu from './SideMenu';

const AppTemplate = () => (
  <Grid container>
    <Grid item sx={{ width: 70 }}>
      <SideMenu />
    </Grid>
    <Grid id="container" item xs sx={{ height: '100vh' }}>
      <Container maxWidth="lg" sx={{ p: 5 }}>
        <Outlet />
      </Container>
    </Grid>
  </Grid>
);

const AppRoutes = () => (
  <Routes>
    <Route element={<AppTemplate />}>
      <Route path="/categories" element={<Categories />} />
      <Route path="/content/:contentId" element={<ContentPreview />} />
      <Route path="/content/:contentId/edit" element={<EditContent />} />
      <Route path="/content/create" element={<CreateExternalContent />} />
      <Route path="/discounts" element={<DiscountList />} />
      <Route path="/discount/create" element={<CreateDiscount />} />
      <Route path="/discount/report" element={<DiscountReport />} />
      <Route path="/discount/report/:id" element={<PreviewDiscountReport />} />
      <Route path="/download/report/" element={<ReportsDownload />} />
      <Route
        path="/financial/report/discount"
        element={<FinancialDiscountReport />}
      />
      <Route path="/financial/report/plan" element={<FinancialPlanReport />} />
      <Route path="/financial/report" element={<FinancialReport />} />
      <Route path="/highlights/create" element={<CreateHighlights />} />
      <Route path="/highlight/edit/:id" element={<EditHighlights />} />
      <Route path="/highlights" element={<Highlights />} />
      <Route path="/team/create" element={<CreateHeartTeam />} />
      <Route path="/team/edit/:teamId" element={<EditHeartTeam />} />
      <Route path="/teams" element={<HeartTeamList />} />
      <Route path="/import/:type" element={<ImportSpallaContent />} />
      <Route path="/library" element={<Library />} />
      <Route path="/managers" element={<ManagersList />} />
      <Route path="/pages" element={<HTMLPagesList />} />
      <Route path="/pages/:pageId/builder" element={<UpdatePage />} />
      <Route path="/pages/builder" element={<HTMLPagesBuilder />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/plan/create" element={<CreatePlan />} />
      <Route path="/plan/:planId" element={<PreviewPlan />} />
      <Route path="/plan/:planId/edit" element={<EditPlan />} />
      <Route path="/section/:sectionId/edit" element={<EditSection />} />
      <Route path="/section/highlights" element={<HighlightSections />} />
      <Route
        path="/section/highlights/create"
        element={<CreateHighlightSections />}
      />
      <Route
        path="/section/highlights/:highlightId"
        element={<EditHighlightSection />}
      />
      <Route path="/section/lives" element={<LiveSection />} />
      <Route path="/sections/order" element={<SectionList />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/plans/overview" element={<PlansOverview />} />
      <Route path="/plans/onboarding" element={<Onboarding />} />
      <Route path="/roles" element={<Roles />} />
      <Route path="/spectators" element={<Spectators />} />
    </Route>
  </Routes>
);

const App = () => {
  const { loading, data } = useQuery(meQuery);
  if (loading) return <div>Carregando...</div>;
  return (
    <Context.Provider value={{ me: data.me }}>
      <PermissionsProvider
        permissions={data.me.role.permissions}
        authorizationStrategy={hasAuthorization}
      >
        <AppRoutes />
      </PermissionsProvider>
    </Context.Provider>
  );
};

export default App;
