import { useMutation, useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import { useNavigate, useParams } from 'react-router-dom';
import { useDialog } from '../../components/Dialog';
import Navbar from '../../components/UI/molecules/Navbar';
import FormFooter from '../../components/UI/templates/FormFooter';
import { HeartTeam } from '../../interfaces/HeartTeam';
import AvatarList from '../CreateHeartTeam/components/AvatarList';
import Details from '../CreateHeartTeam/components/Details';
import Context from '../CreateHeartTeam/Context';
import deleteTeamMutation from '../HeartTeamList/gql/deleteTeamMutation';
import teamQuery from './gql/teamQuery';
import updateTeamMutation from './gql/updateTeamMutation';
import validationSchema from './providers/validationSchema';

const EditHeartTeam = () => {
  const alert = useAlert();
  const dialog = useDialog();
  const navigate = useNavigate();
  const { teamId } = useParams();
  const [deleteTeam] = useMutation(deleteTeamMutation);
  const [updateTeam] = useMutation(updateTeamMutation);
  const { loading, data } = useQuery(teamQuery, { variables: { id: teamId } });
  const form = useFormik<HeartTeam>({
    enableReinitialize: true,
    initialValues: {
      id: data?.team.id,
      name: data?.team.name,
      slug: data?.team.slug,
      externalUrl: data?.team.externalUrl,
      avatars: data?.team.avatars || [],
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const input = validationSchema.cast(values);
        await updateTeam({ variables: { input } });
        alert.success('Time editado com sucesso.');
      } catch {
        alert.error('Ocorre um erro ao editar o time.');
      }
    },
  });
  if (loading) {
    return <div>Carregando...</div>;
  }
  const removeTeam = async () => {
    const confirm = await dialog.confirm({
      title: 'Você tem certeza?',
      message:
        'Excluir um time ocasionará a perda das seções e conteúdos associados.',
    });
    if (confirm) {
      try {
        await deleteTeam({ variables: { id: data.team.id } });
        alert.success('Time excluído com sucesso.');
        navigate('/teams');
      } catch {
        alert.error('Ocorreu um erro ao excluir o time.');
      }
    }
  };
  return (
    <Context.Provider value={{ form }}>
      <Navbar route="/teams" title={`Editar time: ${form.values.name}`} />
      <Grid container sx={{ mt: 4 }} gap={4}>
        <Grid item xs={5}>
          <Details />
        </Grid>
        <Grid item xs>
          <AvatarList />
        </Grid>
      </Grid>
      <FormFooter
        LeftButtonProps={{
          children: 'Salvar time',
          type: 'submit',
          loading: false,
          onClick: () => form.handleSubmit(),
        }}
        helperText=""
        helperTextProps={{ color: 'error.main' }}
        RightButtonProps={{
          children: 'Excluir time',
          onClick: () => removeTeam(),
        }}
        sx={{ mt: 6 }}
      />
    </Context.Provider>
  );
};

export default EditHeartTeam;
