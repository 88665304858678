import { Stack, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useState } from 'react';
import { SortByDirection } from '../../../typings/sort';
import { SortByType } from '../typings';

interface SpectatorsHeaderHeadProps {
  title: string;
  prop?: SortByType['prop'];
  onSortHead?: (sortByType: SortByType) => void;
}

const SpectatorsHeaderHead = ({
  title,
  prop,
  onSortHead,
}: SpectatorsHeaderHeadProps) => {
  const [active, setActive] = useState(false);
  const toggleHeaderHandler = () => {
    setActive((prevState) => !prevState);
    onSortHead?.({
      direction: active ? SortByDirection.Asc : SortByDirection.Desc,
      prop,
    });
  };
  return (
    <Stack direction="row" sx={{ alignItems: 'center' }}>
      <Typography
        sx={{
          color: 'white',
          fontSize: 14,
          fontWeight: 600,
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </Typography>
      <ExpandMore
        sx={{
          color: 'grey.400',
          cursor: 'pointer',
          fontSize: 14,
          transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.2s',
        }}
        onClick={toggleHeaderHandler}
      />
    </Stack>
  );
};

export default SpectatorsHeaderHead;
