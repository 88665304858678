import { Button, Stack, Typography } from '@material-ui/core';
import { useContext } from 'react';
import { PlanType } from '../../interfaces/Plan';
import Context from '../CreatePlan/Context';

const PlanTypeName = {
  [PlanType.monthly]: 'Mensal',
  [PlanType.partner]: 'Parceiros',
  [PlanType.season]: 'Temporada',
  [PlanType.free]: 'Gratuito',
};

export const getFriendlyNameByPlanType = (type: PlanType): string =>
  PlanTypeName[type];

const PaymentBonds = () => {
  const { form } = useContext(Context);
  return (
    <>
      <Typography sx={{ color: 'grey.100', fontWeight: '600', mt: 6 }}>
        Vínculo de pagamento:
      </Typography>
      <Stack direction="row" sx={{ gap: 4, mt: 6 }}>
        <Button
          sx={{
            cursor: 'unset',
            color: 'grey.100',
            bgcolor: 'primary.main',
            height: 59,
            '&:hover': {
              bgcolor: 'primary.main',
            },
          }}
        >
          {getFriendlyNameByPlanType(form.values.type)}
        </Button>
      </Stack>
    </>
  );
};

export default PaymentBonds;
