import { useMutation } from '@apollo/client';
import { Box, Button, Card, Stack, Typography } from '@material-ui/core';
import {
  DragIndicator as DragIndicatorIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';
import { useAlert } from 'react-alert';
import { Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../../components/Dialog';
import { HighlightSection } from '../../../interfaces/HighlightSection';
import deleteMultiSectionItemMutation from '../gql/deleteMultiSectionItemMutation';
import HighlightSectionPreview from './HighlightSectionPreview';

interface HighlightListItemProps {
  multiSectionsItem: HighlightSection;
  index: number;
  refetch: () => void;
}

const HighlightListItem = ({
  multiSectionsItem,
  index,
  refetch,
}: HighlightListItemProps) => {
  const alert = useAlert();
  const dialog = useDialog();
  const navigate = useNavigate();
  const [deleteMultiSectionItem] = useMutation(deleteMultiSectionItemMutation);
  const previewHighlight = () =>
    dialog.open({
      title: 'Prévia',
      element: (
        <HighlightSectionPreview thumbnail={multiSectionsItem.thumbnail} />
      ),
    });
  const deleteItem = async () => {
    await deleteMultiSectionItem({ variables: { id: multiSectionsItem.id } });
    await refetch();
    alert.success('Item deletado com sucesso');
  };
  return (
    <Draggable draggableId={multiSectionsItem.id} index={index}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Card sx={{ height: 96, mb: 4, p: 4 }}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              sx={{ height: '100%', width: '100%' }}
            >
              <Stack
                alignItems="center"
                direction="row"
                gap={4}
                sx={{ height: '100%', width: '100%' }}
              >
                <DragIndicatorIcon
                  sx={{ color: 'grey.400', fontSize: 30, cursor: 'grab' }}
                />
                <Box
                  sx={{
                    alignItems: 'center',
                    bgcolor: 'grey.500',
                    borderRadius: '4px',
                    color: 'grey.100',
                    display: 'flex',
                    fontSize: 24,
                    justifyContent: 'center',
                    height: 40,
                    width: 40,
                  }}
                >
                  {index + 1}
                </Box>
                <Typography sx={{ color: 'grey.100', fontWeight: 600 }}>
                  {multiSectionsItem.title}
                </Typography>
              </Stack>
              <Stack alignItems="center" direction="row" gap={2}>
                <Button sx={{ bgcolor: 'grey.500' }} onClick={deleteItem}>
                  <DeleteIcon sx={{ color: 'info.main' }} />
                </Button>
                <Button sx={{ bgcolor: 'grey.500' }} onClick={previewHighlight}>
                  <VisibilityIcon sx={{ color: 'info.main' }} />
                </Button>
                <Button sx={{ bgcolor: 'grey.500' }}>
                  <EditIcon
                    sx={{ color: 'info.main' }}
                    onClick={() =>
                      navigate(`/section/highlights/${multiSectionsItem.id}`)
                    }
                  />
                </Button>
              </Stack>
            </Stack>
          </Card>
        </Box>
      )}
    </Draggable>
  );
};

export default HighlightListItem;
