import { gql } from '@apollo/client';

const getMultiSectionQuery = gql`
  query GetMultiSectionQuery {
    multiSection {
      id
      title
      items {
        id
      }
    }
  }
`;

export default getMultiSectionQuery;
