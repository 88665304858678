import { Button, Card, Stack, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Plan } from '../../../interfaces/Plan';

interface SpectatorSubscriptionCardProps {
  plan: Plan;
}

const useStyles = makeStyles({
  multiLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    wordBreak: 'break-all',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
});
const SpectatorSubscriptionCard = ({
  plan,
}: SpectatorSubscriptionCardProps) => {
  const classes = useStyles();
  return (
    <Card sx={{ minWidth: 150, border: '2px solid #1e1e1e' }}>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          height: 54,
          justifyContent: 'space-between',
          px: 4,
          borderColor: 'rgba(255, 255, 255, 0.05)',
          borderStyle: 'solid',
          borderWidth: 0,
          borderBottomWidth: 1,
        }}
      >
        <Tooltip title={plan.title}>
          <Typography
            color="white"
            sx={{
              fontSize: 16,
              fontWeight: 600,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {plan.title}
          </Typography>
        </Tooltip>
      </Stack>
      <Stack sx={{ padding: '12px 16px 16px 16px' }}>
        <Typography
          color="grey.300"
          className={classes.multiLineEllipsis}
          sx={{
            fontSize: 12,
            lineHeight: '18px',
          }}
        >
          {plan.description}
        </Typography>
        <Button disabled color="secondary" sx={{ marginTop: '12px' }}>
          Adicionar plano
        </Button>
      </Stack>
    </Card>
  );
};

export default SpectatorSubscriptionCard;
