/* eslint-disable no-nested-ternary */
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import Topbar from '../../components/Topbar';
import { formatDateByFNS } from '../../utils/date';
import sortItems from '../../utils/sortItems';
import EmptyGameList from './components/EmptyGameList';
import FiltersBar from './components/FiltersBar';
import LiveSectionList from './components/LiveSectionList';
import Context from './Context';
import liveContentsQuery from './gql/liveContentsQuery';
import { SortableLiveContents } from './typings';

const LiveSection = () => {
  const [eventDate, setEventDate] = useState(new Date());
  const [sortableContents, setSortableContents] = useState<
    SortableLiveContents[]
  >([]);
  const { loading } = useQuery(liveContentsQuery, {
    variables: {
      input: { eventDate: formatDateByFNS(eventDate, 'yyyy/MM/dd') },
    },
    onCompleted: (data: any) =>
      setSortableContents(sortItems(data.liveContents, 'position')),
  });
  return (
    <Context.Provider
      value={{ setEventDate, sortableContents, setSortableContents }}
    >
      <Topbar
        title="Ao vivo"
        description="Ordene as transmissões atuais e futuras na seção Ao vivo"
      />
      <FiltersBar />
      {loading ? (
        <div>Carregando...</div>
      ) : !sortableContents.length ? (
        <EmptyGameList />
      ) : (
        <LiveSectionList />
      )}
    </Context.Provider>
  );
};

export default LiveSection;
