import { PaymentMethod } from '../interfaces/Spectator';

const FriendlyName = {
  [PaymentMethod.credit]: 'Crédito',
  [PaymentMethod.debit]: 'Débito',
  [PaymentMethod.pix]: 'PIX',
  [PaymentMethod.gratis]: 'Gratuito',
  [PaymentMethod.partner]: 'Parceiro',
};

export const getFriendlyNameByPaymentMethod = (method: PaymentMethod): string =>
  FriendlyName[method];
