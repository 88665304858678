import { SortByDirection } from '../../../typings/sort';

export const sortArrayBy =
  ({ direction, prop }: any) =>
  (prev: any, next: any) => {
    const prevValue = prev[prop];
    const nextValue = next[prop];
    const sortingAsc = direction === SortByDirection.Asc;
    if (!prevValue || !nextValue) {
      return 0;
    }
    if (!prevValue || !nextValue) {
      return 0;
    }
    if (sortingAsc) {
      return Math.abs(prevValue as number) - Math.abs(nextValue as number);
    }
    return Math.abs(nextValue as number) - Math.abs(prevValue as number);
  };
