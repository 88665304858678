import {
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@material-ui/core';
import { DragIndicator as DragIndicatorIcon } from '@material-ui/icons';
import { useDragLayer, XYCoord } from 'react-dnd';
import { Tag } from '../../../interfaces/Tag';

interface DragLayerCollectedProps {
  currentOffset: XYCoord | null;
  isDragging: boolean;
  itemType: string;
  tag: Tag;
}

const TagListItemDragLayer = () => {
  const { palette, shape, spacing } = useTheme();
  const { currentOffset, isDragging, itemType, tag } =
    useDragLayer<DragLayerCollectedProps>((monitor) => ({
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
      itemType: monitor.getItemType() as string,
      tag: monitor.getItem(),
    }));
  const isNotDraggingTagListItem =
    !isDragging || itemType !== 'TagListItem' || !currentOffset;
  if (isNotDraggingTagListItem) {
    return null;
  }
  return (
    <ListItem
      // TODO: using "style" here instead of "sx" to avoid page rendering lagging.
      style={{
        background: 'rgba(137, 80, 252, 0.1)', // TODO: export from theme
        border: `2px solid ${palette.info.main}`,
        borderRadius: shape.borderRadius,
        height: 53,
        left: currentOffset!.x,
        padding: `0 ${spacing(5)}px`,
        pointerEvents: 'none',
        position: 'fixed',
        top: currentOffset!.y,
        transform: 'rotate(2deg)',
        width: '250px',
        zIndex: 100, // TODO: export from theme
      }}
    >
      <ListItemIcon sx={{ minWidth: 28 }}>
        <DragIndicatorIcon style={{ color: palette.info.main }} />
      </ListItemIcon>
      <ListItemText
        primary={tag!.name}
        primaryTypographyProps={{ noWrap: true }}
        style={{ color: palette.common.white }}
      />
    </ListItem>
  );
};

export default TagListItemDragLayer;
