import { Stack } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import CustomDragLayer from './CustomDragLayer';

const DoubleContainerBlockDragLayer = () => (
  <CustomDragLayer height={80} type="DoubleContainerBlock">
    <Stack alignItems="center" flex="1" justifyContent="center">
      <AddIcon sx={{ color: 'info.main', fontSize: 56 }} />
    </Stack>
    <Stack alignItems="center" flex="1" justifyContent="center">
      <AddIcon sx={{ color: 'info.main', fontSize: 56 }} />
    </Stack>
  </CustomDragLayer>
);

export default DoubleContainerBlockDragLayer;
