import { Radio } from '@material-ui/core';
import { ColorType } from '../../../interfaces/Role';

interface RadioButtonProps {
  value: ColorType;
  colorRadio: string;
}

const RadioButton = ({ value, colorRadio }: RadioButtonProps) => (
  <Radio
    value={value}
    sx={{
      '& input+span': { bgcolor: colorRadio },
      '&.Mui-checked input+span': {
        bgcolor: colorRadio,
        boxShadow: `0px 0px 10px ${colorRadio}`,
      },
      '&.Mui-checked svg:nth-child(2) path': {
        color: 'grey.100',
      },
    }}
  />
);

export default RadioButton;
