import React from 'react'
import {
  Stack,
  Typography
} from '@material-ui/core';

interface StackWithLabelProps {
  children: React.ReactNode
  label: string;
}

const StackWithLabel = ({children, label}: StackWithLabelProps) => (
    <Stack direction='column' spacing={1}>
      <Typography
        sx={{ color: 'grey.300', fontSize: 14, fontWeight: 400 }}
      >
        {label}
      </Typography>
      {children}
    </Stack>
  )

export default StackWithLabel