import { useState } from 'react';
import Topbar from '../../components/Topbar';
import Container from './Components/Container';
import Filter from './Components/Filter';
import Context from './Context';
import { FilterAllPlans, PlanTypeToFinancialPlanReport } from './typings';

const FinancialPlanReport = () => {
  const [planType, setPlanType] = useState<PlanTypeToFinancialPlanReport>(
    FilterAllPlans.all,
  );
  return (
    <Context.Provider value={{ planType, setPlanType }}>
      <Topbar
        title="Relatórios de planos"
        description="Tenha acesso a todas as informações referentes aos planos existentes."
      />
      <Filter />
      <Container />
    </Context.Provider>
  );
};

export default FinancialPlanReport;
