import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@material-ui/core';
import { Web as WebIcon } from '@material-ui/icons';
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import Context from '../context';
import TipAlert from '../../../../components/UI/molecules/TipAlert';
import { useDialog } from '../../../../components/Dialog';
import EmptyStateCard from '../../../../components/EmptyStateCard';
import Topbar from '../../../../components/Topbar';
import { Permission } from '../../../../interfaces/Permission';
import { Page } from '../../../../interfaces/Plan';
import deletePageMutation from '../../gql/deletePageQuery';
import pagesQuery from '../../gql/pagesQuery';
import PagesList from './PagesList';

interface ContainerProps {
  permissionToDelete: Permission['delete'];
  permissionToCreate: Permission['create'];
  permissionToRead: Permission['read'];
  permissionToUpdate: Permission['update'];
}

const Container = ({
  permissionToCreate,
  permissionToDelete,
  permissionToRead,
  permissionToUpdate,
}: ContainerProps) => {
  const { loading, data, refetch } = useQuery(pagesQuery);
  const [deletePage] = useMutation(deletePageMutation);
  const alert = useAlert();
  const dialog = useDialog();
  if (loading) {
    return <div>loading...</div>;
  }
  const isEmpty = data?.pages.length === 0;
  const deletePageHandler = async (pagesToDelete: Page[]) => {
    const confirmed = await dialog.confirm({
      title: 'Você tem certeza?',
      message: `Excluir uma página a remove permanentemente da sua biblioteca. Não será possível recuperá-la.`,
    });
    if (confirmed) {
      // eslint-disable-next-line no-restricted-syntax
      for (const selectedPages of pagesToDelete) {
        // eslint-disable-next-line no-await-in-loop
        await deletePage({
          variables: { id: selectedPages.id },
        });
      }
      await refetch();
      alert.success('Página excluída com sucesso.');
    }
  };
  return (
    <Context.Provider
      value={{
        deletePageHandler,
        permissionToDelete,
        permissionToRead,
        permissionToUpdate,
      }}
    >
      <Topbar
        title="Minhas páginas"
        description="Crie suas páginas personalizadas"
      >
        {permissionToCreate && (
          <Link to="/pages/builder">
            <Button color="info" size="large" onClick={() => null}>
              Nova página
            </Button>
          </Link>
        )}
      </Topbar>
      <TipAlert sx={{ my: 4 }}>
        Utilize os modelos de topos e rodapés para criar páginas com mais
        rapidez e agilidade.
      </TipAlert>
      {isEmpty ? (
        <EmptyStateCard
          icon={<WebIcon />}
          title="Crie sua primeira página"
          description="Você poderá criar páginas personalizadas para cada etapa da interação com o seu usuário."
        >
          <Link to="/pages/builder">
            <Button color="info" variant="outlined" size="large">
              Criar nova página
            </Button>
          </Link>
        </EmptyStateCard>
      ) : (
        <PagesList pages={data.pages} />
      )}
    </Context.Provider>
  );
};

export default Container;
