import { gql } from '@apollo/client';

const updatePlanMutation = gql`
  mutation UpdatePlanMutation($input: UpdatePlanInput!, $id: ID!) {
    updatePlan(input: $input, id: $id) {
      id
    }
  }
`;

export default updatePlanMutation;
