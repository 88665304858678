import { createContext } from 'react';
import { Permission } from '../../interfaces/Permission';

type ContextType = {
  permissionToDelete: Permission['delete'];
  permissionToRead: Permission['read'];
  permissionToUpdate: Permission['update'];
};

const Context = createContext<ContextType>({} as ContextType);

export default Context;
