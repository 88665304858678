import { Button, Chip, Divider, Stack, Typography } from '@material-ui/core';
import {
  Folder as HighlightIcon,
  Launch as LaunchIcon,
} from '@material-ui/icons';
import { memo, useContext, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDialog } from '../../../components/Dialog';
import EmptyStateCard from '../../../components/EmptyStateCard';
import TileCard5 from '../../../components/TileCard5';
import { SortableHighlightedContents } from '../../../interfaces/Highlight';
import { Section } from '../../../interfaces/Plan';
import reorder from '../../../utils/reorderList';
import { sortThePositions } from '../tools/sortThePositions';
import Context from '../Context';
import ContentCard from './ContentCard';
import DialogHighlightedContents from './DialogHighlightedContents';
import DialogSections from './DialogSections';

const MemoizedHighlightsList = memo(
  ({
    sortableHighlights,
  }: {
    sortableHighlights: SortableHighlightedContents[];
  }) => (
    <>
      {sortableHighlights?.map((sortableHighlightedContent, index) => (
        <ContentCard
          key={sortableHighlightedContent.highlightedContent.id}
          sortableHighlightedContent={sortableHighlightedContent}
          index={index}
        />
      ))}
    </>
  ),
);

const SectionsAndContents = memo(() => {
  const { form } = useContext(Context);
  const dialog = useDialog();
  const addSectionHandler = (sections: Section[]) =>
    form.setFieldValue('sections', sections);
  const addContentsHandler = (
    highlightedContents: SortableHighlightedContents[],
  ) => form.setFieldValue('sortableHighlightedContents', highlightedContents);
  const openDialogSections = async () => {
    await dialog.open({
      title: 'Adicionar seção',
      element: (
        <DialogSections
          sections={form.values.sections}
          onAdd={addSectionHandler}
        />
      ),
      sx: { height: 667, width: 665 },
    });
  };
  const openDialogHighlightedContents = async () => {
    await dialog.open({
      title: 'Adicionar destaques',
      element: (
        <DialogHighlightedContents
          highlightedContents={form.values.sortableHighlightedContents}
          onSelect={addContentsHandler}
        />
      ),
      sx: { height: 580, width: 667 },
    });
  };
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    if (
      result.source.droppableId === 'highlightsList' &&
      result.destination.droppableId === 'highlightsList'
    ) {
      const switchListPositions = reorder(
        form.values.sortableHighlightedContents,
        result.source.index,
        result.destination.index,
      ) as SortableHighlightedContents[];
      const listReordered = sortThePositions(switchListPositions);
      form.setFieldValue('sortableHighlightedContents', listReordered);
    }
  };
  useEffect(() => {
    const itsEditAction = !!form.values.id;
    if (itsEditAction) {
      const sortHighlightedContents = (prevValue: any, nextValue: any) =>
        Math.abs(prevValue.position as number) -
        Math.abs(nextValue.position as number);
      const sorted = [...form.values.sortableHighlightedContents].sort(
        sortHighlightedContents,
      );
      form.setFieldValue('sortableHighlightedContents', sorted);
    }
  }, []);
  const thereAreHighlights = !!form.values.sortableHighlightedContents.length;
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Stack>
        <TileCard5
          title="Seções Liberadas"
          link="/sections/order"
          rightIcon={LaunchIcon}
          titleProps={{ sx: { color: 'grey.100', fontSize: 16, mb: 4 } }}
          buttonVisible={!!form.values.sections?.length}
          onClickButton={openDialogSections}
          sx={{ height: 195, mb: 4, width: 679 }}
        >
          {form.values.sections?.length ? (
            <Stack
              direction="row"
              sx={{ flexWrap: 'wrap', maxHeight: 125, overflowY: 'auto' }}
            >
              {form.values.sections.map((section: Section) => (
                <Chip
                  data-testid="item-section"
                  variant="outlined"
                  size="small"
                  label={section.title}
                  sx={{ bgcolor: 'grey.500', color: 'grey.100', mb: 4, mr: 4 }}
                />
              ))}
            </Stack>
          ) : (
            <>
              <Typography sx={{ color: 'grey.300', fontSize: 14, mt: 2 }}>
                Escolha quais seções estarão disponíveis aos assinantes deste
                plano. Você pode selecionar quantas seções desejar.
              </Typography>
              <Button sx={{ mt: 4 }} onClick={openDialogSections}>
                Adicionar Seções ao Plano
              </Button>
            </>
          )}
        </TileCard5>
        <TileCard5
          title="Destaques"
          titleProps={{ sx: { color: 'grey.100', fontSize: 16, mb: 4 } }}
          buttonVisible={!!form.values.highlightedContents?.length}
          sx={{ height: 480, mb: 4, width: 679 }}
          onClickButton={openDialogHighlightedContents}
        >
          <>
            <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
              Escolha quais destaques estarão disponíveis aos assinantes deste
              plano.
            </Typography>
            <Button sx={{ mt: 2 }} onClick={openDialogHighlightedContents}>
              Adicionar destaques ao plano
            </Button>
            <Divider
              sx={{ bgcolor: 'grey.500', my: thereAreHighlights ? 4 : 2 }}
            />
            {thereAreHighlights ? (
              <Stack sx={{ height: 290, overflowY: 'auto' }}>
                <Droppable droppableId="highlightsList">
                  {(provided) => (
                    <Stack ref={provided.innerRef} {...provided.droppableProps}>
                      <MemoizedHighlightsList
                        sortableHighlights={
                          form.values.sortableHighlightedContents
                        }
                      />
                    </Stack>
                  )}
                </Droppable>
              </Stack>
            ) : (
              <EmptyStateCard
                icon={<HighlightIcon />}
                title="Sem destaques"
                description="Para listar e ordenar os destaques, é necessário adicioná-los ao plano."
                iconProps={{
                  '& .MuiSvgIcon-root': {
                    color: 'secondary.main',
                    height: 48,
                    width: 48,
                  },
                }}
                sx={{ height: 300, boxShadow: 'none' }}
              />
            )}
          </>
        </TileCard5>
      </Stack>
    </DragDropContext>
  );
});

export default SectionsAndContents;
