import { gql } from '@apollo/client';

const getSectionQuery = gql`
  query GetSectionQuery($id: ID!) {
    section(id: $id) {
      id
      subSections {
        sortableContents {
          position
          content {
            id
            title
            thumbnail
          }
        }
      }
    }
  }
`;

export default getSectionQuery;
