import { createContext } from 'react';
import { Role } from '../../interfaces/Role';

type ContextType = {
  deleteRoleHandler: (role: Role) => void;
  editRoleHandler: (role: Role) => void;
  roles: Role[]
};

const Context = createContext<ContextType>({} as ContextType);

export default Context;
