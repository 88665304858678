import { Stack, Avatar, Typography } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { useSpectator } from '../Context';
import SpectatorHistory from './SpectatorHistory';

const SpectatorHistoryDrawer = () => {
  const { selectedSpectator } = useSpectator();
  if (!selectedSpectator) return null;
  return (
    <Stack direction="column" sx={{ px: 6, py: '85px' }}>
      <Stack
        direction="column"
        sx={{
          alignItems: 'center',
          pb: 6,
          borderColor: 'grey.500',
          borderStyle: 'solid',
          borderWidth: 0,
          borderBottomWidth: 1,
          mb: 6,
        }}
      >
        <Avatar
          alt="avatar"
          sx={{ width: 86, height: 86 }}
        />
        <Typography
          color="white"
          sx={{ fontSize: 16, lineHeight: '24px', fontWeight: 600, mt: 4 }}
        >
          {selectedSpectator.name}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" sx={{ mb: 4 }}>
        <Typography color="white" sx={{ fontSize: 20, fontWeight: 600 }}>
          Histórico financeiro
        </Typography>
        <InfoIcon
          sx={{
            color: 'grey.400',
            ml: 2,
            width: 12,
            height: 12,
          }}
        />
      </Stack>
      <SpectatorHistory />
    </Stack>
  );
};

export default SpectatorHistoryDrawer;
