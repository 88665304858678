import { useQuery } from '@apollo/client';
import { Avatar, Box, Typography, Stack } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import DownloadButton from '../../../components/DownloadButton';
import { PlanStatus, PlanType } from '../../../interfaces/Plan';
import {
  convertJsonArrayToCsv,
  createUriAndNameToFile,
  CreateUriAndNameToFileReturn,
} from '../../../utils/download/convertFiles';
import { Context } from '../Context';
import userQuery from '../gql/userQuery';
import mountSpectatorFinancialObjectForDownload from '../utils/mountSpectatorFinancialObjectForDownload';
import SpectatorSubscriptionCard from './SpectatorSubscriptionCard';
import SpectatorSubscriptionItem from './SpectatorSubscriptionItem';

const SpectatorSubscriptionDrawer = () => {
  const [financialSpectatorDownload, setFinancialSpectatorDownload] = useState({
    name: '',
    uri: '',
  });
  const { listSpectators, plans, setData, selectedSpectator } =
    useContext(Context);
  const { loading, data, refetch } = useQuery(userQuery, {
    variables: { id: selectedSpectator!.id },
  });
  useEffect(() => {
    const theSpectatorContainsPlan = data && data?.user.userPlans.length > 0;
    const financialSpectator = theSpectatorContainsPlan
      ? mountSpectatorFinancialObjectForDownload(data?.user)
      : [];
    const csv = theSpectatorContainsPlan
      ? convertJsonArrayToCsv(financialSpectator)
      : null;
    if (csv) {
      const createUriAndNameToFileProps = {
        file: csv,
        fileName: data?.user.email,
        fileType: 'csv',
      };
      const createUriAndNameToFileReturn: CreateUriAndNameToFileReturn =
        createUriAndNameToFile(createUriAndNameToFileProps);
      setFinancialSpectatorDownload(createUriAndNameToFileReturn);
    }
  }, [data]);
  if (!selectedSpectator) return null;
  if (loading) return <div>Carregando...</div>;
  const updateSpectatorStatus = (planId: string) => {
    const userPlans = [...data.user.userPlans];
    const newPlans = userPlans.map((userPlan) =>
      userPlan.plan.id === planId
        ? {
            ...userPlan,
            userPlanHistory: { status: PlanStatus.cancelled },
          }
        : userPlan,
    );
    const stillHasActivePlan = newPlans.find(
      (userPlan: any) => userPlan.userPlanHistory.status === PlanStatus.active,
    );
    if (!stillHasActivePlan) {
      const getPlanCanceled = userPlans.find(
        (userPlan: any) => userPlan.plan.id === planId,
      );
      const userHasPreCancelledPlanOrActive = userPlans.find(
        (userPlan: any) =>
          userPlan.userPlanHistory.status === PlanStatus.preCancelled ||
          getPlanCanceled.plan.type === PlanType.monthly,
      );
      if (!userHasPreCancelledPlanOrActive) {
        const newSpectators = listSpectators.map((spectator) =>
          spectator.user.id === selectedSpectator!.id
            ? { ...spectator, status: PlanStatus.cancelled }
            : spectator,
        );
        setData({ items: newSpectators });
      }
    }
  };
  return (
    <Stack direction="column" sx={{ px: 6, py: '85px' }}>
      <Stack
        direction="column"
        sx={{
          alignItems: 'center',
          borderColor: 'grey.500',
          borderBottomWidth: 1,
          borderStyle: 'solid',
          borderWidth: 0,
          mb: 6,
          pb: 6,
        }}
      >
        <Avatar alt="avatar" sx={{ width: 86, height: 86 }} />
        <Typography
          color="white"
          sx={{ fontSize: 16, lineHeight: '24px', fontWeight: 600, mt: 4 }}
        >
          {selectedSpectator.name}
        </Typography>
        <DownloadButton
          uri={financialSpectatorDownload.uri}
          fileName={financialSpectatorDownload.name}
          buttonTitle="Baixar relatório"
          sx={{ bgcolor: 'success.main', mt: 4 }}
        />
      </Stack>
      <Stack
        direction="column"
        sx={{
          borderBottomWidth: 1,
          borderColor: 'grey.500',
          borderStyle: 'solid',
          borderWidth: 0,
          mb: 6,
          pb: 6,
        }}
      >
        <Stack direction="row" alignItems="center" sx={{ mb: 6 }}>
          <Typography color="white" sx={{ fontSize: 20, fontWeight: 600 }}>
            Assinaturas
          </Typography>
        </Stack>
        {!data.user.userPlans.length ? (
          <Typography sx={{ color: 'common.white', fontSize: 15 }}>
            Este usuário não possui nenhuma assinatura ativa.
          </Typography>
        ) : (
          data.user.userPlans.map((plan: any) => (
            <SpectatorSubscriptionItem
              isLastItem
              data={plan}
              refetch={refetch}
              updateSpectatorStatus={updateSpectatorStatus}
            />
          ))
        )}
      </Stack>
      <Stack alignItems="center" direction="row" sx={{ mb: 6 }}>
        <Typography color="white" sx={{ fontSize: 20, fontWeight: 600 }}>
          Adicionar assinaturas
        </Typography>
      </Stack>
      <Typography color="grey.300" sx={{ fontSize: 14 }}>
        Ofereça planos gratuitamente para os espectadores.
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gap: 6,
          gridTemplateColumns: '1fr 1fr',
          mt: 6,
        }}
      >
        {plans.map((plan) => (
          <SpectatorSubscriptionCard plan={plan} />
        ))}
      </Box>
    </Stack>
  );
};

export default SpectatorSubscriptionDrawer;
