import { Button, ListItem, ListItemText } from '@material-ui/core';

interface DialogsListItemProps {
  // TODO: add typing
  items: any;
  select: boolean;
  // TODO: add typing
  onToggle: (items: any, action: string) => void;
}

const DialogsListItem = ({ items, onToggle, select }: DialogsListItemProps) => {
  const toggleButtonSelected = () => onToggle(items, select ? 'remove' : 'add');
  return (
    <ListItem
      sx={{
        px: 0,
        '.MuiListItemSecondaryAction-root': { right: 0 },
      }}
      secondaryAction={
        <Button
          data-testid="button-toggle-select"
          variant="outlined"
          color={select ? 'success' : 'primary'}
          sx={{ fontSize: 12, height: 30 }}
          onClick={toggleButtonSelected}
        >
          {select ? 'Adicionada' : 'Adicionar'}
        </Button>
      }
    >
      <ListItemText
        primary={items?.title || items?.name}
        primaryTypographyProps={{
          sx: {
            maxWidth: 170,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        }}
        sx={{
          color: 'white',
        }}
      />
    </ListItem>
  );
};

export default DialogsListItem;
