// TODO: Remove when Craft.js support settings props without param reassign
/* eslint-disable no-param-reassign */
import { useNode } from '@craftjs/core';
import { Stack, Typography } from '@material-ui/core';
import { Image as ImageIcon } from '@material-ui/icons';
import { useState } from 'react';
import Thumbnail from '../../../../../../../components/Thumbnail';
import getAssetUri from '../../../../../../../utils/getAssetUri';
import uploadFile from '../../../../../../ManagersList/services/uploadFile';
import SidebarAccordion from '../../components/SidebarAccordion';
import { NodePropsType } from './typings';

type ObjectFitTypes = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';

interface ImageProps {
  alt: string;
  objectFit: ObjectFitTypes;
  objectPosition: string;
  url: string;
}

const ImagesSettings = () => {
  const [expanded, setExpanded] = useState(true);
  const {
    actions: { setProp },
    props: { url },
  }: NodePropsType<ImageProps> = useNode((node) => ({
    name: node.data.name,
    props: node.data.props,
  }));
  const changeThumbnailHandler = async (evt: any) => {
    const res = await uploadFile<{ data: { fileUpload: string } }>(
      evt.target.files[0],
    );
    if (res.data.data.fileUpload) {
      setProp((currentProps) => {
        currentProps.url = res.data.data.fileUpload;
      });
    }
  };

  return (
    <SidebarAccordion
      expanded={expanded}
      icon={
        <ImageIcon
          sx={{
            color: 'secondary.main',
            height: 24,
            width: 24,
          }}
        />
      }
      title="Opções de imagens"
      onExpand={() => setExpanded(!expanded)}
    >
      <Stack alignItems="center" gap={4}>
        {url ? (
          <>
            <Typography color="grey.300" fontSize={14}>
              Imagem enviada
            </Typography>
          </>
        ) : (
          <>
            <Typography color="common.white" fontSize={16}>
              Carregue uma imagem{' '}
            </Typography>
            <Typography color="grey.300" fontSize={14}>
              Arquivos em PNG, JPG ou GIF.
            </Typography>
          </>
        )}
        <Thumbnail
          onBuilder
          src={getAssetUri(url)}
          onChange={changeThumbnailHandler}
        />
      </Stack>
    </SidebarAccordion>
  );
};

export default ImagesSettings;
