import { Theme } from '@material-ui/core';
import { Components } from '@material-ui/core/styles/components';

const makeMuiMenuItem = (theme: Theme): Components['MuiMenuItem'] => ({
  styleOverrides: {
    root: {
      height: 45,
      '&:hover': { color: theme.palette.secondary.dark },
    },
  },
});

export default makeMuiMenuItem;
