import { Grid, Typography, Stack, IconButton } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

const SpectatorListHeader = () => (
  <Grid
    container
    sx={{
      borderColor: 'grey.500',
      borderStyle: 'solid',
      borderWidth: 0,
      borderBottomWidth: 1,
      mb: 4,
      pb: 2,
    }}
  >
    <Grid item xs={4}>
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <Typography sx={{ color: 'white', fontWeight: 600 }}>
          Nome do espectador
        </Typography>
        <IconButton>
          <ExpandMore sx={{ color: 'grey.400', width: 14 }} />
        </IconButton>
      </Stack>
    </Grid>
    <Grid item xs={2}>
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <Typography sx={{ color: 'white', fontWeight: 600 }}>
          Telefone
        </Typography>
        <IconButton>
          <ExpandMore sx={{ color: 'grey.400', width: 14 }} />
        </IconButton>
      </Stack>
    </Grid>
    <Grid item xs={2}>
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <Typography sx={{ color: 'white', fontWeight: 600 }}>CPF</Typography>
        <IconButton>
          <ExpandMore sx={{ color: 'grey.400', width: 14 }} />
        </IconButton>
      </Stack>
    </Grid>
    <Grid item xs={1}>
      <Stack direction="row" sx={{ alignItems: 'center', width: 200 }}>
        <Typography
          sx={{
            color: 'white',
            fontWeight: 600,
            whiteSpace: 'nowrap',
          }}
        >
          Status do Espectador
        </Typography>
        <IconButton>
          <ExpandMore sx={{ color: 'grey.400', width: 14 }} />
        </IconButton>
      </Stack>
    </Grid>
  </Grid>
);

export default SpectatorListHeader;
