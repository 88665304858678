import { Button, Card, Stack, TextField, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/lab';
import { useContext, useState } from 'react';
import Context from '../Context';

const FiltersBar = () => {
  const { setEventDate } = useContext(Context);
  const [selectedDate, setSelectedDate] = useState(new Date());
  return (
    <Card sx={{ height: 122, mt: 4, p: 6 }}>
      <Typography sx={{ color: 'grey.300', fontSize: 16 }}>
        Selecione a data para ordenar:
      </Typography>
      <Stack alignItems="center" direction="row" gap={4} sx={{ mt: 2 }}>
        <DatePicker
          mask="__/__/__"
          inputFormat="dd/MM/yy"
          value={selectedDate}
          onChange={(e: any) => setSelectedDate(e)}
          renderInput={(params) => (
            <TextField sx={{ width: 120 }} {...params} />
          )}
          InputAdornmentProps={{ sx: { width: 20 } }}
        />
        <Button onClick={() => setEventDate(selectedDate)}>Atualizar</Button>
      </Stack>
    </Card>
  );
};

export default FiltersBar;
