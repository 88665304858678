import { useMutation } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/UI/molecules/Navbar';
import FormFooter from '../../components/UI/templates/FormFooter';
import { HighlightSection, ItemType } from '../../interfaces/HighlightSection';
import ChooseImage from './components/ChooseImage';
import Details from './components/Details';
import Context from './Context';
import createMultiSectionItemMutation from './gql/createMultiSectionItemMutation';
import validationSchema from './providers/validationSchema';

type FormValues = Pick<
  HighlightSection,
  'itemType' | 'title' | 'thumbnail' | 'scheduleDate' | 'finishDate'
>;

const CreateHighlightSections = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [createMultiSectionItem] = useMutation(createMultiSectionItemMutation);
  const [isCreating, setIsCreating] = useState(false);
  const form = useFormik({
    initialValues: {
      itemType: ItemType.Content,
      scheduleDate: null,
      finishDate: null,
      title: 'Titulo para o destaque',
      content: '',
      section: '',
      thumbnail: '',
    },
    validationSchema,
    onSubmit: (values: FormValues) => {
      try {
        setIsCreating(true);
        const input = validationSchema.cast(values);
        createMultiSectionItem({
          variables: { input: { ...input, position: 0 } },
        });
        alert.success('Destaque criado com sucesso!');
        navigate('/section/highlights');
      } catch {
        alert.error('Ocorreu um erro ao salvar o destaque.');
      } finally {
        setIsCreating(false);
      }
    },
  });
  return (
    <Context.Provider value={{ form }}>
      <Navbar title="Criar destaque" route="/section/highlights" />
      <Grid container gap={5} sx={{ mt: 6 }}>
        <Grid item xs={8}>
          <Details />
        </Grid>
        <Grid item xs>
          <ChooseImage />
        </Grid>
      </Grid>
      <FormFooter
        LeftButtonProps={{
          children: 'Salvar destaque',
          type: 'submit',
          loading: isCreating,
          onClick: () => form.handleSubmit(),
        }}
        helperText=""
        RightButtonProps={{
          children: 'Cancelar criação',
          onClick: () => navigate('/section/highlights'),
        }}
        sx={{ mt: 6 }}
      />
    </Context.Provider>
  );
};

export default CreateHighlightSections;
