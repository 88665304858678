import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useDialog } from '../../../components/Dialog';
import { SortableContent as ISortableContent } from '../../../interfaces/Section';

export interface SortableContentProps {
  index: number;
  sortableContent: ISortableContent;
  deleteContent: (sortableContent: ISortableContent) => void;
}

const SortableContent = ({
  sortableContent,
  index,
  deleteContent,
}: SortableContentProps) => {
  const dialog = useDialog();
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const confirmContentDeletion = async () => {
    const shouldDeleteContent = await dialog.confirm({
      title: 'Você tem certeza?',
      message: `Se excluir o conteúdo '${sortableContent.content.title}'`,
      confirmMessage: 'Excluir conteúdo',
    });
    if (shouldDeleteContent) {
      deleteContent(sortableContent);
    }
  };
  return (
    <Draggable draggableId={sortableContent.content.id} index={index}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{ cursor: 'grab', position: 'relative' }}
          onMouseEnter={() => setIsMouseEnter(true)}
          onMouseLeave={() => setIsMouseEnter(false)}
        >
          <img
            alt={sortableContent.content.title}
            src={sortableContent.content.thumbnail}
            loading="lazy"
            style={{ height: 96, width: 170 }}
          />
          <Typography
            sx={{
              display: '-webkit-box',
              color: 'grey.100',
              fontSize: 12,
              fontWeight: 600,
              lineHeight: 1.5,
              mt: 2,
              overflow: 'hidden',
              textAlign: { xs: 'center', lg: 'left' },
              textOverflow: 'ellipsis',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              width: 170,
            }}
          >
            {sortableContent.content.title}
          </Typography>
          <>
            {isMouseEnter && (
              <>
                <Tooltip title="Excluir">
                  <IconButton
                    edge="end"
                    sx={{
                      color: 'grey.100',
                      position: 'absolute',
                      right: 10,
                      top: 2,
                      ':hover': { color: 'error.main' },
                    }}
                    onClick={confirmContentDeletion}
                  >
                    <DeleteIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        </Box>
      )}
    </Draggable>
  );
};

export default SortableContent;
