import { gql } from '@apollo/client';

const updateTagMutation = gql`
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      id
      name
      slug
    }
  }
`;

export default updateTagMutation;
