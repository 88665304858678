import {
  Card,
  Grid,
  OutlinedInput,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import { useContext } from 'react';
import Context from '../../EditContent/context';

const ExternalLinks = () => {
  const { form } = useContext(Context);
  return (
    <Grid container sx={{ mt: 4, gap: 4 }}>
      <Grid item xs>
        <Card sx={{ height: 170, p: 5 }}>
          <Typography
            sx={{
              alignItems: 'center',
              color: 'grey.100',
              display: 'flex',
              fontWeight: 600,
              mb: 2,
            }}
          >
            Embed
          </Typography>
          <OutlinedInput
            name="externalEmbed"
            value={form.values.externalEmbed}
            error={!!form.errors.externalEmbed}
            sx={{ width: '100%', height: 85 }}
            onChange={form.handleChange}
          />
          <FormHelperText error>{form.errors.externalEmbed}</FormHelperText>
        </Card>
      </Grid>
      <Grid item xs>
        <Card sx={{ height: 170, p: 5 }}>
          <Typography
            sx={{
              alignItems: 'center',
              color: 'grey.100',
              display: 'flex',
              fontWeight: 600,
              mb: 2,
            }}
          >
            URL
          </Typography>
          <OutlinedInput
            name="externalUrl"
            value={form.values.externalUrl}
            error={!!form.errors.externalUrl}
            sx={{ width: '100%', height: 85 }}
            onChange={form.handleChange}
          />
          <FormHelperText error>{form.errors.externalUrl}</FormHelperText>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ExternalLinks;
