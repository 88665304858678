import { gql } from '@apollo/client';

const getDiscountReportsQuery = gql`
  query GetDiscountReports($type: DiscountType!) {
    getDiscountReports(type: $type) {
      totalSignedUsers
      viewsDiscountRetentions
      spectatorsWithActiveRetention
      usageAvg
      percetangeValueAvg
      priceValueAvg
      durationAvg
      totalActiveUsers
      successRate
      totalCreated
      totalActive
      totalInactive
    }
  }
`;

export default getDiscountReportsQuery;