import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
} from '@material-ui/core';
import {
  DragIndicator as DragIndicatorIcon,
  Edit as EditIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';
import { Highlight } from '../../../interfaces/Highlight';
import HighlightDetail from './HighlightDetail';

interface HighlightItemProps {
  highlightedContent: Highlight;
  index: number;
}

const HighlightItem = ({ highlightedContent, index }: HighlightItemProps) => {
  const [expanded, setExpanded] = useState(false);
  const { palette } = useTheme();
  return (
    <Draggable draggableId={highlightedContent.id} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Accordion disableGutters expanded={expanded} sx={{ mb: 4 }}>
            <AccordionSummary
              expandIcon={
                <Button
                  size="large"
                  endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  sx={{
                    bgcolor: 'grey.500',
                    color: 'grey.200',
                    cursor: snapshot.isDragging ? 'grabbing' : 'grab',
                    mr: 2,
                  }}
                >
                  {expanded ? 'Ocultar destaque' : 'Ver destaque'}
                </Button>
              }
              sx={{
                bgcolor: snapshot.isDragging ? 'rgba(137, 80, 252, 0.1)' : null,
                border: snapshot.isDragging
                  ? `2px solid ${palette.info.main}`
                  : null,
                borderRadius: '4px',
                minHeight: 72,
                '&.Mui-expanded': {
                  borderColor: 'grey.500',
                  borderStyle: 'solid',
                  borderBottomWidth: 1,
                },
                // FIXME: check another alternative
                '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(0deg)',
                  WebkitTransform: 'rotate(0deg)',
                },
              }}
              onClick={() => setExpanded(!expanded)}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  color: 'common.white',
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'relative',
                  width: '100%',
                }}
              >
                <DragIndicatorIcon
                  sx={{
                    cursor: 'grab',
                    color: 'grey.400',
                    height: 28,
                    width: 28,
                  }}
                />
                <Typography
                  sx={{
                    alignItems: 'center',
                    bgcolor: snapshot.isDragging ? '#8950FC' : 'grey.500',
                    borderRadius: 1,
                    display: 'flex',
                    fontSize: 24,
                    height: 40,
                    justifyContent: 'center',
                    mx: 5,
                    width: 40,
                  }}
                >
                  {index + 1}
                </Typography>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  sx={{ width: '80%' }}
                >
                  <Typography
                    noWrap
                    sx={{
                      color: 'common.white',
                      fontSize: 16,
                      mr: 56,
                      maxWidth: 700,
                    }}
                  >
                    {highlightedContent.title}
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    alignItems: 'center',
                    bgcolor: 'grey.500',
                    borderRadius: 1,
                    display: 'flex',
                    height: 40,
                    justifyContent: 'center',
                    position: 'absolute',
                    right: 16,
                    width: 48,
                  }}
                >
                  <Link to={`/highlight/edit/${highlightedContent.id}`}>
                    <EditIcon
                      sx={{ color: 'grey.300', height: 24, width: 24 }}
                    />
                  </Link>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ py: 5, pl: 6, pr: 4 }}>
              <HighlightDetail highlightedContent={highlightedContent} />
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </Draggable>
  );
};

export default HighlightItem;
