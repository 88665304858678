import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { useContext } from 'react';
import DiscountAndSpectators from './DiscountAndSpectators';
import TotalAndValues from './TotalAndValues';
import getDiscountReportsQuery from '../gql/GetDiscountReportsQuery';
import Context from '../Context';

const Container = () => {
  const { discountType } = useContext(Context);
  const { loading, data } = useQuery(getDiscountReportsQuery, {
    variables: { type: discountType },
  });
  if (loading) return <>Carregando...</>;
  const financialDiscountReport = data.getDiscountReports;
  return (
    <Grid container sx={{ gap: 4, mt: 6 }}>
      <Grid item xs>
        <TotalAndValues financialDiscountReport={financialDiscountReport} />
      </Grid>
      <Grid item xs>
        <DiscountAndSpectators financialDiscountReport={financialDiscountReport} />
      </Grid>
    </Grid>
  );
};

export default Container;
