import * as yup from 'yup';

const validationSchema = yup.object().shape({
  title: yup.string().required().max(160),
  scheduleDate: yup.mixed().when('scheduleDateEnabled', {
    is: true,
    then: yup.date().required('scheduleDateEnabled'),
  }),
  finishDate: yup.mixed().when('finishDateEnabled', {
    is: true,
    then: yup.date().required('finishDateEnabled'),
  }),
  sortableHighlightedContents: yup
    .object()
    .noUnknown()
    .shape({
      sortableItems: yup
        .array()
        .of(
          yup.object().noUnknown().shape({
            position: yup.number().required(),
            childrenId: yup.string().required(),
          }),
        )
        .transform((elements) => {
          const newElements = elements.map((element: any) => ({
            position: element.position,
            childrenId: element.highlightedContent.id,
          }));
          return newElements;
        }),
    }),
  generalSubSection: yup
    .object()
    .noUnknown()
    .shape({
      sortableContents: yup.array().of(
        yup
          .object()
          .noUnknown()
          .shape({
            position: yup.number().required(),
            content: yup
              .object()
              .noUnknown()
              .shape({ id: yup.string().required() }),
          }),
      ),
    }),
  subSections: yup.array().of(
    yup
      .object()
      .noUnknown()
      .shape({
        id: yup.string().required(),
        position: yup.number().required(),
        title: yup.string().required(),
        sortableContents: yup.array().of(
          yup
            .object()
            .noUnknown()
            .shape({
              position: yup.number().required(),
              content: yup
                .object()
                .noUnknown()
                .shape({ id: yup.string().required() }),
            }),
        ),
      }),
  ),
});

export default validationSchema;
