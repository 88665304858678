import Details from '../../EditContent/components/Details';
import Setup from '../../EditContent/components/Setup';
import ExternalLinks from './ExternalLinks';

const Container = () => (
  <>
    <Details />
    <ExternalLinks />
    <Setup />
  </>
);

export default Container;
