import { createContext } from 'react';
import { Permission } from '../../interfaces/Permission';
import { Plan } from '../../interfaces/Plan';

interface ValuesOverviewType {
  getAllActiveDiscountsCount: number;
  getAllMembershipsCount: number;
  getAllPublishedPlansCount: number;
  getTop10Plans: Plan[];
}

type ContextType = {
  valuesOverview: ValuesOverviewType;
  permissionToCreatePlans: Permission['create'];
  permissionToReadPlans: Permission['read'];
  permissionsToReadSpectators: Permission['read'];
};

const Context = createContext<ContextType>({} as ContextType);

export default Context;
