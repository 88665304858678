import { gql } from '@apollo/client';

const changeUserTeamMutation = gql`
  mutation ChangeUserTeamMutation($input: ChangeUserTeamInput!) {
    changeUserTeam(input: $input) {
      id
    }
  }
`;

export default changeUserTeamMutation;
