export enum PermissionType {
  categoriesAndTags = 'categoriesAndTags',
  contents = 'contents',
  coupons = 'coupons',
  managers = 'managers',
  managersLog = 'managersLog',
  pages = 'pages',
  plans = 'plans',
  sections = 'sections',
  spectators = 'spectators',
  teams = 'teams',
}

export interface Permission {
  id: string;
  feature: PermissionType;
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}
