import * as yup from 'yup';

const validationSchema = yup.object().shape({
  title: yup.string().required('Este campo é obrigatório.').max(160),
  description: yup.string().required('Este campo é obrigatório.').max(160),
  published: yup.boolean().required(),
  answersEnabled: yup.boolean().required(),
  required: yup.boolean().required(),
  position: yup.number().required(),
  tags: yup.array().of(
    yup
      .object()
      .shape({ id: yup.string().required() })
      .transform(({ id }) => ({ id })),
      ).when('answersEnabled', (answersEnabled, schema) =>
    answersEnabled
      ? schema.min(1, 'Não é possível criar perguntas sem tags')
      : schema
  ),
});

export default validationSchema;
