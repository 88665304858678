import { Card, Typography, Stack, Button } from '@material-ui/core';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { TileCard5Props } from './typings';

const TileCard5 = memo(
  ({
    buttonVisible = false,
    children,
    title,
    rightIcon: RightIcon,
    titleProps,
    onClickButton,
    link,
    sx,
  }: TileCard5Props) => (
    <Card sx={{ height: 151, p: 5, ...sx }}>
      <Stack
        justifyContent={RightIcon && !buttonVisible ? 'space-between' : ''}
        direction="row"
      >
        <Typography
          sx={{ color: 'grey.100', fontSize: 16, mb: 2 }}
          {...titleProps}
        >
          {title}
        </Typography>
        {RightIcon && (
          <Link to={`${link}`}>
            <RightIcon
              data-testid="link-tile-card-5"
              sx={{
                color: 'secondary.main',
                fontSize: 20,
                ml: buttonVisible ? 2 : 0,
              }}
            />
          </Link>
        )}
        {buttonVisible && (
          <Button
            variant="outlined"
            sx={{ height: 30, ml: 'auto' }}
            onClick={onClickButton}
          >
            Adicionar
          </Button>
        )}
      </Stack>
      {children}
    </Card>
  ),
);

export default TileCard5;
