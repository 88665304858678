import { Avatar, Button, Stack, Typography } from '@material-ui/core';
import { useContext } from 'react';
import Context from '../Context';

interface ShowSelectedTypeProps {
  typeSelectedIsContent: boolean;
  openDialogHighlightedContents: () => void;
}

const ShowSelectedType = ({
  typeSelectedIsContent,
  openDialogHighlightedContents,
}: ShowSelectedTypeProps) => {
  const { form } = useContext(Context);
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{ height: '100%', width: '100%' }}
    >
      <Stack alignItems="center" direction="row" gap={4}>
        {typeSelectedIsContent && (
          <Avatar
            src={form.values?.content?.thumbnail}
            variant="rounded"
            sx={{ height: 42, width: 74 }}
          />
        )}
        <Typography sx={{ color: 'grey.100', fontSize: 14 }}>
          {typeSelectedIsContent
            ? form.values?.content?.title
            : form.values?.section?.title}
        </Typography>
      </Stack>
      <Button onClick={openDialogHighlightedContents}>Alterar</Button>
    </Stack>
  );
};

export default ShowSelectedType;
