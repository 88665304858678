import { ReactNode, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  useTheme,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { DialogContext, IDialogContext } from './IDialogContext';
import { DEFAULT_DIALOG_OPTIONS, IDialogOptions } from './IDialogOptions';
import { IMessageOptions } from './IMessageOptions';

interface DialogProviderProps {
  children: ReactNode;
  // eslint-disable-next-line react/require-default-props
  context?: (context: IDialogContext) => void;
}

const DialogProvider = ({ children, context }: DialogProviderProps) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(DEFAULT_DIALOG_OPTIONS);
  const theme = useTheme();
  const { title, message, element, type, sx } = options;
  const openAsConfirmation = async (newOptions: IDialogOptions) => {
    setOptions({ ...newOptions, type: 'confirmation' });
    setOpen(true);
  };
  const openAsDialog = async (newOptions: IMessageOptions) => {
    setOptions({ ...newOptions, type: 'dialog' });
    setOpen(true);
  };
  const confirmHandler = () => {
    setOpen(false);
    options.callback(true);
  };
  const close = async (result = false) => {
    setOpen(false);
    options?.callback(result);
  };
  const provider: IDialogContext = {
    openAsConfirmation,
    openAsDialog,
    // @ts-ignore
    close,
  };
  const closeHandler = () => close(false);
  if (context) {
    context(provider);
  }
  return (
    <DialogContext.Provider value={provider}>
      {children}
      <Dialog
        data-testid="dialog-content"
        open={open}
        onBackdropClick={closeHandler}
        onClose={closeHandler}
        maxWidth={false}
      >
        <Box sx={{ width: 342, ...sx }}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ height: 72 }}
          >
            <DialogTitle
              sx={{
                color: 'common.white',
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {title}
            </DialogTitle>
            <IconButton
              sx={{
                color: 'grey.300',
                width: 12,
                height: 12,
                mr: 5,
                zIndex: theme.zIndex.modal,
              }}
              onClick={closeHandler}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <DialogContent sx={{ pt: 0 }}>
            {message ? (
              <DialogContentText sx={{ color: 'grey.200', fontSize: 14 }}>
                {message}
              </DialogContentText>
            ) : (
              element
            )}
          </DialogContent>
          {type === 'confirmation' && (
            <Box sx={{ p: 5, pt: 0 }}>
              <Button color="error" onClick={confirmHandler}>
                Confirmar
              </Button>
            </Box>
          )}
        </Box>
      </Dialog>
    </DialogContext.Provider>
  );
};

export default DialogProvider;
