import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { useContext } from 'react';
import Context from '../Context';
import getPlansReportsQuery from '../gql/GetPlanReportQuery';
import DiscountAndMemberships from './DiscountAndMemberships';
import PlansAndSpectators from './PlansAndSpectators';

const Container = () => {
  const { planType } = useContext(Context);
  const { loading, data } = useQuery(getPlansReportsQuery, {
    variables: { type: planType },
  });
  if (loading) return <div>Carregando...</div>;
  const financialDiscountReport = data.getPlansReports;
  return (
    <Grid container sx={{ gap: 4, mt: 6 }}>
      <Grid item xs>
        <PlansAndSpectators financialDiscountReport={financialDiscountReport} />
      </Grid>
      <Grid item xs>
        <DiscountAndMemberships financialDiscountReport={financialDiscountReport} />
      </Grid>
    </Grid>
  );
};

export default Container;
