import { gql } from '@apollo/client';

const spallaLivesQuery = gql`
  query SpallaLives {
    spallaLives {
      spallaContentId
      title
      description
      thumbnail
      createdAt
    }
  }
`;

export default spallaLivesQuery;
