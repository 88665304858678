import { createContext } from 'react';
import { Page } from '../../../interfaces/Plan';

type ContextType = {
  deletePageHandler: (pages: Page[]) => Promise<void>;
  permissionToDelete: boolean;
  permissionToRead: boolean;
  permissionToUpdate: boolean;
};

const Context = createContext<ContextType>({} as ContextType);

export default Context;
