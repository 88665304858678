import { Stack } from '@material-ui/core';
import { useContext } from 'react';
import getAssetUri from '../../../../utils/getAssetUri';
import Context from '../context';

interface DynamicImageProps {
  alt: string;
  objectFit: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  objectPosition: string;
  url: string;
}

const DynamicImage = ({
  alt = '',
  objectFit = 'cover',
  objectPosition = 'center',
  url = 'http://placeimg.com/640/480/tech',
}: DynamicImageProps) => {
  const { smartphoneScreen } = useContext(Context);
  return (
    <Stack
      sx={{
        maxHeight: smartphoneScreen ? 200 : 697,
        position: 'relative'
      }}
    >
      <img
        src={getAssetUri(url)}
        alt={alt}
        style={{
          height: '100%',
          objectFit,
          objectPosition,
          width: '100%',
        }}
      />
    </Stack>
  );
};

export default DynamicImage;
