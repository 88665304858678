import { gql } from '@apollo/client';

const discountReportsAnalyse = gql`
  query DiscountReportsAnalyseQuery($input: DiscountReportsAnalyseInput!) {
    getDiscountReportsAnalyse(input: $input) {
      spectatorsByDiscountId {
        items {
          id
          name
          email
          phone
          createdAt
          remainingRecurrences
          recurrenceUnlimited
          signupAnswer {
            tag {
              name
            }
          }
        }
        meta {
          totalItems
          itemCount
          totalPages
          currentPage
        }
      }
    }
  }
`;

export default discountReportsAnalyse;
