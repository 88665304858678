import { gql } from '@apollo/client';

const listTagsQuery = gql`
  query ListTags {
    tags {
      id
      name
      slug
    }
  }
`;
export default listTagsQuery;
