import * as yup from 'yup';
import { PermissionType } from '../../../interfaces/Permission';
import { ColorType } from '../../../interfaces/Role';

const validationSchema = yup
  .object()
  .noUnknown()
  .shape({
    id: yup.string(),
    title: yup.string().required(),
    color: yup.mixed().oneOf(Object.values(ColorType)),
    enabled: yup.boolean().required(),
    permissions: yup.array().of(
      yup
        .object()
        .noUnknown()
        .shape({
          id: yup.string(),
          feature: yup.mixed().oneOf(Object.values(PermissionType)),
          create: yup.boolean().required(),
          read: yup.boolean().required(),
          update: yup.boolean().required(),
          delete: yup.boolean().required(),
        }),
    ),
  });

export default validationSchema;
