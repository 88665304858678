import { Theme } from '@material-ui/core';
import { Components } from '@material-ui/core/styles/components';

const makeMuiCheckbox = (theme: Theme): Components['MuiCheckbox'] => ({
  styleOverrides: {
    root: {
      color: theme.palette.grey['400'],
      svg: {
        backgroundColor: theme.palette.grey['400'],
        transform: 'scale(.8)',
        transformOrigin: 'center',
        borderRadius: '6px',
      },
      path: {
        transform: 'scale(1.4)',
        transformOrigin: 'center',
      },
      '&.Mui-checked': {
        path: {
          color: theme.palette.secondary.main,
        },
        svg: {
          backgroundColor: theme.palette.grey['100'],
          color: theme.palette.secondary.main,
          boxShadow: `inset 1px 1px 1px ${theme.palette.secondary.main}`
        },
      },
    },
  },
});

export default makeMuiCheckbox;
