import { useEditor } from '@craftjs/core';
import { Stack } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { useDialog } from '../../../../../../components/Dialog';

export enum DeletableBlocks {
  button = 'botão',
  image = 'imagem',
  structure = 'estrutura',
  text = 'texto',
  video = 'vídeo',
}

const DeleteBlockButton = ({ id, name }: { id: string; name: DeletableBlocks }) => {
  const { actions } = useEditor();
  const dialog = useDialog();
  const deleteComponentHandler = async () => {
    const confirmed = await dialog.confirm({
      message: `Se excluir esse componente de ${name}, perderá todas as suas alterações.`,
      title: 'Você tem certeza?',
    });
    if (confirmed) {
      actions.delete(id);
    }
  };
  return (
    <Stack
      sx={{
        backgroundColor: 'info.main',
        height: 24,
        padding: 1,
        position: 'absolute',
        right: '-2px',
        top: '-1px',
        width: 24,
      }}
    >
      <DeleteIcon
        sx={{ color: 'grey.100', cursor: 'pointer', height: 16, width: 16 }}
        onClick={deleteComponentHandler}
      />
    </Stack>
  );
};

export default DeleteBlockButton;
