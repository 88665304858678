import { useMutation, useQuery } from '@apollo/client';
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import { useDialog } from '../../../../components/Dialog';
import { Tag } from '../../../../interfaces/Tag';
import createTagMutation from '../TagsContainer/gql/createTagMutation';
import listTagsQuery from '../TagsContainer/gql/listTagsQuery';
import validationSchema from './form';

const CreateTagForm = () => {
  const formatTagHandler = (tag: string) => {
    const formatedTag = tag
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    return formatedTag;
  };
  const alert = useAlert();
  const dialog = useDialog();
  const { data } = useQuery(listTagsQuery);
  const [createTag] = useMutation(createTagMutation);
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldError,
    values,
  } = useFormik<Pick<Tag, 'name'>>({
    initialValues: { name: '' },
    onSubmit: async ({ name }) => {
      const trimmedName = name.trim();
      const toLowerCaseAndNormalizedTag = trimmedName
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const allTagsNormalized = data.tags.map((tag: Tag) =>
        formatTagHandler(tag.name),
      );
      const duplicatedTag = allTagsNormalized.filter(
        (tagName: String) => tagName === toLowerCaseAndNormalizedTag,
      );
      if (duplicatedTag.length > 0) {
        setFieldError('name', 'Uma tag com este nome já existe');
      } else {
        try {
          await createTag({
            variables: { input: { name: trimmedName, slug: trimmedName } },
          });
          alert.success('Tag criada com sucesso!');
          dialog.close(true);
        } catch (err: any) {
          setFieldError('name', err);
        }
      }
    },
    validationSchema,
  });
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack>
          <InputLabel sx={{ color: 'grey.300', mb: 1, fontSize: 14 }}>
            Nome da tag
          </InputLabel>
          <OutlinedInput
            name="name"
            autoFocus
            placeholder="Digite aqui"
            inputProps={{ maxLength: 160 }}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.name && (
            <Typography sx={{ color: 'error.main', fontSize: 12, mt: 1 }}>
              {errors.name}
            </Typography>
          )}
        </Stack>
      </form>
    </>
  );
};

export default CreateTagForm;
