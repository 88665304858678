import {
  Button,
  Divider,
  InputAdornment,
  List,
  OutlinedInput,
  Stack,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { ChangeEvent, useState } from 'react';
import { Section } from '../../../interfaces/Plan';
import { compareTerms } from '../../../utils/string';
import { SortPositionDirection } from '../../SectionList/Context';
import sectionsPaginateQuery from '../gql/sectionsPaginateQuery';
import DialogsListItem from './DialogsListItem';

interface DialogSectionsProps {
  sections: Section[];
  onAdd: (sections: Section[]) => void;
}

const DialogSections = ({ onAdd, sections }: DialogSectionsProps) => {
  const { loading, data } = useQuery(sectionsPaginateQuery, {
    variables: {
      input: {
        filters: {
          sortPositionDirection: SortPositionDirection.minor,
          term: '',
        },
      },
    },
    fetchPolicy: 'no-cache',
  });
  const [query, setQuery] = useState('');
  const [selectedSections, setSelectedSections] = useState<Section[]>(sections);
  const queryHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setQuery(evt.target.value);
  if (loading) return <div>Carregando...</div>;
  const filteredSections = query
    ? data.sectionsPaginate.filter((section: Section) =>
        compareTerms(section.title, query),
      )
    : data.sectionsPaginate;
  const toggleSelectedSections = (section: Section, action: string) => {
    const isAdding = action === 'add';
    if (isAdding) {
      const newSectionsSelecteds = [...selectedSections, section];
      setSelectedSections(newSectionsSelecteds);
      onAdd(newSectionsSelecteds);
      return;
    }
    const newSectionsSelecteds = selectedSections.filter(
      (sectionCurrent) => sectionCurrent.id !== section.id,
    );
    setSelectedSections(newSectionsSelecteds);
    onAdd(newSectionsSelecteds);
  };
  const isSelected = (section: Section) => {
    const sectionSelectIndex = selectedSections.findIndex(
      (current) => current.id === section.id,
    );
    const sectionSelect = sectionSelectIndex !== -1;
    if (sectionSelect) return true;
    return false;
  };
  const selectAllSections = () => {
    setSelectedSections([...filteredSections]);
    return onAdd([...filteredSections]);
  };
  const removeAllSections = () => {
    setSelectedSections([]);
    return onAdd([]);
  };
  return (
    <>
      <OutlinedInput
        fullWidth
        inputProps={{ maxLength: 160 }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon sx={{ color: 'info.main' }} />
          </InputAdornment>
        }
        placeholder="Busque por uma seção"
        value={query}
        sx={{ my: 4 }}
        onChange={queryHandler}
      />
      <List>
        <Stack direction="row" gap={3}>
          <Button
            onClick={selectAllSections}
            sx={{
              width: 218,
            }}
          >
            Adicionar todas seções
          </Button>
          <Button
            color="secondary"
            onClick={removeAllSections}
            sx={{
              width: 218,
            }}
          >
            Remover todas seções
          </Button>
        </Stack>
        <Divider sx={{ borderColor: 'grey.500', my: 4 }} />
        {filteredSections.map((section: Section) => (
          <DialogsListItem
            items={section}
            select={isSelected(section)}
            onToggle={toggleSelectedSections}
          />
        ))}
      </List>
    </>
  );
};

export default DialogSections;
