import { DiscountType } from '../../../interfaces/Discount';
import updateCollectiveDiscountMutation from '../gql/UpdateCollectiveDiscountMutation';
import updateIndividualDiscountMutation from '../gql/UpdateIndividualDiscountMutation';
import updateInternalDiscountMutation from '../gql/UpdateInternalDiscountMutation';
import updateRetentionDiscountMutation from '../gql/UpdateRetentionDiscountMutation';

export const getUpdateMutationByType = (type: DiscountType) => {
  switch (type) {
    case DiscountType.Internal:
      return {
        mutation: updateInternalDiscountMutation,
      };
    case DiscountType.Coupon:
      return {
        mutation: updateIndividualDiscountMutation,
      };
    case DiscountType.Collective:
      return {
        mutation: updateCollectiveDiscountMutation,
      };
    case DiscountType.Retention:
      return {
        mutation: updateRetentionDiscountMutation,
      };
    default:
      return {
        mutation: updateRetentionDiscountMutation,
      };
  }
};
