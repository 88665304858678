import { gql } from '@apollo/client';

const createMultiSectionItemMutation = gql`
  mutation createMultiSectionItemMutation(
    $input: CreateMultiSectionItemInput!
  ) {
    createMultiSectionItem(input: $input) {
      id
    }
  }
`;

export default createMultiSectionItemMutation;
