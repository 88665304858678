import { Stack } from '@material-ui/core';
import { Widgets as WidgetsIcon } from '@material-ui/icons';
import SidebarAccordion from '../../components/SidebarAccordion';
import ButtonBlock from '../widgets/ButtonBlock';
import ImageBlock from '../widgets/ImageBlock';
import TextBlock from '../widgets/TextBlock';
import VideoBlock from '../widgets/VideoBlock';
import { Group as GroupProps } from './typings';

const Block = ({ expanded, onExpand }: GroupProps) => (
  <SidebarAccordion
    title="Blocos"
    expanded={expanded}
    icon={
      <WidgetsIcon
        sx={{
          color: 'secondary.main',
          height: 24,
          width: 24,
        }}
      />
    }
    onExpand={onExpand}
  >
    <Stack flexDirection="row" justifyContent="space-between">
      <TextBlock />
      <ImageBlock />
    </Stack>
    <Stack flexDirection="row" justifyContent="space-between">
      <VideoBlock />
      <ButtonBlock />
    </Stack>
  </SidebarAccordion>
);

export default Block;
