import {
  Card,
  CardContent,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/lab';
import { memo } from 'react';
import { ScheduleCardProps } from './typings';

const ScheduleCard = memo(
  ({
    error,
    name,
    label,
    value,
    SwitchProps,
    DateTimePickerProps,
    onChange,
  }: ScheduleCardProps) => {
    const datePickerHandler = (date: Date | null) =>
      onChange({ target: { name, type: 'text', value: date } });
    const onKeyPressHandler = (evt: React.KeyboardEvent<HTMLInputElement>) => {
      evt.preventDefault();
    };
    return (
      <Card sx={{ height: 160 }}>
        <CardContent>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              sx={{ color: 'grey.100', fontSize: 16, fontWeight: 600 }}
            >
              {label}
            </Typography>
            <Switch color="secondary" {...SwitchProps} />
          </Stack>
          <Typography sx={{ color: 'grey.300', fontSize: 14, mb: 1, mt: 4 }}>
            Escolha uma data{' '}
            <Typography
              component="span"
              sx={{ color: 'error.main', fontSize: 14 }}
            >
              *
            </Typography>
          </Typography>
          <DateTimePicker
            mask="__/__/__"
            inputFormat="dd/MM/yy HH:mm"
            disabled={!SwitchProps.checked}
            disablePast
            InputProps={{ error: !!error, onKeyPress: onKeyPressHandler }}
            renderInput={(props) => <TextField {...props} />}
            value={value}
            onChange={datePickerHandler}
            {...DateTimePickerProps}
          />
        </CardContent>
      </Card>
    );
  },
);

export default ScheduleCard;
