import { gql } from '@apollo/client';

const getPlansReportsAnalyseQuery = gql`
  query PlansReportsAnalyse($input: PlansReportsAnalyseInput!) {
    getPlansReportsAnalyse(input: $input) {
      totalLinkedUsersInPlan
      totalOferted
      totalCancelledPlans
      totalActiviesPlans
      getIntervalRetentionDiscount
      credit
      debit
      pix
      trialDays
      countTrialDaysCancelled
    }
  }
`;

export default getPlansReportsAnalyseQuery;
