import { createContext } from 'react';

type ContextType = {
  id?: string,
  form: any,
  refetch?: () => void,
  isUpdating?: boolean,
}

const Context = createContext<ContextType>({} as ContextType);

export default Context;
