import { gql } from '@apollo/client';

const updateManagerMutation = gql`
  mutation UpdateManagerMutation($input: UpdateManagerInput!) {
    updateManager(input: $input) {
      id
      name
      email
      thumbnail
      enabled
      role {
        id
        title
        color
        enabled
      }
    }
  }
`;

export default updateManagerMutation;
