import { Button, Stack, Typography } from '@material-ui/core';
import { LocalOffer as TagsIcon } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../../components/Dialog';

const EmptyTeams = () => {
  const dialog = useDialog();
  const navigate = useNavigate();
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ mb: 7, mt: 5 }}>
      <TagsIcon sx={{ color: 'secondary.dark', height: 36, width: 36 }} />
      <Typography
        sx={{ color: 'common.white', fontSize: 16, fontWeight: 600, mt: 2 }}
      >
        Nenhum time encontrado
      </Typography>
      <Typography
        align="center"
        sx={{
          color: 'grey.300',
          fontSize: 14,
          mt: 2,
          width: 284,
        }}
      >
        Você pode criar mais times na área de Times, clicando no botão abaixo:
      </Typography>
      <Button
        sx={{ mt: 2 }}
        onClick={() => {
          dialog.close();
          navigate('/teams');
        }}
      >
        Times
      </Button>
    </Stack>
  );
};

export default EmptyTeams;
