import { Stack, Typography } from '@material-ui/core';
import { PlayArrow as PlayIcon } from '@material-ui/icons';

const EmptyListCard = ({ description }: { description: string }) => (
  <Stack alignItems="center" justifyContent="center">
    <PlayIcon sx={{ color: 'info.main', fontSize: 48 }} />
    <Typography
      sx={{
        color: 'common.white',
        fontSize: 20,
        fontWeight: 600,
        mb: 6,
        mt: 5,
      }}
    >
      {description}
    </Typography>
  </Stack>
);

export default EmptyListCard;
