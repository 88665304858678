import { Checkbox, Chip, ListItem, ListItemText } from '@material-ui/core';
import {
  Check as CheckIcon,
  Delete as DeleteIcon,
  Restore as RestoreIcon,
} from '@material-ui/icons';
import { getPageTypeFriendlyName, Page } from '../../../../interfaces/Plan';

interface PageItemProps {
  page: Page;
  checked: boolean;
  onDeletePages: (page: Page[]) => void;
  onToggleCheckPage: (page: Page) => void;
}

const PageItem = ({
  checked,
  page,
  onToggleCheckPage,
  onDeletePages,
}: PageItemProps) => (
  <ListItem
    data-testid="page-item"
    secondaryAction={
      <DeleteIcon
        data-testid="icon-delete-page"
        sx={{ color: 'grey.300', cursor: 'pointer', mr: 0 }}
        onClick={() => onDeletePages([page])}
      />
    }
    sx={{
      border: checked ? '2px solid #2F49D1' : '2px solid transparent',
      borderRadius: 2,
      height: 88,
      mb: 2,
      p: 0,
      ':nth-child(even)': {
        bgcolor: 'grey.600',
      },
    }}
  >
    <Checkbox
      data-testid="checked-page"
      checked={checked}
      onClick={() => onToggleCheckPage(page)}
      color="secondary"
      sx={{ p: 0 }}
    />
    <ListItemText
      primary={page.title}
      primaryTypographyProps={{
        sx: {
          color: 'common.white',
          fontSize: 16,
          fontWeight: 600,
          mb: 3,
        },
      }}
      secondary={
        <>
          <Chip
            color={page.published ? 'success' : 'info'}
            icon={page.published ? <CheckIcon /> : <RestoreIcon />}
            variant="outlined"
            label={page.published ? 'Publicado' : 'Agendado'}
            size="small"
            sx={{
              pl: 2,
              mr: 4,
            }}
          />
          <Chip
            label={getPageTypeFriendlyName(page.type)}
            variant="outlined"
            size="small"
            sx={{
              bgcolor: 'grey.500',
              color: 'grey.100',
            }}
          />
        </>
      }
      sx={{ m: 0, ml: 4 }}
    />
  </ListItem>
);

export default PageItem;
