import { keyframes } from '@emotion/react';
import { Button, Card, Stack, Typography } from '@material-ui/core';
import {
  Launch as LaunchIcon,
  Sensors as SensorsIcon,
} from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Highlight } from '../../../interfaces/Highlight';

interface LiveSectionItemProps {
  liveSection: Highlight;
}

const liveAnimation = keyframes`
  to {
    opacity: 0;
  }
`;

const LiveSectionItem = ({ liveSection }: LiveSectionItemProps) => {
  const navigate = useNavigate();
  const manageLiveSection = () => navigate('/section/lives');
  return (
    <Card>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ height: 72, p: 2, pl: 4 }}
      >
        <Stack alignItems="center" direction="row" gap={3}>
          <Typography
            sx={{
              alignItems: 'center',
              bgcolor: 'grey.500',
              borderRadius: 1,
              border: '1px solid',
              borderColor: 'grey.100',
              color: 'grey.100',
              display: 'flex',
              fontSize: 24,
              height: 40,
              justifyContent: 'center',
              ml: '44px',
              width: 40,
            }}
          >
            {liveSection.position + 1}
          </Typography>
          <SensorsIcon
            color="error"
            sx={{
              opacity: 1,
              animation: `${liveAnimation} 1s ease infinite`,
            }}
          />
          <Typography
            noWrap
            sx={{
              color: 'common.white',
              fontSize: 16,
              marginRight: 56,
              maxWidth: 700,
            }}
          >
            Ao vivo
          </Typography>
        </Stack>
        <Button
          size="large"
          startIcon={<LaunchIcon color="info" />}
          sx={{
            bgcolor: 'grey.500',
            color: 'grey.200',
            mr: 2,
          }}
          onClick={manageLiveSection}
        >
          Gerenciar
        </Button>
      </Stack>
    </Card>
  );
};

export default LiveSectionItem;
